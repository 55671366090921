<template>
    <div>
        <data-table
                @select_item_id="select_item_id"
                :show_delete_action="false"
                :show_edit_action="false"
                :show_select="true"
                :selected_item_ids="selected_ids"
                :items="users" :headers="headers"></data-table>
    </div>

</template>

<script>
  import DataTable from "@/components/DataTable";
  export default {
    name: "UserList",
    components: {DataTable},
    methods: {
      select_item_id(select_item_id) {
        this.$emit('select_users_ids', select_item_id)
      }
    },
    computed: {
      'headers' () {
        return [{ text: 'Email', value: 'email' }];
      }
    },
    props: {
      'selected_ids': {required: true},
      'users': {required: true}
    },
  }
</script>

<style scoped>

</style>