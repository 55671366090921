<template>
  <row v-if="current_user">
    <loader :loading="loading"></loader>
    <col4 style="max-width: 400px">

      <avataaars v-if="this.properties.top.value" style="max-width: 350px"
              :topType="this.properties.top.value"
              :accessoriesType="this.properties.accessoire.value"
              :facialHairType="this.properties.hair.value"
              :clotheType="this.properties.clothes.value"
              :eyeType="this.properties.eyes.value"
              :eyebrowType="this.properties.eye_brown.value"
              :mouthType="this.properties.mouth.value"
              :skinColor="this.properties.skin_color.value"
              :graphicType="this.properties.graphic_type.value"
              :hairColor="this.properties.hair_color.value"
              :facialHairColor="this.properties.hair_color.value"
              :topColor="this.properties.top_color.value"
              :clotheColor="this.properties.clothes_color.value">
      </avataaars>
    </col4>
    <col8>
      <row v-if="gravatar_initialized && this.properties.top.value" >
        <col12>
        <property-chooser v-for="prop_type in property_types"
                          :current_properties="properties_encoded"
                          :type="prop_type"
                          :key="properties[prop_type].description"
                          :description="typeToDescription(prop_type)"
                          :value="properties[prop_type].value"
                          :options="properties[prop_type].options"
                          @update_prop="update_prop(prop_type, $event)"></property-chooser>
        </col12>
      </row>
    </col8>
  </row>
</template>

<script>

import GravatarMixin from "@/components/users/GravatarMixin";
import PropertyChooser from "@/components/users/PropertyChooser";
import Col4 from "@/components/layout/Col4";
import Col8 from "@/components/layout/Col8";
import Row from "@/components/layout/Row";
import Avataaars from "@/components/users/Avataars";
import Loader from "@/components/layout/loader";
import Col12 from "@/components/layout/Col12";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

export default {
  mixins: [GravatarMixin, CurrentUserMixin],
  components: {Col12, Loader, PropertyChooser, Col4, Col8, Row, Avataaars},
  data () {
    return {
      'edit_mode': false,
      'loading': false,
    }
  },
  watch: {
    current_user() {
      if(this.current_user) {
        this.init();
      }
    },
    gravatar_initialized() {
      this.$set(this, 'loading', false)
    },
    properties_encoded() {
      this.update();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.current_user) {



        //initialize from set propties dict
        let property_types = Object.keys(this.properties)
        for (let i = 0; i < property_types.length; i++) {
          let property_type = property_types[i];
          let set_value = this.current_user.avatar_properties ? this.current_user.avatar_properties[property_type]: false;
          if (set_value) {
            this.update_prop(property_type, set_value)
          } else {
            this.update_prop(property_type, this.get_random_value(property_type))
          }
        }
      } else {
        //initialize random
        let property_types = Object.keys(this.properties)
        for (let i = 0; i < property_types.length; i++) {
          let property_type = property_types[i];
          this.update_prop(property_type, this.get_random_value(property_type))
        }
      }
    },
    toggle_edit() {
      this.$set(this, 'edit_mode', !this.edit_mode)
    },
    update() {
      this.$emit('update', this.properties_encoded)
    }
  }
}
</script>

<style scoped>

</style>