import actions from '@/store/modules/snackbars/actions.js'
import mutations from '@/store/modules/snackbars/mutations.js'
import getters from '@/store/modules/snackbars/getters.js'

const state = {
  snacks: [],
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}