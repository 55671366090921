<template>
  <div>
    <v-snackbar
      :style="{ bottom: calcMargin(i), 'margin-right': '10px' }"
      :color="item.color"
      :absolute="true"
      :key="item.id"
      :top="true"
      :right="true"
      :timeout="item.timeout"
      :value="true"
      v-for="(item, i) in items"
    >
      <span
        v-if="item.redirect"
        :style="{ cursor: item.redirect ? 'pointer' : 'default' }"
        @click.stop="redirect(item)"
      >{{ item.message }}</span>
      <span
        v-else
      >{{ item.message }}</span>
      <template v-slot:action="{ }">
        <v-btn
          v-if="item.persistent"
          color="white"
          text
          @click="removeItem(item.id)"
        >
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
/**
 * A wrapper component for VSnackbar which supports queueing functionality
 */
export default {
  name: 'VSnackbarQueue',
  data: () => ({
    processing: false,
    timeoutId: false
  }),
  props: {
    /**
     * Position snackbar absolute
     */
    absolute: {
      type: Boolean,
      default: false
    },
    /**
     * @deprecated since v2.0.0
     *
     * Auto height prop for snackbar
     */
    autoHeight: {
      type: Boolean,
      default: false
    },
    /**
     * Position snackbar bottom
     */
    bottom: {
      type: Boolean,
      default: false
    },
    /**
     * The color of the close button
     */
    closeButtonColor: {
      type: String,
      default: 'white'
    },
    /**
     * The icon of the close button
     */
    closeButtonIcon: {
      type: String,
      default: 'close'
    },
    /**
     * Array for items to display [{id: '', color: '', message: ''}]
     */
    items: {
      type: Array,
      required: true
    },
    /**
     * Position snackbar left
     */
    left: {
      type: Boolean,
      default: false
    },
    /**
     * Position snackbar multiline
     */
    multiLine: {
      type: Boolean,
      default: false
    },
    /**
     * The color of the next button
     */
    nextButtonColor: {
      type: String,
      default: 'white'
    },
    /**
     * The text displayed next to the count of snackbars
     */
    nextButtonCountText: {
      type: String,
      default: 'More'
    },
    /**
     * The text to display in the next button
     */
    nextButtonText: {
      type: String,
      default: 'Next'
    },
    /**
     * Position snackbar right
     */
    right: {
      type: Boolean,
      default: false
    },
    /**
     * Number of milliseconds to display each snackbar for
     */
    timeout: {
      type: Number,
      default: 1500
    },
    /**
     * Position snackbar top
     */
    top: {
      type: Boolean,
      default: true
    },
    /**
     * Position snackbar vertical
     */
    vertical: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    processItems () {
      const vm = this
      vm.processing = true

      if (vm.items && Array.isArray(vm.items) && vm.items.length > 0) {
        const item = vm.items[0]
        if (!item.persistent) {
          return vm.timeoutId = setTimeout(() => {
            vm.removeItem(item.id)
          }, item.timeout)
        }
      }

      vm.processing = false
    },
    calcMargin(i) {
      return (i * 60) + 'px'
    },
    removeItem (id) {
      const vm = this
      clearTimeout(vm.timeoutId)
      this.$store.dispatch('snackbars/remove', id)
    },
    redirect(item) {
      if (item.redirect) {
        this.$router.push(item.redirect);
        this.removeItem(item.id);
      }
    }
  },
  watch: {
    items () {
      this.processItems()
    }
  }
}
</script>
