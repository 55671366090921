<template>
  <modal :title="$t('progress.boxes_modal_title') + label_name" @close_modal="close" :loading="loading">
    <template v-slot:buttons>
    </template>
    <template v-slot:content>
      <grid @reload="load" :items="images" :no_toggle="true" v-on:remove_item="removeGridItem"></grid>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Grid from "@/components/Grid";
export default {
name: "MyBoxesModal",
  components: {Grid, Modal},
  props: {
    label_name: {required: true},
  },
  data () {
    return {
      loading: false,
      images: [],
      max_results: null,
    }
  },
  mounted() {
    let filters = [
      {'type':1, 'value':this.label_name}, //Contains label
      {'type':9, 'value':'Yes'}, //validated
    ]
    this.load(filters, 12);
  },
  methods: {
    async load(filters, limit) {

      if(!filters) {
        filters = this.filters
        limit = this.limit
      }

      this.images = [];
      this.$set(this, 'loading', true)

      let results = await this.$api.annotation.filter(filters, limit);
      let total_results = results.total;
      let items = results.items
      let max_results = results.max_results;

      this.$set(this, 'max_results', max_results);

      if (items.length === 0) {
        this.$set(this, 'max_results', 0);
        this.$set(this, 'loading', false)
      }

      for (let i = 0; i < items.length; i++) {

        let r = items[i]
        let url = r['url'];
        let asset_id = r['asset_id'];
        let bbox = r['bbox'];
        let bbox_id = r['bbox_id'];
        let certainty = r['certainty']
        let meta = r['meta']

        let data = await this.$api.asset.get_crop(url, bbox, asset_id);
        let cropData = data.crops.map((crop) => {
          return {
            meta: meta,
            data: crop.crop,
            certainty: certainty,
            bbox_id: bbox_id,
            label: crop.label,
            id: asset_id
          }
        })
        this.images = this.images.concat(cropData);

        if (this.images.length === total_results) {
          this.$set(this, 'loading', false)
        }
      }
    },
    removeGridItem(asset_id) {
      let filteredImages = this.images.filter((image) => {
        return image.id !== asset_id
      });
      this.$set(this, 'images', filteredImages);
    },
    close() {
      this.$emit('close_modal')
    },
  }
}
</script>

<style scoped>

</style>