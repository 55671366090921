export default class Asset {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async filter_options() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.project_id);
        return this.api.post_with_token('/filter-options', formData)
    }

    async validate(asset_id, bbox_id, model_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        formData.append('bbox_id', bbox_id);
        formData.append('model_id', model_id);
        return this.api.post_with_token('/validate-asset', formData)
    }

    async file(asset_id) {
        return this.api.get('/download-asset/' + asset_id)
    }

    async get_crop_for_conf_items(type, items) {
        let formData = new FormData();
        if(type.includes('confused_with_background')) {
            type = 'confused_with_background';
        }
        formData.append('type', type);
        formData.append('items', items);
        return this.api.post_with_token('/get_crop_for_conf_items', formData)
    }

    async get_crop_for_bbox(bbox_id) {
        let formData = new FormData();
        formData.append('bbox_id', bbox_id);
        return this.api.post_with_token('/get_crop_for_bbox', formData)
    }

    async get_crop(url, bbox, asset_id) {
        let formData = new FormData();
        formData.append('url', url);
        formData.append('bbox', bbox);
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/get_crop', formData)
    }

    async save(asset_id, annotations, in_validation_set) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.project_id);
        formData.append('asset_id', JSON.stringify(asset_id));
        formData.append('annotations', JSON.stringify(annotations));
        if (in_validation_set !== null) {
            formData.append('in_validation_set', in_validation_set);
        }
        return this.api.post_with_token('/store', formData)
    }

    async add_to_validation_set(asset_ids) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(asset_ids));
        return this.api.post_with_token('/add-to-validation-set', formData)
    }

    async add_filter_to_validation_set(filters) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.project_id);
        formData.append('filters', JSON.stringify(filters));
        return this.api.post_with_token('/add-filters-to-validation-set', formData)
    }

    async remove_from_validation_set(asset_ids) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(asset_ids));
        return this.api.post_with_token('/remove-from-validation-set', formData)
    }

    async delete(asset_ids) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(asset_ids));
        return this.api.post_with_token('/assets/delete', formData)
    }

    async delete_assets_in_filter(filters) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.project_id);
        formData.append('filters', JSON.stringify(filters));
        return this.api.post_with_token('/assets/delete-in-filter', formData)
    }

    async analyse_selected(params) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(params.asset_ids));
        formData.append('model_id', params.model.id);
        return this.api.post_with_token('/analyse-assets', formData)
    }

    async analyse_with_filter(params) {
        let model = params.model;
        let filter = params.filter;
        let clear_existing = params.clear_existing;
        let clear_existing_model = params.clear_existing_model;
        let include_descendents = params.include_descendents;

        let formData = new FormData();
        formData.append('filter', JSON.stringify(filter));
        formData.append('model_id', model.id);
        if(params.crop_on) {
            formData.append('crop_on', params.crop_on);
        }
        if(params.limit) {
            formData.append('limit', params.limit);
        }

        formData.append('clear_existing', clear_existing);
        formData.append('include_descendents', include_descendents);
        formData.append('clear_existing_model', clear_existing_model);
        formData.append('project_id', sessionStorage.project_id);
        return this.api.post_with_token('/analyse-assets-for-filter', formData)
    }

    async filter(filter, limit) {
        let formData = new FormData();
        formData.append('filters', JSON.stringify(filter));
        formData.append('limit', limit);
        formData.append('project_id', sessionStorage.getItem('project_id')
        );

        return this.api.post_with_token('/filter-assets', formData)
    }

    async number_of_validation_images_for_train_config(train_config_id) {
        let formData = new FormData();
        formData.append('train_config_id', train_config_id);
        return this.api.post_with_token('/assets/number-of-validation-images-for-train-config', formData)
    }

    async mark_invalidated(asset_ids) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(asset_ids));
        return this.api.post_with_token('/assets/invalidate', formData)
    }

    async mark_unclear(asset_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/assets/mark-unclear', formData)
    }

    async load(asset_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/assets/load', formData)
    }

    async get(asset_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/asset', formData)
    }

    async mark_validated(asset_ids) {
        let formData = new FormData();
        formData.append('asset_ids', JSON.stringify(asset_ids));
        return this.api.post_with_token('/assets/validate', formData)
    }

    async mark_filter_validated(filters) {
        let formData = new FormData();
        formData.append('filters', JSON.stringify(filters));
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/assets/validate-filters', formData)
    }
    
    async accept_suggestions(asset_id, suggestion_ids) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        formData.append('suggestion_ids', JSON.stringify(suggestion_ids));
        return this.api.post_with_token('/asset/apply-suggestions', formData)
    }

    async discard_all_suggestions(asset_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/asset/discard-all-suggestions', formData)
    }

    async next_asset(filters) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('limit', '1');
        formData.append('filters', filters);
        return this.api.post_with_token('/next', formData)
    }


}