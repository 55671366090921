<template>
  <settings-menu-layout>
    <row12>
      <row12>
        <v-btn color="primary" @click.native="add">{{$t('settings_tasks.btn_create')}}</v-btn>
      </row12>
      <task-list :tasks="tasks"></task-list>
    </row12>
  </settings-menu-layout>
</template>

<script>
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import LoadTasksMixin from "@/mixins/LoadTasksMixin";
  import Row12 from "@/components/layout/Row12";
  import TaskList from "@/components/tasks/TaskList";

  export default {
    name: "SettingsTasks",
    mixins: [LoadTasksMixin],
    data() {
      return {
      }
    },
    methods: {
      async add() {
        await this.$store.dispatch('task/add_new')
      },
      set_loading(loading) {
        this.$set(this, 'loading', loading)
      },
      select_item(page) {
        this.$set(this, 'selected_page', page)
      },
    },
    components: {
      Row12,
      SettingsMenuLayout,
      TaskList},
  }
</script>

<style scoped>

</style>