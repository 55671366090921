
export default {
    add_snack: function (state, payload) {
        if(!payload.timeout && !payload.persistent) {
            payload.timeout = 1500;
        }
        state.snacks.push(payload)
    },
    clear_snacks: function (state) {
        state.snacks = []
    },
    remove_snack: function (state, payload) {
        let snackIds = state.snacks.map(snack => snack.id)
        let index = snackIds.indexOf(payload)
        if (index > -1) {
            state.snacks.splice(index, 1)
        }
    }
}