<template>
        <div class="crop_container">
          <img @click="select_asset" style="width : 100%" :src="'data:image/png;base64, ' + image_data" />
          <div v-if="annotation_type === 2" class="centered white-font">+</div>
          <div v-if="annotation_type === 2" class="centered black-font">+</div>
        </div>

</template>


<script>
// import Checkbox from '@/components/Checkbox'
export default {
  //components: {Checkbox},
  props: {
    image_data: {required: true},
    annotation_type: {required: true},
  },
  methods: {
    select_asset() {
      this.$emit('select_asset')
    },
    toggleChecked() {
      this.$emit('select_toggled', this.image.bbox_id)
    },
    selectImage(assetId, bboxId) {
      this.$emit('select_item', assetId, bboxId)
    },
    remove(assetId, bboxId) {
      this.$emit('remove_item', assetId, bboxId)
    },
  },
  computed: {
    category () {
      if (this.image.translation) {
        return this.image.translation;
      }
      else {
        return this.image.label;
      }
    },
    background_color () {
        let red = "rgba(249,65,68)";
        let green = "rgba(144,190,109)"
        let orange = "rgba(248,150,30)"

        let validation = this.validation
        if(!validation) {
          return ''
        }
        
        if(validation.startsWith('Correct')) {
          return green;
        }
        else if(validation.startsWith('False negative')) {
          return orange;
        }
        else {
          return red;
        }
    }
  },
}
</script>

<style scoped>
  .crop_container {
    position: relative;
    text-align: center;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .white-font {
    font-size: 25px;
    color: rgba(255, 255, 255, 0.4);
  }
  .black-font {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.3);
  }
</style>