<template>
    <v-col
      cols="12"
      md="4"
    >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: "Col4"
}
</script>

<style scoped>

</style>