<template>
  <div>
    <div v-for="button in model_buttons" style="float:left" :key="button.id">
      <model-button
          @click.native="on_click(button)"
          :selected="selected_model_type===button.id"
          :name="button.name"
          :disabled="disabled"></model-button>
    </div>
  </div>
</template>

<script>
import ModelButton from "@/components/train_configs/ModelButton.vue";

export default {
  name: "ModelButtons",
  components: {ModelButton},
  props: {
    model_buttons: {required: true},
    selected_model_type: {required: true},
    disabled: {type: Boolean, default:false},
  },
  methods: {
    on_click(button) {
      this.$emit('on_click', button)
    }
  },
}
</script>

<style scoped>

</style>