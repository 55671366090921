<template>
  <menu-layout></menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";

export default {
  name: "Unauthorized",
  mixins: [],
  data() {
    return {
    }
  },
  methods: {
  },
  components: {
    MenuLayout,
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>