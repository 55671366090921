<script>
export default {
name: "LoadLabelMixin"
  ,
  data() {
    return {
      settings_loaded: false
    }
  },
  mounted() {
    this.load_settings();
  },
  computed: {
    settings () {
      return this.$store.getters['setting/get_items']
    },
    'instances_enabled'() {
      return this.$store.getters['setting/get_setting']('instances_enabled')
    },
  },
  methods: {
    async load_settings() {
      if(this.on_profile_page) {
        return;
      }
      if(this.on_unauthenticated_pages) {
        return;
      }
      if(this.on_password_reset_page) {
        return;
      }
      if(this.on_project_page) {
        return;
      }
      await this.$store.dispatch('setting/all')
      this.$set(this, 'settings_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>