<template>
    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
             <v-list-item
                  link
                  style="margin-top: 1px; "
                  @click.native="open"
                  v-bind="attrs"
                  v-on="on"
                  :id="id"
                  :style="{ boxShadow: isActive ? '0 0 5px rgba(0, 0, 0, 0.3)' : 'none' }"
                >
                  <v-list-item-icon>
                    <v-icon :style="{ opacity: isActive ? '1' : '0.8', width: '22px', color: 'whitesmoke' }">{{icon}}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title style="opacity: 0.8; color: whitesmoke; font-size: 1.0em" >{{name.toUpperCase()}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
        </template>
        <span>{{name.toUpperCase()}}</span>
    </v-tooltip>
</template>

<script>
export default {
  name: "MainSideMenuItem",
  props: {
    'name': {required: true},
    'link': {required: true},
    'icon': {default: false},
    'id': {default: 'no-id-set'},
    'isActive': { type: Boolean, default: false }
  },
  methods: {
    open() {
      if (this.$route.path !== this.link) {
        this.$router.push(this.link);
      }
    }
  }
}
</script>

<style scoped>
</style>
