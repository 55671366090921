<template>
    <v-btn
            class="ma-2"
            text
            icon
            color="primary"
            x-small
            @click.native="pressed"
    >
        <v-icon>far fa-trash-alt</v-icon>
    </v-btn>
</template>

<script>
  export default {
    name: "DeleteButton",
    props: {
      disabled: {default: false}
    },
    methods: {
      pressed () {
        this.$emit('pressed')
      }
    }
  }
</script>

<style scoped>

</style>