import { render, staticRenderFns } from "./ProfileUpgradeSubscription.vue?vue&type=template&id=4d95ceec&scoped=true"
import script from "./ProfileUpgradeSubscription.vue?vue&type=script&lang=js"
export * from "./ProfileUpgradeSubscription.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d95ceec",
  null
  
)

export default component.exports