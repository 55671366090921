<template>
  <div>
    <train-menu-layout>
      <train-workers :workers="workers"></train-workers>
      <train-model v-if="model_type"
              :model_type="model_type"></train-model>
    </train-menu-layout>
  </div>
</template>

<script>

import TrainModel from "@/components/models/TrainModel";
import TrainWorkers from "@/components/train/TrainWorkers";
import TrainMenuLayout from "@/components/layout/TrainMenuLayout";

export default {
  name: "TrainPage",
  mixins: [],
  components: {
    TrainMenuLayout,
    TrainWorkers, TrainModel},
  data() {
    return {
      'selected_page': 'boxes.models',
      'loading': false,
      'workers': [],
      jobs: []
    }
  },
  computed: {
    type() {
      return this.$route.params.model_type
    },
    model_type() {
      if(this.type === 'boundingbox') {
        return 'Boundingbox'
      }
      else if(this.type === 'classifier') {
        return 'Classifier'
      }
      else if(this.type === 'keypoints') {
        return 'Keypoints'
      }
      return false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let workers = await this.$api.worker.get_active()
      this.$set(this, 'workers', workers);
    },
    set_loading(value) {
      this.$set(this, 'loading', value)
    }
  }
}
</script>

<style scoped>

</style>