<template>
    <v-overlay id="v-page-loader" v-if="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
name: "loader",
  props: {
  'loading': {required: true}
  }
}
</script>

<style scoped>

</style>