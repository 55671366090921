<template>
  <menu-layout>
    <jobs :jobs="jobs" @refresh="load_jobs" :message="$t('import_data.message')"></jobs>
    <v-divider></v-divider>
    <div v-if="image_limit_reached">
      <show-alert :alert-message="assetLimitReachedMessage"></show-alert>
    </div>
    <row>
      <col6>
        <h4>{{$t('import_data.upload_title')}}</h4>
        <multi-file-uploader
            :post_url="api_host + '/multi_images_import'"
            :params="{
              'description': this.multi_files_description,
              'validated': this.multi_mark_validated,
              'run_model': multi_run_model_on,
              'validation_set': multi_validation_set,
              'label': multi_select_label ? multi_select_label.name : false
            }"
            :upload_trigger="multi_upload_trigger"
            :trigger_reset_form="multi_trigger_reset_form"
            @multi-upload-complete="handleMultiUploadComplete"
            @image-limit-reached="handleImageLimitReached"
            @success-messages="handleUploadSuccess"
            @failure-messages="handleUploadFailure"
            @files-selected="handleFilesSelected"
            @progress-changed="handleProgressChanged"
            @chunk-progress-changed="handleChunkProgressChanged"
            
            ></multi-file-uploader>
          <div :style="{ 'opacity': (!filesSelected) ? '0.5' : '1', 'pointer-events': (!filesSelected) ? 'none' : 'auto' }">          <div>
            {{$t('import_data.upload_description')}}
            <info-icon :text="$t('import_data.description_info_tooltip')"></info-icon>
            <row>
              <col12>
                <div style="width: 100%;">
                  <div style="display: flex; align-items: center;">
                    <v-icon
                      @click="$refs.descriptionField.focus()"
                      class="mdi mdi-pencil"
                      style="cursor: pointer; margin-right: 10px; color: #757575;"
                    ></v-icon>
                    <v-text-field
                      v-model="multi_files_description"
                      style="width: 100%;"
                      ref="descriptionField"
                    />
                  </div>
                </div>
              </col12>
            </row>
          </div>
        </div>

        <div>
          <row>
            <col12>
              <v-btn color="primary" disabled v-if="image_limit_reached || multi_upload_started || multi_files_description === ''">{{$t('import_data.button_upload')}}</v-btn>
              <v-btn color="primary" @click.native="multiUploadStart" v-else >{{$t('import_data.button_upload')}}</v-btn>
            </col12>
          </row>
        </div>

        <div>
          <h4 v-if="filteredChunkProgress.length > 0">Processing file: {{ chunkProgress.length }}</h4>
          <div v-for="(progress, index) in filteredChunkProgress" :key="index" class="d-flex align-center">
            <v-progress-circular
              indeterminate
              size="32"
              class="mr-3"
            ></v-progress-circular>
            <v-progress-linear :value="progress" height="20" rounded class="flex-grow-1">
              <strong>{{ Math.ceil(progress) }}%</strong>
            </v-progress-linear>
          </div>
        </div>

        <div >
          <row>
            <col12>
              <h4 v-if="progress > 0">Overall progress </h4> 
              <!-- {{$t('import_data.upload_status')}} -->
              <v-progress-linear v-if="progress > 0" :value="progress" height="20" rounded>
                <strong>{{ Math.ceil(progress) }}%</strong>
              </v-progress-linear>
            </col12>
          </row>
        </div>

        <div v-if="uploadSuccessMessages && uploadSuccessMessages.length > 0">
          <v-alert type="success" class="mt-4"> 
            {{$t('import_data.upload_successful')}} {{ uploadSuccessMessages.length }} {{$t('import_data.files')}} 
          </v-alert>
        </div>

        <div v-if="uploadFailureMessages && uploadFailureMessages.length > 0">
          <v-alert type="error" v-for="(failure, index) in uploadFailureMessages" :key="index" class="mt-4"> 
            <v-row align="center">
              <v-col>
                {{$t('import_data.upload_error')}} {{ failure.filename }}.
              </v-col>
              <v-col>
                {{ failure.error }}
              </v-col>
            </v-row>
          </v-alert>
        </div>
      </col6>
      <col6 :style="{ 'opacity': (!filesSelected || multi_files_description === '') ? '0.5' : '1', 'pointer-events': (!filesSelected) ? 'none' : 'auto' }">
        <h4 @click="toggleExtraOptions" style="cursor: pointer;">{{$t('import_data.extra_options')}}</h4>
        <v-icon v-if="!showExtraOptions"
          @click.native="toggleExtraOptions"
          class="mdi mdi-plus"
          style="cursor: pointer; margin-right: 10px; font-size: 24px; color: #757575;"
        ></v-icon>
        <div v-if="showExtraOptions">
          <row class="row">
            <col12>
              <checkbox v-model="multi_mark_validated" :label="$t('import_data.checkbox_mark_validated')"></checkbox>
            </col12>
            <col12>
              <checkbox v-model="multi_validation_set" :label="$t('import_data.checkbox_validation_set')"></checkbox>
            </col12>
            <col12>
              <autocomplete
                  :placeholder="$t('import_data.autocomplete_label')"
                  :options="labels"
                  :clear_on_select="false"
                  @select="on_multi_select_label"
              ></autocomplete>
              {{$t('import_data.assign_label')}} <br />
              <search-label-autocomplete
              @on_select="multi_select_label"></search-label-autocomplete>
            </col12>
          </row>
        </div>
      </col6>
    </row>
  </menu-layout>
</template>

<script>

import Jobs from "@/components/Jobs.vue";
import MultiFileUploader from "@/components/MultiFileUploader.vue";
import Checkbox from "@/components/Checkbox";
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import Col6 from "@/components/layout/Col6";
import Col12 from "@/components/layout/Col12";
import dayjs from "dayjs";
import Autocomplete from "@/components/Autocomplete";
import SearchLabelAutocomplete from "@/components/SearchLabelAutocomplete.vue";
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import ShowAlert from "@/components/ShowAlert.vue";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
import InfoIcon from '@/components/notifications/InfoIcon.vue';
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

export default {
  mixins: [LoadProjectsMixin, LoadLabelsMixin, CurrentUserMixin],
  name: "Import.vue",
  components: {
    SearchLabelAutocomplete,
    Autocomplete, Col12, Col6, Row, MenuLayout, Checkbox, MultiFileUploader, Jobs, ShowAlert,
    InfoIcon},
  data: function () {
    return {
      model_upload_started: false,
      model_description: '',
      model_upload_trigger: 1,
      model_upload_trigger_reset: 1,
      model_upload_type: 'YoloV5',
      task: 'import',
      trigger_reset_form: 1,
      jobs: [],
      csv_run_model_on: false,
      multi_run_model_on: false,
      multi_files_description: '',
      multi_trigger_reset_form: 1,
      multi_upload_trigger: 1,
      multi_mark_validated: false,
      multi_upload_started: false,
      multi_validation_set: false,
      multi_select_label: false,
      image_limit_reached: false, 
      uploadSuccessMessages: [],
      uploadFailureMessages: [],
      filesSelected: false,
      showExtraOptions: false,
      progress: 0,
      chunkProgress: [],
    }
  },
  methods: {
    handleChunkProgressChanged(chunkProgress) {
      this.chunkProgress = chunkProgress;
    },
    handleProgressChanged(newProgress) {
      this.progress = newProgress;
    },
    toggleExtraOptions() {
      this.showExtraOptions = !this.showExtraOptions;
    },
    handleFilesSelected(filesSelected) {
      this.$set(this, 'filesSelected', filesSelected);
    },
    handleUploadSuccess(messages) {
      this.uploadSuccessMessages = messages;
    },
    handleUploadFailure(messages) {
      this.uploadFailureMessages = messages;
    },
    handleImageLimitReached() {
      this.image_limit_reached = true; 
    },
    on_multi_select_label(label) {
      this.$set(this, 'multi_select_label', label);
    },
    multiUploadStart() {
      this.$set(this, 'multi_upload_started', true);
      this.$set(this, 'multi_upload_trigger', this.multi_upload_trigger + 1)
    },
    startModelUpload() {
      this.$set(this, 'model_upload_started', true);
      this.$set(this, 'model_upload_trigger', this.model_upload_trigger + 1)
    },
    handleMultiUploadComplete() {
      this.$set(this, 'multi_upload_started', false);
    },
    upload_completed() {
      this.load_jobs();
    },
    async load_jobs() {
      let data = await this.$api.job.get_active_jobs(this.task);
      let jobs = data.filter(job=> {
        let created_at = dayjs(job.created_at)
        let duration = dayjs().diff(created_at, 'hour')
        return duration < 2;
      });
      this.$set(this, 'jobs', jobs);
    }
  },
  computed: {
    filteredChunkProgress() {
      return this.chunkProgress.filter(progress => progress < 100);
    },
    assetLimitReachedMessage() {
      return this.getLimitMessage('import_data', 'ASSET_LIMIT');
    },
    api_host() {
      return this.$api_host;
    }
  },
  mounted() {
    this.load_jobs();
  },
}
</script>

<style scoped>

</style>