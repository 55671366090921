<template>
    <v-col
      cols="12"
      :lg="lg"
      :sm="sm"
      :md="md"
    >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
    props: {
      lg: {
        default: 3
      },
      sm: {
        default: 3
      },
      md: {
        default: 3
      }
  },
  name: "Col3"
}
</script>

<style scoped>

</style>