<template>
  <div>
    <v-card elevation="2">
      <v-card-title v-if="headerType === 'projectOverview'">
        <h2 class="headline">{{ $t('overview.limits') }}</h2>
      </v-card-title>
      <v-card-text>
        <div v-if="headerType === 'myProfile'" class="icon-title">
          <v-icon>mdi-chart-bar</v-icon>
          <b>{{$t('overview.limits')}}</b><br><br>
        </div>
        <v-row v-if="exceededLimits.length > 0">
          <v-col>
            <v-alert type="warning" text border="left">
              {{ upgradeMessage }}
              <div class="d-flex justify-end" v-if="showUpgradeButton">
                <v-btn color="primary" @click="upgradePlan">{{$t('myprofile.upgrade_plan')}}</v-btn>
              </div>
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-for="(pair, index) in pairedLimitItems" :key="index">
          <v-col v-for="(lim, innerIndex) in pair" :key="innerIndex">
            <h3 v-if="lim && lim.type">{{ $t(`overview.${lim.type}`) }}</h3>
            <info-icon v-if="lim && lim.type === 'trainings'" :text="$t('overview.trainings_tooltip')"></info-icon>
            <div v-if="lim && lim.limit !== null">
              {{ $t('myprofile.used') }} {{ lim.used }}
              <v-progress-linear
                v-model="lim.usagePercentage"
                height="25"
                :color="lim.usagePercentage >= 100 ? '#ff9800' : undefined"
              >
                <strong>{{ Math.ceil(lim.usagePercentage) }}%</strong>
              </v-progress-linear>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import InfoIcon from '@/components/notifications/InfoIcon.vue';

export default {
  name: 'UserLimits',
  components: { InfoIcon },
  props: {
    showUpgradeButton: {
      type: Boolean,
      default: false,
    },
    headerType: {
      type: String,
      default: 'myProfile',
    },
  },
  data() {
    return {
      limitItems: [
        { type: 'images', used: null, limit: null, usagePercentage: 0 },
        { type: 'annotations', used: null, limit: null, usagePercentage: 0 },
        { type: 'invitedUsers',  used: null, limit: null, usagePercentage: 0 },
        { type: 'projects', used: null, limit: null, usagePercentage: 0 },
        { type: 'trainings', used: null, limit: null, usagePercentage: 0 }
      ],
      exceededLimits: [],
      upgradeMessage: '',
    };
  },
  computed: {
    pairedLimitItems() {
      const pairs = [];
      for (let i = 0; i < this.limitItems.length; i += 2) {
        pairs.push([this.limitItems[i], this.limitItems[i + 1]]);
      }
      return pairs;
    },
  },
  methods: {
    upgradePlan() {
      this.$router.push({ name: 'MyProfile' });
    },
    async getLimits() {
      const response = await this.$api.user.get_user_limits();

      this.limitItems.forEach((lim) => {
        const foundLimit = response.find(item => item.type === lim.type);
        if (foundLimit) {
          lim.used = foundLimit.user_count;
          lim.limit = foundLimit.limit;
          lim.usagePercentage = foundLimit.usage_percentage;
          if (lim.usagePercentage >= 100) {
              const label = this.$t(`overview.${lim.type}`);
              if(lim.type !== 'projects') {
                this.exceededLimits.push(label);
              } 
          }
        }
      });

      if (this.exceededLimits.length > 0) {
        this.upgradeMessage = `${this.$t('overview.limit1')} ${this.exceededLimits.join(', ')}. ${this.$t('overview.limit2')}`;
      }
    },
  },
  created() {
    this.getLimits();
  },
};
</script>

<style scoped>

</style>
