<template>
    <v-card >
      <v-card-title>
        <edit-label-dialog @update_label="update_label" :labels="labels" :item="editedItem" @close_modal="close_modal" v-if="dialog"></edit-label-dialog>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('set_labels.tree_view_search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <loader :loading="loading"></loader>
      <v-btn style="margin-left: 15px" @click="open_all(1)" x-small>1</v-btn>
      <v-btn v-for="i in [2, 3,4,5,6,7,8,9]" @click="open_all(i)" v-bind:key="i" x-small>{{i}}</v-btn>
      <v-treeview
          v-if="!loading"
          v-model="selected_labels"
          :items="filtered_tree"
          :selection-type="'independent'"
          :selectable="show_select"
          :open.sync="opened"
          item-text="hidden"
        >
        <template v-slot:prepend="{ item }">
          {{item.display_name}}
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="preview(item.name)"
          >
            fa-table-cells-large
          </v-icon>
          <v-icon
              v-if="item.count === 0"
              small
              class="mr-2"
              @click="delete_label(item)"
          >
            far fa-trash-alt
          </v-icon>
        </template>
      </v-treeview>
    </v-card>
</template>

<script>
import Loader from "@/components/layout/loader";
import EditLabelDialog from "@/components/manage_labels/EditLabelDialog";
import EditLabelMixin from "@/components/manage_labels/EditLabelMixin";
export default {
  name: "TreeView",
  components: {EditLabelDialog, Loader},
  data() {
    return {
      'max_depth': 10,
      'search': '',
      'selected_labels': [],
      'loading': true,
      'ready': false,
      'tree_items': [],
      'opened': [],
    }
  },
  props: {
    show_select: {default: false},
    selected_labels_ids: {default: () => []},
    labels: {required: true}
  },
  watch: {
    selected_labels(n) {
      this.$emit('select_label_ids', n.map(i => {
        if(i.id) {
          return i.id
        }
        return i
      }))
    },
  },
  computed: {
    filtered_tree() {
      if(this.search === '' || this.search.length < 3) {
        return this.tree_items
      }
      return this.filter_tree_children(this.tree_items)
    },
    extended_labels() {
      let this2 = this;
      let labels = JSON.parse(JSON.stringify(this.labels))
      return labels.map(l => {
        if (l.relationships && l.relationships.annotation_stats && l.relationships.annotation_stats.length > 0) {
          let processed_counts = l.relationships.annotation_stats.map(stats => {
            return stats.processed_count
          })
          l.count = processed_counts.reduce(this2.add, 0);
        }
        return l
      })
    },
  },
  methods: {
    open_all(desired_depth) {
      let ids = []
      let this2 = this;
      this.tree_items.forEach(item=>{
        ids = ids.concat(this2.get_ids(item, desired_depth, 1))
      })
      this.$set(this, 'opened', ids);
    },
    get_ids(tree_item, desired_depth, depth) {

      if(depth >= desired_depth) {
        return [];
      }

      let children = tree_item.children;
      let ids = [tree_item.id]
      let this2 = this;
      children.forEach(child => {
        ids = ids.concat(this2.get_ids(child, desired_depth, depth + 1))
      });
      return ids;
    },
    filter_tree_children(items) {
      let filtered_items = []

      // add item if there are children
      // add item if the name matches

      for(let i=0; i<items.length; i++) {
        let item = JSON.parse(JSON.stringify(items[i]))
        if(this.matches_search(item)) {
          filtered_items.push(item)
        }
        else {
          let children = this.filter_tree_children(item.children)
          if(children.length > 0) {
            item.children = children
            filtered_items.push(item)
          }
        }
      }
      return filtered_items
    },
    matches_search(item) {
      return item.name.toLowerCase().includes(this.search.toLocaleLowerCase())
    },
    async delete_label(label) {
      this.$set(this, 'loading', true)
      await this.$store.dispatch('label/delete', label)
      this.$set(this, 'loading', false)
    },
    preview(label_name) {
      this.$emit('preview_label', label_name)
    },
    add(a, b) {
      return a + b
    },
    load_tree() {
      let roots = this.extended_labels.filter(item => {
        return !item.parent_id
      })
      roots = roots.map(root => {
        return this.add_children(root, 1)
      });
      this.$set(this, 'tree_items', roots)
      this.$set(this, 'loading', false)
    },
    add_children(item, depth) {
      if (depth > this.max_depth) {
        item.children = [{name: 'Recursion?'}]
        return item
      }
      let this2 = this;
      let children = this.extended_labels.filter(elem => {
        return elem.parent_id === item.id
      })
      children = children.map(child => {
        return this.add_children(child, depth + 1)
      })
      let total_counts = children.map(child => {
        return child.total_count
      })
      // console.log(total_counts);
      item.total_count = total_counts.reduce(this2.add, 0) + item.count;
      item.children = children
      item.display_name = item.name + this.$t('set_labels.tree_view_tree_count') + item.total_count + ' (' + item.count + ')';
      item.all_names = children.map(child=>{return child.name});
      item.all_names.push(item.name);
      item.all_names_flattened = item.all_names.join()
      return item
    }
  },
  mounted() {
    let selected_labels = this.labels.filter(label => {
      return this.selected_labels_ids.includes(label.id);
    })
    this.$set(this, 'selected_labels', selected_labels.map(l=>{return l.id;}));
    setTimeout(function () {
      this.load_tree()
    }.bind(this), 10)
  },
  mixins: [EditLabelMixin]
}


</script>

<style scoped>

</style>