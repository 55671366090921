import { render, staticRenderFns } from "./SearchLabelAutocomplete.vue?vue&type=template&id=c03b5744&scoped=true"
import script from "./SearchLabelAutocomplete.vue?vue&type=script&lang=js"
export * from "./SearchLabelAutocomplete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c03b5744",
  null
  
)

export default component.exports