<template>
  <menu-layout :loading="loading">
    <annotate-modal v-if="show_modal"
          @remove_item="refresh"
          @close_modal="close_modal" :asset_id="modal_asset_id"
    ></annotate-modal>
    <row>
      <col8 style="margin-top: 20px; margin-bottom: 20px;">
        <v-card-title>Annotate instances</v-card-title>
        <v-card-subtitle>Do you think these two bounding boxes are the same instance (eg individual animal, individual human, etc)?</v-card-subtitle>
        <v-card-text>
          <span v-if="same">
            Ps: the computer thinks they are the same.
          </span>
          <span v-else>
            Ps: the computer thinks they are NOT the same.
          </span>
          <span>Comparison score = {{score}}</span>
        </v-card-text>
      </col8>
    </row>
    <row>
      <col4 v-if="error">
        {{error}}
      </col4>
      <col4 v-for="bbox in bboxes" :key="bbox.id">
        <v-card>
          <v-card-subtitle>
            id: {{bbox.id}}
          </v-card-subtitle>
          <v-card-text>
            <img @click="openModal(bbox.asset_id)" :src="'data:image/png;base64, ' + bbox.data" style="max-width: 100%"/>
          </v-card-text>
        </v-card>
      </col4>
    </row>
    <row v-if="!error">
      <col8>
        <v-btn color="primary" @click.native="saveUnknown">I don't know</v-btn>
        <v-btn color="primary" @click.native="saveInvalid">One/both are invalid</v-btn>
        <v-btn color="primary" @click.native="saveSame">Same!</v-btn>
        <v-btn color="primary" @click.native="saveDifferent">Different!</v-btn>
      </col8>
    </row>
    <row>
      <col8>
        <a href="/found-instances">Show found pairs</a>
      </col8>
    </row>
  </menu-layout>

</template>

<script>
import AnnotateModal from "@/components/AnnotateModal";
import Row from "@/components/layout/Row";
import Col8 from "@/components/layout/Col8";
import MenuLayout from "@/components/layout/MenuLayout";
import Col4 from "@/components/layout/Col4";

export default {
  name: "Instances",
  data() {
    return {
      'bboxes': [],
      'meta_contains': '',
      'original_category': '',
      'label_contains': '',
      'error': false,
      'modal_asset_id': false,
      'show_modal': false,
      'same': null,
      'score': null,
      'loading': false,
    }
  },
  components: {Col4, MenuLayout, Col8, Row, AnnotateModal},
  methods: {
    close_modal() {
      this.modal_asset_id = false;
      this.show_modal = false;
    },
    openModal(asset_id) {
      this.modal_asset_id = asset_id;
      this.$set(this, 'show_modal', false)
      this.$set(this, 'show_modal', true)
    },
    refresh() {
      this.next();
    },
    async saveSame() {
      this.$set(this, 'loading', true);
      await this.$api.instance.save_relation(this.bboxes[0].id, this.bboxes[1].id)
      await this.next();
      this.$set(this, 'loading', true);
    },
    async saveUnknown() {
      this.$set(this, 'loading', true);
      await this.$api.instance.save_unknown_relation(this.bboxes[0].id, this.bboxes[1].id)
      await this.next();
      this.$set(this, 'loading', true);
    },
    async saveInvalid() {
      this.$set(this, 'loading', true);
      await this.$api.instance.save_invalid_relation(this.bboxes[0].id, this.bboxes[1].id)
      await this.next();
      this.$set(this, 'loading', true);
    },
    async saveDifferent() {
      this.$set(this, 'loading', true);
      await this.$api.instance.save_inverse_relation(this.bboxes[0].id, this.bboxes[1].id)
      await this.next();
      this.$set(this, 'loading', true);
    },
    async next() {
      this.$set(this, 'loading', true);
      this.$set(this, 'bboxes', [])

      let results = await this.$api.instance.get_next_instance(this.meta_contains, this.original_category, this.label_contains)

      if(results.error) {
        this.$set(this, 'error', results.error)
        this.$set(this, 'loading', false);
        return;
      }
      this.$set(this, 'error', false)
      this.$set(this, 'same', results.same)
      this.$set(this, 'score', results.score)

      for(let i; i<results.instances.length; i++) {

        let result = results.instance[i];

        let instance = result[0]

        let data = await this.$api.asset.get_crop(instance.asset_path, instance.bbox_coords, instance.asset_id);
        let cropped_data = data.crops[0].crop;
        let label = instance.label
        this.bboxes.push({'asset_id': instance.asset_id, 'id': instance.id, 'label': label, 'data': cropped_data})
        if (this.bboxes.length === results.instances.length) {
          this.$set(this, 'loading', false);
        }
      }
    }
  },
  mounted() {
    this.next()
  },
}
</script>

<style scoped>

</style>