export default class Box {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async mark_bboxes_validated(bbox_ids) {
        let formData = new FormData();
        formData.append('bbox_ids', JSON.stringify(bbox_ids));
        return this.api.post_with_token('/mark-bboxes-validated', formData)
    }

    async delete_selected_annotations(bbox_ids) {
        let formData = new FormData();
        formData.append('bbox_ids', JSON.stringify(bbox_ids));
        return this.api.post_with_token('/delete-selected-annotations', formData);
      }
}