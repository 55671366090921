<template>
  <div>
    <filter-item
        v-for="(filter, index) in filters"
        :key="'filter_'+index"
        :id="'filter_'+index"
        @clear="clear(filter.id)"
        @set_value="set_value(filter.id, $event)"
        :value="filter.value"
        :label="filter.label"
        :input="filter.input"
        :options="filter.options"
        :is_date="filter.is_date"></filter-item>
  </div>

</template>

<script>
import FilterItem from "@/components/asset_filter/FilterItem";
export default {
  name: "FilterGroup",
  props: {
    filters: {required: true}
  },
  methods: {
    clear(filter_id) {
      this.$emit('clear_filter_id', filter_id)
    },
    set_value(filter_id, value) {
      this.$emit('set_value', filter_id, value)
    }
  },
  components: {FilterItem}
}
</script>

<style scoped>

</style>