<template>
  <v-progress-linear
      :value="job.progress"
      height="20"
      style="width: 100%"
      rounded
    >
      <strong>{{ Math.ceil(job.progress) }}%</strong>
    </v-progress-linear>
</template>

<script>

export default {
  name: "JobProgessBar",
  components: {},
  props: {
    'job': {required: true},
  },
}
</script>

<style scoped>

</style>