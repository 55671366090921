<script>
export default {
name: "LoadLabelMixin"
  ,
  data() {
    return {
      labels_loaded: false
    }
  },
  mounted() {
    this.load_labels();
  },
  computed: {
    labels () {
      return this.$store.getters['label/get_items']
    },
    label_names() {
      return this.labels.map(label=>{return label.name});
    },
    labels_dict() {
      return Object.assign({}, ...this.labels.map((x) => ({[x.id]: x})));
    },
    point_labels() {
      return this.labels.filter(label=>{return label.type == 2; })
    },
    box_labels() {
      return this.labels.filter(label=>{return label.type != 2; })
    }

  },
  methods: {
    async load_labels() {
      this.$store.dispatch('label/all', {reload: false}).then(() => {
        this.$set(this, 'labels_loaded', true)
      });
    },
  }
}
</script>

<style scoped>

</style>