<template>
  <row12>
    <edit-task v-if="edit_mode" :task="task" @close_modal="close_modal"></edit-task>
    <v-data-table :items="tasks" :headers="table_headers">
      <template v-slot:item.users="{ item }">
        <div :style="{'backgroundColor': item.rgb_color}" style="height: 18px; width: 18px; float: left; margin-right: 5px; margin-top: 3px;"></div>
        <div class="avatar-container">
          <show-gravatar v-for="user_task in item.relationships.user_tasks" :key="user_task.id" :user="user_task.relationships.user" :small="true"></show-gravatar>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <span @click="toggleEditMode(item)">{{ item.name }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div :style="{'backgroundColor': item.rgb_color}" style="height: 18px; width: 18px; float: left; margin-right: 5px; margin-top: 3px;"></div>
        <v-icon small class="mr-2" @click="delete_task(item)">
          far fa-trash-alt
        </v-icon>
        <v-icon small class="mr-2" @click="copy_task(item)">
          far fa-copy
        </v-icon>
        <v-icon small class="mr-2" @click="toggleEditMode(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </row12>
</template>

<script>
import EditTask from "@/components/tasks/EditTask";
import Row12 from "@/components/layout/Row12.vue";
import ShowGravatar from '@/components/projects/ShowGravatar.vue';

export default {
  name: "TaskList",
  components: { Row12, EditTask, ShowGravatar },
  props: {
    tasks: { required: true },
  },
  data() {
    return {
      edit_mode: false,
      task: "",
    };
  },
  methods: {
    async unassign_user_from_task(user_id, task) {
      let updated_task = await this.$api.task.remove_user_from_task(user_id, task);
      this.$store.dispatch("task/update", updated_task);
    },
    async delete_task(task) {
      this.$set(this, "loading", true);
      this.$store.dispatch("task/delete", task);
      this.$set(this, "loading", false);
    },
    copy_task() {
      this.$set(this, "loading", true);
      let new_task = {
        name: this.name + " COPY",
        filter: this.filter,
      };
      this.$store.dispatch("task/add", new_task);
      this.$set(this, "loading", false);
    },
    toggleEditMode(task) {
      this.task = task;
      this.$set(this, "edit_mode", !this.edit_mode);
    },
    close_modal() {
      this.$set(this, "edit_mode", false);
    },
  },
  computed: {
    table_headers() {
      return [
        { text: this.$t("settings_tasks.id"), value: "id" },
        { text: this.$t("settings_tasks.name"), value: "name" },
        { text: this.$t("settings_tasks.stats"), value: "project_id" },
        { text: this.$t("settings_tasks.users"), value: "users" },
        { text: this.$t("settings_tasks.actions"), value: "actions", sortable: false },
      ];
    },
  },
};
</script>

<style scoped>
.avatar-container {
  display: flex;
}

.show-gravatar {
  margin-right: 5px;
}
</style>
