<template>
  <menu-layout>
    <row> 
    </row>
  </menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import CurrentUserMixin from '@/mixins/CurrentUserMixin';


export default {
  name: "Success",
  mixins: [CurrentUserMixin],
  data: function () {
    return {
      session_id: null, 
    }
  },
  computed: {
  },
  components: {
    Row,
    MenuLayout,
  },
  mounted() {
    this.setSessionId();
    this.$store.commit('user/set_current_project_id', this.project_id)
  },
  methods: {
    async setSessionId() {
      this.session_id = this.$route.query.session_id;
      await this.verifySubscriptionStatus();
    },
    async verifySubscriptionStatus() {
      const response = await this.$api.user.upgrade_subscription_stripe_payment_verification(this.session_id);
      if (response.status === "Subscription activated") {
        const subscriptionId = response.subscription_id;
        await this.$store.dispatch('snackbars/add_success_snack', 'Subscription activated');
        this.$store.commit('user/set_subscription_id', { user_id: this.current_user.id, subscription_id: subscriptionId });
      } else {
        await this.$store.dispatch('snackbars/add_failed_snack', 'Subscription failed');
      }
      this.$router.push({ name: 'MyProfile' });
    }
  },
}
</script>

<style scoped>

</style>