<template>
    <modal :title="$t('settings_tasks.edit_title')" @close_modal="close_modal" :loading="loading"
    >
        <template v-slot:title>
            {{$t('settings_tasks.edit_title')}}
        </template>
        <template v-slot:content>
            <row>
                <col12>
                    <v-text-field
                            v-model="name"
                            required
                            dense
                    ></v-text-field>
                </col12>
            </row>
            <row v-if="initiated">
                <col6>
                    <filter-tree
                            :initial_filter="filter"
                            :max_results="max_results"
                            @update="updateFilterData"
                            @refresh="updateFilterData"
                            :show_button="false"
                            :show_limit="false"
                            @load="updateFilterData"></filter-tree>
                </col6>
                <col6 v-if="users_loaded">
                    <user-list @select_users_ids="select_users_ids" :users="users" :selected_ids="task_user_ids"></user-list>
                </col6>
            </row>

        </template>
        <template v-slot:buttons>
            <v-btn
                    color="primary"
                    text
                    id="cancel_button"
                    @click.native="cancel"
            >
                {{$t('settings_tasks.edit_btn_cancel')}}
            </v-btn>

            <v-btn
                    color="primary"
                    text
                    id="save_button"
                    @click.native="save"
            >
                {{$t('settings_tasks.edit_btn_save')}}
                <v-icon
                        dark
                        primary
                        right
                >
                    fas fa-level-down-alt fa-rotate-90
                </v-icon>
            </v-btn>
        </template>
    </modal>
</template>

<script>
  import Modal from "@/components/Modal";
  import FilterTree from "@/components/asset_filter/FilterTree";
  import Row from "@/components/layout/Row";
  import Col6 from "@/components/layout/Col6";
  import Col12 from "@/components/layout/Col12";
  import LoadUsersMixin from "@/mixins/LoadUsersMixin";
  import UserList from "@/components/users/UserList";
  export default {
    name: "EditTask",
    data () {
      return {
        'loading': false,
        'max_results': 1,
        'filter': '',
        'name': '',
        'initiated': false,
        'task_user_ids': [],
        'db_task_user_ids': []
      }
    },
    props: {
      'task': {required: true}
    },
    mixins: [LoadUsersMixin],
    mounted() {
      let filter = ''
      if(this.task.filter !== '') {
        filter = JSON.parse(this.task.filter)
      }
      this.$set(this, 'filter', filter);
      this.$set(this, 'name', this.task.name);
      this.load_task_users();
    },
    methods: {
      async load_task_users() {
        let users = await this.$api.user_task.all(this.task.id)
        this.$set(this, 'db_task_user_ids', users.map(user => {return user.id;}))
        this.$set(this, 'task_user_ids', users.map(user => {return user.id;}))
        this.$set(this, 'initiated', true)
      },
      updateFilterData (filter) {
        this.$set(this, 'filter', filter)
      },
      cancel() {
        this.$emit('close_modal')
      },
      select_users_ids(user_ids) {
        this.$set(this, 'task_user_ids', user_ids);
      },
      close_modal() {
        this.$emit('close_modal')
      },
      async save() {
        let task = this.task;
        let filter = ''
        if(this.filter !== '') {
          filter = JSON.stringify(this.filter)
        }
        task.filter = filter
        task.name = this.name
        

        for(let i=0; i<this.db_task_user_ids.length; i++) {
          let user_id = this.db_task_user_ids[i]
          if(-1 === this.task_user_ids.indexOf(user_id)) {
            await this.$api.user_task.delete(this.task.id, user_id)
          }
        }
        for(let i=0; i<this.task_user_ids.length; i++) {
          let user_id = this.task_user_ids[i]
          if(-1 === this.db_task_user_ids.indexOf(user_id)) {
            await this.$api.user_task.add(this.task.id, user_id)
          }
        }

        this.$store.dispatch('task/patch', { item: task, dispatch: this.$store.dispatch })
        
        this.close_modal()
      }
    },
    components: {UserList, Col12, Col6, Row, FilterTree, Modal}
  }
</script>

<style scoped>

</style>