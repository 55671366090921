var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('filter-tree',{attrs:{"initial_filter":[
            {'type':27, 'value':''},
            {'type':23, 'value':'No'},
            {'type':9, 'value':'No'},
            {'type':11, 'value':'No'},
            {'type':7, 'value':''}
           ],"max_results":_vm.max_results},on:{"update":_vm.update,"load":_vm.load}})],1),_c('div',{staticClass:"col-md-9"},[_c('div',[_c('asset-grid',{attrs:{"items":_vm.items},on:{"update_selection_asset_ids":_vm.update_selection_asset_ids,"reload":_vm.load}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }