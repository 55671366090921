import actions from '@/store/modules/model/actions.js'
import mutations from '@/store/modules/model/mutations.js'
import getters from '@/store/modules/model/getters.js'
import {base_state} from '@/store/modules/base/state.js'

const state = {
  ...base_state,
  'api_module': 'model'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}