import base_actions from '@/store/modules/base/actions'

export default {
    ...base_actions,
    async add_new(context) {
        let task = {
            'filter': '',
            'name': 'New task'
        }
        await context.dispatch('add', task)

    },
    async update(context, updated_task) {
        let ids = context.state.items.map(item=>{return item.id});
        let index = ids.indexOf(updated_task.id)
        if(index !== -1) {
            this._vm.$set(context.state.items, index, updated_task)
        }
        
    },
}