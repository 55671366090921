<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon small color="primary" v-on="on" class="mr-2" @mouseover="showTooltip" @mouseleave="hideTooltip">        
        mdi-information-outline
      </v-icon>
    </template>
    <span v-html="formattedText"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "InfoIcon",
  data() {
    return {
      showInfoTooltip: false,
    };
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    showTooltip() {
      this.showInfoTooltip = true;
    },
    hideTooltip() {
      this.showInfoTooltip = false;
    },
  },
  computed: {
    formattedText() {
      const words = this.text.split(' ');
      const wordsPerLine = 10;
      const lines = [];
      
      for (let i = 0; i < words.length; i += wordsPerLine) {
        const line = words.slice(i, i + wordsPerLine).join(' ');
        lines.push(line);
      }

      return lines.join('<br>');
    },
  },
};
</script>
