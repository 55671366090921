<template>
  <div>
    <row>
      <col1></col1>
      <col1><v-icon>fa-sharp fa-light fa-boxes-stacked</v-icon></col1>
      <col10>
        <model-buttons :disabled="disabled" @on_click="on_click" :model_buttons="box_model_buttons" :selected_model_type="selected_model_type"></model-buttons>
      </col10>
      <col1></col1>
      <col1><v-icon>fa-solid fa-image</v-icon></col1>
      <col10>
        <model-buttons :disabled="disabled" @on_click="on_click" :model_buttons="classification_model_buttons" :selected_model_type="selected_model_type"></model-buttons>
      </col10>
    </row>
  </div>
</template>

<script>
import Col10 from "@/components/layout/Col10.vue";
import Row from "@/components/layout/Row.vue";
import ModelButtons from "@/components/train_configs/ModelButtons.vue";
import Col1 from "@/components/layout/Col1.vue";

export default {
  name: "ModelButtonOverview",
  components: {Col1, ModelButtons, Row, Col10},
  props: {
    'model_buttons': {required: true},
    'selected_model_type': {required: true},
    'disabled': {type: Boolean, default:false},
  },
  computed: {
    box_model_buttons() {
      return this.model_buttons.filter(button => {
        return button.type === 'bounding_box'
      })
    },
    classification_model_buttons() {
      return this.model_buttons.filter(button => {
        return button.type === 'classification'
      })
    },
    keypoint_model_buttons() {
      return this.model_buttons.filter(button => {
        return button.type === 'point'
      })
    },
  },
  methods: {
    on_click(selected_model_button) {
      this.$emit('clicked', selected_model_button)
    }
  }
}
</script>

<style scoped>

</style>