<template>
   <v-app>
     <menu-bar
     ></menu-bar>
     <v-img style="min-height: 30vh; min-width: 100vh;"
         src="/api/static/frontpage/dolphins.jpeg"
     >
       <div class="hometext" style="color: #F9F6EE; position: absolute; bottom: 30%; margin-left: 5%; font-weight: bold;
font-size: 2.5vw;">
          {{$t('frontpage.title_l1')}} <br />
          {{$t('frontpage.title_l2')}} <br />
          {{$t('frontpage.title_l3')}}<br />
       </div>
       <animated-box
           top="44"
           left="40.5"
           width="12"
           height="23"
           :title="$t('frontpage.animated_box8')"
           subtitle="0.89"
       ></animated-box>
       <animated-box
           top="35"
           left="56.5"
           width="8.9"
           height="12.2"
           :title="$t('frontpage.animated_box4')"
           subtitle="0.94"
       ></animated-box>
       <animated-box
           top="65"
           left="78.5"
           width="2.8"
           height="5.7"
           :title="$t('frontpage.animated_box_bird')"
           subtitle="0.98"
       ></animated-box>
       <!-- <div style="position: absolute; bottom: 7.5%; left: 5%;">
          <v-row align="center" justify="start">
            <v-col cols="auto" style="margin: 10px;">
              <v-btn color="white" @click.native="goToRegister">
                Create account
              </v-btn>
            </v-col>
            <v-col cols="auto" style="margin: 10px;">
              <v-btn color="primary" @click.native="goToLogin">
                Login
              </v-btn>
            </v-col>
          </v-row>              
       </div> -->
     </v-img>

      <v-content style="background-color: #E38229;">

          <row>
              <col12>
                  <v-card style="margin-left: 20%; margin-bottom: 10%; width: 60%; margin-top: -60px;" elevation="10">
                      <v-img style="" src="/api/static/examples/reddeer.jpeg"></v-img>
                  </v-card>
              </col12>
          </row>          
      </v-content>
     <v-content style="padding-top: 7%; padding-bottom: 5%; background-color: #7cacd4">   
       <row>
         <col12>
           <v-layout align-center justify-center>
             <div class='examples-title text-h2' style="text-align: center; color: black; font-weight: bold;
font-size: 2.5vw;" >
               {{$t('frontpage.examples_title')}}
             </div>
           </v-layout>
         </col12>
       </row>
       <row style="margin-top: 50px;">
         <col4>
           <example-project
            :title="$t('frontpage.example1_title')"
            :description="$t('frontpage.example1_description')"
            organisation="Himalayan Tiger Foundation"
            image="/api/static/examples/individual-animal-recognition.png"
           >
           </example-project>
         </col4>
<!--         <col4>-->
<!--           <example-project-->
<!--               title=""-->
<!--               description="Agouti: an AI powered solution for managing wildlife camera-trapping projects. Let AI do the job and get insights about the observations encountered on your wildlife cameras"-->
<!--               organisation="Wageningen University"-->
<!--               image="/api/static/examples/appliances.png"-->
<!--           >-->
<!--           </example-project>-->
<!--         </col4>-->
         <col4>
           <example-project
               :title="$t('frontpage.example2_title')"
               :description="$t('frontpage.example2_description')"
               organisation="Wageningen University"
               image="/api/static/examples/animal-classification.png"
           >
           </example-project>
         </col4>
         <col4>
           <example-project
               :title="$t('frontpage.example3_title')"
               :description="$t('frontpage.example3_description')"
               organisation="Creative builder"
               image="/api/static/examples/lego.png"
           >
           </example-project>
         </col4>
       </row>
         <br />
         <br />
     </v-content>
<!--     <v-content style="background-color: #E38229; padding-top: 5%">-->

<!--       <row>-->
<!--         <col12>-->
<!--           <v-container fluid fill-height>-->
<!--             <v-layout align-center justify-center>-->
<!--               <v-card elevation="15">-->
<!--                 <v-img  style="" src="/api/static/examples/projects.png"></v-img>-->
<!--               </v-card>-->
<!--             </v-layout>-->
<!--           </v-container>-->
<!--         </col12>-->
<!--         <col6>-->
<!--           <v-layout align-center justify-center>-->
<!--             <div class='text-h1' style="text-align: center; line-height: 1.3em" >-->
<!--               BOX21<br />-->
<!--             </div>-->
<!--           </v-layout>-->
<!--           <br />-->
<!--           <v-layout align-center justify-center>-->
<!--             <div class='text-h3' style="text-align: center; line-height: 1.3em" >-->
<!--               First invites have been sent!-->
<!--             </div>-->
<!--           </v-layout>-->
<!--           <br />-->
<!--           <v-layout align-center justify-center>-->
<!--             <div class='text-h5' style="text-align: center; line-height: 1.3em" >-->
<!--               Want to join the other creative builders?-->
<!--             </div>-->
<!--           </v-layout>-->
<!--           <div>-->
<!--             <row12 v-if="form_send">-->
<!--               <col3>-->

<!--               </col3>-->
<!--               <col3>-->
<!--                 <v-btn rounded>Thank you, we will keep you informed!</v-btn>-->
<!--               </col3>-->
<!--             </row12>-->
<!--             <v-row v-if="!form_send" style="margin-top: 20px">-->
<!--               <col3 md="2" lg="2" sm="0">-->
<!--               </col3>-->
<!--               <col6 md="4" lg="4" sm="12">-->
<!--                 <v-textarea-->
<!--                     full-width-->
<!--                     v-model="project"-->
<!--                     placeholder="Describe your project"-->
<!--                     rounded-->
<!--                     dense outlined-->
<!--                 ></v-textarea>-->
<!--               </col6>-->
<!--             </v-row>-->
<!--             <row v-if="!form_send" style="margin-top: 0px">-->
<!--               <col3 md="2" lg="2" sm="0">-->
<!--               </col3>-->
<!--               <col3 md="4" lg="4" sm="12">-->
<!--                 <v-text-field-->
<!--                     v-model="email"-->
<!--                     placeholder="Your email"-->
<!--                     rounded-->
<!--                     dense outlined></v-text-field>-->

<!--               </col3>-->
<!--               <col3 md="4" lg="4" sm="12">-->
<!--                 <v-btn style="width: 100%;-->
<!--                                            padding-left: 32px;-->
<!--                                            padding-right: 30px;"-->
<!--                        @click.native="join" rounded>Join the waiting list</v-btn>-->
<!--               </col3>-->
<!--               <col3 md="2" lg="2" sm="0">-->

<!--               </col3>-->
<!--             </row>-->
<!--           </div>-->
<!--         </col6>-->
<!--       </row>-->
<!--     </v-content>-->
   </v-app>
</template>

<script>
// import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";
// import Col3 from "@/components/layout/Col3";
// import Row12 from "@/components/layout/Row12";
import Col4 from "@/components/layout/Col4";
import ExampleProject from "@/components/front_page/ExampleProject";
import Col12 from "@/components/layout/Col12";
import MenuBar from "@/components/layout/MenuBar.vue";
import AnimatedBox from "@/components/front_page/AnimatedBox.vue";
export default {
name: "FrontPage",
  components: {AnimatedBox, MenuBar, Col12, ExampleProject, Col4, Row},//, Col6, Row12, Col3
  methods: {
    join () {
      if(this.project == '') {
        alert('Please describe your project')
        return
      }
      if(!this.email.includes('@')) {
        alert('Please add a valid email')
        return
      }

      this.$set(this, 'form_send', true)
      this.$api.user.join_waiting_list(this.email, this.project)
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToRegister() {
      this.$router.push({ path: "/login", query: { tab: "register" } });
    },    
  },
  data () {
  return {
        email: '',
        project: '',
        form_send: false,
    }
  },
}
</script>

<style scoped>
.button-container {
  margin-top: 200px;
}

.container {
  position: relative;
  height: 400px;
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 72%;
  left: 47%;
  width: 11%;
  height: 18%;
  transform: translate(-50%, -50%);
}
.centered-element-2 {
  margin: 0;
  position: absolute;
  top: 54%;
  left: 56%;
  width: 10%;
  height: 12%;
  /*transform: translate(-50%, -50%);*/
}
.centered-element-3 {
  margin: 0;
  position: absolute;
  top: 78%;
  left: 78.5%;
  width: 3%;
  height: 6%;
  /*transform: translate(-50%, -50%);*/
}

@media (max-width: 600px) {
  .hometext {
    font-size: 4vw !important;
  }
  .examples-title {
    font-size: 5vw !important;
    margin-bottom: -60px;
  }
}
</style>