<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          <h2 class="headline">{{ $t('overview.steps') }}</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <todo-list-item
                :text="$t('overview.todo_item_1')"
                :redirectPath="'/import'"
                hintNumber="1"
                :condition="imagesNotPresent"
              ></todo-list-item>
              <todo-list-item
                :text="$t('overview.todo_item_2')"
                :redirectPath="'/settings/labels'"
                hintNumber="2"
                :condition="labelsNotPresent"
              ></todo-list-item>
              <todo-list-item
                :text="$t('overview.todo_item_3')"
                :redirectPath="'/myassets'"
                hintNumber="3"
                :condition="validationImagesNotPresent"
              ></todo-list-item>
              <todo-list-item
                :text="$t('overview.todo_item_4')"
                :redirectPath="'/annotate'"
                hintNumber="4"
                :condition="annotationsNotPresent"
              ></todo-list-item>
              <todo-list-item
                :text="$t('overview.todo_item_5')"
                :redirectPath="'/settings/train-configurations'"
                hintNumber="5"
                :condition="trainConfigNotPresent"
              ></todo-list-item>
              <todo-list-item
                :text="$t('overview.todo_item_6')"
                :redirectPath="'/models'"
                hintNumber="6"
                :condition="trainingNotStarted"
              ></todo-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import TodoListItem from "@/components/TodoListItem";

export default {
  name: 'ProjectTodoList',
  components: {
    TodoListItem,
  },
  data() {
    return {
      imagesNotPresent: null,
      validationImagesNotPresent: null,
      labelsNotPresent: null, 
      annotationsNotPresent: null, 
      trainConfigNotPresent: null,
      trainingNotStarted: null, 
    };
  },
  mounted() {
    this.getTodoListBooleans();
  },
  methods: {
    async getTodoListBooleans() {
      let response = await this.$api.overview.get_todo_list_booleans();
      if (response) {
          this.$set(this, 'imagesNotPresent', response.imagesNotPresent);
          this.$set(this, 'validationImagesNotPresent', response.validationImagesNotPresent);
          this.$set(this, 'labelsNotPresent', response.labelsNotPresent);
          this.$set(this, 'annotationsNotPresent', response.annotationsNotPresent);
          this.$set(this, 'trainConfigNotPresent', response.trainConfigNotPresent);
          this.$set(this, 'trainingNotStarted', response.trainingNotStarted);
      }
    },
  },
  computed: {
  },
};
</script>

<style scoped>
</style>

