<template>
    <v-card elevation="1" :style="selected?'border: 3px solid #f7f5f3; background: #f7f5f3':''">
        <v-card-subtitle>
          <span v-if="!title">{{item_id}}</span><span v-else>{{title}}</span> <checkbox style="float:right" @input="toggleSelect" :value="checked"></checkbox>
        </v-card-subtitle>
        <v-card-text>
          <annotated-image :max_height="200" @canvas_selected="selectItem" :image_url="image_url" :zoom_enabled="false" :annotations="annotations"></annotated-image>
          <span v-if="validation" class="label" :style="{backgroundColor: background_color}">{{validation}}</span>
        </v-card-text>
    </v-card>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import AnnotatedImage from '@/components/AnnotatedImage'

export default {
  components: {Checkbox, AnnotatedImage},
  props: {
    checked: {required: true},
    item_id: {required: true},
    image_url: {required: true},
    title: {default: false},
    annotations: {required: true},
    validation: {required: true},
    selected: {default: false}
  },
  methods: {
    toggleSelect() {
      this.$emit('select_toggled', this.item_id)
    },
    selectItem() {
      this.$emit('select_item', this.item_id)
    },
    remove(asset_id, bboxId) {
      this.$emit('remove_item', asset_id, bboxId)
    },
  },
  computed: {
    background_color () {
        let red = "rgba(249,65,68)";
        let green = "rgba(144,190,109)"
        let orange = "rgba(248,150,30)"

        let validation = this.validation
        if(!validation) {
          return ''
        }
        
        if(validation.startsWith('Correct')) {
          return green;
        }
        else if(validation.startsWith('False negative')) {
          return orange;
        }
        else {
          return red;
        }
    }
  },
}
</script>

<style scoped>

</style>