<script>
export default {
  name: "EditLabelMixin",
  data() {
    return {
      'dialog': false,
      'editedIndex': 0,
      'editedItem': false,
    }
  },
  methods: {
    close_modal() {
      this.dialog = false
    },
    editItem (item) {
      this.editedIndex = this.labels.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    update_label(label) {
      this.$emit('update_label', label)
    },
  }
}
</script>

<style scoped>

</style>