<template>
    <tr>
        <td><v-chip close @click:close="unselect_model">{{model.name}}</v-chip></td>
        <td v-if="job && job.processed !== 1">{{$t('most_c.calculating')}} <job-progess-bar :job="job"></job-progess-bar></td>
    </tr>
</template>

<script>
  import JobProgessBar from "@/components/JobProgressBar";
  export default {
    name: "ModelInTable",
    components: {JobProgessBar},
    props: {
      model: {required: true},
      job: {required:true}
    },
    data () {
      return {
        'timeout': false,
      }
    },
    mounted() {
      if(this.job) {
        this.reload_job();
      }
    },
    destroyed() {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
    },
    watch: {
      job(n,o) {
        if(!o && n) {
          this.reload_job();
        }
      }
    },
    methods: {
      async reload_job() {
        if(this.job.processed === 1) {
          return;
        }
        let this2 = this;
        let updated_job = await this.$api.job.load(this.job);
        this.$emit('update_job', updated_job)
        this.timeout = setTimeout(() => {
          this2.reload_job(this2.job)
        }, 500)
      },
      unselect_model () {
        this.$emit('unselect_model')
      }
    },

  }
</script>

<style scoped>

</style>