export default class User {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async add_to_project(user) {
        let formData = new FormData();
        formData.append('user_id', user.id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/user/add-to-project', formData)
    }

    async reset_password(email, password, password_confirmation, token  = null) {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('password_confirmation', password_confirmation);
        formData.append('token', token);
        return this.api.post_with_token('/user/reset-password', formData)
    }

    async initiate_reset_password(email  = null) {
        let formData = new FormData();
        formData.append('email', email);
        return this.api.post_with_token('/user/initiate-reset-password', formData)
    }

    async join_waiting_list(email, project_description) {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('username', email);
        formData.append('first_name', '');
        formData.append('last_name', '');
        formData.append('project_description', project_description);
        return this.api.post_with_token('/user/register', formData)
    }

    async add(user) {
        let formData = new FormData();
        formData.append('email', user.email);
        formData.append('username', user.username);
        formData.append('first_name', user.first_name);
        formData.append('last_name', user.last_name);
        formData.append('password', user.password);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/users/add', formData)
    }

    async find_users(email) {
        let formData = new FormData();
        formData.append('email', email);
        return this.api.post_with_token('/users/find', formData)
    }

    async change_password(current_password, new_password) {
        let formData = new FormData();
        formData.append('password', current_password);
        formData.append('new_password', new_password);
        return this.api.post_with_token('/user/update_password', formData);
    }

    async update_avatar(properties) {
        let formData = new FormData();
        formData.append('json_avatar_properties', JSON.stringify(properties));
        return this.api.post_with_token('/user/patch', formData);
    }

    async delete(user) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('user_id', user.id);
        return this.api.post_with_token('/delete-user', formData);
    }

    async all_for_project(project_id) {
        let formData = new FormData();
        formData.append('project_id', project_id);
        return this.api.post_with_token('/users', formData)
    }

    async all() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/users', formData)
    }

    async get_subscriptions() {
        return this.api.post_with_token('/user/get_subscriptions')
    }

    async get_limits() {
        return this.api.post_with_token('/user/get_limits')
    }

    async upgrade_subscription_stripe_payment_request(sub_id, sub_duration) {
        let formData = new FormData();
        formData.append('sub_id', sub_id);
        formData.append('sub_duration', sub_duration);
        return this.api.post_with_token('/user/upgrade_subscription/stripe_payment_request', formData)
    }

    async upgrade_subscription_stripe_payment_verification(session_id) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        return this.api.post_with_token('/user/upgrade_subscription/stripe_payment_verification', formData);
    }

    async get_payment_invoice(session_id) {
        let formData = new FormData();
        formData.append('session_id', session_id);
        return this.api.post_with_token('/user/download_stripe_invoice', formData);
    }

    async set_or_delete_user_2fa_secret_key() {
        let formData = new FormData();
        return this.api.post_with_token('/user/set_or_delete_user_2fa_secret_key', formData);
    }

    async get_2fa_secret_key() { 
        let formData = new FormData();   
        return this.api.post_with_token('/user/get_2fa_secret_key', formData);
    }

    async verify_2fa_secret_key_otp(secret_key, otp) {
        let formData = new FormData();
        formData.append('secret_key', secret_key)
        formData.append('otp', otp)
        return this.api.post_with_token('/user/verify_2fa_secret_key_otp', formData);
    }

    async store_2fa_secret_key(secret_key) {
        let formData = new FormData();
        formData.append('secret_key', secret_key)
        return this.api.post_with_token('/user/store_2fa_secret_key', formData);
    }

    async delete_2fa_secret_key(secret_key) {
        let formData = new FormData();
        formData.append('secret_key', secret_key)
        return this.api.post_with_token('/user/delete_2fa_secret_key', formData);
    }

    async user_2fa_enabled_login(email) {
        let formData = new FormData();
        formData.append('email', email)
        return this.api.post_with_token('/user/2fa_enabled_login', formData);
    }

    async verify_2fa_secret_key_email(otp, email) {
        let formData = new FormData();
        formData.append('email', email)
        formData.append('otp', otp)
        return this.api.post_with_token('/user/verify_2fa_secret_key_email', formData);
    }

    async user_2fa_enabled_profile() {
        let formData = new FormData();
        return this.api.post_with_token('/user/2fa_enabled_profile', formData);
    }

    async check_confirmation_status(email) {
        let formData = new FormData();
        formData.append('email', email)
        return this.api.post_with_token('/user/check_confirmation_status', formData);
    }  

    async get_user_limits() {
        let formData = new FormData();
        return this.api.post_with_token('/overview/get-user-limits', formData)
    }

}