<script>

import Annotation from "@/classes/annotation";
const { v4: uuidV4 } = require('uuid');


export default {
  components: {
    //
  },

  props: [
    //
  ],

  mixins: [
      //
  ],

  data: function () {
    return {
      annotation_suggestions: [],
      annotations: [],
      initial_annotations: false,
      model_annotations: false,
      mutations: [],
      meta: '',
      'ctrl_pressed': false,
      last_annotation_added: false,
      run_model_timeout: null,
      run_model_loading: false,
    };
  },

  beforeDestroy() {
    if(this.run_model_timeout) {
      clearTimeout(this.run_model_timeout);
    }
  },

  computed: {
    'annotation_selected' () {
      let selected = this.annotations.filter(a=>{return a.selected === true});
      if(selected.length>0) return selected[0];
      return false;
    },
    selected_annotations() {
      return this.annotations.filter((a)=>{return a.selected;})
    },
    hasEncodedBoundingBoxes() {
      if (this.asset && this.asset['meta'] || this.meta) {
        let meta;
        if (this.asset && this.asset['meta']) {
          meta = JSON.parse(this.asset['meta']);
        } else {
          meta = JSON.parse(this.meta);
        }
        if(meta['medication_detection_data']) {
          let medicationDetectionData = JSON.parse(meta['medication_detection_data']);
          if (medicationDetectionData['encodedBoundingBoxes']) {
            let encodedBoundingBoxes = medicationDetectionData['encodedBoundingBoxes'];
            if (medicationDetectionData && encodedBoundingBoxes && encodedBoundingBoxes.length > 0) {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
  
  created() {
    //
  },

  mounted() {
    window.addEventListener('keydown', this.addEventsKeyDown);
    window.addEventListener('keyup', this.addEventsKeyUp);
  },

  destroyed() {
    window.removeEventListener('keydown', this.addEventsKeyDown);
    window.removeEventListener('keyup', this.addEventsKeyUp);
  },

  methods: {
    addEventsKeyUp: function(e) {
      let this2 = this;
      if(e.keyCode === 17) { // ctrl
          this2.$set(this, 'ctrl_pressed', false);
        }
    },
    addEventsKeyDown: function(e) {
        let this2 = this;

        let activeElement = document.activeElement;
        let inputs = ['input', 'select', 'button', 'textarea'];

        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return false;
        }

        if(e.keyCode === 17) { // ctrl
          this2.$set(this, 'ctrl_pressed', true);
        }
        if(this.ctrl_pressed && e.keyCode === 67) { // ctrl + c
          this.selected_annotations.forEach((selected_annotation) => {
            this2.copyAnnotation(selected_annotation, 0.01, 0.01)
          })
        }
        if(this.ctrl_pressed && e.keyCode === 86) { // ctrl + v
          this.selected_annotations.forEach((selected_annotation) => {
            this2.copyAnnotation(selected_annotation)
          })
        }
        if(e.keyCode === 8) { // delete
          let annotations = this2.annotations.filter(annotation=>{return !annotation.selected})
          this2.$set(this, 'annotations', annotations);
        }
        if(e.keyCode === 39) { // arrow right
          this.selected_annotations.forEach((selected_annotation) => {
            let index = this.annotations.map((a)=>{return a.id;}).indexOf(selected_annotation.id)
            this.annotations[index].coords[0] = this.annotations[index].coords[0] + 0.005
            this2.$set(this.annotations, index, this.annotations[index]);
          });
        }
          if(e.keyCode === 37) { // arrow left
            this.selected_annotations.forEach((selected_annotation) => {
              let index = this.annotations.map((a)=>{return a.id;}).indexOf(selected_annotation.id)
              this.annotations[index].coords[0] = this.annotations[index].coords[0] - 0.005
              this2.$set(this.annotations, index, this.annotations[index]);
            });
          }
          if(e.keyCode === 38) { // arrow up
            this.selected_annotations.forEach((selected_annotation) => {
              let index = this.annotations.map((a)=>{return a.id;}).indexOf(selected_annotation.id)
              this.annotations[index].coords[1] = this.annotations[index].coords[1] - 0.005
              this2.$set(this.annotations, index, this.annotations[index]);
            });
          }
          if(e.keyCode === 40) { // arrow down
            this.selected_annotations.forEach((selected_annotation) => {
              let index = this.annotations.map((a)=>{return a.id;}).indexOf(selected_annotation.id)
              this.annotations[index].coords[1] = this.annotations[index].coords[1] + 0.005
              this2.$set(this.annotations, index, this.annotations[index]);
            });
          }
      },
    copyAnnotation(annotation, move_x=0.0, move_y=0.0) {
      annotation.selected = false
      let clone = JSON.parse(JSON.stringify(annotation))
      clone.selected = true;
      clone.coords[0] = clone.coords[0] + move_x
      clone.coords[1] = clone.coords[1] + move_y
      clone.id = this.getUniqueId();
      clone.conf = '1'
      clone.skip_zoom = true;
      this.annotations.push(clone);
    },
    async loadModalAnnotations(model_id, asset_id) {

      if(this.model_annotations) {
        this.$set(this, 'annotations', this.model_annotations)
      }
      else {
        this.$set(this, 'run_model_loading', true)
        let result = await this.$api.model.get_model_preds(JSON.parse(JSON.stringify(asset_id)), model_id);

        if (result.annotations) {
          let this2 = this;
          let annotations = result.annotations
          let i = 0;
          annotations = annotations.map(annotation=>{
            let id = annotation.id;
            if(!id) {
              id = this2.getUniqueId();
            }

            let color = result.colors[i]; i += 1;
            let type = 'box'
            if(annotation.coords.length === 2) {
              type = 'point'
            }
            return new Annotation(id, type, annotation.coords, annotation.conf, annotation.label, color);
          });
          this2.$set(this2, 'annotations', annotations)
          this2.$set(this2, 'model_annotations', annotations)
          this.$set(this, 'run_model_loading', false)
        }
        else {
          if(!this.run_model_loading) {
            clearTimeout(this.run_model_timeout)
            return;
          }
          this.run_model_timeout = setTimeout(()=>{
            this.loadModalAnnotations(model_id, asset_id)
          }, 50)
        }
      }
      this.$set(this, 'loading', false)
    },
    async load() {
      this.$set(this, 'loading', true)
      let result = await this.$api.asset.load(this.asset_id);
      //result eventually contains the annotation_suggestions.

      let label_meta = result.label_meta;
      let annotations = result.annotations
      let i = 0;
      annotations = annotations.map(annotation=>{
        let id = annotation.id;
        let color = result.colors[i]; i += 1;
        if(label_meta[annotation.label].color) {
          color = label_meta[annotation.label].color.split(',')
        }
        let type = annotation.type === 2 ? 'point' : 'box';
        return new Annotation(id, type, annotation.coords, annotation.conf, annotation.label, color, true);
      });

      // Hier gaan we iets leuks doen met de annotation suggestions

      this.$set(this, 'annotation_suggestions', result.annotation_suggestions)
      this.$set(this, 'annotations', [])
      this.$set(this, 'asset_url', this.$api_host + result.path)
      this.$set(this, 'annotations', annotations)
      this.$set(this, 'loading', false)
      this.$set(this, 'meta', JSON.parse(result.meta))
      this.$set(this, 'mutations', result.mutations)
      this.$set(this, 'initial_annotations', JSON.parse(JSON.stringify(this.annotations)));
    },
    getUniqueId() {
      return Math.random().toString(36).slice(2);
    },
    deleteAnnotation(id) {
      this.annotations = this.annotations.filter(annotation => { return annotation.id !== id; });
      this.$set(this, 'annotations', this.annotations);
    },
    resetAnnotations() {
      this.$set(this, 'model_results_shown', false)
      this.$set(this, 'annotations', this.initial_annotations);
    },
    clearAnnotations(less_than) {
      if(less_than) {
        let annotations = this.annotations.filter(annotation => { return annotation.conf > less_than; });
        this.$set(this, 'annotations', annotations);
      }
      else {
        this.$set(this, 'annotations', []);
      }
    },
    add_annotation_deselect_others(annotation) {
      let annotations = this.annotations.map(a => {
        if(a.id !== annotation.id) {
          a.selected = false;
        }
        return a;
      })

      annotations.push(annotation);
      this.$set(this, 'annotations', annotations)
    },
    addAnnotation(annotation) {
      this.last_annotation_added = annotation
      this.annotations.push(annotation);
      this.$set(this, 'annotations', this.annotations);
    },
    addAnnotationClearSelection(annotation) {
      let annotations = this.annotations.map(a=>{a.selected = false; return a;})
      annotations.push(annotation);
      this.$set(this, 'annotations', annotations);
    },
    selectAnnotation(selectedAnnotationId, shift_pressed, skip_reload) {
        let annotations = this.annotations.map(annotation=>{
            if(selectedAnnotationId === annotation.id) {
              annotation.selected = true;
            }
            else if(!shift_pressed) {
              annotation.selected = false
            }
            return annotation;
          });
        if(!skip_reload) {
          this.$set(this, 'annotations', annotations)
        }
    },
    unselectAnnotation(id) {
      this.annotations = this.annotations.map(annotation => {
        if (annotation.id === id) {
          annotation.selected = false;
        }
        return annotation;
      });
      this.$set(this, 'annotations', this.annotations);
    },
    updateAnnotation(id, coords) {
      let annotation = this.annotations.filter(annotation => {
        return annotation.id === id;
      });

      annotation = annotation[0]

      let annotation_ids = this.annotations.map(annotation => {
        return annotation.id;
      });

      annotation.coords = coords;
      let index = annotation_ids.indexOf(id)

      this.$set(this.annotations, index, annotation);
    },
    updateAnnotationLabel(id, label) {
      this.annotations = this.annotations.map(annotation => {
        if(annotation.id === id) {
          annotation.label = label;
          annotation.conf = '1'
        }
        return annotation;
      });
      this.$set(this, 'annotations', this.annotations);
    },
    getEncodedBoundingBoxes() {
      if (this.asset && this.asset['meta'] || this.meta) {
        let meta;
        if (this.asset && this.asset['meta']) {
          meta = JSON.parse(this.asset['meta']);
        } else {
          meta = JSON.parse(this.meta);
        }
        if(meta['medication_detection_data']) {
          let medicationDetectionData = JSON.parse(meta['medication_detection_data']);
          if (medicationDetectionData['encodedBoundingBoxes']) {
            let encodedBoundingBoxes = medicationDetectionData['encodedBoundingBoxes'];
            if (medicationDetectionData && encodedBoundingBoxes && encodedBoundingBoxes.length > 0) {
              return encodedBoundingBoxes;
            }
          }
        }
      }
    },
    show_bbox_meta() {
      this.$set(this, 'annotations', []);
      let encoded_bounding_boxes = this.getEncodedBoundingBoxes()
      encoded_bounding_boxes.forEach(encoded_bounding_box => {
        let bounding_box = encoded_bounding_box;
        let id = uuidV4();
        let color = [0,180,0]
        let confidence = 0.8 
        let annotation = new Annotation(id, 'box', bounding_box.normalized_xywh, confidence, bounding_box.label, color);
        this.addAnnotationClearSelection(annotation)
      })
      this.show_bounding_box_meta = true;
    },
  },

  watch: {
    //
  }
};
</script>