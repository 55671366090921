<template>
  <modal @close_modal="close" :title="'Add user'">
    <template v-slot:buttons>

    </template>
    <template v-slot:content>
    <row>
      <col3>
        <v-subheader>Email</v-subheader>
      </col3>
      <col9>
        <v-text-field solo v-model="email" type="text"></v-text-field>
        <autocomplete :label="'email'" @select="update_existing_user" v-if="existing_user" :value="existing_user" :options="existing_users"></autocomplete>
        <v-btn  v-if="existing_user" color="primary" @click.native="add_user_to_project">
          Add to project
        </v-btn>
      </col9>
      <col3>
        <v-subheader>Username</v-subheader>
      </col3>
      <col9>
        <v-text-field solo v-model="username" type="text"></v-text-field>
      </col9>
      <col3>
        <v-subheader>First name</v-subheader>
      </col3>
      <col9>
        <v-text-field solo  v-model="first_name" type="text"></v-text-field>
      </col9>
      <col3>
        <v-subheader>Last name</v-subheader>
      </col3>
      <col9>
        <v-text-field solo v-model="last_name" type="text"></v-text-field>
      </col9>
      <col3>
        <v-subheader>Initial password</v-subheader>
      </col3>
      <col9>
        <v-text-field solo v-model="password" type="text"></v-text-field>
      </col9>
      <col12>
        <v-btn color="primary" @click.native="add_user">
          Add
        </v-btn>
      </col12>
    </row>
    </template>
  </modal>
</template>

<script>
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import Col3 from "@/components/layout/Col3";
import Col9 from "@/components/layout/Col9";
import User from "@/models/user"
import Modal from "@/components/Modal";
import Autocomplete from "@/components/Autocomplete";
export default {
  name: "AddUser.vue",
  components: {Autocomplete, Modal, Col9, Col3, Col12, Row},
  data () {
    return {
      'existing_user': false,
      'existing_users': [],
      'email': '',
      'username': '',
      'first_name': '',
      'last_name': '',
      'password': ''
    }
  },
  watch: {
    email(n) {
      if(n.length>3) {
        this.find_users(n);
      }
    }
  },
  computed: {
    users () {
      return this.$store.getters['user/get_items'];
    },
    user_ids () {
      return this.users.map(user=>{return user.id;});
    }
  },
  methods: {
    async find_users(email) {
      let users = await(this.$api.user.find_users(email))
      users = users.filter(user=>{return !this.user_ids.includes(user.id)})

      this.$set(this, 'existing_users', users);

      if(users.length>0) {
        this.$set(this, 'existing_user', users[0]);
      }
      else {
        this.$set(this, 'existing_user', false);
      }
    },
    update_existing_user(user) {
      this.$set(this, 'existing_user', user);
    },
    async add_user_to_project() {
      this.$set(this, 'loading', true);
      await this.$api.user.add_to_project(this.existing_user);
      await this.$store.dispatch('user/all')
      this.$set(this, 'existing_users', []);
      this.$set(this, 'existing_user', false);
      this.close();
    },
    close() {
      this.$emit('close_modal')
    },
    async add_user() {
      let user = new User(this.email, this.username, this.first_name, this.last_name, this.password);
      let response = await this.$store.dispatch('user/add', user);
      this.close();

      if (response && typeof response === 'object' && response.limit_reached && response.limit_type === 'USER_LIMIT') {
        this.$emit("user-limit-reached");
      }
    }
  }
}
</script>

<style scoped>

</style>