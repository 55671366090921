<template>
  <settings-menu-layout>
    <train-configurations></train-configurations>
  </settings-menu-layout>
</template>

<script>
  import TrainConfigurations from "@/pages/TrainConfigurations";
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";

  export default {
    name: "SettingsTrainConfigurations",
    mixins: [],
    data() {
      return {
      }
    },
    methods: {
    },
    components: {
      SettingsMenuLayout,
      TrainConfigurations,
    },
    mounted() {
    },
  }
</script>

<style scoped>

</style>