<script>
export default {
  name: "FilterMixin",
  data() {
    return {
        meta_contains:'',
        original_category_like:'',
        original_category_not_like:'',
        original_category_in_list:'',
        original_category_not_in_list:'',
        filter_options: [
          {
            'type': 1, 'description': 'containing box with label',
          },
            {
            'type': 2, 'description': 'not containing box with label',
          },
            {
            'type': 2, 'description': 'Box has min certainty',
          },
            {
            'type': 1, 'description': 'containing box with label',
          },
            {
            'type': 1, 'description': 'containing box with label',
          },
            {
            'type': 1, 'description': 'containing box with label',
          },
        ]
    }
  },
  methods: {
  }

}
</script>

<style scoped>

</style>