<template>
  <auto-menu-layout>
    <no-duplicates></no-duplicates>
  </auto-menu-layout>
</template>

<script>
import AutoMenuLayout from "@/components/layout/AutoMenuLayout.vue";
import NoDuplicates from "@/components/auto/NoDuplicates.vue";

export default {
  name: "Auto",
  mixins: [],
  data() {
    return {
      'selected_page': 'no_duplicates',
      clipped: false,
      drawer: true,
      loading: false,
      items: [
        {
          action: 'local_activity',
          title: 'Attractions',
          path: '/',
          items: [],
        },
        {
          action: 'restaurant',
          title: 'Breakfast',
          path: '/breakfast',
          items: []
        },]
    }
  },
  methods: {
    set_loading(loading) {
      this.$set(this, 'loading', loading)
    },
    select_item(page) {
      this.$set(this, 'selected_page', page)
    },
  },
  components: {
    NoDuplicates,
    AutoMenuLayout,
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>