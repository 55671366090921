<template>
  <div @keydown.enter.prevent="preventDefault">
    <autocomplete
        :placeholder="computedPlaceholder"
        :options="type==='box'? box_labels : point_labels"
        :clear_on_select="true"
        :style="labelStyles"
        @select="select"
    ></autocomplete>
  </div>
</template>

<script>

import Annotation from "@/classes/annotation";
import Autocomplete from "@/components/Autocomplete";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
const { v4: uuidV4 } = require('uuid');

export default {
  name: "AnnotateLabels",
  components: {Autocomplete},
  mixins: [LoadLabelsMixin],
  props: {
    placeholder: {default: null},
    type: null,
    disabled: { type: Boolean, default: false },
  },
  computed: {
    labelStyles() {
      return this.disabled ? { opacity: 0.5, pointerEvents: 'none' } : {};
    },
    translatedPlaceholder() {
      return this.$t('set_label_groups.annotate_labels_placeholder');
    },
    computedPlaceholder() {
      let returnString = this.computedType + " ";
      returnString += this.placeholder !== null ? this.placeholder : this.translatedPlaceholder;
      return this.capitalizeFirstLetter(returnString);
    },
    translatedType() {
      return this.$t('set_label_groups.annotate_labels_type');
    },
    computedType() {
      return this.type !== null ? this.type : this.translatedType;
    },
  },
  data () {
    return {
      label_meta: {},
    }
  },
  methods: {
    capitalizeFirstLetter(returnString) {
      returnString = returnString.toLowerCase();
      return returnString.charAt(0).toUpperCase() + returnString.slice(1);
    },
    select (label) {
      if(!label) {
        return;
      }
      let id = uuidV4();
      let coords = [0.5, 0.5]
      if(this.type === 'box') {
        coords = [0.4, 0.4, 0.2, 0.2];
      }
      let meta = this.label_meta[label.name];
      let color = [0,180,0]
      if (meta && meta.color) {
        color = meta.color.split(',');
      }

      let annotation = new Annotation(id, this.type, coords, 1.0, label.name, color);
      this.$emit('add_annotation', annotation)
    }
  }
}
</script>

<style scoped>

</style>