<template>
  <menu-layout style="margin-top: 13px;" :loading="loading">
    <div v-if="!current_model">
      No model?
    </div>
    <div v-else-if="!loading">
      <div v-if="jobs.length === 0">
        <div class="row">
          <div class="col-md-3">
            <filter-tree
                :initial_filter="[
                  {'type':1, 'value':''},
                  {'type':13, 'value':'Yes'},
                  {'type':9, 'value':'Yes'},
                  {'type':11, 'value':'No'},
                 ]"
                @load="load" :max_results="max_results">
            </filter-tree>
          </div>
          <div class="col-md-8">
            <grid :images="images"></grid>
          </div>
        </div>

      </div>
      <jobs v-else @refresh="reloadProgress" :jobs="jobs" message="Analyse job status"></jobs>
    </div>
    <div v-else>
      LOADING
    </div>
  </menu-layout>
</template>

<script>
import Grid from "@/components/Grid.vue";
import Jobs from "@/components/Jobs.vue";
import FilterTree from "@/components/asset_filter/FilterTree";
import MenuLayout from "@/components/layout/MenuLayout";
import LoadModelsMixin from "@/mixins/LoadModelsMixin";

export default {
  name: "Validate",
  mixins: [LoadModelsMixin],
  data: function () {
    return {
      images: [],
      class_name: '',
      max_results: false,
      jobs: [],
      loading: true,
      limit: 12,
      current_filter: false,
    }
  },
  components: {
    MenuLayout,
    FilterTree,
    Grid, Jobs
  },
  computed: {
    current_model() {
      let active_models = this.models.filter((model) => {return model.active});
      if(active_models.length===0) {
        return false;
      }
      return active_models[0];
    }
  },
  methods: {
    reloadProgress() {
      this.setActiveJobs();
    },
    async setActiveJobs() {
      let jobs = await this.$api.job.get_active_jobs('calculate_conf_matrix_items', this.current_model.id);
      this.$set(this, 'jobs', jobs.filter(job => {
        return !job.processed
      }));
    },
    addGridItemProp(bboxId, prop, value) {
      let pos = this.images.map(function (e) {
        return e.bbox_id;
      }).indexOf(bboxId);
      let image = this.images[pos]
      image[prop] = value;
      this.$set(this.images, pos, image);
    },
    removeItem(asset_id) {
      let filteredImages = this.images.filter((image) => {
        return image.id !== asset_id
      });
      this.$set(this, 'images', filteredImages);
    },
    async validateAsset(asset_id, bbox_id) {
      let result = await this.$api.asset.validate(asset_id, bbox_id, this.current_model.id)
      this.addGridItemProp(bbox_id, 'validation', result)
    },
    async load(data) {

      if (!data) {
        data = this.current_filter
      } else {
        this.$set(this, 'current_filter', data)
      }

      this.$set(this, 'loading', true);

      this.images = [];
      let this2 = this;

      let result = await this.$api.annotation.filter(this.current_filter, this.limit);

      let total_results = result.total;
      let items = result.items;

      this.$set(this, 'max_results', total_results)

      if (total_results === 0) {
        this2.$set(this, 'loading', true);
      }

      for (let i = 0; i < items.length; i++) {

        let r = items[i]
        let url = r['url'];
        let asset_id = r['asset_id'];
        let bbox_id = r['bbox_id'];
        let bbox = r['bbox'];
        let certainty = r['certainty']
        let translation = r['translation']

        let data = await this.$api.asset.get_crop(url, bbox, asset_id)
        let cropData = data.crops.map((crop) => {
          return {
            translation: translation,
            data: crop.crop,
            certainty: certainty,
            bbox_id: bbox_id,
            label: crop.label,
            id: asset_id
          }
        })

        this2.images = this2.images.concat(cropData);
        await this2.validateAsset(asset_id, bbox_id)

        if (this2.images.length === total_results) {
          this.$set(this, 'loading', false);
        }
      }
    },
    async init() {
      let data = await this.$api.job.get_active_jobs('calculate_conf_matrix_items', this.current_model.id);
      if (data.length === 0) {
        let job = await this.$api.job.add_job('calculate_conf_matrix_items', this.current_model.id);
        this.$set(this, 'jobs', [job]);
      } else {
        this.$set(this, 'jobs', data.filter(job => {
          return !job.processed
        }));
      }
    }
  },
  mounted() {
    if (this.current_model) {
      //Check if there is a job, start it otherwise
      this.init();
    }
    this.$set(this, 'loading', false)
  }
}
</script>

<style scoped>

</style>