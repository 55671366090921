<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          small
          color="primary"
          v-bind="attrs"
          v-on="on"      >
        <v-icon :size="size" dark>
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
          v-for="(item, index) in menu_items"
          style="cursor: pointer"
          @click.native="item.on_click"
          :key="index"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    menu_items: {
      required: true
    },
    icon: {
      type: String,
      default: 'fas fa-bars'
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  name: "DropdownMenu"
}
</script>

<style scoped>
</style>