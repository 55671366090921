<template>
  <div>
    <div class="fade-in-out"
      :style="{'margin': '0', 'position': 'absolute', 'top': top + '%', 'left': left + '%', 'width': width + '%', 'height': height + '%', 'border': '1px solid ' + color}">
      <div
          class="show-after-fade-in-out"
          :style="{'overflow': 'none', 'font-size': '0.8em', 'margin': '0', 'position': 'absolute', 'top': '-36px', 'left':  '0px', 'color': color}">
        {{title}}
      </div>
      <div
          class="show-after-fade-in-out"
          :style="{'font-size': '0.8em', 'margin': '0', 'position': 'absolute', 'top': '-20px', 'left':  '0px', 'color': color}">
        {{subtitle}}
      </div>
      <div
           :style="{'margin': '0', 'position': 'absolute', 'top': '2px', 'left':  '2px', 'width': '5px', 'height': '5px', 'border-top': '1px solid ' + color, 'border-left': '1px solid ' + color}">
      </div>
      <div
          :style="{'margin': '0', 'position': 'absolute', 'top': '2px', 'right':  '2px', 'width': '5px', 'height': '5px', 'border-top': '1px solid ' + color, 'border-right': '1px solid ' + color}">
      </div>
      <div
          :style="{'margin': '0', 'position': 'absolute', 'bottom': '2px', 'left':  '2px', 'width': '5px', 'height': '5px', 'border-bottom': '1px solid ' + color, 'border-left': '1px solid ' + color}">
      </div>
      <div
          :style="{'margin': '0', 'position': 'absolute', 'bottom': '2px', 'right':  '2px', 'width': '5px', 'height': '5px', 'border-bottom': '1px solid ' + color, 'border-right': '1px solid ' + color}">
      </div>
    </div>

  </div>
</template>

<script>
export default {
name: "AnimatedBox",
  props: {
    top: {default: '78'},
    left: {default: '78.5'},
    width: {default: '3'},
    height: {default: '6'},
    color: {default: '#F9F6EE'},
    title: {default: null},
    subtitle: {default: null}
  }
}
</script>

<style scoped>
.fade-in-out {
  position: absolute;
  left: 0;
  animation: moveFadeOut 2.5s forwards;
}
.show-after-fade-in-out {
  animation: showAfter 2.5s forwards;
}

@keyframes showAfter {
  0% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  95% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes moveFadeOut {
  0% {
    opacity: 0;
    transform: scaleX(3.0) scaleY(3.0);
  }
  20% {
    opacity: 0.5;
    transform: scaleX(1.4) scaleY(1.4);
  }
  60% {
    opacity: 0.5;
    transform: scaleX(1.5) scaleY(1.5);
  }
  80% {
    opacity: 0.5;
    transform: scaleX(1.5) scaleY(1.5);
  }
  100% {
    opacity: 0.8;
    transform: scaleX(1);
  }
}


</style>