import Vue from 'vue'

export default {
    set_items (state, items) {
        Vue.set(state, 'items', items);
    },
    set_item (state, item) {
        let ids = state.items.map((i)=>{return i.id});
        let i = ids.indexOf(item.id)
        if(i===-1) {
            state.items.append(item);
        }
        else {
            Vue.set(state.items, i, item);
        }
    },
    update_item (state, value) {
        let item = value.item;
        let key_values = value.key_values;
        let ids = state.items.map((i)=>{return i.id});
        let i = ids.indexOf(item.id)
        for (let key in key_values) {
            Vue.set(state.items[i], key, key_values[key]);
        }
    }
}