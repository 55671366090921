<template>
  <my-profile-menu-layout>
    <seg-any-settings></seg-any-settings>
  </my-profile-menu-layout>
</template>

<script>
  import MyProfileMenuLayout from "@/components/layout/MyProfileMenuLayout";
  import SegAnySettings from '@/components/myprofile/SegAnySettings.vue';
  import CurrentUserMixin from "@/mixins/CurrentUserMixin";

  export default {
    name: "ProfileSegAny",
    data() {
      return {
      }
    },
    mixin: [CurrentUserMixin],
    mounted() {
      this.$store.commit('user/set_current_project_id', this.project_id)
    },
    methods: {
    },
    components: {
      MyProfileMenuLayout, SegAnySettings
    },
  }
</script>

<style scoped>

</style>