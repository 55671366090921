<template>
  <div>
    <row>
      <col12>
        <v-card elevation="2">
          <v-card-text>
            <div class="icon-title">
              <v-icon>mdi-security</v-icon>
              <b> {{$t('myprofile.authentication_settings')}}</b>
            </div>
            <div>
              <v-checkbox
                v-model="two_factor_auth_enabled" 
                :label="$t('myprofile.enable_2fa_label')"
                @change="handleTwoFactorAuthToggle"
              ></v-checkbox>
            </div>
          </v-card-text>
          <v-card-text v-if="two_factor_auth_enabled && !secret_key_stored">
            <div class="form-group">
              <div>
                <v-btn type="button" @click.prevent="generateQRCode" color="primary">
                  {{$t('myprofile.generate_qr_code')}}
                </v-btn>
              </div>
              <div id="qrcode-container"></div>
              <label for="secret_key">{{$t('myprofile.secret_key')}}: </label>
              <input type="text" class="form-control secret-input" id="secret_key" :value="secret_key" readonly style="width: 28%;">
              <v-btn @click="copySecret" color="primary">{{$t('myprofile.copy_secret')}}</v-btn>
            </div>

            <h5>{{$t('myprofile.instructions_title')}}</h5>
            <ul>
              <li>{{$t('myprofile.instructions_step_1')}}</li>
              <li>{{$t('myprofile.instructions_step_2')}}</li>
              <li>{{$t('myprofile.instructions_step_3')}}</li>
              <li>{{$t('myprofile.instructions_step_4')}}</li>
              <li>{{$t('myprofile.instructions_step_5')}}</li>
            </ul>

            <form>
              <v-text-field
                type="text"
                v-model="otp"
                :label="$t('myprofile.enter_otp')"
                required
              ></v-text-field>
              <v-btn
                color="primary"
                class="mr-4"
                @click="secretKeyVerification"
              >
                {{$t('myprofile.verify_2fa')}}
              </v-btn>
            </form>
          </v-card-text>
          <v-card-text v-if="two_factor_auth_enabled && secret_key_stored">
            <h5>{{$t('myprofile.2fa_set_up')}}</h5>
          </v-card-text>
        </v-card>
      </col12>
    </row>
  </div>
    
</template>

<script>

import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import QRCode from 'qrcode'
import CurrentUserMixin from "@/mixins/CurrentUserMixin";



export default {
  name: "AuthenticationSettings.vue",
  components: {Row, Col12},
  mixins: [CurrentUserMixin],
  data() {
    return {
      two_factor_auth_enabled: null,
      secret_key: null,
      otp: null,
      secret_key_stored: null,
    }
  },
  mounted() {
    this.check_2fa_enabled();
  },
  methods: {
    async check_2fa_enabled() {
      let response = await this.$api.user.user_2fa_enabled_profile();
      if(response && response.authentication_enabled) {
          this.$set(this, 'two_factor_auth_enabled', true)
          this.$set(this, 'secret_key_stored', true)
          return
      }
    },
    async generateQRCode() {
      const issuer = 'BOX21';
      const label = this.current_user.email;
      const type = 'totp';

      const keyUri = `otpauth://${type}/${encodeURIComponent(issuer)}:${encodeURIComponent(label)}?secret=${this.secret_key}&issuer=${encodeURIComponent(issuer)}`;
      const url = await QRCode.toDataURL(keyUri);

      this.$nextTick(() => {
        let qrcodeContainer = document.getElementById("qrcode-container");
        if (!qrcodeContainer) {
          return;
        }
        qrcodeContainer.innerHTML = `<img src="${url}" alt="QR Code" />`;
      });
    },
    async handleTwoFactorAuthToggle() {
      if (this.two_factor_auth_enabled) {
        let response = await this.$api.user.get_2fa_secret_key();
        if (response.secret_key !== null) {
          this.$set(this, 'secret_key', response.secret_key);
        }
      } else {
        await this.$api.user.delete_2fa_secret_key(this.secret_key);
        this.$set(this, 'secret_key', null);
        this.$set(this, 'secret_key_stored', null);
      }
    },
    async secretKeyVerification() {
      if (!this.otp) {
        await this.$store.dispatch('snackbars/add_failed_snack', this.$t('myprofile.otp_missing'))
        return;
      }
      let response = await this.$api.user.verify_2fa_secret_key_otp(this.secret_key, this.otp);
      if (response.status === 'success') {
        response = await this.$api.user.store_2fa_secret_key(this.secret_key);
        if (response) {
          if (response.status === 'success') {
            await this.$store.dispatch('snackbars/add_success_snack', this.$t('myprofile.success_message'));
            this.$set(this, 'secret_key_stored', true);
          } else {
            await this.$store.dispatch('snackbars/add_failed_snack', this.$t('myprofile.failed_message'))
          }
        }
      } else {
        await this.$store.dispatch('snackbars/add_failed_snack', this.$t('myprofile.otp_invalid'))
      }
    },
    copySecret() {
      var copyText = document.getElementById("secret_key");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/
      document.execCommand("copy");
      alert(this.$t('myprofile.copy_success'));
    },
  }
}
</script>

<style scoped>

</style>