<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: flex; align-items: center;">
                <div v-if="user.avatar_properties == null">
                    <v-avatar color="primary" :size="computedMinWidth">
                        <v-icon dark>
                            mdi-account-circle
                        </v-icon>
                    </v-avatar>
                </div>
                <div v-else>
                    <v-avatar :size="computedMinWidth">
                        <gravatar :selected_properties="user.avatar_properties" :min_width="computedMinWidth"></gravatar>
                    </v-avatar>
                </div>
            </div>
        </template>
        <span>{{ user.username }}</span>
    </v-tooltip>
</template>

<script>
import Gravatar from "@/components/users/Gravatar";

export default {
    name: "ShowGravatar",
    components: { Gravatar },
    props: {
        user: {
            type: Object,
            required: true
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedMinWidth() {
            return this.small ? '25px' : '40px';
        }
    }
}
</script>

<style scoped>

</style>
