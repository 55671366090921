<template>
  <div>
    <row>
      <col12>
        <v-card elevation="2">
          <v-card-text>
            <div>
              <v-icon>mdi-credit-card</v-icon>
              <b> {{$t('myprofile.payment_overview')}}</b><br><br>
            </div>
            <v-data-table
              :items="items"
              :headers="headers"
              :options.sync="options"
              :server-items-length="totalItems"
            >
              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:item.amount="{ item }">
                {{ formatCurrency(item.amount, item.currency) }}
              </template>
              <template v-slot:item.vat="{ item }">
                {{ item.vat }}
              </template>
              <template v-slot:item.subscription_id="{ item }">
                {{ getSubscriptionName(item.subscription_id) }}
              </template>
              <template v-slot:item.paid="{ item }">
                <v-chip 
                  :style="{ marginLeft: '3px', marginRight: '3px', marginBottom: '3px', borderWidth: '1.5px', color: getStatusColor(item.paid), borderColor: getStatusColor(item.paid) }"
                  :outlined="true">
                  {{ formatStatus(item.paid) }}
                </v-chip>
              </template>
              <template v-slot:item.duration="{ item }">
                {{ item.duration }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon v-if="!item.paid" @click="handlePay(item)">mdi-cash</v-icon>
                <v-icon v-if="item.paid" @click="downloadInvoice(item)">mdi-download</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </col12>
    </row>
  </div>
</template>


<script>
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: "PaymentOverview.vue",
  components: { Row, Col12 },
  mixins: [ApiDatatableMixin],
  data() {
    return {
      'item': "",
      'api_model': 'payment',
    };
  },
  mounted() {
    this.initStripe();
  },
  methods: {
    async downloadInvoice(item) {
      let stripe_session_id = item.stripe_session_id;
      const response = await this.$api.user.get_payment_invoice(stripe_session_id);
      if (response && response.invoice_url) {
          const invoiceUrl = response.invoice_url;
          const link = document.createElement('a');
          link.href = invoiceUrl;
          link.target = '_blank';
          link.download = 'invoice.pdf';
          link.click();
      } else {
          console.error('Failed to retrieve invoice:', response.data.error);
      }
    },      
    async initStripe() {
      // this.stripe = await loadStripe('pk_test_51NkuT7EohHftw9zSh9JT89jiVKsvThuKxPHs1BqdGmYpz0tkQS0KGhynFDB6t2OGttl0sHY9DVu3QOKfW0mAkQh200MUl6MR8U');
      this.stripe = await loadStripe('pk_test_51PVfzQG4DbJ70ENNzMELudKgIZsV383tP4DAT2HY5hYH2j4iGOorptqLFnO0OxahYtM6wC0dForfx5yyoiHcFsPF00A8R8Yul8');
    },
    getSubscriptionName(subscription_id) {
      switch (subscription_id) {
        case 0: return this.$t('myprofile.free');
        case 1: return this.$t('myprofile.limit');
        case 2: return this.$t('myprofile.pro');
        case 3: return this.$t('myprofile.superior');
        default: return this.$t('myprofile.unknown');
      }
    },
    formatCurrency(amount, currency) {
      if (amount == null || currency == null) return this.$t('myprofile.unknown');
      const formattedAmount = Number(amount).toFixed(2);
      switch (currency.toLowerCase()) {
        case 'eur': return `€${formattedAmount}`;
        case 'usd': return `$${formattedAmount}`;
        default: return formattedAmount;
      }
    },
    formatDate(date) {
      if (!date) return this.$t('myprofile.unknown');
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    formatStatus(paid) {
      if (paid == null) return this.$t('myprofile.unknown');
      return paid ? this.$t('myprofile.success') : this.$t('myprofile.failed');
    },
    getStatusColor(paid) {
      if (paid == null) return 'default';
      return paid ? 'green' : 'red';
    },
    async handlePay(item) {  
      await this.stripe.redirectToCheckout({ sessionId: item.stripe_session_id });
    }
  },
  computed: {
    headers() {
      const headers = [
        { text: this.$t('myprofile.created_at'), value: 'created_at', sortable: true },
        { text: this.$t('myprofile.amount'), value: 'amount', sortable: true },
        { text: this.$t('myprofile.vat'), value: 'vat', sortable: true },
        { text: this.$t('myprofile.subscription'), value: 'subscription_id', sortable: true },
        { text: this.$t('myprofile.duration'), value: 'duration', sortable: true },
        { text: this.$t('myprofile.status'), value: 'paid', sortable: true },
        { text: this.$t('myprofile.action'), value: 'action', sortable: false }
      ];
      return headers;
    },
  },
};
</script>


<style scoped>

</style>
