<template>
  <div>
    <v-container v-if="project">
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="d-flex justify-space-between align-center">
              <h2 class="headline">{{$t('overview.project')}}</h2>
              <v-icon small class="mr-2" @click="toggleEditMode">mdi-pencil</v-icon>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="!editMode">{{$t('overview.name')}}</v-list-item-title>
                        <v-list-item-subtitle v-if="!editMode">{{ project.name }}</v-list-item-subtitle>
                        <v-text-field v-model="editedName" v-if="editMode" :label="'Edit Name'"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title v-if="!editMode">{{$t('overview.description')}}</v-list-item-title>
                        <v-list-item-subtitle v-if="!editMode">{{ project.description }}</v-list-item-subtitle>
                        <v-text-field v-model="editedDescription" v-if="editMode" :label="'Edit Description'"></v-text-field>
                        <v-btn v-if="editMode" @click="saveChanges" color="primary">Save</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{$t('overview.created_at')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ formatDate(project.created_at) }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{$t('overview.project_id')}}</v-list-item-title>
                        <v-list-item-subtitle>{{ project.id }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{$t('overview.project_owner')}}</v-list-item-title>
                        <show-gravatar :user="projectOwner" :small="true"></show-gravatar> 
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{$t('overview.invite_users')}}</v-list-item-title>
                        <div class="avatar-container">
                          <show-gravatar v-for="user in invitedUsers" :key="user.id" :user="user" :small="true"></show-gravatar>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import LoadUsersMixin from "@/mixins/LoadUsersMixin";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";
import ShowGravatar from '@/components/projects/ShowGravatar.vue';


export default {
  name: 'ProjectDetails',
  mixins: [LoadProjectsMixin, LoadUsersMixin, CurrentUserMixin],
  components: {ShowGravatar},
  data() {
    return {
      editMode: false,
      editedName: '',
      editedDescription: '',
    };
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) {
        return ''; 
      }
      const dateObject = new Date(dateString);
      const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
      return dateObject.toLocaleDateString('en-US', options);
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
      if (this.editMode) {
        this.editedName = this.project.name;
        this.editedDescription = this.project.description;
      }
    },
    saveChanges() {
        let project = JSON.parse(JSON.stringify(this.project)); //check this logic
        project.name = this.editedName;
        project.description = this.editedDescription;
        this.$store.dispatch('project/patch', { item: project, dispatch: this.$store.dispatch });
      },
  },
  computed: {
    projectOwner() {
      return (
        this.project &&
        this.project.relationships &&
        this.project.relationships.owner &&
        this.project.relationships.owner.username
      )
        ? this.project.relationships.owner
        : 'Unknown';
    },
    invitedUsers() {
      if (!this.users) {
        return '';
      }

      const excludedUsernames = ['admin@box21.ai', 'annotate@box21.ai', 'manager@box21.ai', 'trainer@box21.ai'];

      const filteredUsers = this.users.filter(user => !excludedUsernames.includes(user.email));

      if (this.current_user && this.current_user.id === this.projectOwner.id) {
        const currentUserIndex = filteredUsers.findIndex(user => user.id === this.current_user.id);
        if (currentUserIndex !== -1) {
          filteredUsers.splice(currentUserIndex, 1);
        }
      }

      return filteredUsers;
    },
    project() {
        if (!this.projects) {
            return null;
        }
        let project_id = sessionStorage.getItem('project_id');
        project_id = parseInt(project_id);
        let filtered_project = this.projects.find(project => project.id === project_id);
        return filtered_project || null;
      },
    },
};
</script>

<style scoped>
.avatar-container {
  display: flex;
}

.show-gravatar {
  margin-right: 10px;
}
</style>



