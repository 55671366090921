export default class SegAny {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async check_or_create_assets_to_embeddings_job(asset_ids, websocketsToken) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('asset_ids', JSON.stringify(asset_ids));
        formData.append('websocketsToken', websocketsToken)
        return this.api.post_with_token('/embeddings/check-or-create-assets-to-embeddings-job', formData)
    }

    async get_embedding(asset_id) {
        let formData = new FormData();
        formData.append('asset_id', asset_id);
        return this.api.post_with_token('/embedding/get', formData)
    }
}