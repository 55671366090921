<script>

import { onUnauthenticatedPages } from '@/util/routesHelper.js'

export default {
name: "LoadModelsMixin"
  ,
  data() {
    return {
      models_loaded: false
    }
  },
  mounted() {
    this.load_models();
  },
  computed: {
    on_unauthenticated_pages() {
      return onUnauthenticatedPages.call(this);
    },
    models () {
      return this.$store.getters['model/get_items']
    },
    active_models () {
      return this.models.filter(model=>{return model.active;})
    }
  },
  methods: {
    async load_models() {
      if(this.on_unauthenticated_pages || this.on_project_page) {
        return;
      }
      if(this.on_profile_page) {
        return;
      }
      await this.$store.dispatch('model/all')
      this.$set(this, 'models_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>