<template>
  <div>
    <v-chip small>{{model_type}}</v-chip>
    <v-chip small v-if="weights">{{weights}}</v-chip>
    <v-chip small>{{epochs}}e</v-chip>
    <v-chip small>{{size}}</v-chip>
    <v-chip small v-if="eval_idx">eval_idx: {{eval_idx}}</v-chip>
    <v-chip small v-if="depth_mult">depth_mult: {{depth_mult}}</v-chip>
  </div>
</template>

<script>
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";

export default {
  mixins: [LoadProjectsMixin],
  name: "TrainConfigTagsCell",
  props: {
    train_config: Object,
  },
  computed: {
    meta() {
      return JSON.parse(this.train_config.meta);
    },
    model_type() {
      return this.meta.type;
    },
    weights() {
      return this.meta.weights;
    },
    epochs() {
      return this.meta.epochs;
    },
    eval_idx() {
      if(Object.keys(this.meta).includes('eval_idx') && this.meta.eval_idx !== '-1') {
        return this.meta.eval_idx;
      }
      return null;
    },
    depth_mult() {
      if(Object.keys(this.meta).includes('depth_mult') && this.meta.depth_mult !== '1') {
        return this.meta.depth_mult;
      }
      return null;
    },
    size() {
      if(this.meta.rect_training !== 'No') {
        return this.meta.image_size / 2 + 'x' + this.meta.image_size;
      }
      return this.meta.image_size + 'x' + this.meta.image_size;
    }
  },
}
</script>

<style scoped>

</style>