<script>
export default {
name: "LoadAnnotationGroupsMixin"
  ,
  data() {
    return {
      annotation_groups_loaded: false
    }
  },
  mounted() {
    this.load_annotation_groups();
  },
  computed: {
    annotation_groups () {
      return this.$store.getters['annotation_group/get_items']
    },

  },
  methods: {
    async load_annotation_groups() {
      await this.$store.dispatch('annotation_group/all')
      this.$set(this, 'annotation_groups_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>