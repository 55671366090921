<template>
  <v-btn rounded>{{score}}</v-btn>
</template>

<script>
export default {
  name: "ModelPerformanceChip",
  props: {
    model: {required: true},
    threshold: {required: true}
  },
  computed: {
    score () {
      let performance = this.model.performance;
      if(!performance) {
        return '-'
      }
      else {
        performance = JSON.parse(performance);
        return Math.round(performance['correct_' + this.threshold] * 100) + '%';
      }
    }
  }
}
</script>

<style scoped>

</style>