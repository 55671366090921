<template>
    <div>
        <modal @close_modal="closeModal" v-if="showModal" :title="$t('confusion_matrix.details')">
            <template v-slot:content>
                <confusion-matrix-items
                        :model_id="modalContentModelId"
                        :type="modalContentType"
                        :labels_dict="labels_dict"
                        :items="modalContent"></confusion-matrix-items>
            </template>
        </modal>
      <row v-if="items.length > 0">
        <col3>
        <my-boxes-modal :label_name="boxes_modal_label_name" v-if="show_boxes_modal" @close_modal="close_preview_label_in_modal"></my-boxes-modal>
        </col3>
        <col3>
        <v-slider
            :label="$t('confusion_matrix.confidence_treshold')"
            :hint="$t('confusion_matrix.confidence_treshold_hint')"
            max="100"
            min="0"
            step="1"
            thumb-label
            v-model="confidenceThreshold"
        ></v-slider>
        </col3>
        <col3>
          <v-slider
              :label="'Confused threshold'"
              hint="What model score makes the predictions confused?"
              max="100"
              min="0"
              step="1"
              v-model="confusedThreshold"
              thumb-label
          ></v-slider>
        </col3>
        <col3>
          <v-slider
              :label="'Background threshold'"
              hint="What model score makes the prediction false positive?"
              max="100"
              min="0"
              step="1"
              v-model="backgroundThreshold"
              thumb-label
          ></v-slider>
        </col3>
      </row>
        <data-table
                :show_delete_action="false"
                :show_edit_action="false"
                :items_per_page="50"
                :items="items"
                :single_expand="true"
                :template_slots="[
                  'label', 'correct1', 'correct2',
                  'correct_low1', 'correct_low2',
                  'confused_with1', 'confused_with2',
                  'confused_with_low1', 'confused_with_low2',
                  'confused_with_background1', 'confused_with_background2',
                  'confused_with_background_low1', 'confused_with_background_low2',
                  'missed1', 'missed2']"
                :headers="headers">
            <template v-slot:label="{ item }">
                <v-chip light>
                    {{ item.label }}
                    <i class="fas fa-table-cells-large small ml-2" @click="preview_label_in_modal(item.label)"></i>
                </v-chip>
            </template>
            <template v-slot:correct1="{item}">
                <bar-chart
                    @click.native="openModal(item.correct1_items, 'correct', 1)"
                    :max_value="item.total1"
                    :absolutes="false"
                    :inverse_colors="false"
                    :values="[item.correct1]"
                ></bar-chart>
            </template>
            <template v-slot:correct2="{item}">
                <bar-chart
                        @click.native="openModal(item.correct2_items, 'correct', 2)"
                        :max_value="item.total2"
                        :absolutes="false"
                        :inverse_colors="false"
                        :values="[item.correct2]"
                ></bar-chart>
            </template>
            <template v-slot:correct_low1="{item}">
              <bar-chart
                  @click.native="openModal(item.correct_low1_items, 'correct', 1)"
                  :max_value="item.total1"
                  :absolutes="false"
                  :inverse_colors="true"
                  :values="[item.correct_low1]"
              ></bar-chart>
            </template>
            <template v-slot:correct_low2="{item}">
              <bar-chart
                  @click.native="openModal(item.correct_low2_items, 'correct', 2)"
                  :max_value="item.total_low2"
                  :absolutes="false"
                  :inverse_colors="true"
                  :values="[item.correct_low2]"
              ></bar-chart>
            </template>
            <template v-slot:confused_with1="{item}">
                <bar-chart
                        @click.native="openModal(item.confused_with1_items, 'confused_with', 1)"
                        :max_value="item.total1"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.confused_with1]"
                ></bar-chart>
            </template>
            <template v-slot:confused_with2="{item}">
                <bar-chart
                        @click.native="openModal(item.confused_with2_items, 'confused_with', 2)"
                        :max_value="item.total2"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.confused_with2]"
                ></bar-chart>
            </template>
            <template v-slot:confused_with_low1="{item}">
              <bar-chart
                  @click.native="openModal(item.confused_with_low1_items, 'confused_with', 1)"
                  :max_value="item.total1"
                  :absolutes="true"
                  :inverse_colors="true"
                  :values="[item.confused_with_low1]"
              ></bar-chart>
            </template>
            <template v-slot:confused_with_low2="{item}">

              <bar-chart
                  @click.native="openModal(item.confused_with_low2_items, 'confused_with', 2)"
                  :max_value="item.total2"
                  :absolutes="true"
                  :inverse_colors="true"
                  :values="[item.confused_with_low2]"
              ></bar-chart>
            </template>
            <template v-slot:missed1="{item}">

                <bar-chart
                        @click.native="openModal(item.missed1_items, 'missed', 1)"
                        :max_value="item.total1"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.missed1]"
                ></bar-chart>
            </template>
            <template v-slot:missed2="{item}">
                <bar-chart
                        @click.native="openModal(item.missed2_items, 'missed', 2)"
                        :max_value="item.total2"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.missed2]"
                ></bar-chart>
            </template>
            <template v-slot:confused_with_background1="{item}">
                <bar-chart
                        @click.native="openModal(item.confused_with_background1_items, 'confused_with_background', 1)"
                        :max_value="item.total1"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.confused_with_background1]"
                ></bar-chart>
            </template>
            <template v-slot:confused_with_background2="{item}">
                <bar-chart
                        @click.native="openModal(item.confused_with_background2_items, 'confused_with_background', 2)"
                        :max_value="item.total2"
                        :absolutes="true"
                        :inverse_colors="true"
                        :values="[item.confused_with_background2]"
                ></bar-chart>
            </template>
            <template v-slot:confused_with_background_low1="{item}">
              <bar-chart
                  @click.native="openModal(item.confused_with_background_low1_items, 'confused_with_background_low', 1)"
                  :max_value="item.total1"
                  :absolutes="true"
                  :inverse_colors="true"
                  :values="[item.confused_with_background_low1]"
              ></bar-chart>
            </template>
            <template v-slot:confused_with_background2="{item}">
              {{item}}
              <bar-chart
                  @click.native="openModal(item.confused_with_background_low2_items, 'confused_with_background_low', 2)"
                  :max_value="item.total2"
                  :absolutes="true"
                  :inverse_colors="true"
                  :values="[item.confused_with_background_low2]"
              ></bar-chart>
            </template>
        </data-table>
    </div>
</template>

<script>
  import DataTable from "@/components/DataTable";
  import BarChart from "@/components/confusion_matrix/BarChart";
  import Modal from "@/components/Modal";
  import ConfusionMatrixItems from "@/components/confusion_matrix/ConfusionMatrixItems";
  import Row from "@/components/layout/Row";
  import Col3 from "@/components/layout/Col3";
  import LoadLabelsMixin from "@/mixins/LoadLabelsMixin.vue";
  import MyBoxesModal from "@/components/MyBoxesModal";

  export default {
    mixins: [LoadLabelsMixin],
    name: "ConfusionMatrix",
    components: {Col3, Row, ConfusionMatrixItems, Modal, BarChart, DataTable, MyBoxesModal},
    data () {
      return {
        confidenceThreshold: 70,
        confusedThreshold: 50,
        backgroundThreshold: 50,
        showModal: false,
        modalContent: false,
        modalContentType: false,
        modalContentModelId: false,
        show_boxes_modal: false,
        boxes_modal_label_name: false
      }
    },
    props: {
      model_results: {required: true},
    },
    methods: {
      preview_label_in_modal(label_name) {
        this.$set(this, 'show_boxes_modal', true);
        this.$set(this, 'boxes_modal_label_name', label_name);
      },
      close_preview_label_in_modal() {
        this.$set(this, 'show_boxes_modal', false);
        this.$set(this, 'boxes_modal_label_name', null);
      },
      openModal(content, modalContentType, modalContentModelId) {
        if(this.model_results.length > 0 && modalContentModelId === 1){
          modalContentModelId = this.model_results[0].model_id;
        }
        if(this.model_results.length > 1 && modalContentModelId === 2){
          modalContentModelId = this.model_results[1].model_id;
        }

        this.$set(this, 'modalContentType', modalContentType)
        this.$set(this, 'modalContentModelId', modalContentModelId)
        this.$set(this, 'modalContent', content)
        this.$set(this, 'showModal', true)
      },
      closeModal() {
        this.$set(this, 'showModal', false)
      },
      ucfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      add_headers_for_models(text, id, models, items) {
        for(let i=0; i<models.length; i++) {
          let label = this.ucfirst(text) + ' ' + (i + 1)
          let id_label = id + (i + 1).toString()
          items.push(
            { text: label, value: id_label },)
        }
        return items;
      },
      add_values_to_row(result_key, output_result_key, model_results, label_name, row, percentage, threshold, lower_than_threshold) {
        for(let i=0; i<model_results.length; i++) {
          let id_label = output_result_key + (i + 1).toString()

          if(-1 === Object.keys(model_results[i]['results']).indexOf(label_name)) {
            row[id_label] = 0;
          }
          else {
            let items = model_results[i]['results'][label_name][result_key];

            if (result_key === 'total') {
              row[id_label] = items
            } else if (items) {
              items = JSON.parse(items)
              if(threshold) {
                if(lower_than_threshold) {
                  items = items.filter(item=>{return item.conf < threshold})
                }
                else {
                  items = items.filter(item=>{return item.conf >= threshold})
                }

              }

              if(percentage) {
                row[id_label] = items.length / row['total' + (i+1)] * 100;
              }
              else {
                row[id_label] = items.length;
              }

              row[id_label + '_items'] = items;
            } else if (items === '') {
              row[id_label] = 0;
            }
          }
        }
        return row
      },
      add_row_for_models(label_name, model_results, items) {
        let row = {}
        row['label'] = label_name
        row = this.add_values_to_row('total', 'total', model_results, label_name, row)
        row = this.add_values_to_row('correct', 'correct', model_results, label_name, row, true, this.confidenceThreshold, false)
        row = this.add_values_to_row('correct', 'correct_low',model_results, label_name, row, true, this.confidenceThreshold, true)
        row = this.add_values_to_row('confused_with','confused_with',
            model_results, label_name, row, false,
            this.confusedThreshold, false)
        row = this.add_values_to_row('confused_with', 'confused_with_low',
            model_results, label_name, row, false,
            this.confusedThreshold, true)
        row = this.add_values_to_row('confused_with_background','confused_with_background',
            model_results, label_name, row, false,
            this.backgroundThreshold, false)
        row = this.add_values_to_row('confused_with_background', 'confused_with_background_low',
            model_results, label_name, row, false,
            this.backgroundThreshold, true)
        row = this.add_values_to_row( 'missed', 'missed',model_results, label_name, row)
        items.push(row);
        return items;
      }
    },
    computed: {
      model_labels() {
        let labels = [];
        for(let i=0; i<this.model_results.length; i++) {
          let model_result = this.model_results[i];
          let result_labels = Object.keys(model_result.results);
          result_labels.forEach(label_name => {
            labels.push(label_name);
          });
        }
        return [...new Set(labels)];
      },
      items() {
        let items = []
        this.model_labels.forEach(label_name=>{
          this.add_row_for_models(label_name, this.model_results, items)
        })
        return items;
      },
      headers() {
        let items = []
        items.push({ text: 'Label', value: 'label' });
        items = this.add_headers_for_models('Total', 'total', this.model_results, items)
        items = this.add_headers_for_models('Correct', 'correct', this.model_results, items)
        items = this.add_headers_for_models('Correct low', 'correct_low', this.model_results, items)
        items = this.add_headers_for_models('Confused', 'confused_with', this.model_results, items)
        items = this.add_headers_for_models('Confused low', 'confused_with_low', this.model_results, items)
        items = this.add_headers_for_models('Missed', 'missed', this.model_results, items)
        items = this.add_headers_for_models('False positive', 'confused_with_background', this.model_results, items)
        items = this.add_headers_for_models('False positive low', 'confused_with_background_low', this.model_results, items)
        return items;
      },
    }
  }
</script>

<style scoped>

</style>