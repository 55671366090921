<template>
  <my-profile-menu-layout>
    <change-password @loading-change="updateLoading"></change-password>
  </my-profile-menu-layout>
</template>

<script>
  import MyProfileMenuLayout from "@/components/layout/MyProfileMenuLayout";
  import ChangePassword from '@/components/myprofile/ChangePassword.vue';
  import CurrentUserMixin from "@/mixins/CurrentUserMixin";

  export default {
    name: "ProfileChangePassword",
    data() {
      return {
        loading: false,
      }
    },
    mounted() {
      this.$store.commit('user/set_current_project_id', this.project_id)
    },
    mixin: [CurrentUserMixin],
    methods: {
      updateLoading(value) {
        this.loading = value;
      },
    },
    components: {
      MyProfileMenuLayout, ChangePassword
    },
  }
</script>

<style scoped>

</style>