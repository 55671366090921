import vSelect from 'vue-select'

<template>
  <v-dialog
      @click:outside="close"
      v-model="dialog"
      scrollable
    >
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{computedTitle}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="close">far fa-window-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar v-if="$slots.tabs" color="white" style="margin-bottom: 30px;">
        <slot name="tabs"></slot>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
              <row>
                <col12>
                  <slot name="content"></slot>
                </col12>
              </row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="buttons"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Col12 from "@/components/layout/Col12";
import Row from "@/components/layout/Row";
export default {
  components: {Row, Col12},
  props: {
    'loading': {default: false},
    'title': {default: null}
  },
  computed: {
    translatedTitle() {
      return this.$t('set_label_groups.default_title');
    },
    computedTitle() {
      return this.title !== null ? this.title : this.translatedTitle;
    }
  },
  data () {
    return {
      'dialog': true
    }
  },
  methods: {
      close() {
        this.$emit('close_modal')
      },
  },
}
</script>

<style scoped>
</style>