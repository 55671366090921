<template>
    <div class="row">
        <div class="col-md-3">
            <filter-tree
                    :initial_filter="[
                {'type':27, 'value':''},
                {'type':23, 'value':'No'},
                {'type':9, 'value':'No'},
                {'type':11, 'value':'No'},
                {'type':7, 'value':''}
               ]"
                    @update="update"
                    @load="load" :max_results="max_results"></filter-tree>
        </div>
        <div class="col-md-9">

            <div>
                <asset-grid @update_selection_asset_ids="update_selection_asset_ids" @reload="load" :items="items"></asset-grid>
            </div>
        </div>
    </div>
</template>

<script>
  import FilterTree from "@/components/asset_filter/FilterTree";
  import AssetGrid from "@/components/AssetGrid";
  export default {
    name: "FilterAssetWithPreview",
    components: {AssetGrid, FilterTree},
    data () {
      return {
        'items': [],
        'max_results': 12,
      }
    },
    methods: {
      update_selection_asset_ids(asset_ids) {
        this.$emit('update_selection_asset_ids', asset_ids)
      },
      update (filters) {
        this.$emit('update_filter', filters)
      },
      async load(filters, limit) {
        this.$emit('update_filter', filters)

        if(!filters) {
          filters = this.filters
          limit = this.limit
        }

        this.items = [];
        let results = await this.$api.asset.filter(filters, limit)
        this.$emit('loaded')

        let total_results = results.total;
        let items = results.items
        let max_results = results.max_results;
        this.$set(this, 'max_results', max_results);

        if (items.length === 0) {
          this.$set(this,'loading',false);
          this.$set(this, 'max_results', 0);
        }

        for (let i = 0; i < items.length; i++) {

          let r = items[i]

          let data = await this.$api.asset.load(r.id);

          let colors = data.colors;
          let j = 0;
          let annotations = data.annotations.map(annotation => {
            annotation.color_values = colors[j];
            if(annotation.color) {
              annotation.color_values = annotation.color.split(',')
            }
            annotation.type = annotation.type === 1 ? 'box' : 'point'
            j = j + 1;
            return annotation
          })
          let path = this.$api_host + data.path
          this.items = this.items.concat({checked: true, id: r.id, annotations: annotations, path: path});
          if (this.items.length === total_results) {
            this.$set(this,'loading',false);
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>