<template>
  <div>
    <div>
      <model-performance-summary :models="related_models" :threshold="threshold" :related_jobs="related_active_jobs"></model-performance-summary>
    </div>
  </div>
</template>

<script>

import ModelPerformanceSummary from "@/components/models/ModelPerformanceSummary";


export default {
  name: "TrainConfigModelsAndPerformanceCell",
  props: {
    train_config: Object,
    models: Array,
    jobs: Array,
    threshold: Number,
  },
  components: {ModelPerformanceSummary},
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    related_active_jobs() {
      return this.jobs.filter(job => {
        const meta = JSON.parse(job.description);
        return job.processed != 1 && meta.train_config_id === this.train_config.id;
      });
    },
    related_models() {
      if(!this.models) {
        return [];
      }
      return this.models.filter(model => model.train_config_id === this.train_config.id);
    },
  },
}
</script>

<style scoped>

</style>