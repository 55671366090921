<template>
  <div>
    <v-alert 
    :type="alertType" 
    :color="color"

    dense>
      {{ hintNumber }}. {{ text }}
      <span class="redirect-button" @click="redirectToPath">
        <i class="fa fa-arrow-circle-right" style="cursor: pointer; margin-left: 8px;"></i> 
      </span>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'HintBanner',
  props: {
    text: {
      type: String,
      required: true,
    },
    redirectPath: {
      type: String,
      required: true,
    },
    hintNumber: {
      type: String,
      required: true,
    },
    condition: {
      type: Boolean,
      default: true, 
    },
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    redirectToPath() {
      this.$router.push(this.redirectPath);
    },
    
  },
  computed: {  
    alertType() {
      return this.condition ? 'info' : 'success';
    },
    color () {
      return this.condition ? 'primary' : 'success';
    }
  },
  mounted() {
  },
};
</script>

<style scoped>

</style>