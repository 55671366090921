import actions from '@/store/modules/user/actions.js'
import mutations from '@/store/modules/user/mutations.js'
import getters from '@/store/modules/user/getters.js'
import {base_state} from '@/store/modules/base/state'

const state = {
  ...base_state,
  'api_module': 'user',
  'current_user_id': false,
  'current_project_id': false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}