<template>
    <menu-layout
            :loading="loading"
    >
        <row>
            <col2>
                <page-menu
                        :name="$t('settings_menu.name')"
                        :title="$t('settings_menu.title')"
                        :items="[
                          {'name': $t('settings_menu.users') , 'route': '/settings/users'},
                          {'name': $t('settings_menu.Project'), 'route': '/settings/project'},
                          {'name': $t('settings_menu.Tasks'), 'route': '/settings/tasks'},
                          {'name': $t('settings_menu.Instances'), 'route': '/settings/instances'},
                          {'name': $t('settings_menu.Labels'), 'route': '/settings/labels'},
                          {'name': $t('settings_menu.Label_groups'), 'route': '/settings/label-groups'},
                          {'name': $t('settings_menu.Label_sets'), 'route' : '/settings/label-sets'},
                          {'name': $t('settings_menu.Train_configurations'), 'route': '/settings/train-configurations'},
                          {'name': $t('settings_menu.Jobs'), 'route': '/settings/jobs'}
                        ]"
                ></page-menu>
            </col2>
            <col12 style="max-width: 1000px;" >
                <slot></slot>
            </col12>
        </row>
    </menu-layout>
</template>

<script>
import Row from "@/components/layout/Row";
import Col2 from "@/components/layout/Col2";
import PageMenu from "@/components/layout/PageMenu";
import Col12 from "@/components/layout/Col12";
import MenuLayout from "@/components/layout/MenuLayout";

export default {
  name: "SettingsMenuLayout",
  components: {MenuLayout, Col12, PageMenu, Col2, Row},
  props: {
    'loading': {default: false},
    'title': {default: ''},
    'subtitle': {default: ''},
    'menu_items': {
      default: () => {
        return [
        {
          'name': 'Settings',
          'icon': 'fas fa-cog',
          'items': [
              {'name': 'Wallio'},
              {'name': 'Pieter pet'},
              {'name': 'Gekki'},
          ]
        }
      ]
      }
    },
  },
  methods: {
    select_item(item) {
      this.$emit('select_item', item);
    }
  }
}
</script>

<style scoped>

</style>