<template>
    <v-btn
            class="ma-2"
            text
            icon
            @click.native="pressed"
            color="blue lighten-2"
    >
        <v-icon>fa-solid fa-arrows-rotate</v-icon>
    </v-btn>
</template>

<script>
  export default {
    name: "RefreshButton",
    props: {
      disabled: {default: false}
    },
    methods: {
      pressed () {
        this.$emit('pressed')
      }
    }
  }
</script>

<style scoped>

</style>