export default class Overview {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async get_todo_list_booleans() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/overview/get-todo-items', formData)
    }
}