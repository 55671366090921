<template>
  <col4>
  <v-card class="elevation-4">
      <show-alert v-if="nrOfProjectsExceeded" :alert-message="projectLimitReachedMessage"></show-alert>
      <v-card-title>
        {{$t('projects.add_new')}}
      </v-card-title>
      <v-card-subtitle style="min-height: 60px;">
        {{$t('projects.upload_your')}}
      </v-card-subtitle>
     <v-card-text>
      <row>
        <col6>
          <v-img
            :src="static_path + '/examples/africa.png'"
            :lazy-src="'static/examples/chital.png'"
            aspect-ratio="1"
            class="grey lighten-2"
          >
              <v-overlay
                      :absolute="true"
                      :opacity="0.5"
                      :value="true"
              >
                  <v-btn
                          @click="add_project"
                          color="primary"
                          :disabled="nrOfProjectsExceeded"
                  >
                      {{$t('projects.add_btn')}}
                  </v-btn>
              </v-overlay>
          </v-img>
        </col6>
        <col6>
            <v-avatar  style="margin: 1px;" color="primary">
                <v-icon dark>
                    mdi-account-circle
                </v-icon>
            </v-avatar>
            <v-avatar  style="margin: 1px;" color="primary">
                <v-icon dark>
                    mdi-account-circle
                </v-icon>
            </v-avatar>
        </col6>
      </row>
     </v-card-text>
<!--     <v-card-actions>-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn color="primary" to="/">Open</v-btn>-->
<!--     </v-card-actions>-->
  </v-card>
    </col4>
</template>

<script>
import Col4 from "@/components/layout/Col4";
import Row from "@/components/layout/Row";
import Col6 from "@/components/layout/Col6";
import ShowAlert from "@/components/ShowAlert.vue";
import CurrentUserMixin from '@/mixins/CurrentUserMixin.vue';

export default {
name: "ProjectAddCard",
  components: {Col6, Row, Col4, ShowAlert},
  mixins: [CurrentUserMixin],
  data () {
    return {
      nrOfProjectsExceeded: false
    }
  },
  methods: {
    async add_project() {
      let project = { name: 'My new project', description: 'Description for my new project' };
      let response = await this.$store.dispatch('project/add', project);
      if (response && response.id) {
        this.$store.commit('user/set_current_project_id', response.id);
        await this.$router.push({ name: 'SettingsProject' });
      } else if (response && typeof response === 'object' && response.limit_reached && response.limit_type === 'PROJECT_LIMIT') {
        this.$set(this, 'nrOfProjectsExceeded', true);
      }
    }
  },
  computed: {
    projectLimitReachedMessage() {
      return this.getLimitMessage('projects', 'PROJECT_LIMIT');
    },
    static_path () {
      return this.$api_url
    }
  }
}
</script>

<style scoped>

</style>