<template>
  <my-profile-menu-layout>
    <upgrade-subscription v-if="payment_enabled"></upgrade-subscription>
  </my-profile-menu-layout>
</template>

<script>
  import MyProfileMenuLayout from "@/components/layout/MyProfileMenuLayout";
  import UpgradeSubscription from '@/components/myprofile/UpgradeSubscription.vue';
  import CurrentUserMixin from "@/mixins/CurrentUserMixin";

  export default {
    name: "ProfileUpgradeSubscription",
    data() {
      return {
      }
    },
    mixins: [CurrentUserMixin],
    mounted() {
      this.$store.commit('user/set_current_project_id', this.project_id)
    },
    computed: {
      payment_enabled() {
        return this.current_user && this.current_user.payment_enabled;
      }
    },
    methods: {
    },
    components: {
      MyProfileMenuLayout, UpgradeSubscription
    },
  }
</script>

<style scoped>

</style>