<template>
    <v-list dense nav>
      <template v-if="current_user && !on_unauthenticated_pages && !on_password_reset_page">
        <div v-if="!project_id_set || on_project_page">
          <main-side-menu-item :name="$t('side_menu.projects')" icon="fas fa-folder-open" link="/projects" :is-active="isCurrentRoute('/projects')"></main-side-menu-item>
          <v-divider></v-divider>
          <v-list-item
                  link
                  style="margin-top: 1px;"
                  @click.native="toggle_menu"
          >
            <v-list-item-icon>
              <v-icon style="opacity: 0.8; width: 22px; color:whitesmoke;">fas fa-bars</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="opacity: 0.8; color: whitesmoke; font-size: 1.0em" >{{$t('side_menu.hide_text')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
        <div v-else>
          <main-side-menu-item :name="$t('side_menu.projects')" icon="fas fa-folder-open" link="/projects" :is-active="isCurrentRoute('/projects')"></main-side-menu-item>
          <v-divider></v-divider>
          <main-side-menu-item v-if="is_admin || is_manager" icon="fa-solid fa-house" name="Overview" link="/overview" :is-active="isCurrentRoute('/overview')"></main-side-menu-item>
          <main-side-menu-item id="menu_annotate" :name="$t('side_menu.annotate')" icon="fas fa-edit" link="/annotate" :is-active="isCurrentRoute('/annotate')"></main-side-menu-item>
          <!-- <main-side-menu-item v-if="instances_enabled" icon="fas fa-user-friends" name="Instances" link="/instances" :is-active="isCurrentRoute('/instances')"></main-side-menu-item> -->
          <v-divider></v-divider>
          <main-side-menu-item :name="$t('side_menu.boxes')" icon="fas fa-th-large" link="/myboxes" :is-active="isCurrentRoute('/myboxes')"></main-side-menu-item>
          <main-side-menu-item :name="$t('side_menu.assets')" icon="fas fa-images" link="/myassets" :is-active="isCurrentRoute('/myassets')"></main-side-menu-item>
          <main-side-menu-item v-if="is_admin || is_manager" icon="fas fa-chart-bar" :name="$t('side_menu.progress')" link="/progress" :is-active="isCurrentRoute('/progress')"></main-side-menu-item>
          <v-divider></v-divider>
          <main-side-menu-item v-if="model_enabled && (is_admin || is_manager)" icon="fas fa-exclamation-triangle" :name="$t('side_menu.most_confused')" link="/mostconfused" :is-active="isCurrentRoute('/mostconfused')"></main-side-menu-item>
          <main-side-menu-item v-if="(is_admin || is_manager)" icon="fas fa-solid fa-wand-magic-sparkles" :name="$t('side_menu.automatic_tasks')" link="/auto" :is-active="isCurrentRoute('/auto')"></main-side-menu-item>
          <!-- <main-side-menu-item v-if="model_enabled && (is_admin || is_manager)" icon="fas fa-search" :name="$t('side_menu.validate')" link="/validate" :is-active="isCurrentRoute('/validate')"></main-side-menu-item> -->
          <main-side-menu-item v-if="is_admin || is_manager" icon="fas fa-calculator" :name="$t('side_menu.models')" link="/models" :is-active="isCurrentRoute('/models')"></main-side-menu-item>
          <v-divider></v-divider>
          <main-side-menu-item v-if="is_admin || is_manager" icon="fas fa-download" :name="$t('side_menu.import')" link="/import" :is-active="isCurrentRoute('/import')"></main-side-menu-item>
          <main-side-menu-item id="menu_settings" v-if="is_admin || is_manager" icon="fas fa-cog" :name="$t('side_menu.settings')" link="/settings" :is-active="isCurrentRoute('/settings')"></main-side-menu-item>
          <v-divider></v-divider>
          <v-list-item
                  link
                  style="margin-top: 1px;"
                  @click.native="toggle_menu"
          >
            <v-list-item-icon>
              <v-icon style="opacity: 0.8; width: 22px; color:whitesmoke;">fas fa-bars</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="opacity: 0.8; color: whitesmoke; font-size: 1.0em" >{{$t('side_menu.hide_text')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
    </v-list>
</template>

<script>
import MainSideMenuItem from "@/components/layout/MainSideMenuItem";
import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
import LoadModelsMixin from "@/mixins/LoadModelsMixin";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";
import { onProfilePages, onProjectsPage, onPasswordResetPage } from '@/util/routesHelper.js';

export default {
  name: "MainSideMenu",
  components: {MainSideMenuItem},
  props: {
  },
  mixins: [LoadSettingsMixin, LoadModelsMixin, CurrentUserMixin],
  methods: {
    toggle_menu() {
      this.$emit('toggle')
    },
    isCurrentRoute(link) {
      return this.$route.path === link;
    }
  },
  computed: {
    user_authenticated() {
      return localStorage.token !== 'false' && localStorage.token !== 'false';
    },
    project_id_set() {
      let project_id = this.$store.getters['user/get_current_project_id']
      return project_id && project_id !== 'false' && project_id !== false;
    },
    on_project_page () {
      return onProjectsPage.call(this);
    },
    on_password_reset_page () {
      return onPasswordResetPage.call(this);
    },
    on_profile_page() {
      return onProfilePages.call(this);
    },
    model_enabled () {
      return this.models && this.models.length > 0;
    }
  }
}
</script>

<style scoped>

</style>