import base_getters from '@/store/modules/base/getters.js'

export default {
    ...base_getters,
    get_favourite: (state) => (key) => {
        return state.favourites[key];
    },
    get_users: (state) => (key) => {
        return state.users[key];
    },
}