<template>
  <div>
    <v-alert :type="alertType" text border="left">
      {{ alertMessage }}
    </v-alert>
  </div>
</template>

<script>

export default {
  name: "ShowAlert.vue",
  props: {
    alertType: {
      type: String,
      default: "warning",
    },
    alertMessage: {
      type: String,
      default: "", 
    },
  },
  data() {
    return {
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>

</style>