<script>
export default {
name: "LoadAvailableModelsMixin"
  ,
  data() {
    return {
      available_models_loaded: false
    }
  },
  mounted() {
    this.load_available_models();
  },
  computed: {
    available_models () {
      return this.$store.getters['available_model/get_items']
    },
    available_model_parameters () {
      return this.$store.getters['available_model_parameter/get_items'];
    },
    parameters_for_all() {
      return this.available_model_parameters.filter(parameter => {
          return !parameter.for_type && !parameter.for_available_model;
      })
    },
    parameters_per_model_type() {
      let key = 'for_type'
      return this.groupByKey(this.available_model_parameters, key);
    },
    parameters_per_available_model() {
      let key = 'for_available_model'
      return this.groupByKey(this.available_model_parameters, key);
    },
    generic_parameters() {
        return this.available_model_parameters.filter(p=>{return !p.type});
    }
  },
  methods: {
    groupByKey(array, key) {
      return array
        .reduce((hash, obj) => {
            if(obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
        }, {})
    },
    async load_available_models() {
      let promises = []
      promises.push(this.$store.dispatch('available_model/all', {reload: false}))
      promises.push(this.$store.dispatch('available_model_parameter/all', {reload: false}))

      Promise.all(promises).then(() => {
          this.$set(this, 'available_models_loaded', true)
      });
    },
  }
}
</script>

<style scoped>

</style>