export default class Role {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/roles', formData)
    }

    async add(email, role) {
        let formData = new FormData();
        formData.append('user', email);
        formData.append('role', role);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/add-role', formData)
    }

    async remove(email, role) {
        let formData = new FormData();
        formData.append('user', email);
        formData.append('role', role);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/remove-role', formData)
    }
}