<template>
  <div>
    <v-file-input multiple prepend-icon="mdi-camera" v-model="files" @change="select"></v-file-input>
  </div>
</template>

<script>

import axios from 'axios'
const { v4: uuidV4 } = require('uuid')

export default {
  name: "MultiFileUploader",
  data() {
    return {
      files: [],
      ids_processing: [],
      ids_processed: [],
      file_ids: [],
      chunks: [],
      chunkProcessed: [],
      stored_filenames: [],
      failed: [],
      upload_success: [],
      upload_failure: [],
      chunkProgress: [],
    };
  },
  props: {
    'trigger_reset_form' : {required: true},
    'post_url' : {default: '/add-model-file'},
    'upload_trigger': {default: true},
    'params': {default: {}},
  },
  watch: {
    upload_trigger() {
      this.uploadNextFile()
    },
    chunks(n) {
      if (n.length > 0 && !this.failed) {
        this.upload();
      }
    },
    trigger_reset_form() {
      this.files = null;
    },
    upload_success: {
      handler(newSuccess) {
        if (newSuccess && newSuccess.length > 0) {
          this.$emit('success-messages', newSuccess);
        }
      },
      deep: true,
    },
    upload_failure: {
      handler(newFailure) {
        if (newFailure && newFailure.length > 0) {
          this.$emit('failure-messages', newFailure);
        }
      },
      deep: true,
    },
    files: {
      handler(newFiles) {
        if (newFiles && newFiles.length > 0) {
          let inputFilesSelected = newFiles.length > 0;
          this.$emit('files-selected', inputFilesSelected);
        }
      },
      deep: true,
    }
  },
  computed: {
    progress() {
      return Math.floor((this.ids_processed.length) / this.file_ids.length * 100);
    },
  },

  methods: {
    formData(i) {
      let file = this.files[i]
      let formData = new FormData;

      formData.set('is_last', this.chunkProcessed[i].length === this.chunks[i].length-1);
      formData.set('project_id', sessionStorage.getItem('project_id'))
      formData.set('file', this.chunks[i][this.chunkProcessed[i].length], `${file.name}.part`);
      formData.set('original_name', file.name);
      formData.set('id', this.file_ids[i]);
      formData.set('chunk_offset', this.chunkProcessed[i].length);
      Object.keys((this.params)).forEach(paramKey => {
        formData.set(paramKey, this.params[paramKey]);
      });


      return formData;
    },
    config(i) {
      return {
        method: 'POST',
        data: this.formData(i),
        url: this.post_url,
        headers: {
          'Content-Type': 'application/octet-stream',
          'x-access-token': localStorage.token
        },
        onUploadProgress: () => {}
      };
    },
    select() {
      this.createInitialState();

      for(let i=0; i<this.files.length; i++) {
        this.file_ids.push(uuidV4());
      }

      if(this.upload_trigger === false) {
        this.uploadNextFile();
      }
    },
    uploadNextFile() {
      if(this.files.length === 0) {
        return;
      }

      if(this.ids_processed.length === this.files.length) {
        this.$emit('multi-upload-complete');
        return;
      }

      for(let i=0; i<this.files.length; i++) {
        if(!this.ids_processing.includes(i) && !this.ids_processed.includes(i)) {
          this.uploadFile(i)
          return;
        }
      }
    },
    uploadFile(i) {
      this.chunkProcessed.push([])
      this.createChunks(i)
      this.uploadNextChunk(i, 0)
    },
    uploadNextChunk(i, chunk_i) {
      let this2 = this;

      axios(this.config(i)).then(response => {
        if (response.data.error) {
          this2.failed = true;
          this2.error = response.data.error;

          this2.handleChunkProgress(i, this2.chunks[i].length, chunk_i);

          if (chunk_i === this2.chunks[i].length - 1) {
            this2.upload_failure.push({
              filename: response.data.filename,
              error: response.data.error
            });
            this2.ids_processed.push(i);
            this2.$emit("progress-changed", this2.progress);
            this2.uploadNextFile();
          } else {
            this2.uploadNextChunk(i, chunk_i + 1);
          }
        } else {
          this2.stored_filename = response.data.filename;

          this2.chunkProcessed[i].push(chunk_i);

          this2.handleChunkProgress(i, this2.chunks[i].length, chunk_i);

          if (chunk_i === this2.chunks[i].length - 1) {
            this2.upload_success.push(response.data.filename);
            if (this2.chunkProcessed[i].length === this2.chunks[i].length) {
              this2.ids_processed.push(i);
              this2.$emit("progress-changed", this2.progress);
            }
            this2.uploadNextFile();
          } else {
            this2.uploadNextChunk(i, chunk_i + 1);
          }
        }
      }).catch(error => {
        if (
          error &&
          error.response &&
          error.response.data &&
          typeof error.response.data === "object" &&
          error.response.data.limit_reached &&
          error.response.data.limit_type === "ASSET_LIMIT"
        ) {
          this2.$emit("image-limit-reached");
        }
        this2.failed = true;
        this2.error = error;

        this2.handleChunkProgress(i, this2.chunks[i].length, chunk_i);
      });
    },
    handleChunkProgress(fileIndex, totalChunks, uploadedChunks) {
      let chunkProgress;
      // Check if uploadedChunks equals totalChunks - 1, then set progress to 100%
      if (uploadedChunks === totalChunks - 1) {
        chunkProgress = 100;
      } else {
        chunkProgress = (uploadedChunks / totalChunks) * 100;
      }
      this.$set(this.chunkProgress, fileIndex, chunkProgress);
      this.$emit("chunk-progress-changed", this.chunkProgress);
    },
    createInitialState() {
      this.$set(this, 'ids_processing', []);
      this.$set(this, 'ids_processed', []);
      this.$set(this, 'file_ids', []);

      this.$set(this, 'chunks', []);
      this.$set(this, 'chunkProcessed', []);

      this.$set(this, 'stored_filenames', []);

      this.$set(this, 'failed', []);
    },
    createChunks(id) {
      let file = this.files[id]
      this.$emit('upload_started');
      let size = 500000, chunks = Math.ceil(file.size / size);

      this.chunks.push([])
      for (let i = 0; i < chunks; i++) {
        this.chunks[id].push(file.slice(
            i * size, Math.min(i * size + size, file.size), file.type
        ));
      }
    }
  }
}
</script>

<style scoped>

</style>