<template>
  <div>
    <row>
      <col10 style="cursor: pointer" @click.native="edit_mode=!edit_mode">
        {{ group.name }}
      </col10>
      <col2>
        <v-btn icon @click.native="delete_group" color="primary" x-small>
          <v-icon>far fa-trash-alt</v-icon>
        </v-btn>
        <v-btn icon @click.native="copy_group" color="primary" x-small>
          <v-icon>far fa-copy</v-icon>
        </v-btn>
      </col2>
    </row>
    <modal v-if="edit_mode" @close_modal="close_modal" :loading="loading">
      <template v-slot:title>
        {{ group.name }}
      </template>
      <template v-slot:content>
      <row >
        <col12>
          <v-text-field outlined type="text" v-model="name"></v-text-field>
        </col12>
        <col12>
          <v-textarea outlined type="text" v-model="description"></v-textarea>
        </col12>
      </row>
      <row>
        <col7>
              <annotated-image
                @update_coords="updateAnnotation"
                @delete_annotation="deleteAnnotation"
                @select="selectAnnotation"
                @unselect="unselectAnnotation"
              :image_url="'/api/static/white-canvas.png'" :annotations="annotations"></annotated-image>
        </col7>
        <col4>
          <row12>
              <annotate-labels @add_annotation="addAnnotation" type="box"></annotate-labels>
              <annotate-labels @add_annotation="addAnnotation" type="point"></annotate-labels>
          </row12>
          <row12>
              <annotations-list
                      @update_label="updateAnnotationLabel"
                      @clear="clearAnnotations"
                      @select="selectAnnotation"
                      @unselect="unselectAnnotation" :annotations="annotations"></annotations-list>
          </row12>
        </col4>
      </row></template>
      <template v-slot:buttons>
        <v-btn
                color="primary"
                text
                id="cancel_button"
                @click.native="close_modal"
        >
          {{$t('set_label_groups.btn_cancel')}}
        </v-btn>

        <v-btn
                color="primary"
                text
                id="save_button"
                @click.native="save"
        >
          {{$t('set_label_groups.btn_save')}}
          <v-icon
                  dark
                  primary
                  right
          >
            fas fa-level-down-alt fa-rotate-90
          </v-icon>
        </v-btn>
      </template>
      </modal>

  </div>
</template>

<script>
import AnnotatedImage from "@/components/AnnotatedImage";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import AnnotateLabels from "@/components/AnnotateLabels";
import AnnotationsList from "@/components/AnnotationsList";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import Col7 from "@/components/layout/Col7";
import Col4 from "@/components/layout/Col4";
import Row12 from "@/components/layout/Row12";
import Modal from "@/components/Modal";
import Col10 from "@/components/layout/Col10";
import Col2 from "@/components/layout/Col2";
export default {
  name: "EditLabelGroup",
  components: {Col2, Col10, Modal, Row12, Col4, Col7, Col12, Row, AnnotationsList, AnnotateLabels, AnnotatedImage},
  props: {
    'group': {required: true}
  },
  mixins: [AnnotateMixin],
  data () {
    return {
      'edit_mode': false,
      'name': '',
      'description': '',
      'json_annotations': '',
      'loading': false
    }
  },
  methods: {
    async delete_group() {
      await this.$store.dispatch('annotation_group/delete', this.local_group)
    },
    async copy_group() {
      let new_group = JSON.parse(JSON.stringify(this.local_group));
      new_group.name = new_group.name + ' COPY'
      await this.$store.dispatch('annotation_group/add', new_group)
    },
    close_modal() {
      this.$set(this, 'edit_mode', false)
    },
    async save() {
      this.$set(this, 'loading', true)
      await this.update_group()
      this.$set(this, 'loading', false)
      this.close_modal()
    },
    async update_group () {
      await this.$store.dispatch('annotation_group/patch', { item: this.local_group, dispatch: this.$store.dispatch })
    }
  },
  computed: {
    local_group() {
      let group = {
        id: this.group.id,
        name: this.name,
        description: this.description,
        json_annotations: JSON.stringify(this.annotations)
      }
      return group;
    }
  },
  mounted() {
    this.$set(this, 'name', this.group.name)
    this.$set(this, 'description', this.group.description)
    this.$set(this, 'json_annotations', this.group.json_annotations)
    if(!this.json_annotations || this.json_annotations === '') {
        this.$set(this, 'annotations', [])
    }
    else {
       this.$set(this, 'annotations', JSON.parse(this.json_annotations))
    }

  }
}
</script>

<style scoped>

</style>