<template>
  <row v-if="local_user && local_user !== {} && local_user.relationships">
      <loader :loading="loading"></loader>
      <col1>
          <gravatar style="width: 40px;" :selected_properties="local_user.avatar_properties"></gravatar>
      </col1>
      <col3>
        {{local_user.email}}
      </col3>
      <col6>
        <div v-if="roles">
          <div style="width: 80px; float:left; margin-left: 10px;"
             v-for="role in roles" :key="role.id">
              <checkbox :editable="editable" :value="project_roles.includes(role)" @input="updateRole(role, $event)"></checkbox>
          </div>
        </div>
      </col6>
      <col2>
          <v-btn icon v-if="editable"  @click.native="delete_user" color="primary" x-small>
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
      </col2>
  </row>
</template>

<script>
import Checkbox from "@/components/Checkbox";
import Row from "@/components/layout/Row";
import Col6 from "@/components/layout/Col6";
import Col2 from "@/components/layout/Col2";
import Gravatar from "@/components/users/Gravatar";
import Col1 from "@/components/layout/Col1";
import Col3 from "@/components/layout/Col3";
import Loader from "@/components/layout/loader";
export default {
  name: "UserList.vue",
  components: {Loader, Col3, Col1, Gravatar, Col2, Col6, Row, Checkbox},
  data() {
    return {
      local_user: {},
      current_user_email: '',
      loading: false,
    }
  },
  props: {
    user: {required: true},
    roles: {required: true}
  },
  mounted() {
    this.local_user = this.user;
  },
  watch: {
    user() {
      this.local_user = this.user;
    }
  },
  computed: {
    project_roles () {
      let project_id = this.$store.getters['user/get_current_project_id']
      if(!this.local_user.relationships) {
        return [];
      }
      let project_roles = this.local_user.relationships.user_roles.filter(user_role=>{return user_role.project_id == project_id});
      return project_roles.map(project_role=>{return project_role.relationships.role.name});
    },
    editable () {
      return this.local_user.email !== this.current_user_email;
    }
  },
  methods: {
    async updateRole(role, value) {
      this.$set(this, 'loading', true);
      var user = false;
      if(value) {
        user = await this.$api.role.add(this.local_user.email, role)
      }
      else {
        user = await this.$api.role.remove(this.local_user.email, role);
      }
      this.$store.commit('user/set_item', user)
      this.$set(this, 'loading', false);
    },
    delete_user () {
      this.$emit('delete_user', this.local_user)
    }
  }
}
</script>

<style scoped>

</style>