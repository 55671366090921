<template>
  <menu-layout :title="$t('progress.title')" :subtitle="$t('progress.subtitle')">
    <row>
      <col12>
        <my-boxes-modal :label_name="boxes_modal_label_name" v-if="show_boxes_modal" @close_modal="close_preview_label_in_modal"></my-boxes-modal>
        <v-text-field
          :label="$t('progress.search_field')"
          prepend-inner-icon="mdi-magnify"
          v-model="filter" />
        <v-data-table
          :loading="loading"
          :items="items"
          :server-items-length="totalItems"
          :headers="headers"
          :options.sync="options"
        >
        <template v-slot:item.relationships.label.name="{ item }">
          {{ item.relationships.label.name }}
          <v-icon
              v-if="item.processed_count > 0"
              small
              class="mr-2"
              @click="preview_label_in_modal(item)"
          >
            fa-table-cells-large
          </v-icon>
          </template>
        <template v-slot:item.annotation_type="{ item }">
            <span v-if="item.annotation_type === 1">Box</span>
            <span v-else>Keypoint</span>
          </template>
        </v-data-table>
      </col12>
    </row>
  </menu-layout>
</template>

<script>
  import MenuLayout from "@/components/layout/MenuLayout";
  import Col12 from "@/components/layout/Col12";
  import Row from "@/components/layout/Row";
  import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
  import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
  import MyBoxesModal from "@/components/MyBoxesModal";

  export default {
    name: "Progress",
    mixins: [ApiDatatableMixin, LoadSettingsMixin],
    data () {
      return {
        'api_model': 'stat',
        'show_boxes_modal': false,
        'boxes_modal_label_name': false
      }
    },
    methods: {
      preview_label_in_modal(annotation_stat) {
        let label_name = annotation_stat.relationships.label.name;
        this.$set(this, 'show_boxes_modal', true);
        this.$set(this, 'boxes_modal_label_name', label_name);
      },
      close_preview_label_in_modal() {
        this.$set(this, 'show_boxes_modal', false);
        this.$set(this, 'boxes_modal_label_name', null);
      },
    },
    computed: {
      filtered_extended_items () {
        return this.extended_items.filter(item=>{
          if(item.annotation_type === 'Point') {
            return false;
          }
          if(!this.hide_empty) {
            return true;
          }
          return item.processed_count !== 0 || item.to_be_processed_count !== 0;
        });
      },
      'extended_items' () {
        return this.items.map(item=>{
          if(item.annotation_type) {
            item.annotation_type = item.annotation_type === 1 ? 'Box' : 'Point'
          }
          else {
            item.annotation_type = ''
          }
          return item
        })
      },
      headers(){
        return [
            { text: this.$t('progress.label'), value: 'relationships.label.name' },
            { text: this.$t('progress.type'), value: 'annotation_type' },
            { text: this.$t('progress.processed'), value: 'processed_count' },
            { text: this.$t('progress.to_be_validated'), value: 'to_be_processed_count' },
        ]
      }
    },
    components: {
      Row,
      Col12,
      MenuLayout,
      MyBoxesModal},
  }
</script>

<style scoped>

</style>