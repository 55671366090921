<template>
    <div v-if="in_modal" style="float:right; margin-right: 10px;">
      <v-btn
            style="margin-right: 5px"
            color="primary"
            text
            id="unclear_button"
            @click.native="isUnclear"
          >
            {{$t('annotate.btns_unclear')}}
          </v-btn>

      <v-btn
          style="margin-right: 5px"
          text
          color="primary"
          id="unclear_button"
          @click.native="isInvalid"
      >
        {{$t('annotate.btns_invalidate')}}
      </v-btn>

      <v-btn
            color="primary"
            text
            id="save_button"
             @click.native="isCorrect"
          >
           {{$t('annotate.btns_save')}} <v-icon
          dark
          primary
          right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
          </v-btn>
  </div>
  <row12 v-else class="row" style="margin-top: 10px;">
     <v-btn
         style="margin-right: 5px"
            color="primary"
            id="unclear_button"
            @click.native="isUnclear"
          >
            {{$t('annotate.btns_unclear')}}
          </v-btn>

      <v-btn
          style="margin-right: 5px"
          color="primary"
          id="unclear_button"
          @click.native="isInvalid"
      >
        {{$t('annotate.btns_invalidate')}}
      </v-btn>

      <v-btn
            color="primary"
            id="save_button"
             @click.native="isCorrect"
          >
           {{$t('annotate.btns_save')}} <v-icon
          dark
          primary
          right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
          </v-btn>
        <div style="float:left; width:200px;">
          <a v-if="asset_id_history.length > 0" :href="'/check/'+asset_id_history[asset_id_history.length-1]">Check previous</a>
        </div>
  </row12>
</template>

<script>
import Row12 from "@/components/layout/Row12";
export default {
  name: "AnnotateButtons",
  components: {Row12},
  props: {
    'asset_id_history': {required: true},
    'annotations': {required: true},
    'asset_id': {required: true},
    'in_modal': {default: false},
    'asset_in_validation_set': {default: null}
  },
  mounted() {
    window.addEventListener('keydown', this.addEvents);
  },
  destroyed () {
    window.removeEventListener('keydown', this.addEvents);
  },
  methods: {
    setLoading(loading) {
      this.$emit('set_loading', loading);
    },
    addEvents: function(e) {
        let activeElement = document.activeElement;
        let inputs = ['input', 'select', 'button', 'textarea'];

        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return false;
        }

        if(e.keyCode === 13) { //enter
          e.stopPropagation();
          e.preventDefault();
          this.isCorrect();
        }
    },
    async isCorrect() {
      this.setLoading(true)
      let response = await this.$api.asset.save(this.asset_id, this.annotations, this.asset_in_validation_set)
      if (response && typeof response === 'object' && response.limit_reached && response.limit_type === 'ANNOTATION_LIMIT') {
        this.$emit("annotate-limit-reached");
        this.setLoading(false);
        return
      }
      this.setLoading(false);
      this.$emit('stored');
    },
    async isInvalid() {
      this.setLoading(true)
      await this.$api.asset.mark_invalidated([this.asset_id])
      this.setLoading(false);
      this.$emit('stored');
    },
    async isUnclear() {
      this.setLoading(true);
      await this.$api.asset.mark_unclear(this.asset_id);
      this.setLoading(false);
      this.$emit('stored');
    },
  }
}
</script>

<style scoped>

</style>