<template>
    <div>
        <v-btn class="top_margin" small rounded title="Loading export details" v-if="(show_download && loading) || (show_download && export_jobs.length > 0)">
            <v-icon x-small left>
                fa-solid fa-spinner fa-spin
            </v-icon>
            {{capitalized_export_type}}</v-btn>
        <v-btn class="top_margin" download :href="related_files[0].path" small rounded title="Download export" v-else-if="related_files.length > 0">
            <v-icon x-small left>
                fa-solid fa-download
            </v-icon>
            {{capitalized_export_type}}</v-btn>
        <v-btn class="top_margin" small rounded title="Start export" @click="start_export" v-else-if="show_download">
            <v-icon x-small left>
                fa-solid fa-rocket
            </v-icon>
            {{capitalized_export_type}}</v-btn>
    </div>
</template>

<script>
export default {
    name: "ModelExportItem",
    components: {},
    data() {
        return {
            'export_jobs': false,
            'loading': true,
        }
    },
    computed: {
        capitalized_export_type() {
            return this.export_type.charAt(0).toUpperCase() + this.export_type.slice(1)
        },
        show_download () {
            if(this.export_type == 'edgeyolo' || this.export_type == 'pytorch' || this.export_type == 'fastai') {
                return false;
            }
            else {
                return true;
            }
        },
        related_files () {
            if(this.export_type == 'edgeyolo') {
                let related = this.files.filter(file => {return file.name.indexOf('.pth') > -1})
                return related;
            }
            else if(this.export_type == 'pytorch') {
                let related = this.files.filter(file => {return file.name.endsWith('.pt')})
                return related;
            }
            else if(this.export_type == 'fastai') {
                let related = this.files.filter(file => {return file.name.indexOf('.pkl') > -1})
                return related;
            }
            else if(this.export_type == 'tensorrt') {
                let related = this.files.filter(file => {return file.name.indexOf('tensorrt') > -1 || file.name.indexOf('.engine') > -1})
                return related;
            }
            else if(this.export_type == 'tflite') {
                let related = this.files.filter(file => {return file.name.indexOf('tflite.zip') > -1})
                return related;
            }
            else {
                return false;
            }
        }
    },
    props: {
        export_type: {required: true},
        model: {required: true},
        files: {required: true}
    },
    methods: {
        async start_export() {
            this.$set(this, 'loading', true)
            await this.$api.job.start_export_job(this.model, this.export_type);
            this.load_export_jobs();
        },
        async load_export_jobs() {
            let jobs = await this.$api.job.get_export_jobs(this.model.id);
            jobs = jobs.filter(job => {return job.description.includes(this.export_type) && job.processed == 0})
            this.$set(this, 'export_jobs', jobs)
            this.$set(this, 'loading', false)
        }
    },
    mounted() {
        this.load_export_jobs();
    }
}
</script>

<style scoped>
    .top_margin {
        margin-top: 5px;
    }
</style>