import base_getters from '@/store/modules/base/getters.js'

export default {
    ...base_getters,
    get_setting: (state, getters) => (name) => {
        let item = getters.get_item({'key':'name', 'value': name});
        if(item) {
            return item.value === 'true' || item.value === true;
        }
        else {
            return false;
        }
    }
}