<template>
  <div>
      <parameter v-for="parameter in parameters"
          v-bind:key="parameter.id"
          :name="parameter.name"
          :input="parameter.input"
          @select="select(parameter.id, $event)"
          :value="selected_options[parameter.id]"
          :options="parameter.options"
          :default_value="parameter.default_value"
          :disabled="disabled"
      >
      </parameter>
  </div>
</template>

<script>
  import Parameter from "@/components/train_configs/Parameter";
  export default {
    name: "Parameters",
    components: {Parameter},
    props: {
      'all_parameters': {required: true},
      'selected_options': {required: true},
      'advanced': {required: false, default: false},
      'disabled': {type: Boolean, default:false},
    },
    methods: {
      select(parameter_id, value) {
        this.$emit('select', {'parameter_id': parameter_id, 'value': value})
      }
    },
    computed: {
      parameters () {
        if(this.advanced) {
          return this.advanced_parameters;
        }
        else {
          return this.normal_parameters;
        }
      },
      advanced_parameters () {
        if(!this.all_parameters) {
          return []
        }
        return this.all_parameters.filter(parameter=>{return parameter.advanced})
      },
      normal_parameters() {
        if(!this.all_parameters) {
          return []
        }
        return this.all_parameters.filter(parameter=>{return !parameter.advanced})
      }
    },
    data() {
      return {
        'show_advanced': false
      }
    }
  }
</script>

<style scoped>

</style>