<template>
  <div>
    <div>
        {{$t('set_label_groups.current_annotations')}}
    </div>
    <row>
      <col12>
      <my-boxes-modal :label_name="boxes_modal_label_name" v-if="show_boxes_modal" @close_modal="close_preview_label_in_modal"></my-boxes-modal>
        <v-chip
                :dark="annotation.selected"
                :style="{
                'font-weight': annotation.selected ? 'bold': '',
                'background-color': 'rgba('+ annotation.color_values[0] + ',' + annotation.color_values[1] + ',' + annotation.color_values[2] + ')'}"
                style="float: left;" v-for="annotation in filtered_annotations" :key="annotation.id"
                @click="toggleSelect(annotation)">
          {{ annotation.label }} - {{ annotation.conf }}
                <i class="fas fa-table-cells-large small ml-2" @click="preview_label_in_modal(annotation.label)"></i>
                   <!-- <i class="far fa-copy"></i> -->
          <!--          <i class="fas fa-trash-alt"></i> {{annotation.coords}}-->
        </v-chip>
      </col12>
    </row>
    <row>
      <col12>
        <annotate-labels v-if="selected" :type="selected.type" :placeholder="'Change label'"
                         @add_annotation="updateAnnotation"></annotate-labels>
      </col12>
    </row>

    <v-btn rounded style="margin-right: 5px; " small color="primary" id="clear_button_05" @click="clearAnnotations(0.3)">{{$t('set_label_groups.clear_annotations')}}</v-btn>
    <v-btn rounded small color="primary" id="clear_button" @click="clearAnnotations">{{$t('set_label_groups.clear_all')}}</v-btn>

    <row>
      <col12>
        <v-row>
          <v-card-text>
            <v-text-field id="filter_annotations"
                          v-model="filter_annotations" :label="$t('set_label_groups.filter_annotations')"></v-text-field>
          </v-card-text>
        </v-row>
      </col12>
    </row>
  </div>
</template>

<script>
  import AnnotateLabels from "@/components/AnnotateLabels";
  import Col12 from "@/components/layout/Col12";
  import Row from "@/components/layout/Row";
  import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
  // import MyBoxesModal from "@/components/MyBoxesModal";

  export default {
    name: "AnnotationsList",
    mixins: [LoadLabelsMixin],
    data () {
      return {
        'filter_annotations': '',
        'show_boxes_modal': false,
        'boxes_modal_label_name': false
      }
    },
    components: {Row, Col12, AnnotateLabels},
    props: {
      'annotations': {
        required: true
      },
      'suggestions': {
        required: false
      }
    },
    computed: {
      'selected'() {
        let selected = this.annotations.filter(a => {
          return a.selected
        })
        if (selected.length > 0) {
          return selected[0]
        } else return false;
      },
      filtered_annotations () {
        if(this.filter_annotations === '') {
          return this.annotations
        }
        return this.annotations.filter(annotation=>{return annotation.label.toLowerCase().includes(this.filter_annotations.toLowerCase())})
      },
    },
    methods: {
      preview_label_in_modal(label_name) {
        this.$set(this, 'show_boxes_modal', true);
        this.$set(this, 'boxes_modal_label_name', label_name);
      },
      close_preview_label_in_modal() {
        this.$set(this, 'show_boxes_modal', false);
        this.$set(this, 'boxes_modal_label_name', null);
      },
      updateAnnotation(annotation) {
        this.$emit('update_label', this.selected.id, annotation.label);
      },
      clearAnnotations(less_than) {
        this.$emit('clear', less_than)
      },
      clearAnnotationsLessThan(value) {
        this.$emit('clear_less_than', value)
      },
      toggleSelect(annotation) {
        if (annotation.selected) {
          this.$emit('unselect', annotation.id);
        } else {
          this.$emit('select', annotation.id);
        }
      }
    }
  }
</script>

<style scoped>

</style>