export default class Setting {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        if(!sessionStorage.getItem('project_id') || sessionStorage.getItem('project_id') === false) {
            return [];
        }
        if(sessionStorage.getItem('project_id') === 'false') {
            return [];
        }
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/settings', formData);
    }

    async add(setting) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('name', setting.name)
        formData.append('value', setting.value)

        return this.api.post_with_token('/set_setting', formData);
    }

    async patch(setting) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('name', setting.name)
        formData.append('value', setting.value)

        return this.api.post_with_token('/set_setting', formData);
    }
}