<template>
  <div v-if="options">
    <v-autocomplete
        :prepend-icon="prepend_icon"
        v-if="options"
        clearable
        dense
        outlined
        :rounded="rounded"
        :loading="loading"
        @keydown.enter.prevent="preventDefault"
        style="width: 100%; max-width: 350px;"
        @input="select"
        :search-input.sync="search"
        @click:clear="cleared"
        :value="selected_value"
        :taggable="true"
        :placeholder="placeholder"
        :items="options"
        :item-text="label"
        item-value="id"
        :disabled="disabled"
    ></v-autocomplete>
  </div>

</template>

<script>
export default {
name: "Autocomplete",
  props: {
    'placeholder': {default: ""},
    'options': {required: true},
    'value': {default: null},
    'label': {default: 'name'},
    'loading': {default: false},
    'disabled': {default: false},
    'clear_on_select': {default: false},
    'rounded': {default: true},
    'prepend_icon': {default: null}
  },
  watch: {
    value (n) {
      if(!this.selected_value || n !== this.selected_value.name) {
        this.$set(this, 'selected_value', this.get_id_value(n));
      }
    },
    search (n) {
      this.$emit('updated_search_value', n);
    }
  },
  data() {
    return {
      search: null,
      selected_value: null
    }
  },
  mounted() {
    this.$set(this, 'selected_value', this.get_id_value(this.value))
  },
  computed: {

  },
  methods: {
    cleared() {
      this.$emit('cleared')
    },
    get_id_value(value) {
      if(!this.option) {
        return value;
      }
      let index = this.options.map(option=>{return option.name}).indexOf(value);
      if(index !== -1) {
        return this.options[index]
      }
      return value;
    },
    select(id) {
      let ids = this.options.map((option) => {return option.id});
      let index = ids.indexOf(id)
      if(index === -1) {
        this.$emit('select', id);
        this.$emit('input', id);
      }
      else {
        this.$emit('select', this.options[index]);
        this.$emit('input', this.options[index]);
      }
      if(this.clear_on_select) {
        this.$set(this, 'selected_value', []);
      }
    },
    preventDefault(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  }
}
</script>

<style scoped>

</style>