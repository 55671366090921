export default class Task {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all(task_id) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('task_id', task_id)
        return this.api.post_with_token('/task/users', formData);
    }

    async all_for_user() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/user-tasks', formData);
    }

    async add(task_id, user_id) {
        let formData = new FormData();
        formData.append('task_id', task_id);
        formData.append('user_id', user_id);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/task/add-user', formData)
    }

    async delete(task_id, user_id) {
        let formData = new FormData();
        formData.append('task_id', task_id);
        formData.append('user_id', user_id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/task/remove-user', formData)
    }
}