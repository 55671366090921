export default class Task {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/tasks', formData);
    }

    async add(task) {
        let formData = new FormData();
        formData.append('name', task.name);
        formData.append('filter', task.filter);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/task/add', formData)
    }

    async patch(task) {
        let formData = new FormData();
        formData.append('task_id', task.id);
        formData.append('name', task.name);
        formData.append('filter', task.filter);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/task/patch', formData)
    }

    async delete(task) {
        let formData = new FormData();
        formData.append('task_id', task.id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/task/delete', formData)
    }

    async remove_user_from_task(user_id, task) {
        let formData = new FormData();
        formData.append('task_id', task.id);
        formData.append('user_id', user_id);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/task/remove-user', formData)
    }
}