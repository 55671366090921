<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmEmail',
  async mounted() {
    await this.confirmEmail();
  },
  methods: {
    async confirmEmail() {
      if (this.$route.params.token) {
        const confirmation_token = this.$route.params.token;
        const response = await this.$api.confirm_email(confirmation_token);
        if (response && response.status === 'success' && response.refresh_token) {
          await this.handleConfirmationSuccess(response);
        } else {
          await this.handleConfirmationFailure(response);
        }
      } else {
        await this.$store.dispatch('snackbars/add_failed_snack', 'No confirmation token found');
        this.$router.push({ name: 'Login' });
      }
    },
    async handleConfirmationSuccess(response) {
      if (response.refresh_token) {
        localStorage.setItem('token', response.refresh_token);
        await this.$store.commit('user/set_current_user', response.user);
        let projects = await this.$api.project.all();
        if (projects.length > 0) {
          let firstProjectId = projects[0].id;
          this.$router.push(`/project/${firstProjectId}`);
          await this.$store.dispatch('snackbars/add_success_snack', this.$t('login.email_confirmed'));
        } else {
          await this.$store.dispatch('snackbars/add_failed_snack', 'No refresh token found');
          this.$router.push({ name: 'Login' });
        }
      }
    },
    async handleConfirmationFailure(response) {
      await this.$store.dispatch('snackbars/add_failed_snack', `Error confirming email: ${response.message}`);
      this.$router.push({ name: 'Login' });
    },
  },
};
</script>
