<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h2 class="headline">{{ $t('overview.tasks') }}</h2>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <task-list :tasks="tasks"></task-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TaskList from "@/components/tasks/TaskList";

export default {
  name: 'ProjectTasks',
  components: { TaskList },
  props: {
    'tasks': { required: true },
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
}
</script>

<style scoped>

</style>
