<template>
    <row>
      <col2>
        <v-icon>fas fa-plug</v-icon>
      </col2>
      <col5>
        {{worker.name}}
      </col5>
      <col5>
        Last seen on: {{worker.last_seen_on}}
      </col5>
    </row>
</template>

<script>
import Row from "@/components/layout/Row";
import Col2 from "@/components/layout/Col2";
import Col5 from "@/components/layout/Col5";
export default {
  name: "Worker",
  components: {Col5, Col2, Row},
  props: {
    worker: {required: true}
  }
}
</script>

<style scoped>

</style>