export default class Instance {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async save_relation(id_a, id_b) {
        let formData = new FormData();
        formData.append('id_a', id_a);
        formData.append('id_b', id_b);
        return this.api.post_with_token('/save-relation', formData)
    }

    async save_unknown_relation(id_a, id_b) {
        let formData = new FormData();
        formData.append('id_a', id_a);
        formData.append('id_b', id_b);
        return this.api.post_with_token('/save-unknown-relation', formData)
    }

    async save_invalid_relation(id_a, id_b) {
        let formData = new FormData();
        formData.append('id_a', id_a);
        formData.append('id_b', id_b);
        return this.api.post_with_token('/save-invalid-relation', formData)
    }

    async save_inverse_relation(id_a, id_b) {
        let formData = new FormData();
        formData.append('id_a', id_a);
        formData.append('id_b', id_b);
        return this.api.post_with_token('/save-inverse-relation', formData)
    }

     async get_next_instance(meta_contains, original_category, label_contains) {
        let formData = new FormData();
        formData.append('meta_contains', meta_contains);
        formData.append('original_category', original_category);
        formData.append('label_contains', label_contains);
        return this.api.post_with_token('/get-next-instance', formData)
    }

    async get_pairs() {
        let formData = new FormData();
        return this.api.post_with_token('/get-pairs', formData)
    }
}