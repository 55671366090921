<template>
  <row>
    <col4 style="cursor: pointer" @click.native="toggled=!toggled">
      <row>
        <col1>
            <i v-if="!job.processing && !job.processed" class="fas fa-hourglass-start"></i>
            <i v-if="job.processing && !job.processed" class="fas fa-spinner fa-spin"></i>
        </col1>
        <col11>
            {{ message }} - {{job.created_at}} <br />
            <span v-if="job.processed && description.filepath && typeof description.filename !== 'undefined'">{{description.summary}} (<a :href="description.filepath + description.filename">Download</a>)</span>
            <v-chip v-if="job.processed===0 && description.state && typeof description.state !== 'undefined'">{{description.state}}</v-chip>
        </col11>
      </row>
    </col4>
    <col4>
      <v-progress-linear
          :value="job.progress"
          height="20"
          rounded
        >
          <strong>{{ Math.ceil(job.progress) }}%</strong>
        </v-progress-linear>
    </col4>
    <col8 v-if="toggled">
      <span>{{description}} - {{job.id}}</span>
    </col8>
  </row>
</template>

<script>
import Col4 from "@/components/layout/Col4";
import Col8 from "@/components/layout/Col8";
import Col1 from "@/components/layout/Col1";
import Row from "@/components/layout/Row";
import Col11 from "@/components/layout/Col11";
export default {
  name: "Job",
  components: {Col11, Col8, Col4, Col1, Row},
  props: {
    'job': {required: true},
    'message': {default: ''},
  },
  data() {
    return {
      'toggled': false
    }
  },
  computed: {
    'description' () {
      if(this.job.description === '') {
        return {}
      }
      try {
        return JSON.parse(this.job.description)
      }
      catch (error) {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>