<script>
export default {
name: "LoadTasksMixin"
  ,
  data() {
    return {
      tasks_loaded: false
    }
  },
  mounted() {
    this.load_tasks();
  },
  computed: {
    tasks () {
      return this.$store.getters['task/get_items']
    },

  },
  methods: {
    async load_tasks() {
      await this.$store.dispatch('task/all')
      this.$set(this, 'tasks_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>