export default class LabelSet {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all(options, filter) {
        let itemsPerPage = options.itemsPerPage
        let page = options.page;
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('page', page)
        formData.append('sortBy', options.sortBy);
        formData.append('sortDesc', options.sortDesc);
        formData.append('filter', filter);
        formData.append('itemsPerPage', itemsPerPage)
        return this.api.post_with_token('/label-sets/', formData);
    }

    async addLabelSet(labelSetName, labelSetDescription) {
        let formData = new FormData();
        formData.append('name', labelSetName);
        formData.append('description', labelSetDescription);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label-sets/add', formData)
    }

    async patchLabelSet(labelSet, labelSetName, labelSetDescription) {
        let formData = new FormData();
        formData.append('label_set_id', labelSet.id);
        formData.append('name', labelSetName);
        formData.append('description', labelSetDescription);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label-sets/patch', formData)
    }

    async deleteLabelSet(labelSet) {
        let formData = new FormData();
        formData.append('label_set_id', labelSet.id); //project_id deleted
        return this.api.post_with_token('/label-sets/delete', formData)
    }

    async getLabels(labelSet) {
        let formData = new FormData();
        formData.append('label_set_id', labelSet.id);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label-sets/labels', formData)
    }

    async updateLabels(labelSet, labelIds) {
        let formData = new FormData();
        formData.append('label_set_id', labelSet.id);
        formData.append('label_ids', JSON.stringify(labelIds));
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/label-sets/labels/update', formData)
    }

    async getLabelSet(labelSetId) {
        let formData = new FormData();
        formData.append('label_set_id', labelSetId);
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/label-set', formData)
    }
}