<template>
  <div>
    <v-list-item @click="navigateToBaseMenu">
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ title }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list class="grow">
      <div v-for="item in items"
           :key="item.name">
        <v-list-group v-if="item.items"
          :value="true"
          no-action
          :prepend-icon="item.icon"
        >
            <template v-slot:activator>
              <v-list-item-title>{{item.name}}</v-list-item-title>
            </template>
            <v-list-item

                v-for="subitem in item.items"
                @click.native="select_item(subitem.route)"
                :key="subitem.name"
                link
            >
              <v-list-item-title v-text="subitem.name"></v-list-item-title>
            </v-list-item>
        </v-list-group>
        <v-list-item
            v-else
            link
            @click.native="select_item(item.route)"
        >
          <v-list-item-title :id="'menu_'+item.name.toLowerCase()" v-text="item.name"></v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "PageMenu",
  props: {
    name: {required: true},
    title: {required: true},
    items: {required: true}
  },
  methods: {
    select_item(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    navigateToBaseMenu() {
      this.$emit('navigate');
    }
  }
}
</script>

<style scoped>

</style>