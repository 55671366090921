var render = function render(){var _vm=this,_c=_vm._self._c;return _c('menu-layout',{attrs:{"loading":_vm.loading}},[_c('row',[_c('col2',[_c('page-menu',{attrs:{"name":_vm.$t('myprofile.title'),"title":_vm.$t('myprofile.subtitle'),"items":[
                      {'name': _vm.$t('myprofile.avatar') , 'route': '/profile/avatar'},
                      {'name': _vm.$t('myprofile.password'), 'route': '/profile/change-password'},
                      {'name': _vm.$t('myprofile.notification_settings'), 'route': '/profile/notifications'},
                      {'name': _vm.$t('myprofile.authentication_settings'), 'route': '/profile/authentication'},
                      {'name': _vm.$t('myprofile.seg_any_settings'), 'route': '/profile/seg-any'},
                      {'name': _vm.$t('overview.limits'), 'route': '/profile/user-limits'},
                      {'name': _vm.$t('myprofile.upgrade_plan'), 'route': '/profile/upgrade-subscription'},
                      {'name': _vm.$t('myprofile.payment_overview'), 'route': '/profile/payment-overview'},
                    ]},on:{"navigate":_vm.navigateToBaseMenu}})],1),_c('col12',{staticStyle:{"max-width":"1000px"}},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }