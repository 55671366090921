import base_actions from '@/store/modules/base/actions'
import Setting from "@/models/setting";

export default {
    ...base_actions,
    patch_setting({getters, dispatch}, key_value) {
        let setting_name = key_value.k
        let value = key_value.v
        let item = getters['get_item']({key:'name', value:setting_name})
        if(item) {
            let setting = new Setting(item.id, setting_name, value);
            dispatch('patch', setting)
        }
        else {
            let setting = new Setting(false, setting_name, value);
            dispatch('add', setting)
        }
    }
}