<template>
  <settings-menu-layout>
    <row>
      <col12>
        <manage-label-sets></manage-label-sets> 
      </col12>
    </row>
  </settings-menu-layout>
</template>

<script>
  // import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import Col12 from "@/components/layout/Col12";
  import Row from "@/components/layout/Row";
  import ManageLabelSets from "@/components/ManageLabelSets.vue";

  export default {
    name: "SettingsLabelSets",
    data() {
      return {
      }
    },
    methods: {
      
    },
    components: {
      SettingsMenuLayout, 
      Col12, 
      Row,
      ManageLabelSets
    },
  }
</script>

<style scoped>

</style>