<template>
  <row>
    <col8>
        <v-card color="rgb(247, 245, 243)" :sticky="true" v-if="workers.length > 0" style="margin-bottom: 30px;">
        <v-card-text>
          <worker v-for="worker in workers" :key="worker.id" :worker="worker">
          </worker>
        </v-card-text>
      </v-card>
    </col8>
  </row>

</template>

<script>
import Worker from "@/components/train/Worker";
import Row from "@/components/layout/Row";
import Col8 from "@/components/layout/Col8";

export default {
  name: "TrainWorkers",
  components: {Col8, Row, Worker},
  props: {
    workers: {required: true}
  }
}
</script>

<style scoped>

</style>