<template>
  <div>
    <div>
     {{train_config.updated_at}}
    </div>
  </div>
</template>

<script>
export default {
  name: "TrainConfigLastUpdatedCell",
  props: {
    train_config: Object,
  },
  components: {},
}
</script>

<style scoped>

</style>
