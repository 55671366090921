export default class Annotation {
  constructor(id, type, coords, conf, label, color_values, skip_zoom) {

    if(typeof skip_zoom == 'undefined') {
      skip_zoom = false
    }

    this.id = id;
    this.type = type;
    this.coords = coords;
    this.conf = conf;
    this.label = label;
    this.color_values = color_values;
    this.selected = false;
    this.show = true;
    this.skip_zoom = skip_zoom
  }
}
