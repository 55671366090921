<template>
  <modal @close_modal="close_modal" :title="$t('models_and_train_configs.model_title')" :loading="loading"
  >
    <template v-slot:content>
      
      <v-sparkline
          :value="valuesAsc"
          line-width="1"
          height="30"
          padding="10"
      ></v-sparkline>

      <model-list
      :threshold="threshold" 
      :models="models"></model-list>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import ModelList from "@/components/models/ModelList";
export default {
  name: "ModelListInModal",
  data () {
    return {
      'loading': false,
    }
  },
  methods: {
    close_modal() {
      this.$emit('close_modal')
    },
  },
  computed: {
    valuesAsc() {
      // Return an inverse array of this.values
      return this.values.slice().reverse();
    },
    values () {
      let this2 = this;

      return this.models.map((model) => {
        if(!model.performance) {
          return 0;
        }
        let performance = JSON.parse(model.performance);
        return Math.round(performance['correct_' + this2.threshold] * 100);
      })
    }
  },
  components: {ModelList, Modal},
  props: {
    models: {required: true},
    threshold: {required: true}
  }
}
</script>

<style scoped>

</style>