<template>
  <menu-layout :title="$t('overview.overview_title')" :subtitle="$t('overview.overview_subtitle')">
    <div v-if="project" class="mobile-header">{{ project.name }}
    </div>
    <row>
      <col6>
        <project-details></project-details>
        <project-tasks :tasks="tasks"></project-tasks>  
      </col6>
      <col6>
        <project-todo-list></project-todo-list>        
        <one-project-limits></one-project-limits>
      </col6>
    </row>
  </menu-layout>
  
</template>

<script>

import ProjectDetails from "@/components/overview/ProjectDetails";
import ProjectTodoList from "@/components/overview/ProjectTodoList";
import ProjectTasks from "@/components/overview/ProjectTasks";
import OneProjectLimits from "@/components/overview/OneProjectLimits";
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import Col6 from "@/components/layout/Col6";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";
import LoadTasksMixin from "@/mixins/LoadTasksMixin";
import LoadProjectsMixinVue from '@/mixins/LoadProjectsMixin.vue';

  export default {
    name: "ProjectOverview",
    components: {MenuLayout, Row, Col6, ProjectTodoList,
    ProjectDetails, ProjectTasks, OneProjectLimits},
    mixins: [CurrentUserMixin, LoadTasksMixin, LoadProjectsMixinVue],
    data () {
      return {
      }
    },
    methods: {
    },
    computed: {
      project() {
        if (!this.projects) {
            return null;
        }
        let project_id = sessionStorage.getItem('project_id');
        project_id = parseInt(project_id);
        let filtered_project = this.projects.find(project => project.id === project_id);
        return filtered_project || null;
      },
      currentUserSubscriptionId() {
        return this.current_user ? this.current_user.subscription_id : 0;
      },
    },
    created() {
      if (!this.is_admin && !this.is_manager) {
        this.$router.push({ name: "Tasks" });
      }
    },
  }
</script>

<style scoped>
@media (max-width: 600px) {
  .mobile-header {
    display: block;
    text-align: left;
    font-size: 1.5em;
    margin: 1em 0;
  }
}

@media (min-width: 601px) {
  .mobile-header {
    display: none;
  }
}

</style>