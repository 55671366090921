<template>
    <div>
        <model-export-item v-for="export_type in export_types" :key="export_type"
            :export_type="export_type"
            :model="model"
            :files="files"
        ></model-export-item>
    </div>
</template>

<script>

import ModelExportItem from "@/components/models/ModelExportItem";

export default {
    name: "ModelExports",
    components: {ModelExportItem},
    data() {
        return {
            'export_types': ['tensorrt', 'tflite', 'edgeyolo', 'pytorch', 'fastai'],
        }
    },
    props: {
        model: {required: true}
    },
    computed: {
        filenames() {
            return this.files.map(file=>{return file.name});
        },
        files () {
            if(this.decoded_meta && this.decoded_meta !== '' && this.decoded_meta.files) {
                let files = this.decoded_meta.files.map(file => {
                let parts = file.split('_')
                let parts_path = file.split('/')
                let project_id = this.$store.getters['user/get_current_project_id']

                return {
                    'name': parts[parts.length-1],
                    'path': '/api/protected/models/' + project_id + '/' + parts_path[parts_path.length-1] + '?token=' + localStorage.token
                }
                })
                return files
            }
            else {
                return []
            }
        },
        decoded_meta() {
            if(this.model.meta) {
                let meta = JSON.parse(this.model.meta)
                if(meta === '') {
                return {}
                }
                else {
                return meta
                }
            }
            return {}
        }
    },
    methods: {
    }
}
</script>

<style scoped>

</style>