<template>
  <v-row>
    <v-col
        cols="12"
      >
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Row12"
}
</script>

<style scoped>

</style>