<template>
  <div>
    <menu-layout :loading="loading" :title="$t('model_page.title')" :subtitle="$t('model_page.subtitle')">
      <row12>
        <train-configs-and-models></train-configs-and-models>
      </row12>
    </menu-layout>
  </div>
</template>

<script>
  import MenuLayout from "@/components/layout/MenuLayout";
  import TrainConfigsAndModels from "@/components/train_configs_and_models/TrainConfigsAndModels";
  import Row12 from "@/components/layout/Row12";

  export default {
    name: "ModelPage",
    components: {
      Row12,
      TrainConfigsAndModels,
      MenuLayout
    },
    data() {
      return {
        'selected_page': 'boxes.models',
        'loading': false,
        'workers': [],
      }
    },
    computed: {
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        let workers = await this.$api.worker.get_active()
        this.$set(this, 'workers', workers);
      },
      set_loading(value) {
        this.$set(this, 'loading', value)
      }
    }
  }
</script>

<style scoped>

</style>