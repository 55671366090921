import axios from "axios";
import Asset from "@/api/asset";
import User from "@/api/user";
import Model from "@/api/model";
import Role from "@/api/role";
import Job from "@/api/job";
import Stat from "@/api/stat";
import Setting from "@/api/setting";
import Instance from "@/api/instance";
import Label from "@/api/label";
import Box from "@/api/box";
import Project from "@/api/project";
import TrainConfig from "@/api/train-config";
import Worker from "@/api/worker";
import AnnotationGroup from "@/api/annotation-group";
import ConfMatrixItem from "@/api/conf-matrix-item";
import Annotation from "@/api/annotation";
import Task from "@/api/task";
import UserTask from "@/api/user-task";
import UserSetting from "@/api/user-setting";
import dayjs from 'dayjs'
import AvailableModelParameter from "@/api/available-model-parameter";
import AvailableModel from "@/api/available-model";
import LabelSet from "@/api/label-set";
import Overview from "@/api/overview";
import Payment from "@/api/payment";
import SegAny from "@/api/segany";
import { onUnauthenticatedPages } from '@/util/routesHelper.js';

export default class Box21Api {
    constructor(vm, api_host) {
        this.api_url = api_host;
        this.annotation = new Annotation(this);
        this.asset = new Asset(this)
        this.model = new Model(this);
        this.role = new Role(this);
        this.worker = new Worker(this);
        this.user = new User(this);
        this.instance = new Instance(this);
        this.setting = new Setting(this);
        this.job = new Job(this);
        this.box = new Box(this);
        this.project = new Project(this);
        this.label = new Label(this);
        this.stat = new Stat(this);
        this.train_config = new TrainConfig(this);
        this.conf_matrix_item = new ConfMatrixItem(this);
        this.annotation_group = new AnnotationGroup(this);
        this.task = new Task(this);
        this.user_task = new UserTask(this);
        this.user_setting = new UserSetting(this);
        this.available_model = new AvailableModel(this);
        this.available_model_parameter = new AvailableModelParameter(this);
        this.label_set = new LabelSet(this);
        this.overview = new Overview(this);
        this.payment = new Payment(this);
        this.segany = new SegAny(this);
        this.vm = vm
        this.on_unauthenticated_pages = onUnauthenticatedPages.bind(vm);
        if (localStorage.token && !vm.$store.getters['user/get_current_user']) {
            if (this.on_unauthenticated_pages()) {
                return;
            }
            this.get_current_user();
        }
    }

    async register(first_name, last_name, email, password) {
        let formData = new FormData();
        formData.append('first_name', first_name);
        formData.append('last_name', last_name);
        formData.append('email', email);
        formData.append('password', password);
        return this.post_without_token('/user/register', formData)
    }

    async resend_confirmation_email(user_email) {
        let formData = new FormData();
        formData.append('email', user_email);
        return this.post_with_token('/user/resend-confirmation-mail', formData)
    }

    async confirm_email(confirmation_token) {
        let formData = new FormData();
        formData.append('confirmation_token', confirmation_token);
        return this.post_without_token('/user/confirm', formData)
    }

    async set_token(email, password) {
        let response = await this.get_token(email, password);
        if(!response) {
            return null;
        }


        this.token = response.token;
        localStorage.token = this.token;
        localStorage.token_expired_on = dayjs().add(response.expires_in_minutes, 'minutes')

        this.vm.$store.commit('user/set_current_user', response.user);
        this.vm.$store.commit('user/set_current_project_id', sessionStorage.getItem('project_id'));

        this.set_cookie('token', this.token, 30)

        return true
    }

    async get_token(email, password) {
        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);

        let response = await this.post_without_token('/login', formData).catch(error => {console.log(error)});
        if(!response) {
            return null;
        }
        return response;
    }

    async get_current_user() {
        const url = '/users/current-user';
        let formData = new FormData();
        let current_user = await this.post_with_token(url, formData)
        if(current_user) {
            this.vm.$store.commit('user/set_current_user', current_user);
            this.vm.$store.commit('user/set_current_project_id', sessionStorage.getItem('project_id'));
        }
    }

    async refresh_token() {
        let url = '/refresh';
        let formData = new FormData();
        let response = await this.post_with_token(url, formData , true)

        if(response) {
            localStorage.token = response.token;
            localStorage.token_expired_on = dayjs().add(response.expires_in_minutes, 'minutes').toDate()
        }
    }


    async post_without_token(url, formData) {
        url = this.api_url + url;
        let this2 = this;
        let response = await axios.post(url, formData, {
            headers: {}
        }).catch((error) => {
            console.log(error)
            this2.vm.$store.dispatch('snackbars/add_failed_snack', 'An error occurred 1');
        })

        if(!response) {
            return null;
        }

        return response.data
    }

    async post_with_token(url, formData, skip_refresh=false) {
        let diff = dayjs(localStorage.token_expired_on).diff(dayjs(), 'minutes');
        if(!skip_refresh && localStorage.token && diff < 50) {
            await this.refresh_token();
        }
        let config = {
            headers: {
                'x-access-token': localStorage.token
            }
        }

        url = this.api_url + url;
        let this2 = this;
        let response = await axios.post(url, formData, config).catch((error)=>{
            if (error && error.response && error.response.status === 401) {
                localStorage.token = false
                if (!this2.on_unauthenticated_pages()) {
                    this2.vm.$router.push({ name: 'Login' });
                }
            }
            else {
                console.log(error);
                this2.vm.$store.dispatch('snackbars/add_failed_snack', 'An error occurred 2');
            }
        })

        if(!response) {
            return null;
        }

        return response.data
    }

    set_cookie(name,value,minutes) {
        let expires = "";
        if (minutes) {
            let date = new Date();
            date.setTime(date.getTime() + (24*minutes*60*1000));
            expires = "; Expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expires;
    }

    async get(url, with_credentials) {


        if(!with_credentials) {
            with_credentials = false
        }

        let config = {
            headers: {
                'x-access-token': localStorage.token
            },
            with_credentials: with_credentials
        }
        url = this.api_url + url;
        //let this2 = this;


        let response = await axios.get(url, config).catch(()=>{
            //this2.vm.$router.push({name:'Login'});
        })
        return response.data;
    }

    async set_setting(name, value) {
        let formData = new FormData();
        formData.append('project_id', '1');
        formData.append('limit', '1');
        formData.append('name', name);
        formData.append('value', value);
        return this.post_with_token('/set_setting', formData)
    }

    async get_settings() {
        let formData = new FormData();
        formData.append('project_id', '1');
        return this.post_with_token('/settings', formData)
    }
}