<template>
  <div>
    <div>
     <row>
      <col6></col6>
      <col6>
        <v-slider
          :label="$t('models_and_train_configs.confidence_treshold')"
          :hint="$t('models_and_train_configs.confidence_treshold_hint')"
          max="100"
          min="0"
          step="5"
          thumb-label
          v-model="threshold"
          @input="emitThreshold"
        ></v-slider>
      </col6>
    </row>
    </div>
  </div>
</template>

<script>
import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";

export default {
  name: "TresholdSlider",
  props: {
  },
  components: {Col6, Row},
  data () {
    return {
      threshold: 60,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    emitThreshold() {
      this.$emit('threshold-changed', this.threshold);
  }
  },
}
</script>

<style scoped>

</style>
