export default class Annotation {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async patch(group) {
        let formData = new FormData();
        formData.append('id', group.id);
        formData.append('name', group.name);
        formData.append('description', group.description);
        formData.append('json_annotations', group.json_annotations);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/annotation-group/patch', formData)
    }

    async add(group) {
        let formData = new FormData();
        formData.append('name', group.name);
        formData.append('description', group.description);
        formData.append('json_annotations', JSON.stringify(group.annotations));
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/annotation-group/add', formData)
    }

    async delete(group) {
        let formData = new FormData();
        formData.append('group_id', group.id);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/annotation-group/delete', formData)
    }

    async all() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/annotation-group', formData)
    }
}