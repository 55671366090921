<template>
    <v-col
      cols="12"
      md="2"
      :lg="lg"
    >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  props: {
    lg: {
      default: 2}
  },
  name: "Col2"
}
</script>

<style scoped>

</style>