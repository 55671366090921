<template>
  <menu-layout :loading="loading">
    <row>
      <col3>
        <filter-tree
            :initial_filter="[
                {'type':27, 'value':''},
                {'type':9, 'value':'No'},
                {'type':11, 'value':'No'}
               ]"
            @load="load"
            @update="update"
            :max_results="max_results"></filter-tree>
      </col3>
      <col9>
        <row>
          <col12>
            <grid @reload="load" :items="images" v-on:remove_item="removeGridItem"
                  @update_selection_annotation_ids="update_selection">
              <template v-slot:menu>
                <col2>
                  <dropdown-menu v-if="images.length > 0"
                                 :menu_items="menu_items"
                  ></dropdown-menu>
                </col2>
                <col5>
                  <autocomplete
                      style="float:left"
                      v-if="images.length > 0"
                      :options="labels"
                      @select="select"
                  ></autocomplete>
                  <v-btn
                      :disabled="bbox_ids_selected.length === 0 || !class_name_relabel"
                      v-if="images.length > 0"
                      @click.native="relabel()"
                      id="relabel">
                    {{$t('myboxes.btn_change')}}
                  </v-btn>
                </col5>
              </template>
            </grid>
          </col12>
        </row>
      </col9>
    </row>
  </menu-layout>
</template>

<script>
import Grid from "@/components/Grid";
import FilterMixin from "@/mixins/FilterMixin";
import FilterTree from "@/components/asset_filter/FilterTree";
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import Col3 from "@/components/layout/Col3";
import Col9 from "@/components/layout/Col9";
import Col5 from "@/components/layout/Col5";
import Col2 from "@/components/layout/Col2";
import Col12 from "@/components/layout/Col12";
import DropdownMenu from "@/components/layout/DropdownMenu";
import Autocomplete from "@/components/Autocomplete";

export default {
  name: "MyBoxes",
  data: function () {
    return {
      labels: [],
      images: [],
      class_name: '',
      translations: {},
      current_filter: false,
      class_name_relabel: null,
      toggle_all: true,
      max_results: false,
      loading: false,
      filters: false,
      limit: false,
      bbox_ids_selected: []
    }
  },
  computed: {
    menu_items() {
      return [
        {'title': this.$t('myboxes.dropdown_mark_selected'), 'on_click': this.mark_selected_bboxes_as_validated},
        {'title': this.$t('myboxes.dropdown_mark_all'), 'on_click': this.mark_all_bboxes_as_validated},
        {'title': this.$t('myboxes.dropdown_delete_selected'), 'on_click': this.delete_selected_annotations},
      ]
    },
    filters_includes_not_validated() {
      return JSON.parse(this.current_filter.filters).filter(el => {
        return el.type === 9 && el.value === 'No'
      }).length > 0;
    },
    filters_includes_validated() {
      return JSON.parse(this.current_filter.filters).filter(el => {
        return el.type === 9 && el.value === 'Yes'
      }).length > 0;
    },
    nlToScientific() {
      let mapping = {}
      let values = Object.values(this.translations);
      let keys = Object.keys(this.translations);
      for (let i = 0; i < values.length; i++) {
        mapping[values[i]] = keys[i]
      }
      return mapping;
    },
    items() {
      return this.images;
    },
  },
  components: {
    Autocomplete,
    DropdownMenu,
    Col12,
    Col2,
    Col5,
    Col9,
    Col3,
    Row,
    MenuLayout,
    FilterTree,
    Grid
  },
  mixins: [FilterMixin], //Duplicated in MyAssets
  methods: {
    async load_labels() {
      this.$set(this, 'loading', true)
      let this2 = this;
      this.$store.dispatch('label/all').then((labels) => {
        this2.$set(this, 'labels', labels)
      });

      this.$set(this, 'loading', false)
    },
    async delete_selected_annotations() {
      if (this.bbox_ids_selected.length === 0) {
        return;
      }
      this.$set(this, 'loading', true);
      await this.$api.box.delete_selected_annotations(this.bbox_ids_selected);
      await this.load();
      this.$set(this, 'loading', false);
    },
    update(filters, limit) {
      this.$set(this, 'filters', filters)
      this.$set(this, 'limit', limit)
    },
    update_selection(bbox_ids) {
      this.$set(this, 'bbox_ids_selected', bbox_ids);
    },
    select(label) {
      if(!label) {
        this.$set(this, 'class_name_relabel', null);
      }
      else {
        this.$set(this, 'class_name_relabel', label.name);
      }
    },
    removeItem(asset_id) {
      let filteredImages = this.images.filter((image) => {
        return image.id !== asset_id
      });
      this.$set(this, 'images', filteredImages);
    },
    removeGridItem(asset_id) {
      this.removeItem(asset_id)
    },
    async mark_selected_bboxes_as_validated() {
      this.$set(this, 'loading', true)
      await this.$api.box.mark_bboxes_validated(this.bbox_ids_selected)
      await this.load()
    },
    async mark_all_bboxes_as_validated() {
      this.$set(this, 'loading', true)
      await this.$api.box.mark_bboxes_validated(this.images.map(image=>{return image.bbox_id}))
      await this.load()
    },
    async relabel() {
      this.$api.annotation.re_label(this.class_name_relabel, JSON.stringify(this.bbox_ids_selected)).then(() => {})
      //remove selected items from grid
      let filteredImages = this.images.filter((image) => {
        return !this.bbox_ids_selected.includes(image.bbox_id)
      });
      this.$set(this, 'images', filteredImages);
    },
    async load(filters, limit) {

      if(!filters) {
        filters = this.filters
        limit = this.limit
      }

      this.images = [];
      this.$set(this, 'loading', true)

      let results = await this.$api.annotation.filter(filters, limit);
      let total_results = results.total;
      let items = results.items
      let max_results = results.max_results;

      this.$set(this, 'max_results', max_results);

      if (items.length === 0) {
        this.$set(this, 'max_results', 0);
        this.$set(this, 'loading', false)
      }

      for (let i = 0; i < items.length; i++) {

        let item = items[i]

        let data = await this.$api.asset.get_crop(item.url, item.bbox, item.asset_id);
        let cropData = data.crops.map((crop) => {
          return {
            meta: item.meta,
            data: crop.crop,
            certainty: item.certainty,
            bbox_id: item.bbox_id,
            label: item.label,
            id: item.asset_id,
            annotation_type: item.annotation_type
          }
        })
        this.images = this.images.concat(cropData);

        if (this.images.length === total_results) {
          this.$set(this, 'loading', false)
        }
      }
    }
  },
  watch: {
    toggle_all(value) {
      for (let i = 0; i < this.images.length; i++) {
        this.$set(this.images[i], 'checked', value);
      }
    },
  },
  mounted() {
    this.load_labels();
  }
}
</script>

<style scoped>

</style>