<script>
export default {
name: "LoadRolesMixin"
  ,
  data() {
    return {
      roles_loaded: false
    }
  },
  mounted() {
    this.load_roles();
  },
  computed: {
    roles () {
      return this.$store.getters['role/get_items']
    },
  },
  methods: {
    async load_roles() {
      if(this.on_unauthenticated_pages || this.on_project_page) {
        return;
      }
      await this.$store.dispatch('role/all')
      this.$set(this, 'roles_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>