<template>
    <div class="row">
        <div class="col-md-3">
            <filter-tree
                    :title="'Filter annotations:'"
                    :initial_filter="[
                {'type':27, 'value':''},
                {'type':9, 'value':'Yes'},
                {'type':11, 'value':'No'}
               ]"
                    @update="update"
                    @load="load" :max_results="max_results"></filter-tree>
        </div>
        <div class="col-md-9">
            <div>
                <grid @update_selection_asset_ids="update_selection_asset_ids" @reload="load" :items="images"></grid>
            </div>
        </div>
    </div>
</template>

<script>
  import FilterTree from "@/components/asset_filter/FilterTree";
  import Grid from "@/components/Grid";
  export default {
    name: "FilterAnnotationsWithPreview",
    components: {Grid, FilterTree},
    data () {
      return {
        'images': [],
        'max_results': 12,
      }
    },
    methods: {
      update_selection_asset_ids(asset_ids) {
        this.$emit('update_selection_asset_ids', asset_ids)
      },
      update (filters, limit) {
        this.$emit('update_filter', filters, limit)
      },
      async load(filters, limit) {
        this.$emit('update_filter', filters)
        if(!filters) {
          filters = this.filters
          limit = this.limit
        }

        this.images = [];
        this.$set(this, 'loading', true)

        let results = await this.$api.annotation.filter(filters, limit);
        this.$emit('loaded')
        let total_results = results.total;
        let items = results.items
        let max_results = results.max_results;

        this.$set(this, 'max_results', max_results);

        if (items.length === 0) {
          this.$set(this, 'max_results', 0);
          this.$set(this, 'loading', false)
        }

        for (let i = 0; i < items.length; i++) {

          let r = items[i]
          let url = r['url'];
          let label = r['label'];
          let asset_id = r['asset_id'];
          let bbox = r['bbox'];
          let bbox_id = r['bbox_id'];
          let certainty = r['certainty']
          let meta = r['meta']

          let data = await this.$api.asset.get_crop(url, bbox, asset_id);
          let cropData = data.crops.map((crop) => {
            return {
              asset_id: asset_id,
              meta: meta,
              data: crop.crop,
              certainty: certainty,
              bbox_id: bbox_id,
              label: label,
              id: asset_id
            }
          })
          this.images = this.images.concat(cropData);

          if (this.images.length === total_results) {
            this.$set(this, 'loading', false)
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>