<template>
    <row style="margin-top: 0px;" v-if="show">
        <col2 @click.native="toggled=!toggled">
            <v-chip>{{description}}</v-chip>
        </col2>
        <col10 v-if="variations.length > 0 && !toggled">
            <div v-for="variation in variations" :key="variation.key">
                <gravatar
                          v-if="variation.top"
                          :value="variation.key"
                          :min_width="'100px'"
                          :selected_properties="variation" style="max-width: 75px;"
                          @click.native="updateProp(variation[type])"
                ></gravatar>
            </div>
        </col10>
    </row>
</template>

<script>
  import Row from "@/components/layout/Row";
  import Gravatar from "@/components/users/Gravatar";
  import Col2 from "@/components/layout/Col2";
  import Col10 from "@/components/layout/Col10";
  export default {
    name: "PropertyChooser",
    data () {
      return {
        'toggled': true
      }
    },
    props: {
      type: {required: true},
      description: {required: true},
      value: {required: true},
      options: {required: true},
      current_properties: {required: false}
    },
    computed: {
      show() {
        if(this.type === 'graphic_type' && this.current_properties.clothes !== 'GraphicShirt') {
          return false;
        }

        return true;
      },
      variations() {
        let variations = [];
        let i = 0;
        this.options.forEach(option=>{
          let properties = JSON.parse(JSON.stringify(this.current_properties));
          properties[this.type] = option
          variations.push(properties)
          i = i + 1;
        })
        return variations;
      }
    },
    components: {Col10, Col2, Gravatar, Row},
    methods: {
      updateProp(value) {
        this.$set(this, 'toggled', true);
        this.$emit('update_prop', value)
      }
    }
  }
</script>

<style scoped>

</style>