<template>
  <div>
    <div>
      <v-chip :disabled="!train_config" @click="show_train_config_modal(train_config)" style="cursor: pointer">
        {{train_config.name}}
      </v-chip>
      <v-btn icon v-if="train_config" @click.native="copy_config()" color="primary" x-small>
          <v-icon>far fa-copy</v-icon>
      </v-btn>
      <v-btn icon @click.native="showDeleteConfirmationDialog()" color="primary" x-small>
          <v-icon>far fa-trash-alt</v-icon>
      </v-btn>

      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{$t('models_and_train_configs.card_headline_sure')}}</v-card-title>
          <v-card-text>
            {{$t('models_and_train_configs.card_text_warning')}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">{{$t('models_and_train_configs.cancel_button')}}</v-btn>
            <v-btn color="red darken-1" text @click="confirmDeleteConfigAndModels">{{$t('models_and_train_configs.confirm_button')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import TrainConfig from "@/models/train_config";

export default {
  name: "TrainConfigNameCell",
  props: {
    train_config: Object,
    models: Array,
    jobs: Array
  },
  components: {},
  data () {
    return {
      dialog: false, 
    }
  },
  methods: {
    show_train_config_modal(train_config) {
      this.$emit('show_train_config_modal', train_config)
    },
    async copy_config() {
      let copy = new TrainConfig(
          this.train_config.name + ' Copy',
                this.train_config.description,
                this.train_config.meta
      )
      await this.$store.dispatch('train_config/add', copy)
      this.$emit('load_items')
    },
    async showDeleteConfirmationDialog() {
      if (this.related_models.length > 0) {
        this.dialog = true;
      } else {
        await this.$api.train_config.delete_train_config_and_related_models(this.train_config);
        this.$emit('load_items');
      }
    },
    async confirmDeleteConfigAndModels() {
      await this.$api.train_config.delete_train_config_and_related_models(this.train_config);
      this.dialog = false; 
      this.$emit('load_items');
    },
  },
  computed: {
    related_active_jobs() {
      return this.jobs.filter(job => {
        const meta = JSON.parse(job.description);
        return meta.train_config_id === this.train_config.id && job.processed != 1;
      });
    },
    related_models() {
      if(!this.models) return [];
      return this.models.filter(model => model.train_config_id === this.train_config.id);
    },
  }
}
</script>

<style scoped>

</style>