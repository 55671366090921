<template>
  <div>
    <model-list-in-modal v-if="models_in_modal" :threshold="threshold" @close_modal="hide_models_modal" :models="models_in_modal"></model-list-in-modal>
    <confusion-matrix-in-modal v-if="model_confusion_matrix_in_modal" @close_modal="hide_confusion_matrix_modal" :model="model_confusion_matrix_in_modal"></confusion-matrix-in-modal>
    <model-performance-chip @click.native="show_confusion_matrix_modal(model)" v-for="model in filtered_models" :threshold="threshold" :model="model" :key="model.id"></model-performance-chip>
    <v-btn icon v-if="filtered_models.length > 0" @click.native="show_models_modal(models)" color="primary" x-small class="ml-2">
        <v-icon>fa-solid fa-list</v-icon>
    </v-btn>
    <v-btn rounded v-if="related_jobs.length > 0">
      <v-avatar>
        <v-icon>fas fa-spinner fa-spin</v-icon>
      </v-avatar>
    </v-btn>
  </div>
</template>

<script>
import ModelPerformanceChip from "@/components/models/ModelPerformanceChip";
import ModelListInModal from "@/components/models/ModelListInModal";
import ConfusionMatrixInModal from "@/components/models/ConfusionMatrixInModal";
import dayjs from "dayjs";

export default {
  name: "ModelPerformanceSummary",
  components: {ModelListInModal, ModelPerformanceChip, ConfusionMatrixInModal},
  data () {
    return {
      'models_in_modal': null,
      'model_confusion_matrix_in_modal': null,
    }
  },
  computed: {
    sorted_models () {
      let models = JSON.parse(JSON.stringify(this.models));
      models.sort(this.compare_created_at)
      return models;
    },
    filtered_models () {
      return this.sorted_models.slice(-3);
    }
  },
  methods: {
    compare_created_at(a, b) {
      if (dayjs(a.created_at).unix() > dayjs(b.created_at).unix()) {
        return 1;
      }
      if (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) {
        return -1;
      }
      return 0;
    },
    show_models_modal(models) {
      this.$set(this, 'models_in_modal', models)
    },
    show_confusion_matrix_modal(model) {
      this.$set(this, 'model_confusion_matrix_in_modal', model)
    },
    hide_models_modal() {
      this.$set(this, 'models_in_modal', null)
    },
    hide_confusion_matrix_modal() {
      this.$set(this, 'model_confusion_matrix_in_modal', null)
    },
  },
  props: {
    models: {required: true},
    related_jobs: {required: true},
    threshold: {required: true}
  },
}
</script>

<style scoped>

</style>