<template>
  <div>
    <row>
      <div v-if="project_users_exceeded">
        <show-alert :alert-message="userLimitReachedMessage"></show-alert>
      </div>
    </row>
    <row>
      <col12 v-if="users && roles">
        <row style="height: 40px;">
          <col4>
          </col4>
          <col6>
            <div style="width: 80px; float:left; margin-left: 10px;" v-for="role in roles" :key="role.id">{{ role }}</div>
          </col6>
          <col2>
          </col2>
        </row>
        <user @delete_user="delete_user($event)" v-for="user in users" :key="user.email" :roles="roles"
            :user="user"></user>
      </col12>
    </row>
    <row>
      <col12>
        <v-btn :disabled="project_users_exceeded" color="primary" @click.native="toggle_add_modal">{{$t('settings_user.btn')}}</v-btn>
      </col12>
    </row>
    <add-user 
    @close_modal="toggle_add_modal" 
    v-if="show_add_user"
    @user-limit-reached="handleUserLimitReached"
    ></add-user>
  </div>
</template>

<script>
import User from "@/components/User";
import AddUser from "@/components/AddUser";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import Col4 from "@/components/layout/Col4";
import Col2 from "@/components/layout/Col2";
import Col6 from "@/components/layout/Col6";
import LoadUsersMixin from "@/mixins/LoadUsersMixin";
import LoadRolesMixin from "@/mixins/LoadRolesMixin";
import ShowAlert from "@/components/ShowAlert.vue";
import CurrentUserMixin from "@/mixins/CurrentUserMixin.vue";

export default {
  name: "UserList.vue",
  data() {
    return {
      'show_add_user': false,
      'project_users_exceeded': false,
    }
  },
  components: {Col2, Col4, Col6, Col12, Row, User, AddUser, ShowAlert},
  mounted() {},
  mixins: [LoadUsersMixin, LoadRolesMixin, CurrentUserMixin],
  methods: {
    handleUserLimitReached() {
      this.$set(this, 'project_users_exceeded', true)
    },
    toggle_add_modal() {
      this.$set(this, 'show_add_user', !this.show_add_user)
    },
    async delete_user(user) {
      await this.$store.dispatch('user/delete', user)
    }
  },
  computed: {
    userLimitReachedMessage() {
      return this.getLimitMessage('settings_user', 'USER_LIMIT');
    },
  }
}
</script>

<style scoped>

</style>