<template>
    <v-col
      cols="12"
      md="6"
    >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  name: "Col6"
}
</script>

<style scoped>

</style>