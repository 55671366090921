<template>
    <menu-layout :loading="loading">
        <model-loader @model_results_updated="model_results_updated"></model-loader>
        <confusion-matrix style="margin-top: 10px;" :model_results="model_results"></confusion-matrix>
    </menu-layout>

</template>

<script>
  import ConfusionMatrix from "@/components/confusion_matrix/ConfusionMatrix";
  import MenuLayout from "@/components/layout/MenuLayout";
  import ModelLoader from "@/components/confusion_matrix/ModelLoader";
  export default {
    name: "ConfusionMatrixPage",
    components: {ModelLoader, MenuLayout, ConfusionMatrix},
    data () {
      return {
        'loading': false,
        'model_results': [],
      }
    },
    methods: {
      model_results_updated(model_results) {
        this.$set(this, 'model_results', model_results)
      }
    },
  }
</script>

<style scoped>

</style>