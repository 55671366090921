<template>
  <div>
    <div>
      <active-model-state-chip :jobs="related_active_jobs"></active-model-state-chip>
    </div>
  </div>
</template>

<script>

import ActiveModelStateChip from "@/components/models/ActiveModelStateChip";


export default {
  name: "TrainConfigTrainingStateCell",
  props: {
    train_config: Object,
    jobs: Array
  },
  components: {ActiveModelStateChip},
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    related_active_jobs() {
      return this.jobs.filter(job => {
        const meta = JSON.parse(job.description);
        return job.processed != 1 && meta.train_config_id === this.train_config.id;
      });
    },
  }
}
</script>

<style scoped>

</style>