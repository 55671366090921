<template>
<div>{{project_id}}</div>
</template>

<script>
export default {
name: "Project",
  computed: {
    project_id() {
       return this.$route.params.project_id
    },
  },
  mounted () {
    this.$store.commit('user/set_current_project_id', this.project_id);
    this.$router.push({name: 'ProjectOverview'})
  }
}
</script>

<style scoped>

</style>