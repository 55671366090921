<template>
  <row>
    <col12>
    <row>
      <annotate-modal v-if="showModal && modalAssetId"
                      :model_id="model_id"
                      @remove_item="removeItem"
                      @close_modal="closeModal" :asset_id="modalAssetId"
      ></annotate-modal>
      <col10>
        <slot name="menu"></slot>
      </col10>
      <col2 v-if="!no_toggle">
        <checkbox v-if="items.length > 0" v-model="toggleAllChecked" @toggled="toggleSelectAll" :label="$t('myboxes.checkbox')"></checkbox>
      </col2>
    </row>
    <row>
      <col12>
        <row  v-if="grid_size === 2">
          <col6
              v-for="image in items" :key="image.bbox_id"
              :class="{ selected_grid_item: image.bbox_id===selected_id }"
          >

            <grid-item :no_toggle="no_toggle"
                       :image="image"
                       :selected="image.bbox_id===selected_id"
                       :checked="item_ids_selected.includes(image.bbox_id)"
                       @select_toggled="selectToggled"
                       @remove_item="removeItem"
                       @select_item="selectGridItem" :validation="image.validation"
                       @click="selectGridItem(image.bbox_id, image.bbox_id)"></grid-item>
          </col6>
        </row>
        <row v-if="grid_size === 4">
          <col3
              v-for="image in items" :key="image.bbox_id"
              :class="{ selected_grid_item: image.bbox_id===selected_id }"
          >
          
            <grid-item :no_toggle="no_toggle"
                       :image="image"
                       :selected="image.bbox_id===selected_id"
                       :checked="item_ids_selected.includes(image.bbox_id)"
                       @select_toggled="selectToggled"
                       @remove_item="removeItem"
                       @select_item="selectGridItem" :validation="image.validation"
                       @click="selectGridItem(image.bbox_id, image.bbox_id)"></grid-item>
          </col3>
        </row>
      </col12>
    </row>
    </col12>
  </row>

</template>

<script>
import GridItem from "@/components/GridItem";
import AnnotateModal from "@/components/AnnotateModal";
import Col3 from "@/components/layout/Col3";
import Row from "@/components/layout/Row";
import Col10 from "@/components/layout/Col10";
import Col2 from "@/components/layout/Col2";
import Checkbox from "@/components/Checkbox";
import Col12 from "@/components/layout/Col12";
import Col6 from "@/components/layout/Col6";

export default {
  props: {
    items: {required: true},
    model_id: {default: false},
    no_toggle: {default: false},
    grid_size: {default: 4}
  },
  components: {
    Col12,
    Checkbox,
    Col2,
    Col10,
    Col6,
    Row,
    Col3,
    AnnotateModal,
    GridItem
  },
  data() {
    return {
      selected_id: false,
      showModal: false,
      modalAssetId: false,
      modalDefaultOption: '',
      modalMeta: '',
      item_ids_selected: [],
      toggleAllChecked: false,
    }
  },
  watch: {
    items() {
      this.$set(this, 'item_ids_selected', [])
    },
    item_ids_selected() {
      let selected_images = this.items.filter(item=>{return this.item_ids_selected.includes(item.bbox_id)})
      let annotation_ids = selected_images.map(item=>{return item.bbox_id})
      let asset_ids = selected_images.map(item=>{return item.asset_id})
      this.$emit('update_selection_asset_ids', asset_ids);
      this.$emit('update_selection_annotation_ids', annotation_ids);
    }
  },
  computed: {
    selected_index() {
      return this.items.map(item=>{return item.bbox_id;}).indexOf(this.selected_id)
    }
  },
  methods: {
    toggleSelectAll (value) {
      if(value) {
        let item_ids = this.items.map(image=>{return image.bbox_id});
        this.$set(this, 'item_ids_selected', item_ids)
      }
      else {
        this.$set(this, 'item_ids_selected', [])
      }
    },
    addEvents: function(e) {
        let activeElement = document.activeElement;
        let inputs = ['input', 'select', 'button', 'textarea'];

        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return false;
        }

       if(!this.showModal && e.keyCode === 32) { //space
          e.stopPropagation();
          e.preventDefault();

          let index = this.selected_index
          let assetId = this.items[index].id
          this.selectGridItem(assetId)
        }

        if(this.showModal) {
            return;
        }

        if(e.keyCode === 13) { //enter
          e.stopPropagation();
          e.preventDefault();
          this.$emit('reload')
        }

        if(e.keyCode === 39) { //right arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.bbox_id;} ).indexOf(this.selected_id);
          let newI = i + 1
          if(i === -1 || newI > this.items.length -1) {
            newI = 0
          }
          this.$set(this, 'selected_id', this.items[newI].bbox_id);
        }

        if(e.keyCode === 37) { //left arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.bbox_id;} ).indexOf(this.selected_id);
          let newI = i -1
          if(newI < 0) {
            newI = this.items.length -1 ;
          }
          this.$set(this, 'selected_id', this.items[newI].bbox_id);
        }

        if(e.keyCode === 38) { //Up arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.bbox_id;} ).indexOf(this.selected_id);
          let newI = i - this.grid_size
          if(newI < 0) {
            return;
          }
          this.$set(this, 'selected_id', this.items[newI].bbox_id);
        }

        if(e.keyCode === 40) { //Down arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.bbox_id;} ).indexOf(this.selected_id);
          let newI = i + this.grid_size
          if(newI > this.items.length - 1) {
            return;
          }
          this.$set(this, 'selected_id', this.items[newI].bbox_id);
        }

      },
    closeModal() {
      this.$set(this, 'modalAssetId', null);
      this.$set(this, 'showModal', false);
    },
    selectGridItem(assetId) {
      if(!this.modalAssetId && !this.showModal) {
        this.$set(this, 'modalAssetId', assetId);
        this.$set(this, 'showModal', true);
      }
    },
    removeItem(assetId) {
      this.$emit('remove_item', assetId)
    },
    selectToggled(itemId) {
      let index = this.item_ids_selected.indexOf(itemId)
      if(index === -1) {
        this.item_ids_selected.push(itemId)
      }
      else {
        this.item_ids_selected.splice(index, 1);
      }

    }
  },
  mounted() {
    window.addEventListener('keydown', this.addEvents);
  },
  destroyed () {
    window.removeEventListener('keydown', this.addEvents);
  },
}
</script>

<style scoped>

</style>