<template>
  <div>
    <annotate-modal v-if="showModal && modalAssetId"
                    @close_modal="closeModal"
                    @remove_item="removeItem"
                    :asset_id="modalAssetId"
      ></annotate-modal>
    <row>
      <col3>
        <v-btn color="primary" @click="acceptAll">{{$t('suggestions.accept_all')}}</v-btn>
      </col3>
      <col3>
        <v-btn color="primary" @click="acceptSelected">{{$t('suggestions.accept_selected')}}</v-btn>
      </col3>
      <col3>
        <v-btn color="primary" @click="discardAll">{{$t('suggestions.discard_all')}}l</v-btn>
      </col3>
    </row>

    <row>
      <col4 v-for="suggestion in asset.annotation_suggestions" :key="suggestion.id">
        <annotation-suggestion 
        :asset="asset" 
        :suggestion="suggestion"
        @select_toggled="selectToggled"
        @select_asset="selectGridAsset"></annotation-suggestion>
      </col4>
    </row>

  </div>
  
</template>

<script>

import AnnotationSuggestion from "@/components/AnnotationSuggestion";
import Row from "@/components/layout/Row";
import Col3 from "@/components/layout/Col3";
import Col4 from "@/components/layout/Col4";
import AnnotateModal from "@/components/AnnotateModal";

export default {
  name: "AssetSuggestions.vue",
  props: ['asset'],
  components: {AnnotationSuggestion, Row, Col3, Col4, AnnotateModal},
  data() {
    return {
      selectedSuggestionIds: [],
      showModal: false,
      modalAssetId: false,
    }
  },
  computed: {
    allSuggestionIds() {
      return this.asset.annotation_suggestions.map(suggestion => suggestion.id);
    }
  },
  methods: {
    removeItem(asset_id) {
      this.$emit('remove_asset_from_list', asset_id)
    },
    closeModal() {
      this.$set(this, 'modalAssetId', null);
      this.$set(this, 'showModal', false);
    },
    selectGridAsset(asset_id) {
      if(!this.modalAssetId && !this.showModal) {
        this.$set(this, 'modalAssetId', asset_id);
        this.$set(this, 'showModal', true);
      }
    },
    selectToggled(suggestion_id, isChecked) {
      if (isChecked) {
        this.selectedSuggestionIds.push(suggestion_id);
      } else {
        const index = this.selectedSuggestionIds.indexOf(suggestion_id);
        if (index !== -1) {
          this.selectedSuggestionIds.splice(index, 1);
        }
      }
    },
    async acceptAll() {
      await this.$api.asset.accept_suggestions(this.asset.id, this.allSuggestionIds)
      this.$emit('remove_asset_from_list', this.asset.id)
    },
    async acceptSelected() {
      await this.$api.asset.accept_suggestions(this.asset.id, this.selectedSuggestionIds)
      //check if the api call has succeeded
      this.asset.annotation_suggestions = this.asset.annotation_suggestions.filter(
        suggestion => !this.selectedSuggestionIds.includes(suggestion.id)
      );
      this.selectedSuggestionIds = [];

      this.$set(this.asset, 'annotation_suggestions', this.asset.annotation_suggestions);


      // const index = this.assets.findIndex(asset => asset.id === this.asset.id);
      // if (index !== -1) {
      //   this.$set(this.assets, index, this.asset); // Update the asset in the assets array
      // }
      
      if (this.asset.annotation_suggestions === 0) {
        this.$emit('remove_asset_from_list', this.asset.id);
      }
    },
    async discardAll() {
      await this.$api.asset.discard_all_suggestions(this.asset.id)
      this.$emit('remove_asset_from_list', this.asset.id)
    }
  },
}

</script>

<style scoped>

</style>