<template>
  <modal @close_modal="close_modal" :title="$t('set_labels.modal_title_space') + name">
     <template v-slot:title>
       {{$t('set_labels.modal_title')}} {{name}}
     </template>
    <template v-slot:buttons>
       <v-btn
          color="primary"
          text
          id="cancel_button"
          @click.native="close_modal"
      >
        {{$t('set_labels.modal_cancel')}}
      </v-btn>

      <v-btn
          color="primary"
          text
          id="save_button"
          @click.native="save"
      >
        {{$t('set_labels.modal_save')}}
        <v-icon
            dark
            primary
            right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
      </v-btn>
     </template>
     <template v-slot:content>
       <row>
         <col6>{{$t('set_labels.modal_name')}}</col6>
         <col6>
           <v-text-field rounded outlined dense type="text" v-model="name"></v-text-field>
         </col6>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_parent')}}</col6>
         <col6>
           <autocomplete
                   v-model="parent" :options="labels">
           </autocomplete>
         </col6>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_type')}} </col6>
         <col6>
           <autocomplete
           v-model="type"
          :options="type_values"
         ></autocomplete>
         </col6>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_color')}}</col6>
         <col6>
           <v-color-picker
                   v-model="color"
                   dot-size="25"
                   hide-canvas
                   hide-inputs
                   hide-mode-switch
                   hide-sliders
                   show-swatches
                   swatches-max-height="200"
           ></v-color-picker>
         </col6>
       </row>
     </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";
import Label from "@/models/label";
import Autocomplete from "@/components/Autocomplete";
export default {
name: "EditLabelDialog",
  components: {Autocomplete, Row, Col6, Modal},
  props: {
    'item': {default: false},
    'labels': {default: () => []},
  },
  data () {
    return {
      type_values: [{id:1, name:'Box'}, {id:2, name:'Point'}],
      'name': '',
      'type': '',
      'color': '',
      'parent': '',
    }
  },
  computed: {
    label_names () {
      return this.labels.map(label=>{return {'label': label.name, 'value': label.id} })
    },
  },
  methods: {
    set_prop(prop, value) {
      this.$set(this, prop, value)
    },
    select_parent(label) {
      this.$set(this, 'parent', label.id);
    },
    select_type(type) {
      this.$set(this, 'type', type.id);
    },
    async save () {
        this.$set(this, 'saving', true);
        let color = null;

        try {
          if(this.color && this.color.hex) {
            color = this.hexToRGB(this.color.hex);
          }
          else if(this.color) {
            color = this.hexToRGB(this.color);
          }
        } catch (error) {
          console.error('Error saving color', this.color, error);
        }

        let parent_id = null;
        if(this.parent) {
          parent_id = this.parent.id
        }
        let label = new Label(this.item.id, this.name, this.type.id, color, parent_id)
        this.$store.dispatch('label/patch', { item: label, dispatch: this.$store.dispatch });

        await this.$store.dispatch('snackbars/add_success_snack', {
          'message': this.$t('snacks.label_saved'),
        })

        this.close_modal()
      },
      close_modal() {
        this.$emit('close_modal')
      },
      hexToRGB(hex, alpha) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return r + "," + g + "," + b + "," + alpha
        } else {
            return r + "," + g + "," + b
        }
      },
      RGBToHex(r,g,b) {
        r = parseInt(r).toString(16);
        g = parseInt(g).toString(16);
        b = parseInt(b).toString(16);

        if (r.length == 1)
          r = "0" + r;
        if (g.length == 1)
          g = "0" + g;
        if (b.length == 1)
          b = "0" + b;

        return "#" + r + g + b;
      },
  },
  mounted() {
    this.$set(this, 'name', this.item.name);

    let type_ids = this.type_values.map((elem) => {return elem.id});
    let label_ids = this.labels.map((elem) => {return elem.id});
    let type_id = 1;
    if(this.item.type) {
      type_id = this.item.type;
    }

    if(this.item.parent_id) {
      let parent_id = this.item.parent_id;
      this.$set(this, 'parent', this.labels[label_ids.indexOf(parent_id)]);
    }

    this.$set(this, 'type', this.type_values[type_ids.indexOf(type_id)]);

    let color = null;
    if(this.item.color) {
      let rgb = this.item.color.split(',')
      color = this.RGBToHex(rgb[0],rgb[1],rgb[2])
    }
    this.$set(this, 'color', color);
  }
}
</script>

<style scoped>

</style>