<script>

export default {
  name: "SnackbarsMixin",
  computed: {
    snacks() {
      return this.$store.getters['snackbars/all'];
    }
  },
  methods: {
  },
  mounted() {},
}
</script>

<style scoped>

</style>