export default class Project {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        return this.api.post_with_token('/projects', formData)
    }

    async add(project) {
        let formData = new FormData();
        formData.append('name', project.name);
        formData.append('description', project.description);
        return this.api.post_with_token('/project/add', formData)
    }

    async patch(project) {
        let formData = new FormData();
        formData.append('name', project.name);
        formData.append('project_id', project.id);
        formData.append('description', project.description);
    
        return this.api.post_with_token('/project/patch', formData);        
    }

    async favourite_asset(project_id) {
        let formData = new FormData();
        formData.append('project_id', project_id);
        return this.api.post_with_token('/asset/favourite', formData)
    }

    async delete(project) {
        let formData = new FormData();
        formData.append('project_id', project.id);
        return this.api.post_with_token('/project/delete', formData);
    }

}