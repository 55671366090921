<template>
  <autocomplete
      :placeholder="'Type to search'"
      :options="search_label_options"
      :loading="search_label_loading"
      @updated_search_value="search_label_value_updated"
      :clear_on_select="false"
      @select="on_select"
  ></autocomplete>
</template>

<script>
import LoadSearchLabelsMixin from "@/mixins/LoadSearchLabelsMixin.vue";
import Autocomplete from "@/components/Autocomplete.vue";

export default {
name: "SearchLabelAutocomplete",
  components: {Autocomplete},
  mixins: [LoadSearchLabelsMixin],
  props: {
    label_type: {default: null}
  },
  methods: {
    on_select(label) {
      this.$set(this, 'search_label_selected', label);
      this.$emit('on_select', label);
    }
  }
}
</script>

<style scoped>

</style>