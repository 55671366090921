<template>
      <v-card elevation="1">
        <v-card-title>{{computedTitle}}</v-card-title>
        <v-card-text>
          <v-row>
            <col12>
          <filter-group style="margin-top: 10px;"
                        @set_value="set_value"
                        @clear_filter_id="clear_filter_id" :filters="filters"></filter-group>
          <add-filter @load="load" :initial_filter="initial_filter" style="margin-top: 30px;" @add_filter="add_filter"></add-filter>
              <div style="max-width: 250px;" v-if="show_limit"><v-text-field :label="$t('filter_tree.limit')" v-model="limit" rounded filled dense outlined type="text"></v-text-field></div>
          <v-btn v-if="show_button" color="primary" @click.native="load" style="float:none; margin-left:0px; margin-top: 15px" id="load_button" class="button">
            {{$t('filter_tree.btn_load')}}
          </v-btn>
          <div v-if="max_results !== false">
            {{$t('settings_tasks.edit_filter_tree_max_results')}} {{max_results}}
          </div>
              </col12>
            </v-row>

        </v-card-text>
      </v-card>

</template>

<script>
import FilterGroup from "@/components/asset_filter/FilterGroup";
import AddFilter from "@/components/asset_filter/AddFilter";
const { v4: uuidV4 } = require('uuid');
import Col12 from "@/components/layout/Col12";
export default {
  name: "FilterTree",
  components: {Col12, AddFilter, FilterGroup},
  props: {
    'max_results': {required: true},
    'show_limit': {default: true},
    'initial_filter': {default: false},
    'show_button': {default: true},
    'title': { default: null }
  },
  computed: {
    translatedTitle() {
      return this.$t('settings_tasks.edit_title_filter_tree');
    },
    computedTitle() {
      return this.title !== null ? this.title : this.translatedTitle;
    }
  },

  data() {
    return {
      filters: [],
      limit: 12
    }
  },
  watch: {
    filters() {
      this.$emit('update', this.filters, this.limit)
    },
    limit() {
      this.$emit('update', this.filters, this.limit)
    }
  },
  mounted() {},
  methods: {
    add_filter(filter) {
      filter.id = uuidV4();
      if (filter.options && filter.options.length > 0 && !filter.value && filter.options[0] === 'Yes') {
        filter.value = filter.options[0]
      }
      this.filters.push(filter)
    },
    load() {
      this.$emit('load', this.filters, this.limit);
    },
    clear_filter_id(filter_id) {
      let filters = this.filters.filter(f=>{ return f.id !== filter_id})
      this.$set(this, 'filters', filters)
    },
    set_value(filter_id, value) {
      let filterIds = this.filters.map(f=>{return f.id});
      let i = filterIds.indexOf(filter_id)
      this.$set(this.filters[i], 'value', value)
    }
  }
}
</script>

<style scoped>

</style>