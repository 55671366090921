export default class Worker {
    constructor(box21_api) {
        this.api = box21_api;
    }

    get_active() {
        let formData = new FormData()
        formData.set('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/workers/active', formData)
    }
}