<template>
  <settings-menu-layout>
    <row>
      <v-btn color="primary" @click.native="add">{{$t('set_label_groups.btn_create')}}</v-btn>
    </row>
    <row>
      <col12>

        <edit-label-group v-for="group in annotation_groups" :group="group" :key="group.id"></edit-label-group>

      </col12>
    </row>
  </settings-menu-layout>
</template>

<script>
  import LoadLabelsMixin from "@/mixins/LoadLabelsMixin";
  import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import LoadAnnotationGroupsMixin from "@/mixins/LoadAnnotationGroupsMixin";
  import Col12 from "@/components/layout/Col12";
  import EditLabelGroup from "@/components/EditLabelGroup";
  import Row from "@/components/layout/Row";

  export default {
    name: "SettingsLabelGroups",
    mixins: [LoadLabelsMixin, LoadSettingsMixin, LoadAnnotationGroupsMixin],
    data() {
      return {
      }
    },
    methods: {
      add() {
        let group = {
          name: 'New group',
          description: '',
          annotations: [],
        }
        this.$store.dispatch('annotation_group/add', group)
      },
      set_loading(loading) {
        this.$set(this, 'loading', loading)
      },
      select_item(page) {
        this.$set(this, 'selected_page', page)
      },
    },
    components: {
      Row,
      EditLabelGroup,
      Col12,
      SettingsMenuLayout},
    mounted() {
    },
  }
</script>

<style scoped>

</style>