<template>
    <span></span>
</template>

<script>
export default {
  name: "CurrentUserMixin",
  data() {
    return {
      freeUserLimits: null,
    }
  },
  mounted() {
    this.get_limits();
  },
  methods: {
    async get_limits() {
      const response = await this.$api.user.get_limits()
      this.freeUserLimits = response;
    },
    getLimitMessage(translationCategory, limit) {
      let subscriptionPrefix = '';
      if (this.user_sub_id === 0) {
        subscriptionPrefix = 'FREE_';
      } else if (this.user_sub_id === 1) {
        subscriptionPrefix = 'LIM_';
      } else if (this.user_sub_id === 2) {
        subscriptionPrefix = 'PRO_';
      } else if (this.user_sub_id === 3) {
        subscriptionPrefix = 'SUP_';
      }

      const limitMessage = this.$t(`${translationCategory}.limit_reached`);

      return limitMessage.replace('{LIMIT}', this.freeUserLimits[subscriptionPrefix + limit]);
    },
  },
  computed: {
    user_sub_id() {
      return this.current_user ? this.current_user.subscription_id : 0;
    },
    current_user () {
      return this.$store.getters['user/get_current_user'];
    },
    current_user_roles () {
      let project_id = this.$store.getters['user/get_current_project_id'];
      let roles = [];
      if(!this.current_user) {
        return []
      }
      let user_roles_for_project = this.current_user.relationships.user_roles.filter(user_role=>{return user_role.project_id == project_id})
      user_roles_for_project.forEach(user_role=>{
          roles.push(user_role.relationships.role.name);
      })
      return roles;
    },
    is_admin ()
    {
      return this.current_user_roles.includes('Admin')
    },
    is_admin_somewhere ()
    {
      let roles = [];

      if(!this.current_user) {
        return []
      }

      let user_roles = this.current_user.relationships.user_roles
      user_roles.forEach(user_role=>{
        roles.push(user_role.relationships.role.name);
      })
      return roles.includes('Admin')
    },
    is_manager () {
      return this.current_user_roles.includes('Manager')
    },
  }
}
</script>

<style scoped>

</style>