import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuex from 'vuex'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import VueRouter from 'vue-router'
import i18n from "@/i18n";

//whitespace between import packages and pages?
import Login from "@/pages/Login";
import Tasks from "@/pages/Tasks";
import MyAssets from "@/pages/MyAssets";
import Check from "@/pages/Check";
import Box21Api from "@/api/box21_api"
import MyBoxes from "@/pages/MyBoxes";
import store from "@/store/index"
import ConfusionMatrixPage from "@/pages/ConfusionMatrixPage";
import Projects from "@/pages/Projects";
import Settings from "@/pages/Settings";
import ImportData from "@/pages/ImportData";
import Instances from "@/pages/Instances";
import CheckModel from "@/pages/CheckModel";
import TrainPage from "@/pages/TrainPage";
import Validate from "@/pages/Validate";
import SettingsUsers from "@/pages/SettingsUsers";
import SettingsInstances from "@/pages/SettingsInstances";
import SettingsTrainConfigurations from "@/pages/SettingsTrainConfigurations";
import SettingsLabels from "@/pages/SettingsLabels";
import SettingsLabelGroups from "@/pages/SettingsLabelGroups";
import SettingsJobs from "@/pages/SettingsJobs";
import SettingsLabelSets from "@/pages/SettingsLabelSets";
import ProfileChooseAvatar from "@/pages/ProfileChooseAvatar";
import ProfileChangePassword from "@/pages/ProfileChangePassword";
import ProfileNotifications from "@/pages/ProfileNotifications";
import ProfileAuthentication from "@/pages/ProfileAuthentication";
import ProfileSegAny from "@/pages/ProfileSegAny";
import ProfileUserLimits from "@/pages/ProfileUserLimits";
import ProfileUpgradeSubscription from "@/pages/ProfileUpgradeSubscription";
import ProfilePaymentOverview from "@/pages/ProfilePaymentOverview";
import ModelPage from "@/pages/ModelPage";
import Unauthorized from "@/pages/Unauthorized";
import SettingsProject from "@/pages/SettingsProject";
import Project from "@/pages/Project";
import SettingsTasks from "@/pages/SettingsTasks";
import MyProfile from "@/pages/MyProfile";
import FrontPage from "@/pages/FrontPage";
import Progress from "@/pages/Progress";
import ProjectOverview from "@/pages/ProjectOverview";
import Auto from "@/pages/Auto.vue";
import AutoSuggestions from "@/pages/AutoSuggestions.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import Success from "@/pages/Success.vue";
import ErrorPage from "@/pages/ErrorPage.vue";
// import SegmentAnything from "@/pages/SegmentAnything.vue";
import ConfirmEmail from "@/pages/ConfirmEmail.vue";

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueRouter)


const routes = [
  { path: '/', component: FrontPage, name: 'FrontPage' },
  { path: '/login', component: Login, name: 'Login' },
  { path: '/projects', component: Projects, name: 'Projects',},
  { path: '/project/:project_id', component: Project, name: 'Project',},
  { path: '/overview', component: ProjectOverview, name: 'ProjectOverview',},
  { path: '/annotate', component: Tasks, name: 'Tasks' },
  { path: '/myassets', component: MyAssets, name: 'MyAsset' },
  { path: '/validate', component: Validate, name: 'Validate' },
  { path: '/myboxes', component: MyBoxes, name: 'MyBoxes' },
  { path: '/import', component: ImportData, name: 'Import' },
  { path: '/progress', component: Progress, name: 'Progress' },
  { path: '/train/:model_type', component: TrainPage, name: 'Train' },
  { path: '/models', component: ModelPage, name: 'Models' },
  { path: '/settings', component: Settings, name: 'Settings' },
  { path: '/settings/users', component: SettingsUsers, name: 'SettingsUsers' },
  { path: '/settings/instances', component: SettingsInstances, name: 'SettingsInstances' },
  { path: '/settings/train-configurations', component: SettingsTrainConfigurations, name: 'SettingsTrainConfigurations' },
  { path: '/settings/labels', component: SettingsLabels, name: 'SettingsLabels' },
  { path: '/settings/tasks', component: SettingsTasks, name: 'SettingsTasks' },
  { path: '/settings/label-groups', component: SettingsLabelGroups, name: 'SettingsLabelGroups' },
  { path: '/settings/project', component: SettingsProject, name: 'SettingsProject' },
  { path: '/settings/jobs', component: SettingsJobs, name: 'SettingsJobs' },
  { path: '/settings/label-sets', component: SettingsLabelSets, name: 'SettingsLabelSets' },
  { path: '/profile/avatar', component: ProfileChooseAvatar, name: 'ProfileChooseAvatar' },
  { path: '/profile/change-password', component: ProfileChangePassword, name: 'ProfileChangePassword' },
  { path: '/profile/notifications', component: ProfileNotifications, name: 'ProfileNotifications' },
  { path: '/profile/authentication', component: ProfileAuthentication, name: 'ProfileAuthentication' },
  { path: '/profile/seg-any', component: ProfileSegAny, name: 'ProfileSegAny' },
  { path: '/profile/user-limits', component: ProfileUserLimits, name: 'ProfileUserLimits' },
  { path: '/profile/upgrade-subscription', component: ProfileUpgradeSubscription, name: 'ProfileUpgradeSubscription' },
  { path: '/profile/payment-overview', component: ProfilePaymentOverview, name: 'ProfilePaymentOverview' },
  { path: '/auto', component: Auto, name: 'Auto' },
  { path: '/auto/suggestions', component: AutoSuggestions, name: 'AutoSuggestions' },
  { path: '/instances', component: Instances, name: 'Instances' },
  { path: '/mostconfused', component: ConfusionMatrixPage, name: 'ConfusionMatrixPage' },
  { path: '/check/:asset_id', component: Check, name: 'Check' },
  { path: '/check-model/:asset_id', component: CheckModel, name: 'CheckModel' },
  { path: '/unauthorized', component: Unauthorized, name: 'Unauthorized' },
  { path: '/myprofile', component: MyProfile, name: 'MyProfile' },
  { path: '/reset-password/:password_reset_token', component: ResetPassword, name: 'ResetPassword' },
  { path: '/reset-password/', component: ResetPassword, name: 'InitiateResetPassword' },
  { path: '/success', component: Success, name: 'Success' },
  { path: '/error', component: ErrorPage, name: 'Error' },
  // { path: '/sa', component: SegmentAnything, name: 'SegmentAnything' },
  { path: '/confirm/:token', component: ConfirmEmail, name: 'ConfirmEmail' },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
   if(!window.location.host.includes('box21.ai') && !window.location.host.includes('localhost') && !window.location.host.includes('box21.test') && !window.location.host.includes('box21.agouti') && to.name === 'FrontPage' ) {
     router.push('Login');
   }
   else {
    next()
   }
})

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
})

import * as Sentry from "@sentry/vue";

if(!document.URL.includes('localhost') && !document.URL.includes('test')) {
  Sentry.init({
    Vue,
    dsn: "https://d67be3dad0fb84ba3b1f06b01891e54a@o90617.ingest.sentry.io/4506043867856896",
  });
}

let api_host = '/api'
let box21_api = new Box21Api(app, api_host);
Vue.prototype.$api = box21_api
Vue.prototype.$api_url = api_host + '/static'
Vue.prototype.$api_host = api_host

app.$mount('#app')
