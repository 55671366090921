import base_mutations from '@/store/modules/base/mutations.js'
import Vue from 'vue'

export default {
    ...base_mutations,
    set_favourite (state, key_value) {
        let key = key_value.key;
        let value = key_value.value;
        Vue.set(state.favourites, key, value)
    },
    set_users (state, key_value) {
        let key = key_value.key;
        let value = key_value.value;
        Vue.set(state.users, key, value)
    }
}