export default class ConfMatrixItem {
  constructor(box21_api) {
    this.api = box21_api;
  }

  async all(model_id) {
    let formData = new FormData();
    formData.append('model_id', model_id);
    formData.append('project_id', sessionStorage.getItem('project_id'))
    return this.api.post_with_token('/conf-matrix-items', formData)
  }

  async delete(model_id) {
    let formData = new FormData();
    formData.append('model_id', model_id);
    formData.append('project_id', sessionStorage.getItem('project_id'))
    return this.api.post_with_token('/conf-matrix-items/delete', formData)
  }
}