<template>
  <settings-menu-layout>
    <row v-if="project">
      <loader :loading="loading"></loader>
      <v-container>
        <col12>
          <!-- Change Project Name Card -->
          <v-card>
            <v-card-title>
              <h2 class="headline">{{$t('settings_project.change_name_title')}}</h2>
            </v-card-title>
            <v-card-text>
              <form>
                <v-text-field
                v-model="name"
                :counter="255"
                :label="$t('settings_project.name')"
                required
              ></v-text-field>
              <v-text-field
                v-model="description"
                :label="$t('settings_project.description')"
                required
              ></v-text-field>
              <v-btn
                color="primary"
                class="mr-4"
                @click="save"
                :disabled="disable_save"
              >
                {{$t('settings_project.btn_save')}}
              </v-btn>
              <v-btn color="primary" class="mr-4" @click="clear">
                {{$t('settings_project.btn_clear')}}
              </v-btn>
              <v-btn color="primary" class="mr-4" @click="reset">
                {{$t('settings_project.btn_reset')}}
              </v-btn>
              </form>
            </v-card-text>
          </v-card>
        </col12>
      </v-container>

      <v-container>
        <col12>
          <!-- Delete Project Card -->
          <v-card>
            <confirm-delete-modal
              :value="project.name"
              @confirmed="delete_project"
              @close_modal="close_confirm_modal"
              v-if="show_confirm_modal"
              title="Confirm delete project"
            ></confirm-delete-modal>
            <v-card-title>
              <h2 class="headline">{{$t('settings_project.delete_project_title')}}</h2>
            </v-card-title>
            <v-card-text>
              <p class="subheading">
                {{$t('settings_project.delete_project_additional_info')}}
              </p>
              <v-btn
                color="error"
                class="mr-4"
                @click="confirm_delete_project"
              >
                {{$t('settings_project.btn_delete')}}
              </v-btn>
            </v-card-text>
          </v-card>
        </col12>
      </v-container>
    </row>
  </settings-menu-layout>
</template>


<script>
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
  import Row from "@/components/layout/Row";
  import Col12 from "@/components/layout/Col12";
  import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
  import Loader from "@/components/layout/loader";
  import LoadUsersMixin from "@/mixins/LoadUsersMixin";

  export default {
    name: "SettingsProject",
    mixins: [LoadProjectsMixin, LoadUsersMixin],
    data() {
      return {
        description: false,
        name: false,
        show_confirm_modal: false,
        loading: false
      }
    },
    methods: {
      set_loading(loading) {
        this.$set(this, 'loading', loading)
      },
      save() {
        let project = JSON.parse(JSON.stringify(this.project));
        project.name = this.name;
        project.description = this.description;
        this.$store.dispatch('project/patch', { item: project, dispatch: this.$store.dispatch });
      },
      close_confirm_modal () {
        this.$set(this, 'show_confirm_modal', false);
      },
      confirm_delete_project () {
        this.$set(this, 'show_confirm_modal', true);
      },
      delete_project() {
        this.$set(this, 'loading', true);
        this.$store.dispatch('project/delete', this.project);
        this.$router.push({name:'Projects'});
      },
      reset() {
        this.name = this.project.name;
        this.description = this.project.description;
      },
      clear() {
        this.name = '';
        this.description = '';
      },
      init() {
        this.name = this.project.name;
        this.description = this.project.description;
      }
    },
    computed: {
      disable_save() {
        return !this.name || !this.description || (this.name === this.project.name && this.description === this.project.description);
      },
      project() {
        let project_id = this.$store.getters['user/get_current_project_id']
        let ids = this.projects.map(project=>{return project.id});
        let index = ids.indexOf(parseInt(project_id))
        return this.projects[index];
      }
    },
    components: {
      Loader,
      ConfirmDeleteModal,
      Col12,
      Row,
      SettingsMenuLayout,
    },
    watch: {
      project(n) {
        if(n) {
          this.init();
        }
      }
    },
    mounted() {
      if(this.project) {
        this.init();
      }
    }
  }
</script>

<style scoped>

</style>