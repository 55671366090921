
<template>
  <div>
    <loader :loading="loading"></loader>
    <row12 v-if="jobs.length > 0">
      <jobs @refresh="loadJobs" :jobs="jobs" :message="$t('auto_t.message')"></jobs>
    </row12>
    <row12 v-if="running_jobs.length === 0">
      <primary-button :label="$t('auto_t.btn_start')" @pressed="start_remove_duplicates_job"></primary-button>
    </row12>
  </div>
</template>

<script>

import Loader from "@/components/layout/loader.vue";
import Row12 from "@/components/layout/Row12.vue";
import Jobs from "@/components/Jobs.vue";
import PrimaryButton from "@/components/form/PrimaryButton.vue";

export default {
  name: "NoDuplicates",
  components: {PrimaryButton, Jobs, Row12, Loader},
  data() {
    return {
      'jobs': [],
      'loading': true,
      'task': 'remove_duplicates'
    }
  },
  computed: {
    running_jobs() {
      return this.jobs.filter(job => !job.processed);
    }
  },
  methods: {
    async start_remove_duplicates_job() {
      this.$set(this, 'loading', true);
      await this.$api.job.add_job(this.task);
      await this.$store.dispatch('snackbars/add_success_snack', 'Remove duplicates job added')
      this.loadJobs();
    },
    async loadJobs() {
      let jobs = await this.$api.job.get_active_jobs(this.task)
      this.$set(this, 'jobs', jobs);
      this.$set(this, 'loading', false);
    },
  },
  mounted() {
    this.loadJobs();
  },
}
</script>

<style scoped>

</style>