<script>
export default {
name: "LoadLabelMixin"
  ,
  data() {
    return {
      search_label_options: [],
      search_label_loading: false,
      search_label_selected: null,
      search_label_value: null
    }
  },
  mounted() {
  },
  computed: {

  },
  methods: {
    async search_label_value_updated(value) {
      await this.load_label_options(value);
    },
    async load_label_options(value) {

      if(value == null) {
        return;
      }

      // Handle empty value
      if (!value) {
        this.search_label_options = [];
        this.search_label_selected = '';
      }

      if(value && value.length < 2) {
        return;
      }

      // Items have already been requested
      if (this.search_label_loading) {
        return
      }

      if (this.search_label_selected && this.search_label_selected.name === value) {
        return
      }

      this.search_label_loading = true

      // Wait 1 second
      await new Promise(resolve => setTimeout(resolve, 1000))

      await this.$api.label.all({'filter_name': value, 'filter_type': this.label_type}).then((response) => {
        this.search_label_options = response.map((label) => {
          return {id: label.id, name: label.name}
        });
      });

      this.search_label_loading = false;
    },
  }
}
</script>

<style scoped>

</style>