<template>
  <div style="display: flex; align-items: center;" @click="changeHandler">
    <v-btn x-small color="primary" :title="editable ? 'Toggle' : 'Disabled'" :style="label ? 'margin-right: 10px;' : ''">
      <v-icon x-small v-if="value === true || value === 'true'">
        fas fa-check
      </v-icon>
      <input class="checkbox" style="cursor: pointer; display: none" type="checkbox" :checked="value">
    </v-btn>
    <div style="margin-left: 8px;">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    'value': {required: true},
    'label': {default: ''},
    'editable': {default: true}
  },
  methods: {
    changeHandler () {
      if(!this.editable) {
        return;
      }
      let response = true
      if (this.value === true || this.value === 'true') {
        response = false;
      }
      this.$emit('input', response);
      this.$emit('toggled', response)
    }
  }
}
</script>

<style scoped>
</style>