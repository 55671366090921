<template>
  <div>
    <row>
      <col12>
        <v-card elevation="2" v-if="subs.Free !== null">
          <v-card-text>
            <div class="icon-title">
              <v-icon>mdi-trophy</v-icon>
              <b v-if="user_sub_id === 0"> {{$t('myprofile.upgrade_plan')}}<br><br></b>
              <b v-else>{{$t('myprofile.subscription_details')}}<br><br></b>
            </div>

            <div>
              <p v-if="user_sub_id === 0">{{$t('myprofile.subscr_free')}}</p>
              <p v-else-if="user_sub_id === 1">{{$t('myprofile.subscr_limit')}}</p>
              <p v-else-if="user_sub_id === 2">{{$t('myprofile.subscr_pro')}}</p>
              <p v-else-if="user_sub_id === 3">{{$t('myprofile.subscr_superior')}}</p>
              <p v-else>{{$t('myprofile.subscr_title')}}</p>
            </div>

            <v-row style="margin-top: 5px;">
              <v-col cols="3" v-for="(sub, subKey) in filteredSubs" :key="subKey">
                <v-card class="mx-auto" :style="{ backgroundColor: user_sub_id === sub.id ? '#c0d4f4' : '' }">
                  <v-card-text>
                    <div>
                      <div class="text-overline mb-1">{{$t(`myprofile.${sub.title.toLowerCase()}`)}}</div>
                      <v-icon :class="getIconClass(sub.id)" style="font-size: 2.5em" :color="user_sub_id === sub.id ? 'primary' : ''"></v-icon>
                    </div>
                    <div style="margin-top: 10px;">
                      <span v-for="benefit in $t(getBenefitsKey(sub.title))" :key="benefit" style="display: block;">• {{ benefit }}</span>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn v-if="!showOptions[subKey] && (sub.title === 'limited' || sub.title === 'pro' || sub.title === 'superior') && user_sub_id !== sub.id" @click="showUpgradeOptions(subKey)">{{ $t('myprofile.upgrade_plan') }}</v-btn>
                    <div v-if="showOptions[subKey] && (sub.title === 'limited' || sub.title === 'pro')">
                      <v-btn @click="upgradeSub(sub, 'month')">{{ $t(getUpgradeTranslationKey(sub.title, 'month')) }}</v-btn>
                      <v-btn @click="upgradeSub(sub, 'year')">{{ $t(getUpgradeTranslationKey(sub.title, 'year')) }}</v-btn>
                    </div>
                    <div v-else-if="showOptions[subKey] && sub.title === 'superior'">
                      <span style="color: #666;">{{$t('myprofile.contact')}}</span>
                      <a href="mailto:info@box21.ai">info@box21.ai</a>
                    </div>
                  </v-card-actions>
                </v-card>     
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </col12>
    </row>
  </div>
</template>

<script>
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import { loadStripe } from '@stripe/stripe-js';
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

export default {
  name: "UpgradeSubscription.vue",
  components: {Row, Col12},
  mixins: [CurrentUserMixin],
  data() {
    return {
      stripe: null,
      subs: {
        Free: null,
        Limit: null,
        Pro: null,
        Superior: null
      },
      showOptions: {
        Free: false,
        Limit: false,
        Pro: false,
        Superior: false
      },
    };
  },
  computed: {
    filteredSubs() {
      return Object.values(this.subs).filter(sub => sub !== null && this.user_sub_id <= sub.id);
    }
  },
  methods: {
    async initStripe() {
      // this.stripe = await loadStripe('pk_test_51NkuT7EohHftw9zSh9JT89jiVKsvThuKxPHs1BqdGmYpz0tkQS0KGhynFDB6t2OGttl0sHY9DVu3QOKfW0mAkQh200MUl6MR8U');
      this.stripe = await loadStripe('pk_test_51NkuT7EohHftw9zSh9JT89jiVKsvThuKxPHs1BqdGmYpz0tkQS0KGhynFDB6t2OGttl0sHY9DVu3QOKfW0mAkQh200MUl6MR8U');
    },
    getBenefitsKey(sub_title) {
      return `myprofile.${sub_title.toLowerCase()}_plan_benefits`;
    },
    async getSub() {
      let subs = await this.$api.user.get_subscriptions();
      if (!subs) {
        return;
      }
      this.subs = {
        Free: this.findSub(subs, 0),
        Limit: this.findSub(subs, 1),
        Pro: this.findSub(subs, 2),
        Superior: this.findSub(subs, 3)
      };
    },
    findSub(subs, id) {
      const titles = ['free', 'limited', 'pro', 'superior'];
      const sub = subs.find(sub => sub.id === id);
      if (sub) {
        sub.title = titles[id];
      }
      return sub;
    },
    getCardVariant() {
      return 'tonal';
    },
    getIconClass(subId) {
      const iconMapping = {
        0: "fas fa-seedling",
        1: "fas fa-tree",
        2: "fa-solid fa-mountain",
        3: "fa-solid fa-earth-americas"
      };
      return iconMapping[subId];
    },
    showUpgradeOptions(subKey) {
      this.$set(this.showOptions, subKey, !this.showOptions[subKey]);
    },
    async upgradeSub(subscription, duration) {
      if (!subscription || !subscription.id || !duration) {
        return;
      }
      const response = await this.$api.user.upgrade_subscription_stripe_payment_request(subscription.id, duration);
      if (response.sessionId) {
        await this.stripe.redirectToCheckout({ sessionId: response.sessionId });
      }
    },
    getUpgradeTranslationKey(subTitle, period) {
      const normalizedTitle = subTitle.charAt(0).toUpperCase() + subTitle.slice(1);
      return `myprofile.upgr_${normalizedTitle.toLowerCase()}_${period}`;
    },
  },
  mounted() {
    this.getSub();
    this.initStripe();
  }
};
</script>

<style scoped>

</style>