<template>
  <div id="search_div" style="margin-top:20px;">
    <div class="row">
      <div class="col-md-8">
        <annotated-image
            :zoom_enabled="true"
            :image_url="asset_url"
            :annotations="annotations"
            @update_coords="updateAnnotation"
            @delete_annotation="deleteAnnotation"
            @select="selectAnnotation"
            @unselect="unselectAnnotation"
        ></annotated-image>
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="col-md-12">
          <annotate-labels @add_annotation="addAnnotation" :type="'box'"></annotate-labels>
          <annotate-labels @add_annotation="addAnnotation" :type="'point'"></annotate-labels>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <annotations-list
                @clear="clearAnnotations"
                @select="selectAnnotation"
                @unselect="unselectAnnotation" :annotations="annotations"></annotations-list>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div v-if="original_category" style="margin-top: 10px">
          Original category: <br />
          {{original_category}}
        </div>

        <annotate-buttons
          :asset_id="asset_id"
          :annotations="annotations"
          :asset_id_history="asset_id_history"
        ></annotate-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import AnnotatedImage from "@/components/AnnotatedImage";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import Annotation from "@/classes/annotation";
import AnnotationsList from "@/components/AnnotationsList";
import AnnotateLabels from "@/components/AnnotateLabels";
import AnnotateButtons from "@/components/AnnotateButtons";
export default {
  name: "CheckModel",
  components: {AnnotateButtons, AnnotateLabels, AnnotationsList, AnnotatedImage},
  mixins: [AnnotateMixin],
  props: {
    'asset_id': {required: true}
  },
  data () {
    return {
      'canvas_id': 'custom_id',
      'find_meta_contains': '',
      'find_original_category': '',
      'original_category': '',
      'asset_id_history': [],
      'asset_url': false,
    }
  },
  mounted () {
    this.load();
    window.addEventListener('keydown', this.addEvents);
  },
  computed: {
  },
  destroyed () {
    window.removeEventListener('keydown', this.addEvents);
  },
  methods: {
    addEvents: function(e) {
        if(e.keyCode === 13) { //enter
          e.stopPropagation();
          e.preventDefault();
          this.isCorrect();
        }
        if(e.keyCode === 8) { // delete
          let annotations = this.annotations.filter(annotation=>{return !annotation.selected})
          this.$set(this, 'annotations', annotations);
        }
        if(e.keyCode === 39) { // arrow right
          if(this.annotation_selected) {
            console.log('TODO move to the right');
          }
        }
      },
      async load() {
          this.$set(this, 'loading', true);

          let result = await this.$api.model.get_preds(this.asset_id)
          let annotations = result.annotations
          let i = 0;
          annotations = annotations.map(annotation=>{
            let id = this.getUniqueId();
            let color = result.colors[i]; i += 1;
            return new Annotation(id, 'box', annotation.coords, annotation.conf, annotation.label, color);
          });
          this.$set(this, 'annotations', [])
          this.$set(this, 'asset_url', result.path)
          this.$set(this, 'annotations', annotations)
          this.$set(this, 'loading', false)
        
    }
  }
}
</script>

<style scoped>

</style>