<template>
  <row v-if="jobs.length > 0" style="margin-bottom: 30px;">
    <col12>
      <job :message="message" v-for="job in filtered_jobs" :key="job.id" :job="job"></job>
    </col12>

    <col12 v-if="!hide_refresh">
      <v-btn @click.native="refresh"
           color="primary"
           small>
      {{$t('auto_t.btn_refresh')}}
    </v-btn>
      <v-btn v-if="jobs.length > 1"
          small left @click.native="show_last=!show_last">
        {{$t('auto_t.btn_show_all')}}
      </v-btn>
    </col12>
  </row>
</template>

<script>

import Job from '@/components/Job'
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
export default {
  name: "Jobs",
  props: {
    'jobs': {required: true},
    'message': {default: ''},
    'hide_refresh': {default: false}
  },
  data () {
    return {
      'show_last': true,
    }
  },
  components: {Row, Job, Col12},
  methods: {
    refresh() {
      this.$emit('refresh');
    }
  },
  computed: {
    sorted_jobs() {
      let jobs = this.jobs;
      return jobs.sort(function(job_a,job_b) {
          if (job_a.created_at > job_b.created_at) {
            return 1;
          }
          if (job_a.created_at < job_b.created_at) {
            return -1;
          }
          // a must be equal to b
          return 0;
      })
    },
    filtered_jobs() {
      if(this.show_last) {
        return [this.sorted_jobs[this.jobs.length-1]];
      }
      return this.jobs;
    }
  }
}
</script>

<style scoped>

</style>