<template>
    <modal
            :loading="loading"
            :title="'Run model for ' + model.name"
            style="max-width: 500px"
            @close_modal="close_modal"
    >
        <template v-slot:buttons>
            <primary-button label="Run on filter" :disabled="disable_run_button" @pressed="add_run_modal_task"></primary-button>
            <primary-button label="Run on selection" :disabled="disable_run_button || selected_asset_ids.length === 0" @pressed="add_run_modal_task_on_selection"></primary-button>
        </template>
        <template v-slot:content>
            <v-switch v-model="run_on_crops" :label="'Run model on cropped annotations'"></v-switch>
            <filter-asset-with-preview @update_selection_asset_ids="update_selection" @loaded="loaded" @update_filter="update_filter" v-if="!run_on_crops"></filter-asset-with-preview>
            <filter-annotations-with-preview @update_selection_asset_ids="update_selection" @loaded="loaded" @update_filter="update_filter" v-if="run_on_crops"></filter-annotations-with-preview>
        </template>
    </modal>
</template>

<script>
  import Modal from "@/components/Modal";
  import PrimaryButton from "@/components/form/PrimaryButton";
  import FilterAssetWithPreview from "@/components/run_model_modal/FilterAssetsWithPreview";
  import FilterAnnotationsWithPreview from "@/components/run_model_modal/FilterAnnotationsWithPreview";
  export default {
    name: "RunModalModal",
    components: {FilterAnnotationsWithPreview, FilterAssetWithPreview, PrimaryButton, Modal},
    props: {
      model: {
        required: true
      }
    },
    data () {
      return {
        'disable_run_button': true,
        'loading': false,
        'filter': false,
        run_on_crops: true,
        selected_asset_ids: [],
      }
    },
    methods: {
      update_selection(asset_ids) {
        this.$set(this, 'selected_asset_ids', asset_ids);
      },
      loaded() {
        this.$set(this, 'disable_run_button', false)
      },
      update_filter(filters) {
        this.$set(this, 'filter', filters);
        this.$set(this, 'disable_run_button', true)
      },
      close_modal() {
        this.$emit('close_modal')
      },
      add_run_modal_task_assets() {
        let params = {
          'model': this.model,
          'filter': this.filter
        }

        this.$api.asset.analyse_with_filter(
            params)

        this.close_modal()
      },

      add_run_modal_task_assets_on_selection() {
        let params = {
          'model': this.model,
          'asset_ids': this.selected_asset_ids
        }

        this.$api.asset.analyse_selected(params);

        this.close_modal()
      },

      valid_run_crops_task() {

        let type_27_filters = this.filter.filter(filter=>{return filter.type === 27;})
        let type_1_filters = this.filter.filter(filter=>{return filter.type === 1;})

        if(!type_27_filters || !type_1_filters) {
          alert('You should add the filter "Containing Annotation"');
          return false;
        }
        if(type_27_filters.length > 0 && type_1_filters.length > 0) {
          alert('Only add one filter "Containing Annotation"');
          return false;
        }
        if(type_27_filters && type_27_filters.length > 1) {
          alert('Only add one filter "Containing Annotation"');
          return false;
        }
        if(type_1_filters && type_1_filters.length > 1) {
          alert('Only add one filter "Containing Annotation"');
          return false;
        }

        if(!this.get_crop_on()) {
          alert('Please select a label');
          return false
        }

        return true;
      },
      get_crop_on() {
        let type_27_filters = this.filter.filter(filter=>{return filter.type === 27;})
        let type_1_filters = this.filter.filter(filter=>{return filter.type === 1;})

        let crop_on = false;
        if(type_1_filters.length > 0 && type_1_filters[0].value) {
          crop_on = type_1_filters[0].value;
        }
        if(type_27_filters.length > 0 && type_27_filters[0].value) {
          crop_on = type_27_filters[0].value;
        }
        return crop_on;
      },
      add_run_modal_task_crops_on_selection() {

        if(!this.valid_run_crops_task()) {
          return;
        }

        let crop_on = this.get_crop_on();
        let type_27_filters = this.filter.filter(filter=>{return filter.type === 27;})

        let params = {
          'model': this.model,
          'include_descendents': type_27_filters.length > 0,
          'asset_ids': this.selected_asset_ids
        }

        params['crop_on'] = crop_on

        this.$api.asset.analyse_selected(params)

        this.close_modal()
      },
      add_run_modal_task_crops() {

        if(!this.valid_run_crops_task()) {
          return;
        }

        let crop_on = this.get_crop_on();
        let type_27_filters = this.filter.filter(filter=>{return filter.type === 27;})

        let params = {
          'model': this.model,
          'filter': this.filter,
          'include_descendents': type_27_filters.length > 0,
        }

        params['crop_on'] = crop_on

        this.$api.asset.analyse_with_filter(
          params)

        this.close_modal()
      },
      add_run_modal_task () {

        this.$store.dispatch('snackbars/add_success_snack', 'Model run queued successfully')

        if(this.run_on_crops) {
          this.add_run_modal_task_crops();
        }
        else {
          this.add_run_modal_task_assets();
        }
      },
      add_run_modal_task_on_selection () {

        this.$store.dispatch('snackbars/add_success_snack', 'Model run queued successfully')

        if(this.run_on_crops) {
          this.add_run_modal_task_crops_on_selection();
        }
        else {
          this.add_run_modal_task_assets_on_selection();
        }
      },
    }
  }
</script>

<style scoped>

</style>