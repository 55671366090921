<template>
    <div>
        <v-simple-table v-if="has_named_keys">
            <crops :model_id="model_id" :type="type" :title="type" :items="items_dict"></crops>
        </v-simple-table>
        <v-simple-table v-else>
            <crops :model_id="model_id" :type="type" v-for="pred_label in item_labels" :key="pred_label"
                   :title="pred_label" :items="items_dict[pred_label]"></crops>
        </v-simple-table>
    </div>
</template>

<script>
  import Crops from "@/components/confusion_matrix/Crops";
  export default {
    name: "ConfusionMatrixItems",
    components: {Crops},
    props: {
      model_id: {required: true},
      type: {required: true},
      items: {required: true},
      labels_dict: {required: true}
    },
    computed: {
      enriched_items() {
        this.items.forEach(item=>{
          if(item.label_id) {
            item.label_name = this.labels_dict[item.label_id].name;
          }
          if(item.pred_label_id) {
            item.pred_label_name = this.labels_dict[item.pred_label_id].name;
          }
          return item;
        });
        return this.items;
      },
      has_named_keys() {
        if(this.enriched_items.length === 0) {
          return false;
        }

        return !this.enriched_items[0].pred_label_name;
      },
      items_dict() {
        if(this.enriched_items.length === 0) {
          return []
        }

        if(!this.enriched_items[0].pred_label_name) {
          return this.enriched_items;
        }

        let items_dict = {}
        this.enriched_items.forEach((item) => {
          let pred_label_name = item.pred_label_name;
          if(Object.keys(items_dict).indexOf(pred_label_name) === -1) {
            items_dict[pred_label_name] = []
          }
          items_dict[item.pred_label_name].push(item);
        })

        return items_dict;
      },
      item_labels() {
        return Object.keys(this.items_dict);
      }
    }

  }
</script>

<style scoped>

</style>