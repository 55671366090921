<template>
  <div>
    <v-container>
      <v-card elevation="2" v-if="canHaveMultipleProjects && user_sub_id !== 3">
        <v-card-title>
          <h2 class="headline">{{ $t('overview.limits') }}</h2>
        </v-card-title>
        <v-card-text>
          {{ $t('overview.multiple_projects_message') }}<br><br>
          <v-btn color="primary" @click="upgradePlan">{{$t('overview.visit_my_profile')}}</v-btn>
        </v-card-text>
      </v-card>
      <user-limits v-if="canHaveOneProject" 
      :showUpgradeButton="true"
      headerType="projectOverview"
      ></user-limits>
    </v-container>
  </div>
</template>

<script>

import UserLimits from "@/components/myprofile/UserLimits";
import CurrentUserMixin from "@/mixins/CurrentUserMixin.vue";

export default {
  name: 'OneProjectLimits.vue',
  components: { UserLimits},
  mixins: [CurrentUserMixin],
  data() {
    return {
    };
  },
  methods: {
    upgradePlan() {
      this.$router.push({ name: 'MyProfile' });
    },
  },
  computed: {
    canHaveMultipleProjects() {
      return [2, 3].includes(parseInt(this.user_sub_id));
    },
    canHaveOneProject() {
      return [0, 1].includes(parseInt(this.user_sub_id));
    }
  },
}
</script>

<style scoped>

</style>
