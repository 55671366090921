<template>
    <tr>
        <td></td>
        <td>
            <div>{{title}}:</div>
            <div style="padding-bottom: 20px;">
                <grid :model_id="model_id"
                :options="[]"
                      :no_toggle="true"
                :items="crops"></grid>
            </div>
            <div style="padding-bottom: 20px;" v-if="crops.length < items.length" >
                <v-btn color="primary" @click="load_more_crops">Load more</v-btn>
            </div>

        </td>
    </tr>
</template>

<script>
  import Grid from "@/components/Grid";
  export default {
    name: "Crops",
    components: {Grid},
    props: {
      model_id: {required: true},
      items: {required: true},
      title: {required: true},
      type: {required: true},
    },
    data () {
      return {
        'loading': false,
        'crops': []
      }
    },
    methods: {
      async load_crop(item) {
        let crop_data = await this.$api.asset.get_crop_for_conf_items(this.type, JSON.stringify([item]))
        if(crop_data.crops) {
          crop_data = crop_data.crops;
          this.crops.push({data: crop_data[0].crop, id: crop_data[0].asset_id});
        }
        else {
          this.crops.push({data: false, id: crop_data[0].asset_id});
        }

      },
      load_more_crops() {
        for(let i=this.crops.length; i<Math.min(this.crops.length + 4, this.items.length); i++) {
          this.load_crop(this.items[i])
        }
      }
    },
    mounted() {
      //Load first five crops:
      for(let i=0; i<Math.min(this.items.length, 4); i++) {
        this.load_crop(this.items[i])
      }
    }
  }
</script>

<style scoped>

</style>