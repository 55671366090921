export default {
    get_items(state) {
        return state.items;
    },
    get_item: (state) => (key_value) => {
        let key = key_value.key
        let value = key_value.value
        let keys = state.items.map((i)=>{return i[key]});
        let i = keys.indexOf(value)
        if(i===-1) {
            return false
        }
        return state.items[i];
    },
    get_item_by_id: (state, getters) => (id) => {
        return getters['get_item']({'key': 'id', 'value': id});
    },
}