<template>
    <row v-if="name">
        <col3>
          {{name}}
          <info-icon v-if="showInfoIcon" :text="infoIconText"></info-icon>
        </col3>
      <col3 v-if="yesno">
        <v-switch
            style="margin-top: 0px; margin-left: 5px; padding-top: 0px"
            inset
            :value="true"
            @change="toggleSwitch($event)"
            :input-value="value==='Yes'"
            :label="''"
            color="primary"
            :disabled="disabled"
        ></v-switch>
      </col3>
      <col3 v-else-if="str_options">
          <autocomplete
                  :rounded="false"
                  @select="select"
                  :value="selected_option"
                  :options="str_options"
                  :disabled="disabled"></autocomplete>
      </col3>
      <col3 v-if="input">
        <v-text-field 
              @input="select" 
              :value="value" 
              dense outlined
              :disabled="disabled"
              ></v-text-field>
      </col3>

    </row>
</template>

<script>
  import Row from "@/components/layout/Row";
  import Col3 from "@/components/layout/Col3";
  import Autocomplete from "@/components/Autocomplete";
  import InfoIcon from '@/components/notifications/InfoIcon.vue';
  
  export default {
    name: "Parameter",
    components: {Autocomplete, Col3, Row, InfoIcon},
    props: {
      'name': {required: true},
      'value': {default: null},
      'input': {default: null},
      'default_value': {required: true},
      'options': {required: true},
      'disabled': {type: Boolean, default:false},
    },
    methods: {
      toggleSwitch(value) {
        if (value) {
          this.select('Yes')
        } else {
          this.select('No')
        }
      },
      select(value) {
        this.$emit('select', value)
      },
      convert_parameter_name(name) {
        return name.toLowerCase().replace('_', ' ');
      }
    },
    computed: {
      showInfoIcon() {
        const parameterName = this.convert_parameter_name(this.name);
        const infoIconNames = ['epochs', 'weights', 'image size'];
        return infoIconNames.includes(parameterName);
      },
      infoIconText() {
          const parameterName = this.convert_parameter_name(this.name);
          switch (parameterName) {
              case 'epochs':
                  return this.$t('models_and_train_configs.epochs');
              case 'weights':
                  return this.$t('models_and_train_configs.weights');
              case 'image size':
                  return this.$t('models_and_train_configs.image_size');
              default:
                  return '';
          }
      },
      str_options() {
          if(!this.options) {
              return this.options;
          }
          return this.options.map(option=>{return option.toString()})
      },
      yesno () {
        if(!this.options) {
            return false;
        }
        return this.options.length === 2 && this.options[0] === 'Yes' && this.options[1] === 'No';
      },
      selected_option () {
        if(this.value) {
          return this.value;
        }
        return this.default_value
      }
    }
  }
</script>

<style scoped>

</style>