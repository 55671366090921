<template>
  <v-chip>
    <v-avatar left>
      <v-icon >fas fa-spinner fa-spin</v-icon>
    </v-avatar>
    {{ state }}
    <v-avatar v-if="state === 'In queue'" right @click="handleDeleteClick" style="cursor: pointer">
      <v-icon size="large">far fa-trash-alt</v-icon>
    </v-avatar>
  </v-chip>
</template>

<script>
export default {
  name: "JobStateChip",
  props: {
    job: {required: true}
  },
  computed: {
    state () {
      let meta = JSON.parse(this.job.description);
      if (meta.state) {
        return meta.state
      }
      return 'In queue'
    }
  },
  methods: {
    async handleDeleteClick() {
      await this.$api.job.delete(this.job);
    }
  }
}
</script>

<style scoped>

</style>