<template>
  <div>
    <v-card >
      <v-card-title>
        <v-text-field v-if="items.length > 10"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected_items"
        sort-by="name"
        :show-select="show_select"
        :items-per-page="items_per_page"
        :search="search"
        :items="items"
        :headers="table_headers">
        <template v-slot:top>
          <v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
              <v-card-title>Are you sure you want to delete this {{item_name}}?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close_dialog_delete">Cancel</v-btn>
                <v-btn color="primary" text @click="delete_item_for_real">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template v-for="property in template_slots" v-slot:[`item.${property}`]="{ item }">
          <slot :name="property" v-bind:item="item"></slot>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="show_edit_action" icon @click.native="edit_item(item)" color="primary" x-small>
            <v-icon>fas fa-pencil-alt</v-icon>
          </v-btn>
          <v-btn v-if="show_delete_action" icon @click.native="delete_item(item)" color="primary" x-small>
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    </div>
</template>

<script>

export default {
  name: "DataTable",
  data() {
    return {
      'selected_items': [],
      'search': '',
      'dialog': false,
      'editedIndex': 0,
      'editedItem': false,
      'dialog_delete': false,
    }
  },
  watch: {
    selected_items(n) {
      this.$emit('select_item_id', n.map(i=>{return i.id}))
    }
  },
  props: {
    template_slots: {default: () => []},
    selected_item_ids: {default: () => []},
    items_per_page: {default: 50},
    show_select: {default: false},
    items: {required: true},
    headers: {required: true},
    show_header: {default: true},
    parent: {default: false},
    item_name: {default: 'item'},
    show_delete_action: {default: true},
    show_edit_action: {default: true},
    single_expand: {default: false}
  },

  computed: {
    item_ids() {
      return this.items.map(l=>{return l.id})
    },
    table_headers() {
      let headers = this.headers
      if(this.show_delete_action || this.show_edit_action) {
        headers.push({text: 'Actions', value: 'actions', sortable: false})
      }
      return headers
    },
  },
  methods: {
    delete_item(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.$set(this, 'dialog_delete', true)
    },
    async delete_item_for_real() {
      await this.$api.model.delete(this.editedItem.id);
      this.$emit('delete_item', this.editedItem.id);
      this.close_dialog_delete();
    },
    close_dialog_delete() {
      this.$set(this, 'dialog_delete', false)
    },
    close_modal() {
      this.dialog = false
    },
    edit_item (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
    },
    update_item(item) {
      this.$emit('update_item', item)
    },
    refresh () {
      this.$emit('refresh')
    },
  },
  mounted() {
    this.$set(this, 'selected_items',
            this.items.filter(item=>{return this.selected_item_ids.includes(item.id);})
    )
  }
}
</script>

<style scoped>

</style>