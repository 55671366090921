<template>
  <settings-menu-layout>
    <user-list></user-list>
  </settings-menu-layout>
</template>

<script>
  import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import UserList from "@/components/UserList";
  import LoadLabelsMixin from "@/mixins/LoadLabelsMixin.vue";

  export default {
    name: "SettingsUsers",
    mixins: [LoadSettingsMixin, LoadLabelsMixin],
    data() {
      return {
      }
    },
    methods: {
      set_loading(loading) {
        this.$set(this, 'loading', loading)
      },
      select_item(page) {
        this.$set(this, 'selected_page', page)
      },
    },
    components: {
      UserList,
      SettingsMenuLayout},
    mounted() {
    },
  }
</script>

<style scoped>

</style>