import Vue from 'vue'
import Vuex from 'vuex'

// Modules import
import LabelModule from '@/store/modules/label'
import JobModule from '@/store/modules/job'
import TaskModule from '@/store/modules/task'
import settingModule from '@/store/modules/setting'
import ModelModule from '@/store/modules/model'
import UserModule from '@/store/modules/user'
import RoleModule from '@/store/modules/role'
import ProjectModule from '@/store/modules/project'
import TrainConfigModule from '@/store/modules/train-config'
import AnnotationGroupModule from '@/store/modules/annotation-group'
import AvailableModelModule from "@/store/modules/available-model";
import AvailableModelParameterModule from "@/store/modules/available-model-parameter";
import Snackbars from "@/store/modules/snackbars";

Vue.use(Vuex)

const state = {}
const actions = ({})
const mutations = ({})
const getters = ({})

const modules = {
  label: LabelModule,
  available_model: AvailableModelModule,
  available_model_parameter: AvailableModelParameterModule,
  job: JobModule,
  task: TaskModule,
  setting: settingModule,
  model: ModelModule,
  user: UserModule,
  role: RoleModule,
  project: ProjectModule,
  train_config: TrainConfigModule,
  annotation_group: AnnotationGroupModule,
  snackbars: Snackbars
}

export default new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules
})