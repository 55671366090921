<template>
  <settings-menu-layout>
    <instance-setting></instance-setting>
  </settings-menu-layout>
</template>

<script>
  import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import InstanceSetting from "@/pages/InstanceSetting";

  export default {
    name: "SettingsInstances",
    mixins: [LoadSettingsMixin],
    data() {
      return {
      }
    },
    methods: {
      set_loading(loading) {
        this.$set(this, 'loading', loading)
      },
      select_item(page) {
        this.$set(this, 'selected_page', page)
      },
    },
    components: {
      InstanceSetting,
      SettingsMenuLayout},
    mounted() {
    },
  }
</script>

<style scoped>

</style>