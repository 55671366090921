<template>
    <menu-layout :loading="loading">
        <row v-if="selected_task">
            <col8>
                <autocomplete :value="selected_task" @select="select_task" :options="tasks"></autocomplete>
            </col8>
            <col4 v-if="progress">
                {{progress}} left
            </col4>
        </row>
        <annotate :filters="filters" @update_progress="update_progress" v-if="initiated"></annotate>
    </menu-layout>
</template>

<script>
  import MenuLayout from "@/components/layout/MenuLayout";
  import Annotate from "@/pages/Annotate";
  import Row from "@/components/layout/Row";
  import Col8 from "@/components/layout/Col8";
  import Col4 from "@/components/layout/Col4";
  import Autocomplete from "@/components/Autocomplete";
  export default {
    data () {
      return {
        'loading': false,
        'filters': false,
        'initiated': false,
        'progress': false,
        'selected_task': false,
        'tasks': []
      }
    },
    mounted() {
      this.initiate()
    },
    methods: {
      async initiate() {
        let tasks = await this.$api.user_task.all_for_user()
        this.$set(this, 'tasks', tasks)
        if(this.tasks && this.tasks.length>0) {
          this.$set(this, 'filters', this.tasks[0].filter)
          this.$set(this, 'selected_task', this.tasks[0])
        }
        this.$set(this, 'initiated', true)
      },
      select_task (task) {
        if(task) {
          this.$set(this, 'filters', task.filter)
          this.$set(this, 'selected_task', task)
        }
      },
      update_progress (progress) {
        this.$set(this, 'progress', progress)
      }
    },
    mixins: [],
    name: "Tasks",
    components: {Autocomplete, Col4, Col8, Row, Annotate, MenuLayout}
  }
</script>

<style scoped>

</style>