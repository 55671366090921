<template>
  <!--          <model @delete_model="delete_model(model.id)" v-for="model in models" :model="model"></model>-->
    <v-card >
      <v-card-title>
<!--        <edit-label-dialog @update_label="update_label":labels="labels" :item="editedItem" @close_modal="close_modal" v-if="dialog"></edit-label-dialog>-->
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('modellist.search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <loader :loading="loading"></loader>
      <run-modal-modal v-if="show_run_modal_modal" :model="model_in_modal" @close_modal="close_modal"></run-modal-modal>
      <delete-button @pressed="delete_selected_items" v-if="selected_model_ids.length > 0"></delete-button>
      <v-data-table
        sort-by="created_at"
        :items-per-page="5"
        :search="search"
        :items="filter_models"
        @item-selected="item_selection_toggled"
        @toggle-select-all="toggle_select_all"
        show-select
        :headers="table_headers">
        <template v-slot:item.active="{ item }">
          <model-active :disabled="false" @toggle_model_active="toggle_model_active" :model="item"></model-active>
        </template>
        <template v-slot:item.meta="{ item }">
          <model-meta :model="item"></model-meta>
        </template>
        <template v-slot:item.exports="{ item }">
          <div style="max-width: 200px;">
            <model-exports :model="item"></model-exports>
          </div>
        </template>
         <template v-slot:item.performance="{ item }">
           <model-performance-chip :threshold="threshold" :model="item"></model-performance-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <delete-button @pressed="delete_item(item)"></delete-button>
          <v-btn icon @click.native="show_run_model_modal(item)" color="primary" x-small>
            <v-icon>far fa-circle-play</v-icon>
          </v-btn>
        </template>
        <template v-slot:top>
          <v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
              <v-card-title v-if="dialog_items_delete.length === 1">Are you sure you want to delete {{dialog_items_delete[0].name}}?</v-card-title>
              <v-card-title v-if="dialog_items_delete.length > 1">Are you sure you want to delete these models?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close_dialog">Cancel</v-btn>
                <v-btn color="primary" text @click="delete_item_for_real">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import ModelMeta from "@/components/models/ModelMeta";
import ModelExports from "@/components/models/ModelExports";
import ModelActive from "@/components/models/ModelActive";
import dayjs from 'dayjs';
import RunModalModal from "@/components/run_model_modal/RunModelModal";
import ModelPerformanceChip from "@/components/models/ModelPerformanceChip";
import DeleteButton from "@/components/form/DeleteButton.vue";
import Loader from "@/components/layout/loader.vue";

export default {
  name: "ModelList",
  components: { Loader, DeleteButton, ModelPerformanceChip, RunModalModal, ModelActive, ModelMeta, ModelExports },
  props: ['models', 'threshold'],
  data () {
    return {
      'selected_model_ids': [],
      'search': '',
      'dialog_delete': false,
      'dialog_items_delete': [],
      'item_name': 'model',
      'model_in_modal': false,
      'show_run_modal_modal': false,
      'loading': false,
    }
  },
  methods: {
    item_selection_toggled(param) {
      let item = param.item;
      let value = param.value;
      if(value) {
        this.select_model(item)
      }
      else {
        this.unselect_model(item);
      }
    },
    select_model(model) {
      let index = this.selected_model_ids.indexOf(model.id);
      if(index === -1) {
        this.selected_model_ids.push(model.id);
      }
    },
    unselect_model(model) {
      let index = this.selected_model_ids.indexOf(model.id);
      if(index!==-1) {
        this.selected_model_ids.splice(index, 1);
      }
    },
    toggle_select_all(param) {
      let items = param.items;
      let value = param.value;

      if(value) {
        items.forEach(item=>{this.select_model(item)});
      }
      else {
        items.forEach(item=>{this.unselect_model(item)});
      }
    },
    close_modal() {
      this.$set(this, 'show_run_modal_modal', false);
      this.$set(this, 'model_in_modal', false)
    },
    delete_item(item) {
      this.$set(this, 'dialog_items_delete', [item]);
      this.$set(this, 'dialog_delete', true)
    },
    delete_selected_items() {
      this.$set(this, 'dialog_items_delete', this.models.filter(model=>{
        return this.selected_model_ids.indexOf(model.id) !== -1;
      }));
      this.$set(this, 'dialog_delete', true)
    },
    show_run_model_modal(item) {
      let model_id_index = this.models.indexOf(item);
      this.$set(this, 'show_run_modal_modal', true);
      this.$set(this, 'model_in_modal', this.models[model_id_index])
    },
    async delete_item_for_real() {
      this.close_dialog()
      this.$set(this, 'loading', true);
      for(let i=0; i<this.dialog_items_delete.length; i++) {
        await this.$store.dispatch('model/delete', this.dialog_items_delete[i]);
      }
      this.$set(this, 'loading', false);
    },
    async toggle_model_active(model) {
      if(model.active) {
        await this.$api.model.deactivate(model.id);
        model.active = false;
      }
      else {
        await this.$api.model.activate(model.id);
        model.active = true;
      }
    },
    close_dialog() {
      this.$set(this, 'dialog_delete', false)
    }
  },
  watch: {
    dialog_delete (val) {
        val || this.close_dialog()
    },
  },
  computed: {
    filter_models() {
      let this2 = this;
      return this.models.filter(model=>{
        return this2.all_models_ids.indexOf(model.id) !== -1
      })
    },
    all_models_ids() {
      return this.$store.getters['model/get_items'].map(model=>{return model.id});
    },
    table_headers() {
      return [
          { text: this.$t('modellist.name'), value: 'name' },
          { text: this.$t('modellist.active'), value: 'active' },
          { text: this.$t('modellist.meta'), value: 'meta' },
          { text: this.$t('modellist.performance'), value: 'performance' },
          { text: this.$t('modellist.created_at'), value: 'created_at', sort: (a,b) => {return dayjs(a).unix() < dayjs(b).unix()} },
          { text: this.$t('modellist.actions'), value: 'actions', sortable: false },
          { text: this.$t('modellist.download'), value: 'exports', sortable: false },
        ]
    },
  },
}
</script>

<style scoped>

</style>