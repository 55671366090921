<template>
    <row>
        <col3>
            <gravatar :selected_properties="user.avatar_properties"></gravatar>
        </col3>
        <col9>
            {{user.first_name}} {{user.last_name}} <br>
            {{mutation.mutated_at}}
            
        </col9>
    </row>
</template>

<script>
  import Row from "@/components/layout/Row";
  import Col3 from "@/components/layout/Col3";
  import Col9 from "@/components/layout/Col9";
  import Gravatar from "@/components/users/Gravatar";
  import LoadUsersMixin from "@/mixins/LoadUsersMixin";
  export default {
    components: {Gravatar, Col9, Col3, Row},
    mixins: [LoadUsersMixin],
    props: {
      'mutation': {required: true}
    },
    computed: {
      user () {
        return this.$store.getters['user/get_item_by_id'](this.mutation.user_id);
      }
    },
    name: "Mutation"
  }
</script>

<style scoped>

</style>