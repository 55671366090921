<template>
    <my-profile-menu-layout>
      <choose-avatar></choose-avatar>
      <change-password @loading-change="updateLoading"></change-password>
      <notification-settings></notification-settings>
      <authentication-settings></authentication-settings>
      <!-- <seg-any-settings></seg-any-settings> -->
      <my-profile-user-limits></my-profile-user-limits>
      <upgrade-subscription v-if="payment_enabled"></upgrade-subscription>
      <payment-overview></payment-overview>
    </my-profile-menu-layout>
</template>

<script>

  import MyProfileMenuLayout from "@/components/layout/MyProfileMenuLayout";
  import CurrentUserMixin from "@/mixins/CurrentUserMixin";
  import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";

  import ChooseAvatar from "@/components/myprofile/ChooseAvatar";
  import ChangePassword from '@/components/myprofile/ChangePassword.vue';
  import NotificationSettings from '@/components/myprofile/NotificationSettings.vue';
  import AuthenticationSettings from '@/components/myprofile/AuthenticationSettings.vue';
  // import SegAnySettings from '@/components/myprofile/SegAnySettings.vue';
  import MyProfileUserLimits from "@/components/myprofile/MyProfileUserLimits";
  import UpgradeSubscription from '@/components/myprofile/UpgradeSubscription.vue';
  import PaymentOverview from '@/components/myprofile/PaymentOverview.vue';

  export default {
    name: "MyProfile.vue",
    components: {ChangePassword, NotificationSettings, AuthenticationSettings, //SegAnySettings,
    MyProfileUserLimits, UpgradeSubscription, MyProfileMenuLayout, ChooseAvatar, PaymentOverview},
    mixins: [CurrentUserMixin, LoadProjectsMixin],
    computed: {
      payment_enabled() {
        return this.current_user && this.current_user.payment_enabled;
      }
    },
    data() {
      return {
        loading: false,
      }
    },
    mounted() {
      this.$store.commit('user/set_current_project_id', this.project_id)
    },
    methods: {
      updateLoading(value) {
        this.loading = value;
      },
    }
  }
</script>

<style scoped>

</style>