<template>
    <menu-layout
            :loading="loading"
    >
        <row>
            <col2>
                <page-menu
                        :name="$t('myprofile.title')"
                        :title="$t('myprofile.subtitle')"
                        :items="[
                          {'name': $t('myprofile.avatar') , 'route': '/profile/avatar'},
                          {'name': $t('myprofile.password'), 'route': '/profile/change-password'},
                          {'name': $t('myprofile.notification_settings'), 'route': '/profile/notifications'},
                          {'name': $t('myprofile.authentication_settings'), 'route': '/profile/authentication'},
                          {'name': $t('myprofile.seg_any_settings'), 'route': '/profile/seg-any'},
                          {'name': $t('overview.limits'), 'route': '/profile/user-limits'},
                          {'name': $t('myprofile.upgrade_plan'), 'route': '/profile/upgrade-subscription'},
                          {'name': $t('myprofile.payment_overview'), 'route': '/profile/payment-overview'},
                        ]"
                        @navigate="navigateToBaseMenu"
                ></page-menu>
            </col2>
            <col12 style="max-width: 1000px;" >
                <slot></slot>
            </col12>
        </row>
    </menu-layout>
</template>

<script>
import Row from "@/components/layout/Row";
import Col2 from "@/components/layout/Col2";
import PageMenu from "@/components/layout/PageMenu";
import Col12 from "@/components/layout/Col12";
import MenuLayout from "@/components/layout/MenuLayout";

export default {
  name: "MyProfileMenuLayout",
  components: {MenuLayout, Col12, PageMenu, Col2, Row},
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    navigateToBaseMenu() {
      if (this.$route.path !== '/myprofile') {
        this.$router.push('/myprofile');
      }
    },
  }
}
</script>

<style scoped>

</style>