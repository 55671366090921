<template>
    <div>
        <v-row dense>
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                        <model-in-table v-for="model in selected_models" :key="model.id"
                                        @unselect_model="unselect_model(model)"
                                        :model="model"
                                        @update_job="update_or_add_job"
                                        :job="get_related_job(model)"
                        ></model-in-table>
                        <tr>
                            <td v-if="!select_model_toggled && selected_models.length < 2">
                                <v-chip color="primary" @click="select_model_toggled = !select_model_toggled">
                                    <v-icon left>
                                        fa-solid fa-circle-plus
                                    </v-icon>{{$t('most_c.add_model')}}
                                </v-chip>
                            </td>
                            <td v-else-if="select_model_toggled && selected_models.length < 2">
                                <autocomplete
                                        v-if="select_model_toggled && selected_models.length < 2"
                                        :options="models"
                                        :value="selected_model"
                                        :name="$t('most_c.label')"
                                        style="float:left;"
                                        @select="select_model"
                                ></autocomplete>
                            </td>
                            <td v-else></td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
  import Autocomplete from "@/components/Autocomplete";
  import LoadModelsMixin from "@/mixins/LoadModelsMixin";
  import ModelInTable from "@/components/confusion_matrix/ModelInTable";
  export default {
    mixins: [LoadModelsMixin],
    name: "ModelLoader",
    components: {ModelInTable, Autocomplete},
    data () {
      return {
        'selected_model': false,
        'selected_models': [],
        'model_results': [],
        'jobs': [],
        'select_model_toggled': false,
      }
    },
    watch: {

    },
    computed: {
      active_jobs() {
        return this.jobs.filter(job=>{return !job.processed;});
      },
      selected_model_ids () {
        return this.selected_models.map(m => {
          return m.id
        });
      },
      model_results_ids () {
        return this.model_results.map(m => {
          return m.model_id
        });
      }
    },
    methods: {
      unselect_model (model) {
        let index = this.selected_model_ids.indexOf(model.id);
        this.selected_models.splice(index, 1);

        index = this.model_results_ids.indexOf(model.id);
        if(index !== -1) {
          this.model_results.splice(index, 1);
          this.$emit('model_results_updated', this.model_results)
        }
      },
      get_related_job(model) {
        let job_model_ids = this.jobs.map(job=>{return parseInt(job.description)});
        let index = job_model_ids.indexOf(model.id);
        if(index !== -1) {
          return this.jobs[index];
        }
      },
      update_or_add_job(job) {
        let job_ids = this.jobs.map(job=>{return job.id});
        let index = job_ids.indexOf(job.id);
        if(index === -1) {
          this.jobs.push(job);
        }
        else {
          this.$set(this.jobs, index, job)
        }
      },
      async load_model(model) {
        console.log('start_calc_c_m from load_model/model_loader.vue')
        let data = await this.$api.job.get_active_analyse_conf_matrix_job(model.id);
        if(data.length === 0) {
          let job = await this.$api.job.start_calculate_conf_matrix_job(model.id);
          job.model = model
          this.update_or_add_job(job)
        }
        else {
          let job = data[0]

          if(job.processed === 1) {
            let response = await this.$api.conf_matrix_item.all(model.id);
            this.add_conf_matrix_items(model, response)
          }
          else {
            job.model = model
          }
          this.update_or_add_job(job)
        }
      },
      select_model (model) {
        if(!model) {
          return;
        }

        this.$set(this, 'select_model_toggled', false)

        this.$set(this, 'selected_model', false)

        if(-1 === this.selected_model_ids.indexOf(model.id)) {
          this.selected_models.push(model);
        }

        this.load_model(model);
      },
      add_conf_matrix_items(model, response) {
        let result = {}
        result['model_id'] = model.id
        result['model'] = model

        let labels = Object.assign({}, ...response.labels.map((x) => ({[x.id]: x})));

        let result_per_label = {}
        for(let i=0; i<response.items.length; i++) {
          let item = response.items[i];
          let label = labels[item.label_id];
          result_per_label[label.name] = item
        }

        this.model_results.push(
          {
            'model_id': model.id,
            'model': model.name,
            'results': result_per_label
          }
        )

        this.$emit('model_results_updated', this.model_results)
      }
    }
  }
</script>

<style scoped>

</style>