<template>
  <modal @close_modal="close_modal" :title="$t('set_jobs.details')" :loading="loading"
  >
    <template v-slot:content>
      {{text}}
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  name: "TextInModal",
  data () {
    return {
      'loading': false,
    }
  },
  methods: {
    close_modal() {
      this.$emit('close_modal')
    }
  },
  computed: {
  },
  components: {Modal},
  props: {
    text: {required: true},
  }
}
</script>

<style scoped>

</style>