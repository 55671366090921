<template>
    <v-card>
      <v-card-title>
        <edit-label-dialog @update_label="update_label" :labels="labels" :item="editedItem" @close_modal="close_modal" v-if="dialog"></edit-label-dialog>
      </v-card-title>
      <v-data-table
        v-model="selected_labels"
        sort-by="name"
        :show-select="show_select"
        :items-per-page="50"
        :search="search"
        :items="filtered_labels"
        :headers="table_headers">
        <template v-slot:body.prepend>
          <tr>
            <td>
            </td>
            <td>
            </td>
            <td>
              <v-text-field append-icon="mdi-magnify" single-line v-model="search" type="text" :label="$t('set_labels.list_view_search')"></v-text-field>
            </td>
            <td>
            </td>
            <td>
              <v-text-field append-icon="mdi-magnify" single-line v-model="greater_than" type="text" :label="$t('set_labels.list_view_greater')"></v-text-field>
            </td>
            <td v-if="menu_items_on_select.length > 0" style="text-align: right;">
              <dropdown-menu v-if="selected_labels.length > 0" :menu_items="menu_items_on_select"
                  ></dropdown-menu>
            </td>
          </tr>
        </template>
        <template v-slot:item.actions="{ item }">

          <div :style="{'backgroundColor': item.rgb_color}" style="height: 18px; width: 18px; float: left; margin-right: 5px; margin-top: 3px;"></div>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="preview(item.name)"
          >
            fa-table-cells-large
          </v-icon>
          <v-icon
              v-if="item.count === 0"
              small
              class="mr-2"
              @click="delete_label(item)"
          >
            far fa-trash-alt
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import EditLabelDialog from "@/components/manage_labels/EditLabelDialog";
import EditLabelMixin from "@/components/manage_labels/EditLabelMixin";
import DropdownMenu from "@/components/layout/DropdownMenu";

export default {
  name: "ListView",
  data() {
    return {
      'search': '',
      'greater_than': '',
      'selected_labels': [],
    }
  },
  watch: {
    selected_labels(n) {
      this.$emit('select_label_ids', n.map(i=>{return i.id}))
    },
    selected_labels_ids(newValue, oldValue) {
      let sortedOldValue = oldValue.slice().sort();
      let sortedNewValue = newValue.slice().sort();
      if (JSON.stringify(sortedOldValue) !== JSON.stringify(sortedNewValue)) {
        this.$set(this, 'selected_labels',
          this.labels.filter(label=>{return this.selected_labels_ids.includes(label.id);})
      )}
    },
  },
  props: {
    selected_labels_ids: {default: () => []},
    show_select: {default: false},
    labels: {required: true},
    show_header: {default: true},
    parent: {default: false},
    menu_items_on_select: {default: () => []},
  },
  components: {EditLabelDialog, DropdownMenu},

  computed: {
    label_ids() {
      return this.labels.map(l=>{return l.id})
    },
    extended_labels() {
      let this2 = this;
      return this.labels.map(l=>{

        l.rgb_color = 'rgb(' + l.color + ')'

        if(l.relationships && l.relationships.annotation_stats && l.relationships.annotation_stats.length > 0) {
          let processed = l.relationships.annotation_stats.map(stats=>{return stats.processed_count})
          let sum = processed.reduce(this2.add, 0);
          l.stats = sum
          l.count = sum
        }
        else {
          l.stats = 'N'
          l.count = 0
        }

        let i = this2.label_ids.indexOf(l.parent_id)
        if(i<0) {
          l.parent = ''
        }
        else {
          l.parent = this2.labels[i].name
        }
        return l
      })
    },
    table_headers() {
      return [
          { text: this.$t('set_labels.list_view_id'), value: 'id' },
          { text: this.$t('set_labels.list_view_name'), value: 'name' },
          { text: this.$t('set_labels.list_view_parent'), value: 'parent' },
          { text: this.$t('set_labels.list_view_stats'), value: 'stats' },
          { text: this.$t('set_labels.list_view_actions'), value: 'actions', sortable: false },
        ]
    },
    filtered_labels() {
      if(this.greater_than) {
        return this.extended_labels.filter(label=>{return label.count>this.greater_than});
      }
      else {
        return this.extended_labels;
      }
    },
  },
  methods: {
    async delete_label(label) {
      this.$set(this, 'loading', true)
      await this.$store.dispatch('label/delete', label)

      await this.$store.dispatch('snackbars/add_success_snack', {
          'message': this.$t('snacks.label_deleted'),
        })
      this.$set(this, 'loading', false)
    },
    preview(label_name) {
      this.$emit('preview_label', label_name)
    },
    add(a, b) {
      return a + b
    },
  },
  mounted() {
    this.$set(this, 'selected_labels',
        this.labels.filter(label=>{return this.selected_labels_ids.includes(label.id);})
    )
  },
  mixins: [EditLabelMixin]
}
</script>

<style scoped>

</style>