<template>
  <div>
    <row>
        <col12>
          <v-card
                    elevation="2"
            >
                <v-card-text>
                  <div class="icon-title">
                    <v-icon>mdi-lock</v-icon>
                    <b>{{$t('myprofile.password')}}</b>
                  </div>
                  <form>
                      <v-text-field
                              v-model="password"
                              type="password"
                              :label="$t('myprofile.current_password')"
                              required
                      ></v-text-field>
                      <v-text-field
                              type="password"
                              v-model="new_password"
                              :label="$t('myprofile.new_password')"
                              required
                      ></v-text-field>
                      <v-btn
                              color="primary"
                              class="mr-4"
                              @click="change_password"
                      >
                          {{$t('myprofile.change')}}
                      </v-btn>
                  </form>
                </v-card-text>
            </v-card>
        </col12>
    </row>
  </div>
    
</template>

<script>

  import Row from "@/components/layout/Row";
  import Col12 from "@/components/layout/Col12";

  export default {
    name: "ChangePassword.vue",
    components: {Row, Col12},
    mixins: [],
    data() {
      return {
        password: '',
        new_password: '',
        loading: false,
      }
    },
    mounted() {
    },
    methods: {
      async change_password() {
        this.$emit("loading-change", true)
        await this.$api.user.change_password(this.password, this.new_password);
        this.$emit("loading-change", false)
      },      
    }
  }
</script>

<style scoped>

</style>