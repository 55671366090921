<template>
  <div>
    <checkbox :editable="editable" @input="toggled" :value="model.active"></checkbox>
  </div>

</template>

<script>
import Checkbox from "@/components/Checkbox";
export default {
name: "ModelActive",
  props: {
    model: {required: true},
    disabled: {required: true}
  },
  methods: {
    toggled() {
      this.$emit('toggle_model_active', this.model)
    }
  },
  computed: {
    'editable' () {
      return !this.disabled
    }
  },
  components: {Checkbox}
}
</script>

<style scoped>

</style>