export default class UserSetting {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        return this.api.post_with_token('/user/settings', formData);
    }

    async add_user_setting_for_project(key, value) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('key', key)
        formData.append('value', value)

        return this.api.post_with_token('/user/setting/add', formData);
    }

    async update_user_setting(key, value) {
        let formData = new FormData();
        formData.append('key', key)
        formData.append('value', value)

        return this.api.post_with_token('/user/setting/update', formData);
    }

    async get_user_setting_and_convert_to_bool(key) {
        let formData = new FormData();
        formData.append('key', key)

        return this.api.post_with_token('/user/setting/get_bool', formData);
    }
}