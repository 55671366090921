<template>
  <div>
    <row>
      <col12>
        <user-limits
          headerType="myProfile">
        </user-limits>
      </col12>
    </row>
  </div>
</template>

<script>

import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import UserLimits from "@/components/myprofile/UserLimits";


export default {
  name: 'MyProfileUserLimits',
  components: {Row, Col12, UserLimits},
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style scoped>


</style>
