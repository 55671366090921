import base_mutations from '@/store/modules/base/mutations.js'
import Vue from 'vue'
export default {
    ...base_mutations,
    set_current_user (state, user) {
        state.current_user_id = user.id;
        let ids = state.items.map(user=>{return user.id});
        let index = ids.indexOf(user.id);

        if(index<0) {
            state.items.push(user);
        }
        else {
            state.items[index] = user;
        }
    },
    set_current_project_id (state, project_id) {
        sessionStorage.setItem('project_id', project_id);
        Vue.set(state, 'project_id', project_id);
    },
    set_current_user_avatar (state, properties) {
        let ids = state.items.map(user=>{return user.id});
        let index = ids.indexOf(state.current_user_id);
        Vue.set(state.items[index], 'avatar_properties', properties);
    },
    clear_users(state) {
        state.items = []
        state.current_user_id = false
    },
    set_subscription_id(state, { user_id, subscription_id }) {
        let user = state.items.find(user => user.id === user_id);
        if (user) {
            Vue.set(user, 'subscription_id', subscription_id);
        }
    }
}