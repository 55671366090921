<template>
  <modal @close_modal="close_modal" :loading="loading" :title="$t('set_labels.edit_selected_labels')">
     <template v-slot:title>
       {{$t('set_labels.modal_title')}} {{name}}
     </template>
    <template v-slot:buttons>
       <v-btn
          color="primary"
          text
          id="cancel_button"
          @click.native="close_modal"
      >
        {{$t('set_labels.modal_cancel')}}
      </v-btn>

      <v-btn
          color="primary"
          text
          id="save_button"
          @click.native="save"
      >
        {{$t('set_labels.modal_save')}}
        <v-icon
            dark
            primary
            right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
      </v-btn>
     </template>
     <template v-slot:content>
      <row>
        <col12 style="text-align:right">
          <h4>{{$t('set_label_sets.selected_labels')}}</h4>
          <v-chip v-for="label in selected_labels" :key="label.id" close @click:close="() => unselect_label(label.id)"
                  :dark="true" small
                  :style="{ 'font-weight': 'bold', 'background-color': `rgba(${label.color})` }">
            {{ label.name }}
          </v-chip>
        </col12>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_parent')}}</col6>
         <col6>
           <autocomplete
                   v-model="parent" :options="labels">
           </autocomplete>
         </col6>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_type')}} </col6>
         <col6>
           <autocomplete
           v-model="type"
          :options="type_values"
         ></autocomplete>
         </col6>
       </row>
       <row>
         <col6>{{$t('set_labels.modal_color')}}</col6>
         <col6>
           <v-color-picker
                   v-model="color"
                   dot-size="25"
                   hide-canvas
                   hide-inputs
                   hide-mode-switch
                   hide-sliders
                   show-swatches
                   swatches-max-height="200"
           ></v-color-picker>
         </col6>
       </row>
     </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import Col6 from "@/components/layout/Col6";
import Row from "@/components/layout/Row";
import Autocomplete from "@/components/Autocomplete";
import Label from "@/models/label";
import Col12 from "@/components/layout/Col12";

export default {
name: "EditMultiLabelDialog",
  components: {Autocomplete, Row, Col6, Modal, Col12},
  props: {
    'item': {default: false},
    'labels': {default: () => []},
    'selected_labels_ids': {default: () => []},
  },
  data () {
    return {
      type_values: [{id:1, name:'Box'}, {id:2, name:'Point'}],
      'name': '',
      'type': '',
      'color': '',
      'parent': '',
      loading: false,
    }
  },
  methods: {
    unselect_label(label_id) {
      let updated_selected_label_ids = this.selected_labels_ids.filter(id => id != label_id);
      this.$emit('select_label_ids', updated_selected_label_ids)
    },
    async save () {
        this.$set(this, 'loading', true);
        let color = null;

        try {
          if(this.color && this.color.hex) {
            color = this.hexToRGB(this.color.hex);
          }
          else if(this.color) {
            color = this.hexToRGB(this.color);
          }
        } catch (error) {
          console.error('Error saving color', this.color, error);
        }

        let parent_id = null;
        if(this.parent) {
          parent_id = this.parent.id
        }

        

        await this.$api.label.patch_labels(this.selected_labels_ids, parent_id, color, this.type.id);

        for(let labelId of this.selected_labels_ids) {
          const label = new Label(labelId, this.name, this.type.id, color, parent_id)
          await this.$store.commit('label/update_item', {
            item: label,
            key_values: {
              type_id: this.type.id,
              color: color,
              parent_id: parent_id
            }
          });
        }

        await this.$store.dispatch('snackbars/add_success_snack', {
          'message': this.$t('snacks.label_saved'),
        })

        this.close_modal()
      },
      close_modal() {
        this.$emit('close_modal')
      },
      hexToRGB(hex, alpha) {
        let r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        if (alpha) {
            return r + "," + g + "," + b + "," + alpha
        } else {
            return r + "," + g + "," + b
        }
      },
      RGBToHex(r,g,b) {
        r = parseInt(r).toString(16);
        g = parseInt(g).toString(16);
        b = parseInt(b).toString(16);

        if (r.length == 1)
          r = "0" + r;
        if (g.length == 1)
          g = "0" + g;
        if (b.length == 1)
          b = "0" + b;

        return "#" + r + g + b;
      },
  },
  computed: {
    selected_labels() {
      return this.labels.filter(label => this.selected_labels_ids.includes(label.id))
    },
  },
  mounted() {
    let type_id = 1;
    let type_ids = this.type_values.map((elem) => {return elem.id});
    this.$set(this, 'type', this.type_values[type_ids.indexOf(type_id)]);
  }
}
</script>

<style scoped>

</style>