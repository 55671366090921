
export default {
    add_info_snack: function (context, payload) {
        if (typeof payload === 'string') {
            payload = {message: payload}
        }

        payload.color = 'primary'
        payload.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('add_snack', payload)
    },
    add_failed_snack: function (context, payload) {
        if (typeof payload === 'string') {
            payload = {message: payload}
        }

        payload.color = '#E0483D'
        payload.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('add_snack', payload)
    },
    add_success_snack: function (context, payload) {
        if (typeof payload === 'string') {
            payload = {message: payload}
        }

        payload.color = '#1BB32D'
        payload.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('add_snack', payload)
    },
    add_sticky_success_snack: function (context, payload) {
        if (typeof payload === 'string') {
            payload = {message: payload}
        }

        payload.color = '#1BB32D'
        payload.timeout = 5000
        payload.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('add_snack', payload)
    },
    add_persistent_snack: function (context, payload) {
        if (typeof payload === 'string') {
            payload = {message: payload}
        }
    
        payload.color = '#E0483D'
        payload.persistent = true;
        payload.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        context.commit('add_snack', payload)
    },    
    get_and_clear_snacks: function (context) {
        let snacks = context.getters.get_snacks
        context.commit('clear_snacks')
        return snacks
    },
    remove: function (context, id) {
        context.commit('remove_snack', id)
    }
}