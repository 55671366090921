<template>
  <v-app>
      <menu-bar
      ></menu-bar>
      <v-navigation-drawer
          app
          permanent
          :mini-variant="mini"
          color="secondary"
          style="margin-top: 1px">
        <main-side-menu @toggle="mini = !mini"></main-side-menu>
      </v-navigation-drawer>
      <v-main style="padding-left: 65px; padding-top: 70px;">
            <v-snackbar-queue
                :items="snacks"
                @remove="() => {}"></v-snackbar-queue>
            <v-overlay id="v-page-loader" v-if="loading">
              <v-progress-circular
                  indeterminate
                  size="64"
              ></v-progress-circular>
            </v-overlay>
        <v-container fluid>
            <row v-if="title">
                <col12>
                    <v-card
                            elevation="2"
                    >
                        <v-card-title>
                            {{title}}
                        </v-card-title>
                        <v-card-subtitle v-if="subtitle">
                            {{subtitle}}
                        </v-card-subtitle>
                    </v-card>

                </col12>
            </row>
          <slot></slot>
        </v-container>
      </v-main>
    </v-app>
</template>

<script>
import MenuBar from "@/components/layout/MenuBar";
import MainSideMenu from "@/components/layout/MainSideMenu";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import VSnackbarQueue from "@/components/SnacksQueue.vue";
import SnackbarsMixin from "@/mixins/SnackbarsMixin.vue";

export default {
  name: "MenuLayout",
  components: {VSnackbarQueue, Col12, Row, MainSideMenu, MenuBar},
  mixins: [SnackbarsMixin],
  props: {
    'loading': {default: false},
    'title': {default: ''},
    'subtitle': {default: ''},
    'menu_items': {
      default: () => {
        return [
        {
          'name': 'Settings',
          'icon': 'fas fa-cog',
          'items': [
              {'name': 'Wallio'},
              {'name': 'Pieter pet'},
              {'name': 'Gekki'},
          ]
        }
      ]
      }
    },
  },
  data () {
    return {
      'mini': true,
      'snackbar': true,
    }
  },
  mounted() {
    document.title = 'BOX21'
  },
  methods: {
    select_item(item) {
      this.$emit('select_item', item);
    }
  }
}
</script>

<style scoped>

</style>