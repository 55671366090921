<template>
  <div>
    <row v-if="label_only">
      <col11>
        {{ label }}:
      </col11>
      <col1 v-if="label_only">
        <v-btn icon @click.native="clear" color="primary" x-small>
          <v-icon>far fa-trash-alt</v-icon>
        </v-btn>
      </col1>
    </row>
    <row v-else>
      <col10 v-if="toggleOption">
        <v-switch
            style="margin-top: 0px; margin-left: 5px; padding-top: 0px"
            inset
            :value="true"
            @change="toggleSwitch($event)"
            :input-value="value==='Yes'"
            :label="label"
            color="primary"
        ></v-switch>
      </col10>
      <col10 v-else-if="options && options.length > 0">
        <autocomplete
            :value="value"
            :placeholder="label"
            :options="options"
            @select="set_value"
        ></autocomplete>
<!--        <v-select style="margin-bottom: 15px;" :placeholder="label" :value="value" @input="set_value" :options="options"></v-select>-->
      </col10>
      <col10 v-else-if="is_date">
        <v-menu
          ref="dialog"
          v-model="modal"
          persistent
          lazy
          full-width
          offset-y
          width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="value"
              :label="label"
              prepend-icon=""
              readonly
              rounded
              outlined
              dense
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker @input="set_value_on_date" :value="value" scrollable>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="modal = false">Cancel</v-btn>
          </v-date-picker>
        </v-menu>
      </col10>
      <col10 v-else-if="input">
        <v-text-field
            rounded
            :value="value" :label="label" dense outlined @input="set_value_on_input"></v-text-field>
      </col10>
      <col1>
        <v-btn icon @click.native="clear" color="primary" x-small>
          <v-icon>fa-thin fa-x</v-icon>
        </v-btn>
      </col1>
    </row>
  </div>

</template>

<script>
import Row from "@/components/layout/Row";
import Col1 from "@/components/layout/Col1";
import Col11 from "@/components/layout/Col11";
import Col10 from "@/components/layout/Col10";
import Autocomplete from "@/components/Autocomplete";

export default {
  name: "FilterItem",
  components: {Autocomplete, Col10, Col11, Col1, Row},
  props: {
    label: {required: true},
    options: {default: false},
    input: {default: false},
    value: {default: ''},
    is_date: {default: ''}
  },
  data() {
    return {
      modal: false,
    }
  },
  methods: {
    toggleSwitch(value) {
      if (value) {
        this.set_value('Yes')
      } else {
        this.set_value('No')
      }
    },
    clear() {
      if(!this.toggleOption && this.input) {
        if(this.value!== '') {
          this.set_value('')
          return;
        }
      }
      this.$emit('clear')
    },
    set_value(value) {
      if (!value) {
        value = ''
      }
      this.$emit('set_value', value)
    },
    set_value_on_input(value) {
      this.$emit('set_value', value);
    },
    set_value_on_date(value) {
      this.$emit('set_value', value);
      this.$set(this, 'modal', false);
    },
  },
  computed: {
    label_only() {
      return this.options.length === 0 && !this.input && this.label
    },
    toggleOption() {
      return this.options[0] === 'Yes' && this.options[1] === 'No';
    }
  }
}
</script>

<style scoped>

</style>