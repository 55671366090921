import actions from '@/store/modules/role/actions.js'
import mutations from '@/store/modules/role/mutations.js'
import getters from '@/store/modules/role/getters.js'
import {base_state} from '@/store/modules/base/state'

const state = {
  ...base_state,
  'api_module': 'role'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}