<template>
  <div>
    <row>
      <col12>
        <v-card elevation="2">
          <v-card-text>
            <div class="icon-mailbox">
              <v-icon>mdi-bell</v-icon>
              <b> {{$t('myprofile.notification_settings')}}</b><br><br>
            </div>
            {{$t('myprofile.send_email')}}

            <div style="display: flex; flex-direction: column;">
              <v-checkbox
                v-model="setting_mail_label"
                :label="$t('myprofile.a_label_is_added')"
                @change="toggleAddLabelMailSetting"
                style="margin-bottom: 0px;"
              ></v-checkbox>
              <v-checkbox
                v-model="setting_model_trained"
                :label="$t('myprofile.a_model_is_trained')"
                @change="toggleModelTrainedMailSetting"
                style="margin-top: 0px;"
              ></v-checkbox>
            </div>
          </v-card-text>
        </v-card>
      </col12>
    </row>
  </div>
</template>

<script>
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

export default {
  name: "NotificationSettings.vue",
  components: { Row, Col12 },
  mixins: [CurrentUserMixin],
  data() {
    return {
      setting_mail_label: null,
      setting_model_trained: null,
    };
  },
  mounted() {
    this.set_setting();
  },
  methods: {
    async set_setting() {
      this.all_user_settings = await this.$api.user_setting.all();
      let mail_user_setting_list = this.all_user_settings.items.filter(
        (user_setting) => {
          return user_setting.key === "SettingMailLabel";
        }
      );
      if (mail_user_setting_list.length !== 0) {
        this.$set(
          this,
          "setting_mail_label",
          mail_user_setting_list[0].value === "true"
        );
      } else {
        this.$set(this, "setting_mail_label", false);
      }
      let model_user_setting_list = this.all_user_settings.items.filter(
        (user_setting) => {
          return user_setting.key === "SettingMailModelTrained";
        }
      );
      if (model_user_setting_list.length !== 0) {
        this.$set(
          this,
          "setting_model_trained",
          model_user_setting_list[0].value === "true"
        );
      } else {
        this.$set(this, "setting_model_trained", false);
      }
    },
    async toggleAddLabelMailSetting() {
      await this.$api.user_setting.update_user_setting(
        "SettingMailLabel",
        this.setting_mail_label
      );
    },
    async toggleModelTrainedMailSetting() {
      await this.$api.user_setting.update_user_setting(
        "SettingMailModelTrained",
        this.setting_model_trained
      );
    },
  },
};
</script>

<style scoped>

</style>
