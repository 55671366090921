<template>
  <div>
    <job-state-chip v-for="job in jobs" :key="job.id" :job="job">
    </job-state-chip>
  </div>
</template>

<script>
import JobStateChip from "@/components/models/JobStateChip";
export default {
  name: "ActiveModelStateChip",
  components: {JobStateChip},
  props: {
    jobs: {required: true}
  }
}
</script>

<style scoped>

</style>