<template>
  <modal @close_modal="close_modal"
         :title="$t('set_label_sets.edit_title')"
         :loading="loading"
  >
    <template v-slot:content>
      <v-text-field id="name"
                          v-model="label_set_name" :label="$t('set_label_sets.edit_name')"></v-text-field>
      <v-text-field id="description"
                          v-model="label_set_description" :label="$t('set_label_sets.edit_description')"></v-text-field>
      <label-list v-if="loading===false"
              @select_label_ids="select_label_ids"
              :selected_labels_ids="selected_label_ids"
              :show_select="true"></label-list>
    </template>
    <template v-slot:buttons>
      <v-btn
          color="primary"
          text
          id="cancel_button"
          @click.native="cancel"
      >
        {{$t('set_label_sets.btn_cancel')}}
      </v-btn>

      <v-btn
          color="primary"
          text
          id="save_button"
          @click.native="save"
      >
        {{$t('set_label_sets.btn_save')}}
        <v-icon
            dark
            primary
            right
        >
          fas fa-level-down-alt fa-rotate-90
        </v-icon>
      </v-btn>
    </template>

  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import LabelList from "@/components/manage_labels/LabelList";
import LoadLabelsMixin from "@/mixins/LoadLabelsMixin.vue";

export default {
  props: {
    label_set: {required: true},
  },
  data() {
    return {
      selected_label_ids: [],
      loading: true,
      label_set_name: null, 
      label_set_description: null 
    }
  },
  methods: {
    close_modal() {
      this.$emit('close_modal')
    },
    cancel () {
      this.$emit('close_modal')
    },
    select_label_ids(label_ids) {
      this.$set(this, 'selected_label_ids', label_ids);
    },
    async save() {
      this.$set(this, 'loading', true); 
      let local_label_set = null;   
      if (!this.label_set) {
        local_label_set = await this.$api.label_set.addLabelSet(this.label_set_name, this.label_set_description);
      }
      else {
        local_label_set = await this.$api.label_set.patchLabelSet(this.label_set, this.label_set_name, this.label_set_description);
      }
      await this.$api.label_set.updateLabels(local_label_set, this.selected_label_ids);
      this.$emit('reload')
      this.$emit('close_modal')
      this.$set(this, 'loading', false); 
    },
  },
  computed: {
  },
  mounted() {
  if (this.label_set) {
    this.$set(this, 'label_set_name', this.label_set.name);
    this.$set(this, 'label_set_description', this.label_set.description);
    if (!this.label_set.relationships || !this.label_set.relationships.label_set_labels) {
      this.$set(this, 'selected_label_ids', []);
    }
    else {
      let selected_label_ids = this.label_set.relationships.label_set_labels.map(label_set_labels => label_set_labels.label_id);
      this.$set(this, 'selected_label_ids', selected_label_ids);
    }
    this.$set(this, 'loading', false);
  }
  else {
    this.$set(this, 'selected_label_ids', []);
    this.$set(this, 'label_set_name', 'new');
    this.$set(this, 'label_set_description', 'new');
    this.$set(this, 'loading', false);
  }
},

  name: "EditLabelSet",
  components: { LabelList, Modal},
  mixins: [LoadLabelsMixin],
}
</script>

<style scoped>

</style>