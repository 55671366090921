<template>
     <menu-layout :loading="loading">
        <row>
          <col12>
            <v-card style="max-width: 1000px;">
              <v-card-title>
                Reset password
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                      outlined
                      prepend-icon="fas fa-at"
                      name="login"
                      placeholder="E-mail"
                      type="text"
                      id="email"
                      v-model="email"
                  ></v-text-field>
                  <v-text-field
                      v-if="password_reset_token"
                      outlined
                      prepend-icon="fas fa-lock"
                      placeholder="Password"
                      type="password"
                      v-model="password"
                  ></v-text-field>
                  <v-text-field
                      v-if="password_reset_token"
                      outlined
                      prepend-icon="fas fa-lock"
                      placeholder="Password confirmation"
                      type="password"
                      v-model="password_confirmation"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large color="primary" @click="resetPassword">Reset password</v-btn>
              </v-card-actions>
            </v-card>
          </col12>
        </row>
     </menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12.vue";
export default {
   name: 'Login',
  data () {
    return {
      loading: false,
      email: '',
      password: '',
      password_confirmation: ''
    }
  },
  mounted() {
    localStorage.token = false;
  },
  computed: {
    password_reset_token() {
      return this.$route.params.password_reset_token
    }
  },
  methods: {
    async initiateResetPassword() {
      let response = await this.$api.user.initiate_reset_password(this.email)
      if(response.error) {
        await this.$store.dispatch('snackbars/add_failed_snack', response.error)
      }
      else {
        await this.$store.dispatch('snackbars/add_sticky_success_snack', response.success)
      }
    },
    async resetPassword() {
      if(this.email === '') {
        await this.$store.dispatch('snackbars/add_failed_snack', 'Please enter your email')
        return
      }

      if(!this.password_reset_token) {
        return this.initiateResetPassword();
      }

      if(this.password === '' || this.password_confirmation === '') {
        await this.$store.dispatch('snackbars/add_failed_snack', 'Please enter a password')
        return
      }
      if(this.password !== this.password_confirmation) {
        await this.$store.dispatch('snackbars/add_failed_snack', 'Passwords do not match')
        return
      }

      let password_reset_token = this.$route.params.password_reset_token
      let response = await(this.$api.user.reset_password(this.email, this.password, this.password_confirmation, password_reset_token))
      if(response.error) {
        await this.$store.dispatch('snackbars/add_failed_snack', response.error)
      }
      else {
        await this.$store.dispatch('snackbars/add_success_snack', response.success)
        await this.$router.push('/login')
      }
    }
  },
  components: {Col12, Row, MenuLayout},
};
</script>

<style></style>