<script>

import { onUnauthenticatedPages } from '@/util/routesHelper.js'

export default {
name: "LoadProjectsMixin"
  ,
  data() {
    return {
      projects_loaded: false,
    }
  },
  mounted() {
    this.$set(this, 'projects_loaded', false)
    this.load_projects();
  },
  computed: {
    project_owner_subscription_id() {

      if(!this.projects) {
        return null;
      }
      
      let project_id = sessionStorage.getItem('project_id');
      project_id = parseInt(project_id);
      if (isNaN(project_id)) {
        return null;
      }
      let project = this.projects.find(project => project.id === project_id);
      if (!project) {
        return null; 
      }

      if (project.relationships && project.relationships.owner && project.relationships.owner.subscription_id) {
        let project_owner_subscription_id = project.relationships.owner.subscription_id;
        if (project_owner_subscription_id === undefined) {
          return null; 
        }
        return project_owner_subscription_id;
      } else {
        return null;
      }
    },
    projects () {
      return this.$store.getters['project/get_items']
    },
    on_unauthenticated_pages() {
      return onUnauthenticatedPages.call(this);
    },
  },
  watch: {
    projects(n,o) {
      if(n.length !== o.length && n.length > 0) {
        for (let i = 0; i < this.projects.length; i++) {
          this.set_asset_for_project(this.projects[i].id)
        }
      }
    }
  },
  methods: {
    async set_asset_for_project(project_id) {
      let favourite = this.$store.getters['project/get_favourite'](project_id)
      let users = this.$store.getters['project/get_users'](project_id)
      if(!favourite) {
        users = await this.$api.user.all_for_project(project_id);
        favourite = await this.$api.project.favourite_asset(project_id)
        this.$store.commit('project/set_favourite', {key: project_id, value: favourite})
        this.$store.commit('project/set_users', {key: project_id, value: users})
      }
    },
    async load_projects() {
      if(this.on_unauthenticated_pages) {
        return;
      }
      await this.$store.dispatch('project/all')
      for (let i = 0; i < this.projects.length; i++) {
        await this.set_asset_for_project(this.projects[i].id)
      }
      this.$set(this, 'projects_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>