import { render, staticRenderFns } from "./ModelButtons.vue?vue&type=template&id=10970d57&scoped=true"
import script from "./ModelButtons.vue?vue&type=script&lang=js"
export * from "./ModelButtons.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10970d57",
  null
  
)

export default component.exports