export default class Annotation {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async get_roles() {
        return this.api.get('/get-roles')
    }

    async filter(filters, limit) {
        let formData = new FormData();
        formData.append('filters', JSON.stringify(filters));
        formData.append('limit', limit);
        formData.append('project_id', sessionStorage.project_id);
        return this.api.post_with_token('/filter-boxes', formData)
    }

    async re_label(label, bbox_ids) {
        let formData = new FormData();
        formData.append('bbox_ids', bbox_ids);
        formData.append('label', label);
        return this.api.post_with_token('/re-label', formData)
    }
}