<template>
  <div>
    <jobs @refresh="loadJobs" :message="'Train job at'" :jobs="jobs"></jobs>
    <train-configurations
      @update_label="update_label"
      @start_train_job="start_train_job"
      :filter_model_type="model_type"
      :show_train_button="true"
      :triggered_config_ids="triggered_config_ids"
    ></train-configurations>
  </div>

</template>

<script>
import Jobs from "@/components/Jobs";
import TrainConfigurations from "@/pages/TrainConfigurations";
import dayjs from 'dayjs'

export default {
  name: "TrainModel",
  components: {TrainConfigurations, Jobs},
  props: {
    model_type: {required: true},
  },
  data() {
    return {
      'triggered_config_ids': [],
      'jobs': []
    }
  },
  methods: {
    async loadJobs() {
      let jobs = await this.$api.job.get_active_jobs('train', false, this.model_type)
      jobs = jobs.filter(job => {
          let created_at = dayjs(job.created_at)
          let duration = dayjs().diff(created_at, 'hour')
          return !job.processed || duration < 12;
      });
      this.$set(this, 'jobs', jobs);
    },
    update_label(label) {
      this.$emit('update_label', label)
    },
    async start_train_job(train_config_id) {
      this.triggered_config_ids.push(train_config_id)
      await this.$api.job.start_train_job(train_config_id)
      await this.loadJobs();
    }
  },
  mounted() {
    this.loadJobs();
  }
}
</script>

<style scoped>
</style>