<template>
  <div>
    <edit-train-configuration
        @reload="reload"
        :train_config="item" 
        @close_modal="close_modal"
        v-if="edit_mode"
        :is_new_train_conf="is_new_train_conf"
        >
    </edit-train-configuration>
    <v-text-field
          :label="$t('set_train_c.search')"
          prepend-inner-icon="mdi-magnify"
          v-model="filter" />
    <row12>
      <v-btn color="primary" @click.native="add">{{$t('set_train_c.button_add')}}</v-btn>
    </row12>
    <v-data-table
          :items="items"
          :headers="headers"
          :options.sync="options"
          :server-items-length="totalItems"
        >
        <template v-slot:item.name="{ item }" >
            <span @click="toggleEditMode(item)">{{ item.name }}</span>
            
          </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="delete_config(item)"
            >
              far fa-trash-alt
            </v-icon>
            <v-icon
                    small
                    class="mr-2"
                    @click="copy_config(item)"
            >
              far fa-copy
            </v-icon>
            <v-icon
            small
            class="mr-2"
            @click="toggleEditMode(item)"
          >
            mdi-pencil
          </v-icon>
          </template>
        </v-data-table>
  </div>
  
</template>

<script>
import Row12 from "@/components/layout/Row12";
import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
import EditTrainConfiguration from "@/components/EditTrainConfiguration";


export default {
  props: {
    'triggered_config_ids': {
      default: function () {
        return []
      }
    },
    'show_train_button': {default: false},
    'filter_model_type': {default: false}
  },
  mixins: [ApiDatatableMixin],
  data () {
    return {
      'api_model': 'train_config',
      edit_mode: false,
      item : "",
      is_new_train_conf : false
    }
  },
  methods: {
    close_modal() {
      this.$set(this, 'edit_mode', false)
      this.is_new_train_conf = false
    },
    async add() {
      this.$set(this, 'is_new_train_conf', true)
      let meta = JSON.stringify({label_ids: [], model_type: this.filter_model_type});
      let config = {name: 'New config', description: 'New config description', meta: meta};

      this.item = config
      this.$set(this, 'edit_mode', !this.edit_mode)

      // await this.$api.train_config.add(config)
      // await this.loadItems();
    },
    async reload() {
      await this.loadItems();
    },
    async delete_config(item) {
      this.$set(this, 'loading', true);
      await this.$api.train_config.delete_train_config_and_related_models(item);
      await this.loadItems();
      this.$set(this, 'loading', false);
    },
    async copy_config(item) {
      this.$set(this, 'loading', true);
      const copyConfig = { ...item, name: item.name + ' Copy' }; 
      await this.$api.train_config.add(copyConfig);
      await this.loadItems();
      this.$set(this, 'loading', false);
    },
    toggleEditMode (item) {
      this.item = item
      this.$set(this, 'edit_mode', !this.edit_mode)
    },
  },
  computed: {
      headers(){
        return [
            { text: this.$t('set_train_c.train_configuration'), value: 'name' },
            { text: this.$t('set_train_c.actions'), value: 'actions' },
        ]
      }
    },
  name: "TrainConfigurations",
  components: {Row12, EditTrainConfiguration},
}
</script>

<style scoped>

</style>