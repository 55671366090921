export default class Stat {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all(options, filter) {
        let itemsPerPage = options.itemsPerPage
        let page = options.page;
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        formData.append('page', page)
        formData.append('sortBy', options.sortBy);
        formData.append('sortDesc', options.sortDesc);
        formData.append('filter', filter);
        formData.append('itemsPerPage', itemsPerPage)
        return this.api.post_with_token('/stats/nonempty', formData)
    }

    async all_empty() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'))
        return this.api.post_with_token('/stats/empty', formData)
    }
}