<template>
  <v-chip 
    style="cursor: pointer; margin-left: 3px; margin-right: 3px; margin-bottom: 3px;" 
    :color="color"
    :disabled="disabled">
    {{name}}
  </v-chip>
</template>

<script>
export default {
  name: "ModelButton",
  props: {
    name: {required: true},
    selected: {required: true},
    disabled: { type: Boolean, default: false },
  },
  computed: {
    color () {
      if(this.selected) {
        return 'primary'
      }
      return 'default';
    }
  }
}
</script>

<style scoped>

</style>