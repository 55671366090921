<script>
export default {
name: "LoadTrainConfigsMixin"
  ,
  data() {
    return {
      train_configs_loaded: false
    }
  },
  mounted() {
    this.load_train_configs();
  },
  computed: {
    train_configs () {
      return this.$store.getters['train_config/get_items']
    },
  },
  methods: {
    async load_train_configs() {
      if(this.on_unauthenticated_pages || this.on_project_page) {
        return;
      }
      await this.$store.dispatch('train_config/all', this.filter_model_type)
      this.$set(this, 'train_configs_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>