export default class Label {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async all() {
        let formData = new FormData();
        let items = await this.api.post_with_token('/available-model-parameters', formData);
        if (items) {
            return items.map(item => {
                if (item.options && typeof this.options !== 'object') {
                    item.options = JSON.parse(item.options)
                }
                return item
            })
        }
        else {
            return items;
        }
    }
}