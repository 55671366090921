export default class TrainConfig {
    name
    description
    meta

    constructor(name, description, meta) {
        this.name = name;
        this.description = description;
        this.meta = meta
    }
}