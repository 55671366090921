export const onUnauthenticatedPages = function() {
  const unauthenticatedPages = [
    'Login',
    'ResetPassword',
    'InitiateResetPassword',
    'FrontPage',
    'ConfirmEmail'
  ];
  return unauthenticatedPages.includes(this.$router.currentRoute.name);
};

export const onProfilePages = function() {
  const profilePages = [
    'MyProfile',
    'ProfileChooseAvatar',
    'ProfileChangePassword',
    'ProfileNotifications',
    'ProfileAuthentication',
    'ProfileSegAny',
    'ProfileUserLimits',
    'ProfileUpgradeSubscription',
    'ProfilePaymentOverview',
    'Success',
    'Error'
  ];
  return profilePages.includes(this.$router.currentRoute.name);
};

export const onProjectsPage = function() {
  return this.$router.currentRoute.name === 'Projects';
};

export const onPasswordResetPage = function() {
  return this.$router.currentRoute.name === 'ResetPassword';
};

