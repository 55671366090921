import dayjs from "dayjs";

export default {
    async all(context, payload) {
        await context.dispatch('fetch_items', payload)
        return context.getters['get_items'];
    },
    async fetch_items(context, payload) {

        if(context.fetching) {
            return;
        }
        let now = dayjs();
        let project_id = sessionStorage.getItem('project_id')

        var seconds = 1;
        if(payload && payload.reload === false) {
            seconds = 60 * 60;
        }

        if (context.state.project_id !== project_id || (!context.state.updated_at || context.state.updated_at.isBefore(now.subtract(seconds, "second")))) {

            let items = await this._vm.$api[context.state.api_module].all(payload);

            if(!items) {
                return;
            }

            if (items.items) {
                items = items.items;
            }

            context.commit('set_items', items);
            context.state.updated_at = now;
            context.state.project_id = project_id;
            // context.state.refresh_timeout = setTimeout(() => {
            //     context.dispatch('fetch_items');
            //     }, 5000);
        }
    },
    async delete(context, item) {
        await this._vm.$api[context.state.api_module].delete(item);
        let items = context.state.items.filter(i=>{return i.id !== item.id});
        this._vm.$set(context.state, 'items', items)
    },
    async patch(context, { item, dispatch }) {
        let updated_item = await this._vm.$api[context.state.api_module].patch(item);
    
        let ids = context.state.items.map(item => item.id);
        let index = ids.indexOf(item.id);
    
        if (updated_item.id === item.id) {
            this._vm.$set(context.state.items, index, updated_item);
            dispatch('snackbars/add_success_snack', 'Updated successfully')
        } else {
            this._vm.$set(context.state.items, index, item);
            dispatch('snackbars/add_failed_snack', 'Failed to update')
        }
    },    
    async add({ state }, item) {
        const response = await this._vm.$api[state.api_module].add(item);
              
        if (response && response.id) {
          state.items.push(response);
          return response;
        } else if (response && response.limit_reached && response.limit_type) {
          return response;
        } else {
          this.$store.dispatch('snackbars/add_failed_snack', 'Failed to add');
        }
      }      
}