export default class TrainConfig {
    constructor(box21_api) {
        this.api = box21_api;
    }

    async patch(train_config) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('id', train_config.id);
        formData.append('name', train_config.name);
        formData.append('description', train_config.description);
        formData.append('meta', train_config.meta);
        formData.append('repeat_after_days', train_config.repeat_after_days);
        return this.api.post_with_token('/train-config/patch', formData)
    }

    async add(train_config) {
        let formData = new FormData();
        formData.append('name', train_config.name);
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('description', train_config.description);
        formData.append('meta', train_config.meta);
        return this.api.post_with_token('/train-config/add', formData)
    }

    async delete_train_config_and_related_models(train_config) {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        formData.append('train_config_id', train_config.id);
        return this.api.post_with_token('/train-config/delete-train-config-and-related-models', formData)
    }

    async all(options, filter, model_type) {

        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));

        if(options) {
            let itemsPerPage = options.itemsPerPage
            let page = options.page;
            formData.append('page', page)
            formData.append('sortBy', options.sortBy);
            formData.append('sortDesc', options.sortDesc);
            formData.append('filter', filter);
            formData.append('itemsPerPage', itemsPerPage)
        }
        if(typeof model_type !== 'undefined') {
            formData.append('filter_model_type', model_type);
        }
        
        return this.api.post_with_token('/train-configs', formData)
    }

    async available_models() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/train-config/available-models', formData)
    }

    async available_model_parameters() {
        let formData = new FormData();
        formData.append('project_id', sessionStorage.getItem('project_id'));
        return this.api.post_with_token('/train-config/available-model-parameters', formData)
    }

}