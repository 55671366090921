<template>
    <menu-layout
            :loading="loading"
    >
        <row>
            <col2>
                <page-menu
                        name="AI models"
                        title="Smartify your Box"
                ></page-menu>
            </col2>
            <col10 style="max-width: 800px;">
                <slot></slot>
            </col10>
        </row>
    </menu-layout>
</template>

<script>
import Row from "@/components/layout/Row";
import Col2 from "@/components/layout/Col2";
import PageMenu from "@/components/layout/PageMenu";
import Col10 from "@/components/layout/Col10";
import MenuLayout from "@/components/layout/MenuLayout";

export default {
  name: "TrainMenuLayout",
  components: {MenuLayout, Col10, PageMenu, Col2, Row},
  props: {
    'loading': {default: false},
    'title': {default: ''},
    'subtitle': {default: ''},
    'menu_items': {
      default: () => {
        return [
        {
          'name': 'Settings',
          'icon': 'fas fa-cog',
          'items': [
              {'name': 'Wallio'},
              {'name': 'Pieter pet'},
              {'name': 'Gekki'},
          ]
        }
      ]
      }
    },
  },
  methods: {
    select_item(item) {
      this.$emit('select_item', item);
    }
  }
}
</script>

<style scoped>

</style>