<template>
  <div>
    <row>
      <loader :loading="loading"></loader>
      <col12><checkbox :label="$t('settings_instances.checkbox')" :value="instances_enabled" @input="patch_setting"></checkbox></col12>
    </row>
  </div>

</template>

<script>
import Checkbox from "@/components/Checkbox";
import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
import Loader from "@/components/layout/loader";
export default {
  name: "InstanceSetting",
  data () {
    return {
      'loading': false
    }
  },
  mixins: [LoadSettingsMixin],
  components: {Loader, Col12, Row, Checkbox},
  methods: {
    async patch_setting(value) {
      this.$set(this, 'loading', true)
      await this.$store.dispatch('setting/patch_setting', {k:'instances_enabled', v: value});
      this.$set(this, 'loading', false)
    },
  },
  computed: {

  },
  mounted() {
  },
}
</script>

<style scoped>

</style>