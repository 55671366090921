<template>
    <div style="margin-left: 20px; margin-right: 20px;">
        <v-card elevation="10" style="margin-left: 10px; border-radius: 10px">
<!--            <v-card-title>{{title}}</v-card-title>-->
<!--            <v-card-text>{{description}}</v-card-text>-->
            <v-img :src="image"></v-img>
        </v-card>
      <div style="margin-top: 15px; margin-left: 25px; margin-right: 15px; ">
        {{description}}
      </div>

    </div>
</template>

<script>
export default {
name: "ExampleProject",
  props: {
    'image': {required: true},
    'description': {required: true},
    'title': {required: true}
  }
}
</script>

<style scoped>

</style>