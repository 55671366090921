<template>
    <v-btn :disabled="disabled"
           color="primary"
           class="mr-4" @click="pressed">
        {{label}}
    </v-btn>
</template>

<script>
  export default {
    name: "PrimaryButton",
    props: {
      label: {required: true},
      disabled: {default: false}
    },
    methods: {
      pressed () {
        this.$emit('pressed')
      }
    }
  }
</script>

<style scoped>

</style>