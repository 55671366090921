import base_getters from '@/store/modules/base/getters.js'

export default {
    ...base_getters,
    get_current_user(state) {
        let current_user_id = state.current_user_id

        if(!current_user_id) {
            return false;
        }

        let ids = state.items.map(user=>{return user.id});
        let index = ids.indexOf(current_user_id)
        return state.items[index];
    },

    get_current_project_id(state) {
        return state.project_id;
    }
}