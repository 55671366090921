import actions from '@/store/modules/task/actions.js'
import mutations from '@/store/modules/task/mutations.js'
import getters from '@/store/modules/task/getters.js'
import {base_state} from '@/store/modules/base/state.js'

const state = {
  ...base_state,
  'api_module': 'task'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}