export default class Label {
    id
    name
    type
    color
    parent_id

    constructor(id, name, type, color, parent_id) {
        this.id = id
        this.name = name
        this.type = type
        this.color = color
        this.parent_id = parent_id
    }
}