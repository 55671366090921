<template>

  <div class="locale-switcher" style="padding-top: 4px; ">
    <select
      v-model="$i18n.locale"
      style="
        cursor: pointer;
        color: #46423a;
        font-size: 1.3em;
        /* Add font-family here, e.g., font-family: 'Your Custom Font', sans-serif; */
      "
      @change="saveSelectedLanguage"
    >
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{ locale.name }}
      </option>
    </select>

  </div>

</template>

<script>

import { getSupportedLocales } from "@/util/i18n/supported-locales"

export default {

  data: () => ({ 
    locales: getSupportedLocales(),
    selectedLanguage: ""
  }),
  methods: {
    saveSelectedLanguage() {
      this.selectedLanguage = this.$i18n.locale;
      localStorage.setItem("chosenLanguage", this.selectedLanguage);
    },
  },
  mounted() {
    const storedLanguage = localStorage.getItem("chosenLanguage");
    if (storedLanguage) {
      this.$i18n.locale = storedLanguage;
    }
  },
}

</script>