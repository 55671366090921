<template>
    <div>
      <edit-label-set
        @reload="reload"
        @close_modal="edit_mode = false"
        :label_set="label_set" 
        v-if="edit_mode">
      </edit-label-set>

      <div>
        <h4 v-if="selectedLabelSet.length == 0">{{$t('models_and_train_configs.select_your_labelset')}}</h4>
        <h4 v-if="selectedLabelSet && selectedLabelSet.length > 0">Selected label set:</h4>
        <v-chip v-if="selectedLabelSet && selectedLabelSet.length > 0"
          close @click:close="() => unselect_label_set()">
          {{ selectedLabelSet[0].name }}
        </v-chip>
      </div>

      <div style="margin-top: 15px">
        <v-btn color="primary" @click.native="createNewLabelSet" id="load_button" class="button">
          {{$t('set_label_sets.button_create')}}
        </v-btn>
      </div>

      <v-text-field
        :label="$t('set_label_sets.search')"
        prepend-inner-icon="mdi-magnify"
        v-model="filter" />
      <v-data-table
        :loading="loading"
        :items="items"
        :server-items-length="totalItems"
        :headers="headers"
        :options.sync="options"
        :single-select="true"
        :show-select = "show_select_boxes"
        v-model="selectedLabelSet"
      >
        <template v-slot:item.name="{ item }" >
          <span @click="editExistingLabelSet(item)">{{ item.name }}</span>
        </template>
        <template v-slot:item.description="{ item }">
          <span @click="editExistingLabelSet(item)">{{ item.description }}</span>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span @click="editExistingLabelSet(item)">{{ item.created_at }}</span>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <span @click="editExistingLabelSet(item)">{{ item.updated_at }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
                  small
                  class="mr-2"
                  @click="deleteLabelSet(item)"
          >
            far fa-trash-alt
          </v-icon>
          <v-icon
                  small
                  class="mr-2"
                  @click="copyLabelSet(item)"
          >
            far fa-copy
          </v-icon>
          <v-icon
          small
          class="mr-2"
          @click="editExistingLabelSet(item)"
        >
          mdi-pencil
        </v-icon>
        </template>
      </v-data-table>
    </div>
</template>

<script>

import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
import EditLabelSet from "@/components/EditLabelSet.vue";

  export default {
    name: "Manage",
    components: {
      EditLabelSet,
    },
    props: {
      show_select_boxes: {
        type: Boolean,
        default: false,
      },
      selected_label_set_id: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        'api_model': 'label_set',
        edit_mode: false,
        label_set : null,
        selectedLabelSet: [],
        selectedLabelSetDetails: [],
        filterHasBeenSet: false,
      }
    },
    mixins: [ApiDatatableMixin],
    methods: {
      createNewLabelSet() {
        this.$set(this, 'label_set', null) 
        this.$set(this, 'edit_mode', true)
      },
      editExistingLabelSet(item) {
        this.$set(this, 'label_set', item) 
        this.$set(this, 'edit_mode', true)
      },
      async deleteLabelSet(item) {
        this.$set(this, 'loading', true);
        await this.$api.label_set.deleteLabelSet(item);
        await this.loadItems();
        this.$set(this, 'loading', false);
      },
      async reload() {
        await this.loadItems();
      },
      showLabelSetModal() {
        this.$set(this, 'edit_mode', true);
      },
      async copyLabelSet(item) {
        this.$set(this, 'loading', true);

        let originalLabelsResponse = await this.$api.label_set.getLabels(item);
        let originalLabelIds = originalLabelsResponse.map(label => label.id);

        let copiedItemName = item.name + ' Copy';
        let copiedLabelSet = { ...item, name: copiedItemName };
        let copiedLabelSetResponse = await this.$api.label_set.addLabelSet(copiedLabelSet.name, copiedLabelSet.description);
        
        await this.$api.label_set.updateLabels(copiedLabelSetResponse, originalLabelIds);
        await this.loadItems();
        this.$set(this, 'loading', false);
      },
      unselect_label_set() {
          this.$set(this, 'selectedLabelSet', [])
          this.$set(this, 'selected_label_set_id', null);
          this.$set(this, 'filter', '')
          this.$set(this, 'filterHasBeenSet', false);
      },
      async loadSelectedLabelSetDetails() {
        if (this.selected_label_set_id) {
          this.selectedLabelSetDetails = await this.$api.label_set.getLabelSet(this.selected_label_set_id); 
          this.$set(this, 'selectedLabelSet', [this.selectedLabelSetDetails]);
          if (!this.filterHasBeenSet) {
              this.$set(this, 'filter', this.selectedLabelSetDetails.name);
              this.$set(this, 'filterHasBeenSet', true);
          }
        }
      },
    },
    watch: {
      selectedLabelSet(newValue) {
        if (newValue && newValue.length > 0) {
            this.$emit("selected_label_set_id", newValue[0].id);
            if (!this.filterHasBeenSet) {
                this.filter = newValue[0].name;
                this.filterHasBeenSet = true;
            }
        } else {
            this.$emit("selected_label_set_id", null);
            this.filter = '';
            this.filterHasBeenSet = false;
        }
      },
      items(newItems) {
        if (this.selected_label_set_id && newItems.length > 0) {
          let selected_label_set = newItems.find(item => item.id === this.selected_label_set_id);
          if (selected_label_set) {
            this.selectedLabelSet = [selected_label_set];
          }
        }
      }
    },
    mounted() {
      this.loadSelectedLabelSetDetails();
    },
    computed: {
      headers(){
        return [
        {text: this.$t('set_label_sets.name'), value: 'name'},
        {text: this.$t('set_label_sets.description'), value: 'description'},
        {text: this.$t('set_label_sets.created_at'), value: 'created_at'},
        {text: this.$t('set_label_sets.updated_at'), value: 'updated_at'},
        {text: this.$t('set_label_sets.actions'), value: 'actions' },
        ]
      }
    },
  }
</script>

<style scoped>

</style>