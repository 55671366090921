<template>
  <menu-layout
    :title="$t('projects.title')"
    :subtitle="subtitle"
    :loading="loading"
    >
    <v-row>
      <v-card-text>
      <v-text-field id="filter_projects"
              v-model="filter" :label="$t('projects.filter')"></v-text-field>
      </v-card-text>
    </v-row>
    <v-row>
      <project-card
          style="cursor: pointer"
          v-for="project in filtered_projects"
          :key="project.id"
          :id="'project_'+project.id"
          :project="project"
          @click.native="select(project.id)"
      >
      </project-card>
      <project-add-card v-if="is_admin_somewhere">
      </project-add-card>
    </v-row>
  </menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";
import ProjectCard from "@/components/projects/ProjectCard";
import ProjectAddCard from "@/components/projects/ProjectAddCard";
import LoadProjectsMixin from "@/mixins/LoadProjectsMixin";
import CurrentUserMixin from "@/mixins/CurrentUserMixin";

export default {
  name: "Projects",
  data () {
    return {
      loading: true,
      filter: ''
    }
  },
  watch: {
    projects_loaded() {
      this.$set(this, 'loading', false)
    }
  },
  components: {
    ProjectCard,
    MenuLayout,
    ProjectAddCard
  },
  methods: {
    async check_2fa_setup_message() {
      let is2faMessageShown = sessionStorage.getItem('2fa_message_shown');
      if (is2faMessageShown) {
        return;
      } else {
        let response = await this.$api.user.user_2fa_enabled_profile();
        if (response && !response.authentication_enabled) {
          this.$store.dispatch('snackbars/add_persistent_snack', {
            message: this.$t('projects.2fa_not_enabled'),
            redirect: '/profile/authentication'
          });
          sessionStorage.setItem('2fa_message_shown', true);
        }
      }
    },
    select(project_id) {
      this.$store.commit('user/set_current_project_id', project_id);
      this.$router.push({ name: 'ProjectOverview' })
    },
  },
  computed: {
    subtitle() {
      if(this.projects.length > 1) {
        return this.$t('projects.subtitle_1');
      }
      else if(this.projects.length === 1) {
        return this.$t('projects.subtitle_2');
      }
      else {
        return this.$t('projects.subtitle_3');
      }
    },
    filtered_projects () {
      if(this.filter === '') {
        return this.projects
      }
      return this.projects.filter(project=>{return project.name.includes(this.filter)})
    },
  },
  mounted() {
    this.$store.commit('user/set_current_project_id', this.project_id)
    this.check_2fa_setup_message();
  },
  mixins: [LoadProjectsMixin, CurrentUserMixin]
}
</script>

<style scoped>

</style>