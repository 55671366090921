export default class Setting {
    id
    name
    value

    constructor(id, name, value) {
        this.id = id
        this.name = name
        this.value = value
    }
}