import vSelect from 'vue-select'

<template>
  <confirm-modal @close_modal="close" :title="'Confirm delete:' + ' ' + value" :loading="loading">
    <template v-slot:buttons>
      <v-btn :disabled="!can_delete"
              color="primary"
             class="mr-4" @click="confirmed">
        DELETE
      </v-btn>
    </template>
    <template v-slot:content>
      <row>
        <col12>
        Type project name to confirm delete:
        </col12>
        <col12>
          <v-text-field
                  v-model="name"
                  required
                  dense
          ></v-text-field>
        </col12>
      </row>
    </template>
  </confirm-modal>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal";
import Row from "@/components/layout/Row";
import Col12 from "@/components/layout/Col12";
export default {
  components: {Col12, Row, ConfirmModal},
  data () {
    return {
      'name': '',
    }
  },
  props: {
    'value': {required: true},
    'loading': {default: false},
    'title': {default: 'Edit annotations'}
  },
  computed: {
    can_delete() {
      return this.value === this.name;
    }
  },
  methods: {
    close() {
      this.$emit('close_modal')
    },
    confirmed() {
      this.$emit('confirmed')
    }
  },
}
</script>

<style scoped>
</style>