<template>
  <menu-layout>
    <row>
      Payment failed!   <br>
      <a href="/">Go back to home page</a> 
    </row>
  </menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";
import Row from "@/components/layout/Row";
import CurrentUserMixin from '@/mixins/CurrentUserMixin';


export default {
  name: "ErrorPage",
  data: function () {
    return {
    }
  },
  mixin: [CurrentUserMixin],
  mounted() {
      this.$store.commit('user/set_current_project_id', this.project_id)
    },
  computed: {
  },
  components: {
    Row,
    MenuLayout,
  },
  methods: {
  },
  
}
</script>

<style scoped>

</style>