<template>
  <menu-layout>
    <div class="row">
      <div class="col-md-8">
        <annotated-image
            :zoom_enabled="true"
            :image_url="asset_url"
            :annotations="annotations"
            @update_coords="updateAnnotation"
            @delete_annotation="deleteAnnotation"
            @select="selectAnnotation"
            @unselect="unselectAnnotation"
        ></annotated-image>
      </div>
      <div class="col-md-4">

        <div class="row">
          <div class="col-md-12">
          <annotate-labels @add_annotation="addAnnotation" :type="'box'"></annotate-labels>
          <annotate-labels @add_annotation="addAnnotation" :type="'point'"></annotate-labels>
          <annotate-label-groups @add_annotation="addAnnotation" :type="'group'"></annotate-label-groups>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <annotations-list
                @update_label="updateAnnotationLabel"
                @clear="clearAnnotations"
                @select="selectAnnotation"
                @unselect="unselectAnnotation" :annotations="annotations"></annotations-list>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
          </div>
        </div>

        <div v-if="original_category" style="margin-top: 10px">
          Original category: <br />
          {{original_category}}
        </div>

        <annotate-buttons
          :asset_id="asset_id"
          :annotations="annotations"
          :asset_id_history="asset_id_history"
        ></annotate-buttons>
      </div>
    </div>
  </menu-layout>
</template>

<script>
import AnnotatedImage from "@/components/AnnotatedImage";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import AnnotationsList from "@/components/AnnotationsList";
import AnnotateLabels from "@/components/AnnotateLabels";
import AnnotateButtons from "@/components/AnnotateButtons";
import AnnotateLabelGroups from "@/components/AnnotateLabelGroups";
import MenuLayout from "@/components/layout/MenuLayout";
export default {
  name: "Check",
  components: {MenuLayout, AnnotateLabelGroups, AnnotateButtons, AnnotateLabels, AnnotationsList, AnnotatedImage},
  mixins: [AnnotateMixin],
  data () {
    return {
      'canvas_id': 'custom_id',
      'find_meta_contains': '',
      'find_original_category': '',
      'original_category': '',
      'asset_id_history': [],
      'asset_url': false,
      'loading': false,
      'asset_id': null,
    }
  },
  mounted () {
    this.$set(this, 'asset_id', this.$route.params.asset_id)
    this.load();
    window.addEventListener('keydown', this.addEvents);
  },
  destroyed () {
    window.removeEventListener('keydown', this.addEvents);
  },
  methods: {
    addEvents: function(e) {
        if(e.keyCode === 13) { //enter
          e.stopPropagation();
          e.preventDefault();
          this.isCorrect();
        }
      },
  }
}
</script>

<style scoped>

</style>