<template>
  <div>
    <div v-for="(file) in files" :key="file.path">
      <a target="_blank" :href="file.path">{{file.name}}</a>
    </div>
  </div>
</template>

<script>
export default {
name: "ModelMeta",
  props: {
    'model': {required: true}
  },
  computed: {
    filenames () {
      if(this.decoded_meta.files) {
        let files = this.decoded_meta.files.map(file => {
          let parts = file.split('_')
          return parts[parts.length-1] + '?token=' + localStorage.token
        })
        return files
      }
      else {
        return []
      }
    },
    files () {
      if(this.decoded_meta && this.decoded_meta !== '' && this.decoded_meta.files) {
        let files = this.decoded_meta.files.map(file => {
          let parts = file.split('_')
          let parts_path = file.split('/')
          let project_id = this.$store.getters['user/get_current_project_id']

          return {
            'name': parts[parts.length-1],
            'path': '/api/protected/models/' + project_id + '/' + parts_path[parts_path.length-1] + '?token=' + localStorage.token
          }
        })
        return files
      }
      else {
        return []
      }
    },
    decoded_meta() {
      if(this.model.meta) {
        let meta = JSON.parse(this.model.meta)
        if(meta === '') {
          return {}
        }
        else {
          return meta
        }
      }
      return {}
    }
  }

}
</script>

<style scoped>

</style>