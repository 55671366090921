import actions from '@/store/modules/available-model-parameter/actions.js'
import mutations from '@/store/modules/available-model-parameter/mutations.js'
import getters from '@/store/modules/available-model-parameter/getters.js'
import {base_state} from '@/store/modules/base/state.js'

const state = {
  ...base_state,
  'api_module': 'available_model_parameter'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}