<template>
    <div >
        <div v-if="absolutes">
            <v-btn v-for="(value, index) in values"
                    label style="margin-left: 1px; width: 50px;"
                    :style="{height: '25px'}"
                    @click="on_click(index)"
                    :key="index"
                   elevation="0"
                    :color="color_value(percentages[index])">{{roundToOne(value)}}</v-btn>
        </div>
        <div v-else>
            <v-btn v-for="(value, index) in values"
                    label style="margin-left: 1px; width: 50px;"
                    :style="{height: '25px'}"
                    @click="on_click(index)"
                    :key="index"
                   elevation="0"
                    :color="color_value(value)">{{roundToOne(value)}}%</v-btn>
        </div>
    </div>
</template>

<script>
  export default {
    name: "BarChart",
    props: {
      "max_value": { required: true },
      "values": { required: true },
      "inverse_colors": {default: false},
      "absolutes": {default: false},
    },
    computed: {
      percentages () {
        return this.values.map(value=>{return value * 100});
      }
    },
    methods: {
      roundToOne(num) {
          return +(Math.round(num + "e+1")  + "e-1");
      },
      on_click(index) {
        this.$emit('clicked', index)
      },
      color_value (percentage) {
        if(this.inverse_colors) {
          if(percentage===0) {
            return 'white'
          }
          if(percentage<10) {
            return 'green'
          }
          if(percentage<40) {
            return 'orange'
          }
          return 'red'
        }
        else {
          if(percentage<50) {
            return 'red'
          }
          if(percentage<70) {
            return 'orange'
          }
          return 'green'
        }


      }
    }
  }
</script>

<style scoped>

</style>