var render = function render(){var _vm=this,_c=_vm._self._c;return _c('menu-layout',{attrs:{"loading":_vm.loading}},[_c('row',[_c('col2',[_c('page-menu',{attrs:{"name":_vm.$t('settings_menu.name'),"title":_vm.$t('settings_menu.title'),"items":[
                      {'name': _vm.$t('settings_menu.users') , 'route': '/settings/users'},
                      {'name': _vm.$t('settings_menu.Project'), 'route': '/settings/project'},
                      {'name': _vm.$t('settings_menu.Tasks'), 'route': '/settings/tasks'},
                      {'name': _vm.$t('settings_menu.Instances'), 'route': '/settings/instances'},
                      {'name': _vm.$t('settings_menu.Labels'), 'route': '/settings/labels'},
                      {'name': _vm.$t('settings_menu.Label_groups'), 'route': '/settings/label-groups'},
                      {'name': _vm.$t('settings_menu.Label_sets'), 'route' : '/settings/label-sets'},
                      {'name': _vm.$t('settings_menu.Train_configurations'), 'route': '/settings/train-configurations'},
                      {'name': _vm.$t('settings_menu.Jobs'), 'route': '/settings/jobs'}
                    ]}})],1),_c('col12',{staticStyle:{"max-width":"1000px"}},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }