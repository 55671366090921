<template>
    <div style="float: left;" :style="{minWidth: min_width}">
        <avataaars style="" v-if="this.selected_properties"
            :topType="this.selected_properties.top"
            :accessoriesType="this.selected_properties.accessoire"
            :facialHairType="this.selected_properties.hair"
            :clotheType="this.selected_properties.clothes"
            :eyeType="this.selected_properties.eyes"
            :eyebrowType="this.selected_properties.eye_brown"
            :mouthType="this.selected_properties.mouth"
            :skinColor="this.selected_properties.skin_color"
            :graphicType="this.selected_properties.graphic_type"
            :hairColor="this.selected_properties.hair_color"
            :facialHairColor="this.selected_properties.hair_color"
            :topColor="this.selected_properties.top_color"
            :clotheColor="this.selected_properties.clothes_color">
        </avataaars>
    </div>
</template>

<script>

import GravatarMixin from "@/components/users/GravatarMixin";
import Avataaars from "@/components/users/Avataars";

export default {
  props: {
    selected_properties: {required: true},
    min_width: {default: '40px' }
  },
  mixins: [GravatarMixin],
  components: {Avataaars},
}
</script>

<style scoped>

</style>