<template>
  <div>
    <div>
      {{train_config.repeat_after_days}}
    </div>
  </div>
</template>

<script>


export default {
  name: "TrainConfigRepeatAfterDaysCell",
  props: {
    train_config: Object,
  },
  components: {},
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>