<template>
  <settings-menu-layout>
    <user-list></user-list>
  </settings-menu-layout>
</template>

<script>
import UserList from "@/components/UserList"
import LoadSettingsMixin from "@/mixins/LoadSettingsMixin";
import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";

export default {
  name: "Settings",
  mixins: [LoadSettingsMixin],
  data() {
    return {
      'selected_page': 'labels',
      clipped: false,
      drawer: true,
      loading: false,
      items: [
        {
          action: 'local_activity',
          title: 'Attractions',
          path: '/',
          items: [],
        },
        {
          action: 'restaurant',
          title: 'Breakfast',
          path: '/breakfast',
          items: []
        },]
    }
  },
  methods: {
    set_loading(loading) {
      this.$set(this, 'loading', loading)
    },
    select_item(page) {
      this.$set(this, 'selected_page', page)
    },
  },
  components: {
    SettingsMenuLayout,
    UserList
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>