<script>
  export default {
    name: "ApiDatatableMixin",
    data() {
      return {
        'options': {},
        'items': [],
        'totalItems': 0,
        'loading': true,
        'filter': '',
      }
    },
    watch: {
      options() {
        this.loadItems();
      },
      filter() {
        this.loadItems();
      },
      api_model() {
        this.loadItems();
      }
    },
    methods: {
      async loadItems() {
        if(!this.api_model) {
          return;
        }
        this.$set(this, 'loading', true);
        let response = await this.$api[this.api_model].all(this.options, this.filter);
        this.$set(this, 'items', response.items);
        this.$set(this, 'totalItems', response.total);
        this.$set(this, 'loading', false);
      }
    },
  }
</script>

<style scoped>

</style>