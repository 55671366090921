<template>
     <menu-layout :loading="loading">
          <v-card style="max-width: 1000px" >
            <div>
              <v-tabs v-model="tab" show-arrows background-color="white" icons-and-text dark grow>
<!--                  <v-tabs-slider color="rgba(0, 0, 0, 0.6)"></v-tabs-slider>-->
                  <v-tab style="align-items: flex-start; margin-left: 11px;" v-for="(i, index) in tabs" :key="index">
                    <div class="d-flex align-center">
                      <v-icon color="rgba(0, 0, 0, 0.6)" large class="mr-2">{{ i.icon }}</v-icon>
                      <div style="color: rgba(0, 0, 0, 0.6)" class="py-1">{{ i.name }}</div>
                    </div>
                  </v-tab>
                  <v-tab-item>
                      <v-card class="px-4">
                          <v-card-text>
                              <v-form ref="loginForm" v-model="valid" lazy-validation>
                                <v-row>
                                  <v-col cols="12">
                                      <v-text-field id="email" v-model="userEmail" :rules="emailValidationMessage" :label="$t('login.email')" required></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                      <v-text-field id="password" v-on:keydown.enter="validate('loginForm')" v-model="loginPassword" :append-icon="show1?'eye':'eye-off'" :rules="[requiredRule, minRule(6)]" :type="show1 ? 'text' : 'password'" name="input-10-1" :label="$t('login.password')" :hint="this.$t('login.min_charact')" counter @click:append="show1 = !show1"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field v-if="show_otp" id="otp" v-model="otp" :label="this.$t('login.generated_otp')" required></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col class="d-flex" cols="3" sm="3" xsm="3">
                                      <v-btn large block :disabled="!valid" color="primary" @click="validate('loginForm')"> {{$t('login.login')}} </v-btn>
                                  </v-col>
                                  <v-spacer></v-spacer>
                                  <v-col @click="goToResetPassword" cols="12" sm="3" xsm="12" style="text-align: right">
                                      <span style="text-decoration: underline; cursor: pointer; text-align: right">{{$t('login.reset_password')}}</span>
                                  </v-col>
                                  
                                </v-row>
                              </v-form>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
                  <v-tab-item>
                      <v-card class="px-4">
                          <v-card-text>
                              <v-form ref="registerForm" v-model="valid" lazy-validation>
                                  <v-row v-if="!registerSuccess">
                                      <v-col cols="12" sm="6" md="6">
                                          <v-text-field v-model="firstName" :rules="[requiredRule]" :label="$t('login.first_name')" maxlength="20" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                          <v-text-field v-model="lastName" :rules="[requiredRule]" :label="$t('login.last_name')" maxlength="20" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="email" :rules="emailValidationMessage" :label="$t('login.email')" required></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[requiredRule, minRule(6)]" :type="show1 ? 'text' : 'password'" name="input-10-1" :label="$t('login.password')" :hint="$t('login.hint')" counter @click:append="show1 = !show1"></v-text-field>
                                      </v-col>
                                      <v-col cols="12">
                                          <v-text-field block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[requiredRule, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" :label="$t('login.confirm_password')" counter @click:append="show1 = !show1"></v-text-field>
                                      </v-col>
                                      <v-spacer></v-spacer>
                                      <v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
                                        <!-- Move the Register button here -->
                                        <v-btn large block :disabled="!valid" color="primary" @click="validate('registerForm')">{{$t('login.register_button')}}</v-btn>
                                      </v-col>
                                  </v-row>
                                  <v-row v-else>
                                    <v-col cols="12">
                                      <p><b>{{$t('login.email_sent')}} <v-icon large class="mr-2">mdi-email</v-icon></b></p>
                                      <p>{{$t('login.confirm_registration')}}</p>
                                      <p>{{$t('login.didnt_get_email')}}</p>
                                      <v-btn color="primary" @click="resendConformationEmail">{{$t('login.resend_email')}}</v-btn>
                                    </v-col>
                                  </v-row>
                              </v-form>
                          </v-card-text>
                      </v-card>
                  </v-tab-item>
              </v-tabs>
            </div>
          </v-card>
        <!-- </v-dialog> -->
     </menu-layout>
</template>

<script>
import MenuLayout from "@/components/layout/MenuLayout";

export default {
  name: 'Login',
  components: {
    MenuLayout,
  },
  mounted() {
    const tab = this.$route.query.tab;
    if (tab === "register") {
      this.tab = 1;
    }
  },  
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || this.$t('login.password_match');
    },
    tabLogin() {
      return this.$t('login.tab_login');
    },
    tabRegister() {
      return this.$t('login.tab_register');
    },
    tabs() {
      return [
        { name: this.tabLogin, icon: "mdi-account" },
        { name: this.tabRegister, icon: "mdi-account-outline" }
      ];
    },
    emailValidationMessage() {
      return [
        v => !!v || this.$t('login.required'),
        v => /.+@.+\..+/.test(v) || this.$t('login.valid_email')
      ];
    },
    requiredRule() {
      return v => !!v || this.$t('login.required');
    },
    minRule() {
      return min => v => (v && v.length >= min) || this.$t('login.min_charact');
    },
  },
  methods: {
    async validate(formType) {
      if (formType === 'loginForm') {
        this.handleLoginForm();
      } else if (formType === 'registerForm') {
        this.handleRegisterForm();
      }
    },
    async handleLoginForm() {
      if (this.$refs.loginForm.validate()) {
        let response = await this.$api.user.check_confirmation_status(this.userEmail);
        if (response.error) {
          await this.$store.dispatch('snackbars/add_failed_snack', response.error);
          this.$set(this, 'loading', false);
          return;
        }
        if (response && response.confirmed) {
          if (this.show_otp) {
            this.handleOtpLogin();
          }
          if (!this.userEmail) {
            await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.missing_email'));
            return;
          } else {
            let response = await this.$api.user.user_2fa_enabled_login(this.userEmail);
            if (response && response.authentication_enabled) {
              this.$set(this, 'show_otp', true);
              return;
            } else {
              let login_success = await this.$api.set_token(this.userEmail, this.loginPassword);
              if (login_success) {
                this.$router.push('/projects');
              } else {
                await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.invalid_creds'));
                this.$set(this, 'loading', false);
              }
            }
          }
        } else {
          await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.snack_failed_confirm'));
          this.$set(this, 'loading', false);
        }
      }
    },
    async handleOtpLogin() {
      if (!this.otp) {
        await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.otp_missing'))
        return
      }
      else {
        let response = await this.$api.user.verify_2fa_secret_key_email(this.otp, this.userEmail);
        if (response.status === 'success') {

          let login_success = await this.$api.set_token(this.userEmail, this.loginPassword);
          if(login_success) {
            this.$router.push('/projects')
          }
          else {
            await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.invalid_creds'))
            this.$set(this, 'loading', false)
          }
        } else {
          await this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.invalid_otp'))
          return
        }
      }
    },
    async handleRegisterForm() {
        if (this.$refs.registerForm.validate()) {
            this.$set(this, 'loading', true);
            let register_response = await this.$api.register(this.firstName, this.lastName, this.email, this.password)
                .then(response => {
                    if (response.success) {
                        this.$set(this, 'registerSuccess', true);
                        this.$store.dispatch('snackbars/add_success_snack', this.$t('login.check_email'));
                    } else if (response.error) {
                        if (response.error === 'User already exists') {
                            this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.email_already_exists'));
                        } else {
                            this.$store.dispatch('snackbars/add_failed_snack', this.$t('login.something_went_wrong'));
                        }
                    } else {
                        return this.$api.set_token(this.email, this.password)
                            .then(login_success => {
                                if (login_success) {
                                    return this.$api.project.all()
                                        .then(projects => {
                                            if (projects.length > 0) {
                                                let firstProjectId = projects[0].id;
                                                this.$router.push(`/project/${firstProjectId}`);
                                                this.$store.dispatch('snackbars/add_success_snack', {
                                                    'message': 'Registered successfully',
                                                });
                                            }
                                        });
                                }
                            });
                    }
                })
                .finally(() => {
                    this.$set(this, 'loading', false);
                });

            await register_response;
        }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async resendConformationEmail() {
      if (this.userEmail) {
        let response = await this.$api.resend_confirmation_email(this.email);
        if (response && response.success) {
          await this.$store.dispatch('snackbars/add_success_snack', this.$t('login.email_resend'));
        } else {
          await this.$store.dispatch('snackbars/add_failed_snack', response.error);
        }
      }
    },
    goToResetPassword() {
      this.$router.push('/reset-password')
    },
  },
  data: () => ({
    loading: false,
    dialog: true,
    tab: 0,
    valid: true,
    
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verify: "",
    loginPassword: "",
    userEmail: "",
    show1: false,
    show_otp: false,
    otp: '',
    registerSuccess: false,
  })
  
};
</script>

<style></style>