var render = function render(){var _vm=this,_c=_vm._self._c;return _c('menu-layout',{attrs:{"loading":_vm.loading}},[_c('row',[_c('col3',[_c('filter-tree',{attrs:{"initial_filter":[
              {'type':27, 'value':''},
              {'type':23, 'value':'Yes'},
              {'type':9, 'value':'No'},
              {'type':11, 'value':'No'},
              {'type':7, 'value':''}
             ],"max_results":_vm.max_results},on:{"update":_vm.update,"load":_vm.load}})],1),_c('col9',[_c('row',[_c('col12',[_c('asset-grid',{attrs:{"items":_vm.items},on:{"reload":_vm.load,"remove_item":_vm.removeGridItem,"update_selection_asset_ids":_vm.update_selection},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('row',[_c('col2',[(_vm.items.length > 0)?_c('dropdown-menu',{attrs:{"menu_items":_vm.menu_items}}):_vm._e()],1)],1)]},proxy:true}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }