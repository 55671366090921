<template>
  <modal @close_modal="close_modal" :title="$t('models_and_train_configs.confusion_matrix_title') + model.name" :loading="loading"
  >
    <template v-slot:content>
      <confusion-matrix style="margin-top: 10px;" :model_results="model_results"></confusion-matrix>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import ConfusionMatrix from "@/components/confusion_matrix/ConfusionMatrix";

export default {
  name: "ConfusionMatrixInModal",
  data () {
    return {
      'loading': false,
      'jobs': [],
      'model_results': [],
    }
  },
  components: {Modal, ConfusionMatrix},
  props: {
    model: {required: true}
  },
  mounted() {
    this.load_model()
  },
  methods: {
    async load_model() {
      let data = await this.$api.job.get_active_analyse_conf_matrix_job(this.model.id);
      if(data.length === 0) {
        let job = await this.$api.job.start_calculate_conf_matrix_job(this.model.id);
        job.model = this.model
        this.update_or_add_job(job)
      }
      else {
        let job = data[0]      
        if(job.processed === 1) {
          let response = await this.$api.conf_matrix_item.all(this.model.id);
          this.add_conf_matrix_items(this.model, response)
        }
        else {
          job.model = this.model
        }
        this.update_or_add_job(job)
      }
    },
    add_conf_matrix_items(model, response) {
      let result = {}
      result['model_id'] = model.id
      result['model'] = model

      let labels = Object.assign({}, ...response.labels.map((x) => ({[x.id]: x})));

      let result_per_label = {}
      for(let i=0; i<response.items.length; i++) {
        let item = response.items[i];
        let label = labels[item.label_id];
        result_per_label[label.name] = item
      }

      this.model_results.push(
        {
          'model_id': model.id,
          'model': model.name,
          'results': result_per_label
        }
      )

      this.$emit('model_results_updated', this.model_results)
    },
    update_or_add_job(job) {
        let job_ids = this.jobs.map(job=>{return job.id});
        let index = job_ids.indexOf(job.id);
        if(index === -1) {
          this.jobs.push(job);
        }
        else {
          this.$set(this.jobs, index, job)
        }
      },
    close_modal() {
      this.$emit('close_modal')
    },
  },
  computed: {
  }
}
</script>

<style scoped>

</style>