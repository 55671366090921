<script>
export default {
  name: "LoadUsersMixin",
  data() {
    return {
      users_loaded: false,
    }
  },
  mounted() {
    this.load_users();
  },
  computed: {
    users () {
      return this.$store.getters['user/get_items']
    },
  },
  methods: {
    async load_users() {
      if(this.on_unauthenticated_pages || this.on_project_page) {
        return;
      }
      await this.$store.dispatch('user/all')
      this.$set(this, 'users_loaded', true)
    },
  }
}
</script>

<style scoped>

</style>