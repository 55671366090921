<template>
  <modal @close_modal="close_modal" :title="$t('annotate.image_details')" :loading="loading"
  >
    <template v-slot:content>
      <div class="meta-grid">
        <div v-for="(value, key) in meta" :key="key" class="meta-item">
          <v-row>
            <v-col cols="2">
              <strong>{{ key }}:</strong>
            </v-col>
            <v-col cols="10">
              {{ value }}
            </v-col>
          </v-row>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  name: "MetaInModal",
  data () {
    return {
      'loading': false,
    }
  },
  methods: {
    close_modal() {
      this.$emit('close_modal')
    }
  },
  components: {Modal},
  props: {
    meta: {required: true},
  }
}
</script>

<style scoped>
.meta-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.meta-item {
  background: rgb(247, 245, 243);
  padding: 10px;
  border: 1px solid rgb(225, 223, 219);
}
</style>