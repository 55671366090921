<template>
    <v-card elevation="1" style="min-height:200px; height: 200px" :style="selected?'border: 3px solid #f7f5f3; background: #f7f5f3':''">
      <v-card-subtitle>
        {{image.label}} : {{image.certainty}} <checkbox v-if="!no_toggle" style="float:right" :value="checked" @input="toggleChecked"></checkbox>
      </v-card-subtitle>
      <v-card-subtitle v-if="validation">
        <span :style="{backgroundColor: background_color}">{{validation}}</span>
      </v-card-subtitle>
      <v-card-text>
        <div class="container">
          <img @click="selectImage(image.id, image.bbox_id)" style="min-height: 130px; cursor:pointer; max-height: 130px; max-width: 100%" :src="'data:image/png;base64, ' + image.data" />
          <div v-if="image.annotation_type === 2" class="centered white-font">+</div>
          <div v-if="image.annotation_type === 2" class="centered black-font">+</div>
        </div>
      </v-card-text>
    </v-card>
</template>

<script>
import Checkbox from '@/components/Checkbox'
export default {
  components: {Checkbox},
  props: {
    image: {required: true},
    validation: {required: true},
    selected: {default: false},
    checked: {default: false},
    no_toggle: {default: false}
  },
  methods: {
    toggleChecked() {
      this.$emit('select_toggled', this.image.bbox_id)
    },
    selectImage(assetId, bboxId) {
      this.$emit('select_item', assetId, bboxId)
    },
    remove(assetId, bboxId) {
      this.$emit('remove_item', assetId, bboxId)
    },
  },
  computed: {
    category () {
      if (this.image.translation) {
        return this.image.translation;
      }
      else {
        return this.image.label;
      }
    },
    background_color () {
        let red = "rgba(249,65,68)";
        let green = "rgba(144,190,109)"
        let orange = "rgba(248,150,30)"

        let validation = this.validation
        if(!validation) {
          return ''
        }
        
        if(validation.startsWith('Correct')) {
          return green;
        }
        else if(validation.startsWith('False negative')) {
          return orange;
        }
        else {
          return red;
        }
    }
  },
}
</script>

<style scoped>
  .container {
    position: relative;
    text-align: center;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .white-font {
    font-size: 35px;
    color: rgba(255, 255, 255, 0.8);
  }
  .black-font {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.6);
  }
</style>