<template>
  <row12>
    <row>
      <col10>
        <slot name="menu"></slot>
      </col10>
      <col2>
        <checkbox v-if="items.length > 0" v-model="toggleAllChecked" @toggled="toggleSelectAll" :label="$t('myassets.checkbox')"></checkbox>
      </col2>
    </row>
    <row>
      <annotate-modal v-if="showModal && modalAssetId"
                      @remove_item="removeItem"
                      @close_modal="closeModal"
                      :asset_id="modalAssetId"
      ></annotate-modal>
      <col3 style=""
           class="results col-md-3"
           v-for="item in items" :key="item.id"
           :class="{ selected_grid_item: item.id===selected_id }"
      >
        <asset-grid-item
            :checked="item_ids_selected.includes(item.id)"
            :item_id="item.id"
            :image_url="item.path"
            :annotations="item.annotations"
            :selected="item.id===selected_id"
            @select_toggled="selectToggled"
            @remove_item="removeItem"
            @select_item="selectGridItem" :validation="item.validation"
            @click="selectGridItem(item.id)"></asset-grid-item>
      </col3>
    </row>
  </row12>
</template>

<script>
import AssetGridItem from "@/components/AssetGridItem";
import AnnotateModal from "@/components/AnnotateModal";
import Row from "@/components/layout/Row";
import Col10 from "@/components/layout/Col10";
import Col2 from "@/components/layout/Col2";
import Checkbox from "@/components/Checkbox";
import Row12 from "@/components/layout/Row12";
import Col3 from "@/components/layout/Col3";

export default {
  props: {
    items: {required: true},
  },
  components: {
    Col3,
    Row12,
    Checkbox,
    Col2,
    Col10,
    Row,
    AnnotateModal,
    AssetGridItem
  },
  data() {
    return {
      selected_id: false,
      showModal: false,
      modalAssetId: false,
      modalDefaultOption: '',
      modalMeta: '',
      item_ids_selected: [],
      toggleAllChecked: false,
    }
  },
  computed: {
    selected_index() {
      return this.items.map(item=>{return item.id;}).indexOf(this.selected_id)
    }
  },
  watch: {
    items() {
      this.$set(this, 'item_ids_selected', [])
    },
    item_ids_selected() {
      this.$emit('update_selection_asset_ids', this.item_ids_selected);
    }
  },
  methods: {
    toggleSelectAll (value) {
      if(value) {
        let item_ids = this.items.map(image=>{return image.id});
        this.$set(this, 'item_ids_selected', item_ids)
      }
      else {
        this.$set(this, 'item_ids_selected', [])
      }
    },
    addEvents: function(e) {
        let activeElement = document.activeElement;
        let inputs = ['input', 'select', 'button', 'textarea'];

        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return false;
        }

       if(!this.showModal && e.keyCode === 32) { //space
          e.stopPropagation();
          e.preventDefault();

          let index = this.selected_index
          let asset_id = this.items[index].id
          this.selectGridItem(asset_id)
        }

        if(this.showModal) {
            return;
        }

        if(e.keyCode === 13) { //enter
          e.stopPropagation();
          e.preventDefault();
          this.$emit('reload')
        }

        if(e.keyCode === 39) { //right arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.id;} ).indexOf(this.selected_id);
          let newI = i + 1
          if(i === -1 || newI > this.items.length -1) {
            newI = 0
          }
          this.$set(this, 'selected_id', this.items[newI].id);
        }

        if(e.keyCode === 37) { //left arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.id;} ).indexOf(this.selected_id);
          let newI = i -1
          if(newI < 0) {
            newI = this.items.length -1 ;
          }
          this.$set(this, 'selected_id', this.items[newI].id);
        }

        if(e.keyCode === 38) { //Up arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.id;} ).indexOf(this.selected_id);
          let newI = i - 4
          if(newI < 0) {
            return;
          }
          this.$set(this, 'selected_id', this.items[newI].id);
        }

        if(e.keyCode === 40) { //Down arrow
          e.stopPropagation();
          e.preventDefault();
          let i = this.items.map( item => {return item.id;} ).indexOf(this.selected_id);
          let newI = i + 4
          if(newI > this.items.length - 1) {
            return;
          }
          this.$set(this, 'selected_id', this.items[newI].id);
        }
      },
    closeModal() {
      this.$set(this, 'modalAssetId', null);
      this.$set(this, 'showModal', false);
    },
    selectGridItem(asset_id) {
      if(!this.modalAssetId && !this.showModal) {
        this.$set(this, 'modalAssetId', asset_id);
        this.$set(this, 'showModal', true);
      }
    },
    removeItem(asset_id) {
      this.$emit('remove_item', asset_id)
    },
    selectToggled(item_id) {
      let index = this.item_ids_selected.indexOf(item_id)
      if(index === -1) {
        this.item_ids_selected.push(item_id)
      }
      else {
        this.item_ids_selected.splice(index, 1);
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', this.addEvents);
  },
  destroyed () {
    window.removeEventListener('keydown', this.addEvents);
  },
}
</script>

<style scoped>

</style>