<template>
  <div>
    <row>
        <col12>
            <v-card
                    elevation="2"
            >
                <v-card-text>
                    <gravatar-chooser @update="update_avatar"></gravatar-chooser>
                    <row>
                        <col12>
                        <v-btn
                                color="primary"
                                class="mr-4"
                                @click="save_avatar"
                        >
                            {{$t('myprofile.save')}}
                        </v-btn>
                        </col12>
                    </row>
                </v-card-text>
            </v-card>
        </col12>
    </row>
  </div>
    
</template>

<script>

  import Row from "@/components/layout/Row";
  import Col12 from "@/components/layout/Col12";
  import GravatarChooser from "@/components/users/GravatarChooser";

  export default {
    name: "ChooseAvatar.vue",
    components: {Row, Col12, GravatarChooser},
    mixins: [],
    data() {
      return {
        properties_encoded: false,
      }
    },
    mounted() {
    },
    methods: {
      async save_avatar() {
        this.$api.user.update_avatar(this.properties_encoded)
        this.$store.commit('user/set_current_user_avatar', this.properties_encoded);
      },
      async update_avatar(properties_encoded) {
        this.$set(this, 'properties_encoded', properties_encoded)
      },
    }
  }
</script>

<style scoped>

</style>