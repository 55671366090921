<template>
  <settings-menu-layout>
    <row>
      <col12>
      {{details}}
        <text-in-modal  v-if="details" :text="details" @close_modal="showDetails(null)"></text-in-modal>

        <v-text-field
          :label="$t('set_jobs.search')"
          prepend-inner-icon="mdi-magnify"
          v-model="filter" />
        <v-data-table
          :loading="loading"
          :items="items"
          :server-items-length="totalItems"
          :headers="headers"
          :options.sync="options"
        >
          <template v-slot:item.description="{ item }">
            <div @click="showDetails(item.description)">{{ item.description.substring(0, 30) }} ...</div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="delete_job(item)"
            >
              far fa-trash-alt
            </v-icon>
          </template>
        </v-data-table>
      </col12>
    </row>
  </settings-menu-layout>
</template>

<script>
  import SettingsMenuLayout from "@/components/layout/SettingsMenuLayout";
  import Col12 from "@/components/layout/Col12";
  import Row from "@/components/layout/Row";
  import ApiDatatableMixin from "@/mixins/ApiDatatableMixin";
  import TextInModal from "@/components/TextInModal.vue";

  export default {
    name: "SettingsLabelGroups",
    mixins: [ApiDatatableMixin],
    data () {
      return {
        'api_model': 'job',
        'details': null,
      }
    },
    methods: {
      showDetails(details) {
        this.$set(this, 'details', details)
      },
      async delete_job(item) {
        this.$set(this, 'loading', true);
        await this.$api.job.delete(item);
        await this.loadItems();
        this.$set(this, 'loading', false);
      }
    },
    computed: {
      headers(){
        return [
        {text: this.$t('set_jobs.task'), value: 'task'},
        {text: this.$t('set_jobs.description'), value: 'description'},
        {text: this.$t('set_jobs.created_at'), value: 'created_at'},
        {text: this.$t('set_jobs.progress'), value: 'progress'},
        {text: this.$t('set_jobs.processed'), value: 'processed'},
        {text: this.$t('set_jobs.actions'), value: 'actions', sortable: false},
        ]
      }
    },
    components: {
      TextInModal,
      Row,
      Col12,
      SettingsMenuLayout},
  }
</script>

<style scoped>

</style>