<template>
    <v-col
      cols="12"
      md="10"
      :lg="lg"
    >
    <slot></slot>
  </v-col>
</template>

<script>
export default {
  props: {
      lg: {
        default: 10
      }
  },
  name: "Col10"
}
</script>

<style scoped>

</style>