<template>
  <col4>
  <v-card class="elevation-4">
      <v-card-title>
          {{project.name}}
      </v-card-title>
      <v-card-subtitle style="min-height: 60px;">
          {{project.description}}
      </v-card-subtitle>
     <v-card-text>
      <row>
        <col6>
          <v-img v-if="favourite && favourite.id"
            :src="api_path + '/protected/asset/' + favourite.id + '?token=' + token"
            :lazy-src="static_path + '/examples/chital.png'"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </col6>
        <col6>
            <div v-for="user in users" :key="user.id" :title="user.first_name">
                <show-gravatar :user="user"></show-gravatar> 
            </div>
        </col6>
      </row>
     </v-card-text>
  </v-card>
    </col4>
</template>

<script>
import Col4 from "@/components/layout/Col4";
import Row from "@/components/layout/Row";
import Col6 from "@/components/layout/Col6";
import ShowGravatar from '@/components/projects/ShowGravatar';

export default {
name: "ProjectCard",
  components: {ShowGravatar, Col6, Row, Col4},
  props: {
    project: {required: true},
  },
  computed: {
    token () {
      return localStorage.token;
    },
    favourite () {
      return this.$store.getters['project/get_favourite'](this.project.id)
    },
    users () {
      return this.$store.getters['project/get_users'](this.project.id)
    },
    api_path () {
      return this.$api_host
    },
    static_path () {
      return this.$api_url
    }
  }
}
</script>

<style scoped>

</style>