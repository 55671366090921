export default class Label {
    email
    username
    first_name
    last_name
    password

    constructor(email, username, first_name, last_name, password) {
        this.email = email
        this.username = username
        this.first_name = first_name
        this.last_name = last_name
        this.password = password
    }
}