<template>
  <div v-if="meta" style="margin-top: 30px">
    <meta-in-modal v-if="showMeta" :meta="parsedMeta" @close_modal="handleShowMeta"></meta-in-modal>
    <div class="meta-grid">
      <div v-for="(value, key) in parsedMeta" :key="key" class="meta-item" style="cursor: pointer">
        <v-row @click="handleShowMeta">
          <v-col :cols="colsKey">
            <strong>{{ key }}:</strong>
          </v-col>
          <v-col :cols="colsValue">
            {{ formatMetaValue(value) }}
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>

import MetaInModal from "@/components/MetaInModal";

export default {
  name: "MetaGrid",
  components: {MetaInModal},
  props: {
    meta: {
      type: [Object, String],
      default: null
    },
    colsKey: { type: String, default: "4" },
    colsValue: { type: String, default: "8" }
  },
  data() {
    return {
      showMeta: false
    };
  },
  computed: {
    parsedMeta() {
      if (!this.meta) {
        return {};
      } else if (typeof this.meta === 'string') {
        return JSON.parse(this.meta);
      } else {
        return this.meta;
      }
    }
  },
  methods: {
    formatMetaValue(value) {
      const maxLength = 10;
      if (typeof value === 'string' && value.length > maxLength) {
        return value.substring(0, maxLength) + '...';
      }
      return value;
    },
    handleShowMeta() {
      this.showMeta = !this.showMeta;
    }
  }
};
</script>

<style scoped>
.meta-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}

.meta-item {
  background: rgb(247, 245, 243);
  padding: 10px;
  border: 1px solid rgb(225, 223, 219);
}
</style>
