<template>
  <div>
    <auto-menu-layout>
      <row>
        <col3>
          <filter-tree
                :initial_filter="[
                    {'type':11, 'value':'No'},
                    {'type':31, 'value':'Yes'}
                  ]"
                @update="update"
                @load="load"
                :max_results="max_results"
                ></filter-tree>
        </col3>
        <col9>
          <row>
            <v-btn v-if="assets.length > 0" @click="accept_all_suggestions_on_page" color="primary">{{$t('suggestions.accept_all_on_page')}}</v-btn>
            <col12 v-for="asset in assets" :key="asset.id">
              <asset-suggestions 
              :asset="asset"
              @remove_asset_from_list="remove_asset_from_list"
              ></asset-suggestions>
            </col12>
          </row>
        </col9>
      </row>
    </auto-menu-layout>
  </div>
</template>

<script>

import AutoMenuLayout from "@/components/layout/AutoMenuLayout";
const { v4: uuidV4 } = require('uuid');
import Annotation from "@/classes/annotation";
import AssetSuggestions from "@/components/AssetSuggestions";
import AnnotateMixin from "@/mixins/AnnotateMixin";
import FilterTree from "@/components/asset_filter/FilterTree";
import Row from "@/components/layout/Row";
import Col9 from "@/components/layout/Col9";
import Col3 from "@/components/layout/Col3";
import Col12 from "@/components/layout/Col12";

export default {
  name: "Suggestions",
  components: {AutoMenuLayout, AssetSuggestions, FilterTree, Row, Col9, Col3, Col12},
  mixins: [AnnotateMixin],
  data() {
    return {
      assets: [],
      items_suggestions: [],
      labels: '',
      setting_asset_accept_suggestions: null,
      max_results: false,
      filters: false,
      limit: false,
      loading: false,
    }
  },
  methods: {
    async accept_all_suggestions_on_page() {
      if (this.assets.length !== 0) {
        this.$set(this, 'loading', true)
        for (const asset of this.assets) {
          await this.$api.asset.accept_suggestions(asset.id, asset.annotation_suggestions.map(suggestion => suggestion.id));
        }
        this.load()
        this.$set(this, 'loading', false)
      }
    },
    remove_asset_from_list(asset_id) {
      const index = this.assets.findIndex((asset) => asset.id === asset_id);
      if (index !== -1) {
        this.loading = true;
        const newAssets = [...this.assets];
        newAssets.splice(index, 1);
        this.$set(this, 'assets', newAssets);
        this.loading = false;
      }
    },
    update(filters, limit) {
      this.$set(this, 'filters', filters)
      this.$set(this, 'limit', limit)
    },
    async load(filters, limit) {

      if(!filters) {
        filters = this.filters
        limit = this.limit
      }

      this.assets = [];
      this.$set(this, 'loading', true)
      let results = await this.$api.asset.filter(filters, limit)

        let total_results = results.total;
        let assets = results.items
        let max_results = results.max_results;
        this.$set(this, 'max_results', max_results);

        if (assets.length === 0) {
          this.$set(this, 'max_results', 0);
          this.$set(this, 'loading', false)
        }

        for (let i = 0; i < assets.length; i++) {

          let asset = assets[i]

          let asset_data = await this.$api.asset.load(asset.id);

          let annotation_suggestions = asset_data.annotation_suggestions

          let colors = asset_data.colors;
            let annotations = asset_data.annotations.map(annotation => {
              annotation.color_values = colors[i];
              if(annotation.color) {
                annotation.color_values = annotation.color.split(',')
              }
              annotation.type = annotation.type === 1 ? 'box' : 'point'
              return annotation
            })
            let path = this.$api_host + asset_data.path
            this.assets = this.assets.concat({checked: true, id: asset.id, annotations: annotations, path: path, annotation_suggestions: annotation_suggestions});
            if (this.assets.length === total_results) {
              this.$set(this,'loading',false);
            }
        }
    },
    change_current_annotations_to_suggestions() {
      this.annotation_suggestions.forEach(annotation_suggestion => {
        let suggestion = JSON.parse(annotation_suggestion.suggestion)
        if(suggestion.action === 'add_bounding_box') {
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'box', suggestion.coords, suggestion.confidence, suggestion.label, color);
          this.addAnnotationClearSelection(annotation)
        }
        else if(suggestion.action === 'add_keypoint') {
          let id = uuidV4();
          let color = [0,180,0]
          let annotation = new Annotation(id, 'point', suggestion.coords, suggestion.confidence, suggestion.label, color);
          this.addAnnotationClearSelection(annotation)
        }
        else if(suggestion.action === 'delete_annotation') {
          let annotations = this.annotations.filter(annotation => annotation.id !== annotation_suggestion.annotation_id)
          this.$set(this, 'annotations', annotations)
        }
        else if(suggestion.action === 'rename_label_annotation') {
          let suggested_label = suggestion.suggested_label
          let annotation_ids = this.annotations.map(annotation => annotation.id)
          let index = annotation_ids.indexOf(annotation_suggestion.annotation_id)
          this.annotations[index].label = suggested_label
        }
      });
    },
    async toggleAssetAcceptSuggestions() {
      // if you don't toggle you want [items] with original annotations
      // if you toggle you want [items] with suggestions
    },
    
    accept_all_suggestions() {
      console.log('this part should accept all suggestions')
    },
  mounted() {
    this.load();
    }
  }
}
</script>

<style scoped>

</style>