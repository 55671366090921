<template>
  <div>
    <autocomplete
            :label="'label'"
            :clear_on_select="true"
        @select="add_filter"
        :placeholder="$t('settings_tasks.edit_filter_tree_placeholder')"
        :options="options"></autocomplete>
  </div>
</template>

<script>
import Autocomplete from "@/components/Autocomplete";
export default {
  name: "AddFilter",
  components: {Autocomplete},
  props: {
    'initial_filter': {default: false}
  },
  data () {
    return {
      options: [],
      selected_option: false,
    }
  },
  methods: {
    add_filter(selected_option) {
      let filter = this.options.filter(option=>{return option.label === selected_option})
      if(filter.length === 0) {
        return
      }

      this.$emit('add_filter', filter[0])
      this.$set(this,'selected_option', false)
    },
    async load_filter_options() {
      let data = await this.$api.asset.filter_options();
      this.$set(this, 'options', data);
      if(this.initial_filter) {
        this.initial_filter.forEach(initial_filter=> {
          let related_filters = data.filter((f) => { return initial_filter.type == f.type });
          if(related_filters.length>0) {
            let to_add = JSON.parse(JSON.stringify(related_filters[0]))

            if(initial_filter.value) {
              to_add.value = initial_filter.value;
            }
            this.$emit('add_filter', to_add)
          }
        })
      }
    }
  },
  mounted() {
    this.load_filter_options()
  }
}
</script>

<style scoped>

</style>